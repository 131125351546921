import React, { memo, useLayoutEffect } from "react";

import clsx from "clsx";

import OldNavbar from "components/shared/Navbar";
import { useAppDispatch } from "hooks/app";
import { routeLoaded, routeLoading } from "app/store/route-slice";

import ProgressOverlay2 from "./ProgressOverlay2";

interface SuspenseOverlayProps {
    hideProgressOverlay?: boolean;
    hideNavbar?: boolean;
}

const SuspenseOverlay: React.FC<SuspenseOverlayProps> = memo(({ hideProgressOverlay = false, hideNavbar = true }) => {
    const dispatch = useAppDispatch();

    useLayoutEffect(() => {
        dispatch(routeLoading());
        return () => {
            setTimeout(() => {
                dispatch(routeLoaded());
            }, 200); // HACK to wait for the lazy loaded component to load
        };
    }, [dispatch]);

    return (
        <>
            <div
                id="suspense"
                className="absolute z-40 top-0 w-screen h-screen transition duration-200 ease-in-out blur-sm filter"
            >
                <div className={clsx("fixed", { hidden: hideNavbar })}>
                    <OldNavbar withSkeletonRoutes />
                </div>
            </div>
            <ProgressOverlay2 hideLoadingAnimation={hideProgressOverlay} />
        </>
    );
});

export default SuspenseOverlay;
