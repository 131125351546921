/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import { Action, AnyAction, combineReducers, Reducer } from "redux";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { type PersistConfig } from "redux-persist/lib/types";
import { configureStore, isPlain } from "@reduxjs/toolkit";
import EventEmitter from "eventemitter3";

import { emrApi } from "services/emr-service";
import { baseApi } from "services";
import { instagramApi } from "services/instagram-service";

import usersReducer from "./user-reducer";
import emrReducer from "./emr-reducer";
import appLocaleReducer from "./locale-reducer";
import routeReducer from "./route-slice";

const createReducer = (asyncReducers: Record<string, Reducer<any, Action>> = {}, dynamicBlacklist: string[] = []) => {
    const rootReducer = combineReducers({
        ...asyncReducers,
        users: usersReducer,
        emr: emrReducer,
        appLocale: appLocaleReducer,
        routes: routeReducer,
        [emrApi.reducerPath]: emrApi.reducer,
        [baseApi.reducerPath]: baseApi.reducer,
        [instagramApi.reducerPath]: instagramApi.reducer,
    });

    const defaultBlacklist: ReadonlyArray<string> = [
        emrApi.reducerPath,
        baseApi.reducerPath,
        instagramApi.reducerPath,
        "users",
        "appLocale",
        "routes",
    ];

    const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
        key: process.env.REACT_APP_PRODUCT_NAME,
        storage,
        version: 1,
        stateReconciler: autoMergeLevel2,
        debug: process.env.NODE_ENV === "development",
        // Recommended to blacklist API reducers. See https://redux-toolkit.js.org/usage/usage-guide
        blacklist: [...defaultBlacklist, ...dynamicBlacklist],
    };

    return persistReducer(persistConfig, rootReducer);
};

// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
const appStore = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                ignoredActionPaths: ["meta", "payload"],
                ignoredPaths: ["appLocale"],
                // See https://redux-toolkit.js.org/api/serializabilityMiddleware
                isSerializable: (value: any) => isPlain(value) || value instanceof Date,
            },
        }).concat(emrApi.middleware, baseApi.middleware, instagramApi.middleware),
    devTools: {
        name: `${process.env.REACT_APP_PRODUCT_NAME}-Redux-DevTools`,
    },
});

// https://redux.js.org/usage/code-splitting#reducer-injection-approaches
const asyncReducers: Parameters<typeof createReducer>[0] = {};
const asyncBlacklist: string[] = [];
const storeEvents = new EventEmitter<"root-reducer-change">();

/**
 * dynamically injects a reducer at runtime
 * @param key an identifier for this reducer
 * @param reducer The reducer
 * @param blacklist do not persist the reducer state
 */
const injectReducer = <RState>(key: string, reducer: Reducer<RState, AnyAction>, blacklist = false) => {
    if (!(key in asyncReducers)) {
        asyncReducers[key] = reducer;
        if (blacklist) {
            asyncBlacklist.push(key);
        }
        appStore.replaceReducer(createReducer(asyncReducers, asyncBlacklist));
        storeEvents.emit("root-reducer-change");
    }
};

export const store = { ...appStore, injectReducer };
export const persistor = persistStore(appStore);

storeEvents.on("root-reducer-change", () => persistor.persist());
