import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://graph.instagram.com";

// eslint-disable-next-line import/no-unused-modules
export interface MediaQueryParams {
    // https://developers.facebook.com/docs/instagram-basic-display-api/reference/media#fields
    fields: (
        | "caption"
        | "id"
        | "media_type"
        | "media_url"
        | "permalink"
        | "thumbnail_url"
        | "timestamp"
        | "username"
    )[];
    access_token: string;
    limit?: number;
    pretty?: boolean;
    since?: number;
    until?: number;
}

export const instagramApi = createApi({
    reducerPath: "instagramApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
    }),
    endpoints: (builder) => ({
        fetchInstagramMedia: builder.query({
            query: ({ pretty = false, ...params }: MediaQueryParams) => ({
                url: "/me/media",
                params: { ...params, pretty: pretty ? 1 : 0 },
            }),
        }),
    }),
});

export const { useFetchInstagramMediaQuery } = instagramApi;
