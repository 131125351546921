import { defineMessages, MessageDescriptor } from "react-intl";

const Provinces: Record<string, Record<string, MessageDescriptor>> = {
    AW: defineMessages({}),
    AF: defineMessages({
        KAP: { id: "provinces.AF.KAP", defaultMessage: "Kāpīsā" },
        KDZ: { id: "provinces.AF.KDZ", defaultMessage: "Kunduz" },
        KHO: { id: "provinces.AF.KHO", defaultMessage: "Khōst" },
        KNR: { id: "provinces.AF.KNR", defaultMessage: "Kunar" },
        LAG: { id: "provinces.AF.LAG", defaultMessage: "Laghmān" },
        LOG: { id: "provinces.AF.LOG", defaultMessage: "Lōgar" },
        BAL: { id: "provinces.AF.BAL", defaultMessage: "Balkh" },
        NAN: { id: "provinces.AF.NAN", defaultMessage: "Nangarhār" },
        BAM: { id: "provinces.AF.BAM", defaultMessage: "Bāmyān" },
        NIM: { id: "provinces.AF.NIM", defaultMessage: "Nīmrōz" },
        BDG: { id: "provinces.AF.BDG", defaultMessage: "Bādghīs" },
        NUR: { id: "provinces.AF.NUR", defaultMessage: "Nūristān" },
        BDS: { id: "provinces.AF.BDS", defaultMessage: "Badakhshān" },
        PAN: { id: "provinces.AF.PAN", defaultMessage: "Panjshayr" },
        BGL: { id: "provinces.AF.BGL", defaultMessage: "Baghlān" },
        PAR: { id: "provinces.AF.PAR", defaultMessage: "Parwān" },
        DAY: { id: "provinces.AF.DAY", defaultMessage: "Dāykundī" },
        PIA: { id: "provinces.AF.PIA", defaultMessage: "Paktiyā" },
        FRA: { id: "provinces.AF.FRA", defaultMessage: "Farāh" },
        PKA: { id: "provinces.AF.PKA", defaultMessage: "Paktīkā" },
        FYB: { id: "provinces.AF.FYB", defaultMessage: "Fāryāb" },
        SAM: { id: "provinces.AF.SAM", defaultMessage: "Samangān" },
        GHA: { id: "provinces.AF.GHA", defaultMessage: "Ghaznī" },
        SAR: { id: "provinces.AF.SAR", defaultMessage: "Sar-e Pul" },
        GHO: { id: "provinces.AF.GHO", defaultMessage: "Ghōr" },
        TAK: { id: "provinces.AF.TAK", defaultMessage: "Takhār" },
        HEL: { id: "provinces.AF.HEL", defaultMessage: "Helmand" },
        URU: { id: "provinces.AF.URU", defaultMessage: "Uruzgān" },
        HER: { id: "provinces.AF.HER", defaultMessage: "Herāt" },
        WAR: { id: "provinces.AF.WAR", defaultMessage: "Wardak" },
        JOW: { id: "provinces.AF.JOW", defaultMessage: "Jowzjān" },
        ZAB: { id: "provinces.AF.ZAB", defaultMessage: "Zābul" },
        KAB: { id: "provinces.AF.KAB", defaultMessage: "Kābul" },
        KAN: { id: "provinces.AF.KAN", defaultMessage: "Kandahār" },
    }),
    AO: defineMessages({
        HUA: { id: "provinces.AO.HUA", defaultMessage: "Huambo" },
        CAB: { id: "provinces.AO.CAB", defaultMessage: "Cabinda" },
        MOX: { id: "provinces.AO.MOX", defaultMessage: "Moxico" },
        HUI: { id: "provinces.AO.HUI", defaultMessage: "Huíla" },
        CCU: { id: "provinces.AO.CCU", defaultMessage: "Cuando-Cubango" },
        NAM: { id: "provinces.AO.NAM", defaultMessage: "Namibe" },
        LNO: { id: "provinces.AO.LNO", defaultMessage: "Lunda Norte" },
        CNN: { id: "provinces.AO.CNN", defaultMessage: "Cunene" },
        BGO: { id: "provinces.AO.BGO", defaultMessage: "Bengo" },
        UIG: { id: "provinces.AO.UIG", defaultMessage: "Uíge" },
        LSU: { id: "provinces.AO.LSU", defaultMessage: "Lunda Sul" },
        CNO: { id: "provinces.AO.CNO", defaultMessage: "Cuanza Norte" },
        BGU: { id: "provinces.AO.BGU", defaultMessage: "Benguela" },
        ZAI: { id: "provinces.AO.ZAI", defaultMessage: "Zaire" },
        LUA: { id: "provinces.AO.LUA", defaultMessage: "Luanda" },
        CUS: { id: "provinces.AO.CUS", defaultMessage: "Cuanza Sul" },
        BIE: { id: "provinces.AO.BIE", defaultMessage: "Bié" },
        MAL: { id: "provinces.AO.MAL", defaultMessage: "Malange" },
    }),
    AI: defineMessages({}),
    AX: defineMessages({}),
    AL: defineMessages({
        MT: { id: "provinces.AL.MT", defaultMessage: "Mat" },
        DL: { id: "provinces.AL.DL", defaultMessage: "Delvinë" },
        PG: { id: "provinces.AL.PG", defaultMessage: "Pogradec" },
        DR: { id: "provinces.AL.DR", defaultMessage: "Durrës" },
        PQ: { id: "provinces.AL.PQ", defaultMessage: "Peqin" },
        DV: { id: "provinces.AL.DV", defaultMessage: "Devoll" },
        PR: { id: "provinces.AL.PR", defaultMessage: "Përmet" },
        EL: { id: "provinces.AL.EL", defaultMessage: "Elbasan" },
        PU: { id: "provinces.AL.PU", defaultMessage: "Pukë" },
        ER: { id: "provinces.AL.ER", defaultMessage: "Kolonjë" },
        SH: { id: "provinces.AL.SH", defaultMessage: "Shkodër" },
        FR: { id: "provinces.AL.FR", defaultMessage: "Fier" },
        "01": { id: "provinces.AL.01", defaultMessage: "Berat" },
        SK: { id: "provinces.AL.SK", defaultMessage: "Skrapar" },
        GJ: { id: "provinces.AL.GJ", defaultMessage: "Gjirokastër" },
        "02": { id: "provinces.AL.02", defaultMessage: "Durrës" },
        SR: { id: "provinces.AL.SR", defaultMessage: "Sarandë" },
        GR: { id: "provinces.AL.GR", defaultMessage: "Gramsh" },
        "03": { id: "provinces.AL.03", defaultMessage: "Elbasan" },
        TE: { id: "provinces.AL.TE", defaultMessage: "Tepelenë" },
        HA: { id: "provinces.AL.HA", defaultMessage: "Has" },
        "04": { id: "provinces.AL.04", defaultMessage: "Fier" },
        TP: { id: "provinces.AL.TP", defaultMessage: "Tropojë" },
        KA: { id: "provinces.AL.KA", defaultMessage: "Kavajë" },
        "05": { id: "provinces.AL.05", defaultMessage: "Gjirokastër" },
        TR: { id: "provinces.AL.TR", defaultMessage: "Tiranë" },
        KB: { id: "provinces.AL.KB", defaultMessage: "Kurbin" },
        "06": { id: "provinces.AL.06", defaultMessage: "Korçë" },
        VL: { id: "provinces.AL.VL", defaultMessage: "Vlorë" },
        KC: { id: "provinces.AL.KC", defaultMessage: "Kuçovë" },
        "07": { id: "provinces.AL.07", defaultMessage: "Kukës" },
        KO: { id: "provinces.AL.KO", defaultMessage: "Korçë" },
        "08": { id: "provinces.AL.08", defaultMessage: "Lezhë" },
        KR: { id: "provinces.AL.KR", defaultMessage: "Krujë" },
        "09": { id: "provinces.AL.09", defaultMessage: "Dibër" },
        KU: { id: "provinces.AL.KU", defaultMessage: "Kukës" },
        10: { id: "provinces.AL.10", defaultMessage: "Shkodër" },
        LB: { id: "provinces.AL.LB", defaultMessage: "Librazhd" },
        11: { id: "provinces.AL.11", defaultMessage: "Tiranë" },
        LE: { id: "provinces.AL.LE", defaultMessage: "Lezhë" },
        12: { id: "provinces.AL.12", defaultMessage: "Vlorë" },
        LU: { id: "provinces.AL.LU", defaultMessage: "Lushnjë" },
        BR: { id: "provinces.AL.BR", defaultMessage: "Berat" },
        MK: { id: "provinces.AL.MK", defaultMessage: "Mallakastër" },
        BU: { id: "provinces.AL.BU", defaultMessage: "Bulqizë" },
        MM: { id: "provinces.AL.MM", defaultMessage: "Malësi e Madhe" },
        DI: { id: "provinces.AL.DI", defaultMessage: "Dibër" },
        MR: { id: "provinces.AL.MR", defaultMessage: "Mirditë" },
    }),
    AD: defineMessages({
        "04": { id: "provinces.AD.04", defaultMessage: "La Massana" },
        "05": { id: "provinces.AD.05", defaultMessage: "Ordino" },
        "06": { id: "provinces.AD.06", defaultMessage: "Sant Julià de Lòria" },
        "07": { id: "provinces.AD.07", defaultMessage: "Andorra la Vella" },
        "08": { id: "provinces.AD.08", defaultMessage: "Escaldes-Engordany" },
        "02": { id: "provinces.AD.02", defaultMessage: "Canillo" },
        "03": { id: "provinces.AD.03", defaultMessage: "Encamp" },
    }),
    AE: defineMessages({
        UQ: { id: "provinces.AE.UQ", defaultMessage: "Umm al Qaywayn" },
        AZ: { id: "provinces.AE.AZ", defaultMessage: "Abū Ȥaby [Abu Dhabi]" },
        DU: { id: "provinces.AE.DU", defaultMessage: "Dubayy" },
        FU: { id: "provinces.AE.FU", defaultMessage: "Al Fujayrah" },
        RK: { id: "provinces.AE.RK", defaultMessage: "Ra’s al Khaymah" },
        SH: { id: "provinces.AE.SH", defaultMessage: "Ash Shāriqah" },
        AJ: { id: "provinces.AE.AJ", defaultMessage: "'Ajmān" },
    }),
    AR: defineMessages({
        B: { id: "provinces.AR.B", defaultMessage: "Buenos Aires" },
        Z: { id: "provinces.AR.Z", defaultMessage: "Santa Cruz" },
        C: { id: "provinces.AR.C", defaultMessage: "Ciudad Autónoma de Buenos Aires" },
        D: { id: "provinces.AR.D", defaultMessage: "San Luis" },
        E: { id: "provinces.AR.E", defaultMessage: "Entre Rios" },
        G: { id: "provinces.AR.G", defaultMessage: "Santiago del Estero" },
        H: { id: "provinces.AR.H", defaultMessage: "Chaco" },
        J: { id: "provinces.AR.J", defaultMessage: "San Juan" },
        K: { id: "provinces.AR.K", defaultMessage: "Catamarca" },
        L: { id: "provinces.AR.L", defaultMessage: "La Pampa" },
        M: { id: "provinces.AR.M", defaultMessage: "Mendoza" },
        N: { id: "provinces.AR.N", defaultMessage: "Misiones" },
        P: { id: "provinces.AR.P", defaultMessage: "Formosa" },
        Q: { id: "provinces.AR.Q", defaultMessage: "Neuquen" },
        R: { id: "provinces.AR.R", defaultMessage: "Rio Negro" },
        S: { id: "provinces.AR.S", defaultMessage: "Santa Fe" },
        T: { id: "provinces.AR.T", defaultMessage: "Tucuman" },
        U: { id: "provinces.AR.U", defaultMessage: "Chubut" },
        V: { id: "provinces.AR.V", defaultMessage: "Tierra del Fuego" },
        W: { id: "provinces.AR.W", defaultMessage: "Corrientes" },
        X: { id: "provinces.AR.X", defaultMessage: "Cordoba" },
        Y: { id: "provinces.AR.Y", defaultMessage: "Jujuy" },
        A: { id: "provinces.AR.A", defaultMessage: "Salta" },
    }),
    AM: defineMessages({
        GR: { id: "provinces.AM.GR", defaultMessage: "Gegarkunik'" },
        TV: { id: "provinces.AM.TV", defaultMessage: "Tavus" },
        KT: { id: "provinces.AM.KT", defaultMessage: "Kotayk'" },
        AG: { id: "provinces.AM.AG", defaultMessage: "Aragacotn" },
        VD: { id: "provinces.AM.VD", defaultMessage: "Vayoc Jor" },
        LO: { id: "provinces.AM.LO", defaultMessage: "Lory" },
        AR: { id: "provinces.AM.AR", defaultMessage: "Ararat" },
        SH: { id: "provinces.AM.SH", defaultMessage: "Sirak" },
        AV: { id: "provinces.AM.AV", defaultMessage: "Armavir" },
        SU: { id: "provinces.AM.SU", defaultMessage: "Syunik'" },
        ER: { id: "provinces.AM.ER", defaultMessage: "Erevan" },
    }),
    AS: defineMessages({}),
    AQ: defineMessages({}),
    TF: defineMessages({}),
    AG: defineMessages({
        11: { id: "provinces.AG.11", defaultMessage: "Redonda" },
        "05": { id: "provinces.AG.05", defaultMessage: "Saint Mary" },
        "06": { id: "provinces.AG.06", defaultMessage: "Saint Paul" },
        "07": { id: "provinces.AG.07", defaultMessage: "Saint Peter" },
        "08": { id: "provinces.AG.08", defaultMessage: "Saint Philip" },
        "03": { id: "provinces.AG.03", defaultMessage: "Saint George" },
        10: { id: "provinces.AG.10", defaultMessage: "Barbuda" },
        "04": { id: "provinces.AG.04", defaultMessage: "Saint John" },
    }),
    AU: defineMessages({
        ACT: { id: "provinces.AU.ACT", defaultMessage: "Australian Capital Territory" },
        VIC: { id: "provinces.AU.VIC", defaultMessage: "Victoria" },
        NSW: { id: "provinces.AU.NSW", defaultMessage: "New South Wales" },
        WA: { id: "provinces.AU.WA", defaultMessage: "Western Australia" },
        NT: { id: "provinces.AU.NT", defaultMessage: "Northern Territory" },
        QLD: { id: "provinces.AU.QLD", defaultMessage: "Queensland" },
        SA: { id: "provinces.AU.SA", defaultMessage: "South Australia" },
        TAS: { id: "provinces.AU.TAS", defaultMessage: "Tasmania" },
    }),
    AT: defineMessages({
        5: { id: "provinces.AT.5", defaultMessage: "Salzburg" },
        6: { id: "provinces.AT.6", defaultMessage: "Steiermark" },
        1: { id: "provinces.AT.1", defaultMessage: "Burgenland" },
        7: { id: "provinces.AT.7", defaultMessage: "Tirol" },
        2: { id: "provinces.AT.2", defaultMessage: "Kärnten" },
        8: { id: "provinces.AT.8", defaultMessage: "Vorarlberg" },
        3: { id: "provinces.AT.3", defaultMessage: "Niederösterreich" },
        9: { id: "provinces.AT.9", defaultMessage: "Wien" },
        4: { id: "provinces.AT.4", defaultMessage: "Oberösterreich" },
    }),
    AZ: defineMessages({
        IMI: { id: "provinces.AZ.IMI", defaultMessage: "İmişli" },
        ISM: { id: "provinces.AZ.ISM", defaultMessage: "İsmayıllı" },
        KAL: { id: "provinces.AZ.KAL", defaultMessage: "Kəlbəcər" },
        KAN: { id: "provinces.AZ.KAN", defaultMessage: "Kǝngǝrli" },
        KUR: { id: "provinces.AZ.KUR", defaultMessage: "Kürdəmir" },
        LA: { id: "provinces.AZ.LA", defaultMessage: "Lənkəran" },
        LAC: { id: "provinces.AZ.LAC", defaultMessage: "Laçın" },
        LAN: { id: "provinces.AZ.LAN", defaultMessage: "Lənkəran" },
        LER: { id: "provinces.AZ.LER", defaultMessage: "Lerik" },
        MAS: { id: "provinces.AZ.MAS", defaultMessage: "Masallı" },
        MI: { id: "provinces.AZ.MI", defaultMessage: "Mingəçevir" },
        NA: { id: "provinces.AZ.NA", defaultMessage: "Naftalan" },
        NEF: { id: "provinces.AZ.NEF", defaultMessage: "Neftçala" },
        NV: { id: "provinces.AZ.NV", defaultMessage: "Naxçıvan" },
        NX: { id: "provinces.AZ.NX", defaultMessage: "Naxçıvan" },
        OGU: { id: "provinces.AZ.OGU", defaultMessage: "Oğuz" },
        ORD: { id: "provinces.AZ.ORD", defaultMessage: "Ordubad" },
        QAB: { id: "provinces.AZ.QAB", defaultMessage: "Qəbələ" },
        ABS: { id: "provinces.AZ.ABS", defaultMessage: "Abşeron" },
        QAX: { id: "provinces.AZ.QAX", defaultMessage: "Qax" },
        AGA: { id: "provinces.AZ.AGA", defaultMessage: "Ağstafa" },
        QAZ: { id: "provinces.AZ.QAZ", defaultMessage: "Qazax" },
        QBA: { id: "provinces.AZ.QBA", defaultMessage: "Quba" },
        AGC: { id: "provinces.AZ.AGC", defaultMessage: "Ağcabədi" },
        AGM: { id: "provinces.AZ.AGM", defaultMessage: "Ağdam" },
        QBI: { id: "provinces.AZ.QBI", defaultMessage: "Qubadlı" },
        AGS: { id: "provinces.AZ.AGS", defaultMessage: "Ağdaş" },
        QOB: { id: "provinces.AZ.QOB", defaultMessage: "Qobustan" },
        AGU: { id: "provinces.AZ.AGU", defaultMessage: "Ağsu" },
        QUS: { id: "provinces.AZ.QUS", defaultMessage: "Qusar" },
        AST: { id: "provinces.AZ.AST", defaultMessage: "Astara" },
        SA: { id: "provinces.AZ.SA", defaultMessage: "Şəki" },
        BA: { id: "provinces.AZ.BA", defaultMessage: "Bakı" },
        SAB: { id: "provinces.AZ.SAB", defaultMessage: "Sabirabad" },
        BAB: { id: "provinces.AZ.BAB", defaultMessage: "Babək" },
        SAD: { id: "provinces.AZ.SAD", defaultMessage: "Sədərək" },
        BAL: { id: "provinces.AZ.BAL", defaultMessage: "Balakən" },
        SAH: { id: "provinces.AZ.SAH", defaultMessage: "Şahbuz" },
        BAR: { id: "provinces.AZ.BAR", defaultMessage: "Bərdə" },
        SAK: { id: "provinces.AZ.SAK", defaultMessage: "Şəki" },
        BEY: { id: "provinces.AZ.BEY", defaultMessage: "Beyləqan" },
        SAL: { id: "provinces.AZ.SAL", defaultMessage: "Salyan" },
        BIL: { id: "provinces.AZ.BIL", defaultMessage: "Biləsuvar" },
        SAR: { id: "provinces.AZ.SAR", defaultMessage: "Şərur" },
        CAB: { id: "provinces.AZ.CAB", defaultMessage: "Cəbrayıl" },
        SAT: { id: "provinces.AZ.SAT", defaultMessage: "Saatlı" },
        CAL: { id: "provinces.AZ.CAL", defaultMessage: "Cəlilabab" },
        SBN: { id: "provinces.AZ.SBN", defaultMessage: "Şabran" },
        CUL: { id: "provinces.AZ.CUL", defaultMessage: "Culfa" },
        SIY: { id: "provinces.AZ.SIY", defaultMessage: "Siyəzən" },
        DAS: { id: "provinces.AZ.DAS", defaultMessage: "Daşkəsən" },
        SKR: { id: "provinces.AZ.SKR", defaultMessage: "Şəmkir" },
        FUZ: { id: "provinces.AZ.FUZ", defaultMessage: "Füzuli" },
        SM: { id: "provinces.AZ.SM", defaultMessage: "Sumqayıt" },
        GA: { id: "provinces.AZ.GA", defaultMessage: "Gəncə" },
        SMI: { id: "provinces.AZ.SMI", defaultMessage: "Şamaxı" },
        GAD: { id: "provinces.AZ.GAD", defaultMessage: "Gədəbəy" },
        SMX: { id: "provinces.AZ.SMX", defaultMessage: "Samux" },
        GOR: { id: "provinces.AZ.GOR", defaultMessage: "Goranboy" },
        SR: { id: "provinces.AZ.SR", defaultMessage: "Şirvan" },
        GOY: { id: "provinces.AZ.GOY", defaultMessage: "Göyçay" },
        SUS: { id: "provinces.AZ.SUS", defaultMessage: "Şuşa" },
        GYG: { id: "provinces.AZ.GYG", defaultMessage: "Göygöl" },
        TAR: { id: "provinces.AZ.TAR", defaultMessage: "Tərtər" },
        TOV: { id: "provinces.AZ.TOV", defaultMessage: "Tovuz" },
        HAC: { id: "provinces.AZ.HAC", defaultMessage: "Hacıqabul" },
        UCA: { id: "provinces.AZ.UCA", defaultMessage: "Ucar" },
        XA: { id: "provinces.AZ.XA", defaultMessage: "Xankəndi" },
        XAC: { id: "provinces.AZ.XAC", defaultMessage: "Xaçmaz" },
        XCI: { id: "provinces.AZ.XCI", defaultMessage: "Xocalı" },
        XIZ: { id: "provinces.AZ.XIZ", defaultMessage: "Xızı" },
        XVD: { id: "provinces.AZ.XVD", defaultMessage: "Xocavənd" },
        YAR: { id: "provinces.AZ.YAR", defaultMessage: "Yardımlı" },
        YE: { id: "provinces.AZ.YE", defaultMessage: "Yevlax" },
        YEV: { id: "provinces.AZ.YEV", defaultMessage: "Yevlax" },
        ZAN: { id: "provinces.AZ.ZAN", defaultMessage: "Zəngilan" },
        ZAQ: { id: "provinces.AZ.ZAQ", defaultMessage: "Zaqatala" },
        ZAR: { id: "provinces.AZ.ZAR", defaultMessage: "Zərdab" },
    }),
    BI: defineMessages({
        KY: { id: "provinces.BI.KY", defaultMessage: "Kayanza" },
        CA: { id: "provinces.BI.CA", defaultMessage: "Cankuzo" },
        RY: { id: "provinces.BI.RY", defaultMessage: "Ruyigi" },
        MA: { id: "provinces.BI.MA", defaultMessage: "Makamba" },
        CI: { id: "provinces.BI.CI", defaultMessage: "Cibitoke" },
        BB: { id: "provinces.BI.BB", defaultMessage: "Bubanza" },
        MU: { id: "provinces.BI.MU", defaultMessage: "Muramvya" },
        GI: { id: "provinces.BI.GI", defaultMessage: "Gitega" },
        BL: { id: "provinces.BI.BL", defaultMessage: "Bujumbura Rural" },
        MW: { id: "provinces.BI.MW", defaultMessage: "Mwaro" },
        KI: { id: "provinces.BI.KI", defaultMessage: "Kirundo" },
        BM: { id: "provinces.BI.BM", defaultMessage: "Bujumbura Mairie" },
        NG: { id: "provinces.BI.NG", defaultMessage: "Ngozi" },
        KR: { id: "provinces.BI.KR", defaultMessage: "Karuzi" },
        BR: { id: "provinces.BI.BR", defaultMessage: "Bururi" },
        RT: { id: "provinces.BI.RT", defaultMessage: "Rutana" },
    }),
    BE: defineMessages({
        VLG: { id: "provinces.BE.VLG", defaultMessage: "Vlaams Gewest" },
        WHT: { id: "provinces.BE.WHT", defaultMessage: "Hainaut" },
        VLI: { id: "provinces.BE.VLI", defaultMessage: "Limburg" },
        WLG: { id: "provinces.BE.WLG", defaultMessage: "Liège" },
        VOV: { id: "provinces.BE.VOV", defaultMessage: "Oost-Vlaanderen" },
        BRU: { id: "provinces.BE.BRU", defaultMessage: "Bruxelles-Capitale, Région de;Brussels Hoofdstedelijk Gewest" },
        WLX: { id: "provinces.BE.WLX", defaultMessage: "Luxembourg" },
        VWV: { id: "provinces.BE.VWV", defaultMessage: "West-Vlaanderen" },
        VAN: { id: "provinces.BE.VAN", defaultMessage: "Antwerpen" },
        WNA: { id: "provinces.BE.WNA", defaultMessage: "Namur" },
        WAL: { id: "provinces.BE.WAL", defaultMessage: "wallonne, Région" },
        VBR: { id: "provinces.BE.VBR", defaultMessage: "Vlaams-Brabant" },
        WBR: { id: "provinces.BE.WBR", defaultMessage: "Brabant wallon" },
    }),
    BJ: defineMessages({
        OU: { id: "provinces.BJ.OU", defaultMessage: "Ouémé" },
        CO: { id: "provinces.BJ.CO", defaultMessage: "Collines" },
        PL: { id: "provinces.BJ.PL", defaultMessage: "Plateau" },
        DO: { id: "provinces.BJ.DO", defaultMessage: "Donga" },
        AK: { id: "provinces.BJ.AK", defaultMessage: "Atakora" },
        ZO: { id: "provinces.BJ.ZO", defaultMessage: "Zou" },
        KO: { id: "provinces.BJ.KO", defaultMessage: "Kouffo" },
        AL: { id: "provinces.BJ.AL", defaultMessage: "Alibori" },
        LI: { id: "provinces.BJ.LI", defaultMessage: "Littoral" },
        AQ: { id: "provinces.BJ.AQ", defaultMessage: "Atlantique" },
        MO: { id: "provinces.BJ.MO", defaultMessage: "Mono" },
        BO: { id: "provinces.BJ.BO", defaultMessage: "Borgou" },
    }),
    BQ: defineMessages({
        SE: { id: "provinces.BQ.SE", defaultMessage: "Sint Eustatius" },
        SA: { id: "provinces.BQ.SA", defaultMessage: "Saba" },
        BO: { id: "provinces.BQ.BO", defaultMessage: "Bonaire" },
    }),
    BF: defineMessages({
        KEN: { id: "provinces.BF.KEN", defaultMessage: "Kénédougou" },
        "07": { id: "provinces.BF.07", defaultMessage: "Centre-Sud" },
        SNG: { id: "provinces.BF.SNG", defaultMessage: "Sanguié" },
        KMD: { id: "provinces.BF.KMD", defaultMessage: "Komondjari" },
        "08": { id: "provinces.BF.08", defaultMessage: "Est" },
        SOM: { id: "provinces.BF.SOM", defaultMessage: "Soum" },
        KMP: { id: "provinces.BF.KMP", defaultMessage: "Kompienga" },
        "09": { id: "provinces.BF.09", defaultMessage: "Hauts-Bassins" },
        SOR: { id: "provinces.BF.SOR", defaultMessage: "Sourou" },
        KOP: { id: "provinces.BF.KOP", defaultMessage: "Koulpélogo" },
        10: { id: "provinces.BF.10", defaultMessage: "Nord" },
        TAP: { id: "provinces.BF.TAP", defaultMessage: "Tapoa" },
        KOS: { id: "provinces.BF.KOS", defaultMessage: "Kossi" },
        SMT: { id: "provinces.BF.SMT", defaultMessage: "Sanmatenga" },
        11: { id: "provinces.BF.11", defaultMessage: "Plateau-Central" },
        TUI: { id: "provinces.BF.TUI", defaultMessage: "Tui" },
        KOT: { id: "provinces.BF.KOT", defaultMessage: "Kouritenga" },
        12: { id: "provinces.BF.12", defaultMessage: "Sahel" },
        YAG: { id: "provinces.BF.YAG", defaultMessage: "Yagha" },
        KOW: { id: "provinces.BF.KOW", defaultMessage: "Kourwéogo" },
        13: { id: "provinces.BF.13", defaultMessage: "Sud-Ouest" },
        YAT: { id: "provinces.BF.YAT", defaultMessage: "Yatenga" },
        LER: { id: "provinces.BF.LER", defaultMessage: "Léraba" },
        BAL: { id: "provinces.BF.BAL", defaultMessage: "Balé" },
        ZIR: { id: "provinces.BF.ZIR", defaultMessage: "Ziro" },
        LOR: { id: "provinces.BF.LOR", defaultMessage: "Loroum" },
        BAM: { id: "provinces.BF.BAM", defaultMessage: "Bam" },
        ZON: { id: "provinces.BF.ZON", defaultMessage: "Zondoma" },
        MOU: { id: "provinces.BF.MOU", defaultMessage: "Mouhoun" },
        BAN: { id: "provinces.BF.BAN", defaultMessage: "Banwa" },
        ZOU: { id: "provinces.BF.ZOU", defaultMessage: "Zoundwéogo" },
        NAM: { id: "provinces.BF.NAM", defaultMessage: "Namentenga" },
        BAZ: { id: "provinces.BF.BAZ", defaultMessage: "Bazèga" },
        NAO: { id: "provinces.BF.NAO", defaultMessage: "Naouri" },
        BGR: { id: "provinces.BF.BGR", defaultMessage: "Bougouriba" },
        NAY: { id: "provinces.BF.NAY", defaultMessage: "Nayala" },
        BLG: { id: "provinces.BF.BLG", defaultMessage: "Boulgou" },
        NOU: { id: "provinces.BF.NOU", defaultMessage: "Noumbiel" },
        BLK: { id: "provinces.BF.BLK", defaultMessage: "Boulkiemdé" },
        OUB: { id: "provinces.BF.OUB", defaultMessage: "Oubritenga" },
        COM: { id: "provinces.BF.COM", defaultMessage: "Comoé" },
        "01": { id: "provinces.BF.01", defaultMessage: "Boucle du Mouhoun" },
        OUD: { id: "provinces.BF.OUD", defaultMessage: "Oudalan" },
        GAN: { id: "provinces.BF.GAN", defaultMessage: "Ganzourgou" },
        "02": { id: "provinces.BF.02", defaultMessage: "Cascades" },
        PAS: { id: "provinces.BF.PAS", defaultMessage: "Passoré" },
        GNA: { id: "provinces.BF.GNA", defaultMessage: "Gnagna" },
        "03": { id: "provinces.BF.03", defaultMessage: "Centre" },
        PON: { id: "provinces.BF.PON", defaultMessage: "Poni" },
        GOU: { id: "provinces.BF.GOU", defaultMessage: "Gourma" },
        "04": { id: "provinces.BF.04", defaultMessage: "Centre-Est" },
        SEN: { id: "provinces.BF.SEN", defaultMessage: "Séno" },
        HOU: { id: "provinces.BF.HOU", defaultMessage: "Houet" },
        "05": { id: "provinces.BF.05", defaultMessage: "Centre-Nord" },
        SIS: { id: "provinces.BF.SIS", defaultMessage: "Sissili" },
        IOB: { id: "provinces.BF.IOB", defaultMessage: "Ioba" },
        "06": { id: "provinces.BF.06", defaultMessage: "Centre-Ouest" },
        KAD: { id: "provinces.BF.KAD", defaultMessage: "Kadiogo" },
    }),
    BD: defineMessages({
        28: { id: "provinces.BD.28", defaultMessage: "Kurigram" },
        F: { id: "provinces.BD.F", defaultMessage: "Rangpur" },
        "07": { id: "provinces.BD.07", defaultMessage: "Bhola" },
        49: { id: "provinces.BD.49", defaultMessage: "Pabna" },
        29: { id: "provinces.BD.29", defaultMessage: "Khagrachari" },
        G: { id: "provinces.BD.G", defaultMessage: "Sylhet" },
        "08": { id: "provinces.BD.08", defaultMessage: "Comilla" },
        50: { id: "provinces.BD.50", defaultMessage: "Pirojpur" },
        30: { id: "provinces.BD.30", defaultMessage: "Kushtia" },
        "09": { id: "provinces.BD.09", defaultMessage: "Chandpur" },
        51: { id: "provinces.BD.51", defaultMessage: "Patuakhali" },
        31: { id: "provinces.BD.31", defaultMessage: "Lakshmipur" },
        10: { id: "provinces.BD.10", defaultMessage: "Chittagong" },
        52: { id: "provinces.BD.52", defaultMessage: "Panchagarh" },
        32: { id: "provinces.BD.32", defaultMessage: "Lalmonirhat" },
        11: { id: "provinces.BD.11", defaultMessage: "Cox's Bazar" },
        53: { id: "provinces.BD.53", defaultMessage: "Rajbari" },
        33: { id: "provinces.BD.33", defaultMessage: "Manikganj" },
        12: { id: "provinces.BD.12", defaultMessage: "Chuadanga" },
        48: { id: "provinces.BD.48", defaultMessage: "Naogaon" },
        54: { id: "provinces.BD.54", defaultMessage: "Rajshahi" },
        34: { id: "provinces.BD.34", defaultMessage: "Mymensingh" },
        13: { id: "provinces.BD.13", defaultMessage: "Dhaka" },
        55: { id: "provinces.BD.55", defaultMessage: "Rangpur" },
        35: { id: "provinces.BD.35", defaultMessage: "Munshiganj" },
        14: { id: "provinces.BD.14", defaultMessage: "Dinajpur" },
        56: { id: "provinces.BD.56", defaultMessage: "Rangamati" },
        36: { id: "provinces.BD.36", defaultMessage: "Madaripur" },
        15: { id: "provinces.BD.15", defaultMessage: "Faridpur" },
        57: { id: "provinces.BD.57", defaultMessage: "Sherpur" },
        37: { id: "provinces.BD.37", defaultMessage: "Magura" },
        16: { id: "provinces.BD.16", defaultMessage: "Feni" },
        58: { id: "provinces.BD.58", defaultMessage: "Satkhira" },
        38: { id: "provinces.BD.38", defaultMessage: "Moulvibazar" },
        17: { id: "provinces.BD.17", defaultMessage: "Gopalganj" },
        59: { id: "provinces.BD.59", defaultMessage: "Sirajganj" },
        39: { id: "provinces.BD.39", defaultMessage: "Meherpur" },
        18: { id: "provinces.BD.18", defaultMessage: "Gazipur" },
        60: { id: "provinces.BD.60", defaultMessage: "Sylhet" },
        40: { id: "provinces.BD.40", defaultMessage: "Narayanganj" },
        19: { id: "provinces.BD.19", defaultMessage: "Gaibandha" },
        61: { id: "provinces.BD.61", defaultMessage: "Sunamganj" },
        41: { id: "provinces.BD.41", defaultMessage: "Netrakona" },
        20: { id: "provinces.BD.20", defaultMessage: "Habiganj" },
        62: { id: "provinces.BD.62", defaultMessage: "Shariatpur" },
        42: { id: "provinces.BD.42", defaultMessage: "Narsingdi" },
        21: { id: "provinces.BD.21", defaultMessage: "Jamalpur" },
        63: { id: "provinces.BD.63", defaultMessage: "Tangail" },
        "01": { id: "provinces.BD.01", defaultMessage: "Bandarban" },
        43: { id: "provinces.BD.43", defaultMessage: "Narail" },
        22: { id: "provinces.BD.22", defaultMessage: "Jessore" },
        64: { id: "provinces.BD.64", defaultMessage: "Thakurgaon" },
        "02": { id: "provinces.BD.02", defaultMessage: "Barguna" },
        44: { id: "provinces.BD.44", defaultMessage: "Natore" },
        23: { id: "provinces.BD.23", defaultMessage: "Jhenaidah" },
        A: { id: "provinces.BD.A", defaultMessage: "Barisal" },
        "03": { id: "provinces.BD.03", defaultMessage: "Bogra" },
        45: { id: "provinces.BD.45", defaultMessage: "Nawabganj" },
        24: { id: "provinces.BD.24", defaultMessage: "Jaipurhat" },
        B: { id: "provinces.BD.B", defaultMessage: "Chittagong" },
        E: { id: "provinces.BD.E", defaultMessage: "Rajshahi" },
        "04": { id: "provinces.BD.04", defaultMessage: "Brahmanbaria" },
        46: { id: "provinces.BD.46", defaultMessage: "Nilphamari" },
        25: { id: "provinces.BD.25", defaultMessage: "Jhalakati" },
        C: { id: "provinces.BD.C", defaultMessage: "Dhaka" },
        "05": { id: "provinces.BD.05", defaultMessage: "Bagerhat" },
        47: { id: "provinces.BD.47", defaultMessage: "Noakhali" },
        26: { id: "provinces.BD.26", defaultMessage: "Kishorganj" },
        D: { id: "provinces.BD.D", defaultMessage: "Khulna" },
        "06": { id: "provinces.BD.06", defaultMessage: "Barisal" },
        27: { id: "provinces.BD.27", defaultMessage: "Khulna" },
    }),
    BG: defineMessages({
        12: { id: "provinces.BG.12", defaultMessage: "Montana" },
        13: { id: "provinces.BG.13", defaultMessage: "Pazardzhik" },
        14: { id: "provinces.BG.14", defaultMessage: "Pernik" },
        15: { id: "provinces.BG.15", defaultMessage: "Pleven" },
        16: { id: "provinces.BG.16", defaultMessage: "Plovdiv" },
        17: { id: "provinces.BG.17", defaultMessage: "Razgrad" },
        18: { id: "provinces.BG.18", defaultMessage: "Ruse" },
        19: { id: "provinces.BG.19", defaultMessage: "Silistra" },
        20: { id: "provinces.BG.20", defaultMessage: "Sliven" },
        21: { id: "provinces.BG.21", defaultMessage: "Smolyan" },
        22: { id: "provinces.BG.22", defaultMessage: "Sofia-Grad" },
        "01": { id: "provinces.BG.01", defaultMessage: "Blagoevgrad" },
        23: { id: "provinces.BG.23", defaultMessage: "Sofia" },
        "02": { id: "provinces.BG.02", defaultMessage: "Burgas" },
        24: { id: "provinces.BG.24", defaultMessage: "Stara Zagora" },
        "03": { id: "provinces.BG.03", defaultMessage: "Varna" },
        25: { id: "provinces.BG.25", defaultMessage: "Targovishte" },
        "04": { id: "provinces.BG.04", defaultMessage: "Veliko Tarnovo" },
        26: { id: "provinces.BG.26", defaultMessage: "Haskovo" },
        "05": { id: "provinces.BG.05", defaultMessage: "Vidin" },
        27: { id: "provinces.BG.27", defaultMessage: "Shumen" },
        "06": { id: "provinces.BG.06", defaultMessage: "Vratsa" },
        28: { id: "provinces.BG.28", defaultMessage: "Yambol" },
        "07": { id: "provinces.BG.07", defaultMessage: "Gabrovo" },
        "08": { id: "provinces.BG.08", defaultMessage: "Dobrich" },
        "09": { id: "provinces.BG.09", defaultMessage: "Kardzhali" },
        10: { id: "provinces.BG.10", defaultMessage: "Kyustendil" },
        11: { id: "provinces.BG.11", defaultMessage: "Lovech" },
    }),
    BH: defineMessages({
        17: { id: "provinces.BH.17", defaultMessage: "Ash Shamālīyah" },
        13: { id: "provinces.BH.13", defaultMessage: "Al Manāmah (Al ‘Āşimah)" },
        14: { id: "provinces.BH.14", defaultMessage: "Al Janūbīyah" },
        15: { id: "provinces.BH.15", defaultMessage: "Al Muḩarraq" },
        16: { id: "provinces.BH.16", defaultMessage: "Al Wusţá" },
    }),
    BS: defineMessages({
        FP: { id: "provinces.BS.FP", defaultMessage: "City of Freeport" },
        GC: { id: "provinces.BS.GC", defaultMessage: "Grand Cay" },
        HI: { id: "provinces.BS.HI", defaultMessage: "Harbour Island" },
        HT: { id: "provinces.BS.HT", defaultMessage: "Hope Town" },
        IN: { id: "provinces.BS.IN", defaultMessage: "Inagua" },
        LI: { id: "provinces.BS.LI", defaultMessage: "Long Island" },
        MC: { id: "provinces.BS.MC", defaultMessage: "Mangrove Cay" },
        MG: { id: "provinces.BS.MG", defaultMessage: "Mayaguana" },
        MI: { id: "provinces.BS.MI", defaultMessage: "Moore's Island" },
        NE: { id: "provinces.BS.NE", defaultMessage: "North Eleuthera" },
        AK: { id: "provinces.BS.AK", defaultMessage: "Acklins" },
        NO: { id: "provinces.BS.NO", defaultMessage: "North Abaco" },
        BI: { id: "provinces.BS.BI", defaultMessage: "Bimini" },
        NS: { id: "provinces.BS.NS", defaultMessage: "North Andros" },
        BP: { id: "provinces.BS.BP", defaultMessage: "Black Point" },
        RC: { id: "provinces.BS.RC", defaultMessage: "Rum Cay" },
        BY: { id: "provinces.BS.BY", defaultMessage: "Berry Islands" },
        RI: { id: "provinces.BS.RI", defaultMessage: "Ragged Island" },
        CE: { id: "provinces.BS.CE", defaultMessage: "Central Eleuthera" },
        SA: { id: "provinces.BS.SA", defaultMessage: "South Andros" },
        CI: { id: "provinces.BS.CI", defaultMessage: "Cat Island" },
        SE: { id: "provinces.BS.SE", defaultMessage: "South Eleuthera" },
        CK: { id: "provinces.BS.CK", defaultMessage: "Crooked Island and Long Cay" },
        SO: { id: "provinces.BS.SO", defaultMessage: "South Abaco" },
        CO: { id: "provinces.BS.CO", defaultMessage: "Central Abaco" },
        SS: { id: "provinces.BS.SS", defaultMessage: "San Salvador" },
        CS: { id: "provinces.BS.CS", defaultMessage: "Central Andros" },
        SW: { id: "provinces.BS.SW", defaultMessage: "Spanish Wells" },
        EG: { id: "provinces.BS.EG", defaultMessage: "East Grand Bahama" },
        WG: { id: "provinces.BS.WG", defaultMessage: "West Grand Bahama" },
        EX: { id: "provinces.BS.EX", defaultMessage: "Exuma" },
    }),
    BA: defineMessages({
        10: { id: "provinces.BA.10", defaultMessage: "Kanton br. 10 (Livanjski kanton)" },
        "05": { id: "provinces.BA.05", defaultMessage: "Bosansko-podrinjski kanton" },
        BIH: { id: "provinces.BA.BIH", defaultMessage: "Federacija Bosne i Hercegovine" },
        "06": { id: "provinces.BA.06", defaultMessage: "Srednjobosanski kanton" },
        "01": { id: "provinces.BA.01", defaultMessage: "Unsko-sanski kanton" },
        BRC: { id: "provinces.BA.BRC", defaultMessage: "Brčko distrikt" },
        "07": { id: "provinces.BA.07", defaultMessage: "Hercegovačko-neretvanski kanton" },
        "02": { id: "provinces.BA.02", defaultMessage: "Posavski kanton" },
        SRP: { id: "provinces.BA.SRP", defaultMessage: "Republika Srpska" },
        "08": { id: "provinces.BA.08", defaultMessage: "Zapadnohercegovački kanton" },
        "03": { id: "provinces.BA.03", defaultMessage: "Tuzlanski kanton" },
        "09": { id: "provinces.BA.09", defaultMessage: "Kanton Sarajevo" },
        "04": { id: "provinces.BA.04", defaultMessage: "Zeničko-dobojski kanton" },
    }),
    BL: defineMessages({}),
    BY: defineMessages({
        HR: { id: "provinces.BY.HR", defaultMessage: "Hrodzienskaja voblasć" },
        MA: { id: "provinces.BY.MA", defaultMessage: "Mahilioŭskaja voblasć" },
        MI: { id: "provinces.BY.MI", defaultMessage: "Minskaja voblasć" },
        BR: { id: "provinces.BY.BR", defaultMessage: "Bresckaja voblasć" },
        VI: { id: "provinces.BY.VI", defaultMessage: "Viciebskaja voblasć" },
        HM: { id: "provinces.BY.HM", defaultMessage: "Horad Minsk" },
        HO: { id: "provinces.BY.HO", defaultMessage: "Homieĺskaja voblasć" },
    }),
    BZ: defineMessages({
        CZL: { id: "provinces.BZ.CZL", defaultMessage: "Corozal" },
        OW: { id: "provinces.BZ.OW", defaultMessage: "Orange Walk" },
        SC: { id: "provinces.BZ.SC", defaultMessage: "Stann Creek" },
        TOL: { id: "provinces.BZ.TOL", defaultMessage: "Toledo" },
        BZ: { id: "provinces.BZ.BZ", defaultMessage: "Belize" },
        CY: { id: "provinces.BZ.CY", defaultMessage: "Cayo" },
    }),
    BM: defineMessages({}),
    BO: defineMessages({
        N: { id: "provinces.BO.N", defaultMessage: "Pando" },
        O: { id: "provinces.BO.O", defaultMessage: "Oruro" },
        B: { id: "provinces.BO.B", defaultMessage: "El Beni" },
        P: { id: "provinces.BO.P", defaultMessage: "Potosí" },
        C: { id: "provinces.BO.C", defaultMessage: "Cochabamba" },
        S: { id: "provinces.BO.S", defaultMessage: "Santa Cruz" },
        H: { id: "provinces.BO.H", defaultMessage: "Chuquisaca" },
        T: { id: "provinces.BO.T", defaultMessage: "Tarija" },
        L: { id: "provinces.BO.L", defaultMessage: "La Paz" },
    }),
    BR: defineMessages({
        PR: { id: "provinces.BR.PR", defaultMessage: "Paraná" },
        RJ: { id: "provinces.BR.RJ", defaultMessage: "Rio de Janeiro" },
        RN: { id: "provinces.BR.RN", defaultMessage: "Rio Grande do Norte" },
        RO: { id: "provinces.BR.RO", defaultMessage: "Rondônia" },
        AC: { id: "provinces.BR.AC", defaultMessage: "Acre" },
        RR: { id: "provinces.BR.RR", defaultMessage: "Roraima" },
        AL: { id: "provinces.BR.AL", defaultMessage: "Alagoas" },
        RS: { id: "provinces.BR.RS", defaultMessage: "Rio Grande do Sul" },
        AM: { id: "provinces.BR.AM", defaultMessage: "Amazonas" },
        SC: { id: "provinces.BR.SC", defaultMessage: "Santa Catarina" },
        AP: { id: "provinces.BR.AP", defaultMessage: "Amapá" },
        SE: { id: "provinces.BR.SE", defaultMessage: "Sergipe" },
        BA: { id: "provinces.BR.BA", defaultMessage: "Bahia" },
        SP: { id: "provinces.BR.SP", defaultMessage: "São Paulo" },
        CE: { id: "provinces.BR.CE", defaultMessage: "Ceará" },
        TO: { id: "provinces.BR.TO", defaultMessage: "Tocantins" },
        DF: { id: "provinces.BR.DF", defaultMessage: "Distrito Federal" },
        ES: { id: "provinces.BR.ES", defaultMessage: "Espírito Santo" },
        FN: { id: "provinces.BR.FN", defaultMessage: "Fernando de Noronha" },
        GO: { id: "provinces.BR.GO", defaultMessage: "Goiás" },
        MA: { id: "provinces.BR.MA", defaultMessage: "Maranhão" },
        MG: { id: "provinces.BR.MG", defaultMessage: "Minas Gerais" },
        MS: { id: "provinces.BR.MS", defaultMessage: "Mato Grosso do Sul" },
        MT: { id: "provinces.BR.MT", defaultMessage: "Mato Grosso" },
        PA: { id: "provinces.BR.PA", defaultMessage: "Pará" },
        PB: { id: "provinces.BR.PB", defaultMessage: "Paraíba" },
        PE: { id: "provinces.BR.PE", defaultMessage: "Pernambuco" },
        PI: { id: "provinces.BR.PI", defaultMessage: "Piauí" },
    }),
    BB: defineMessages({
        "02": { id: "provinces.BB.02", defaultMessage: "Saint Andrew" },
        "08": { id: "provinces.BB.08", defaultMessage: "Saint Michael" },
        "03": { id: "provinces.BB.03", defaultMessage: "Saint George" },
        "09": { id: "provinces.BB.09", defaultMessage: "Saint Peter" },
        "04": { id: "provinces.BB.04", defaultMessage: "Saint James" },
        10: { id: "provinces.BB.10", defaultMessage: "Saint Philip" },
        "05": { id: "provinces.BB.05", defaultMessage: "Saint John" },
        11: { id: "provinces.BB.11", defaultMessage: "Saint Thomas" },
        "06": { id: "provinces.BB.06", defaultMessage: "Saint Joseph" },
        "01": { id: "provinces.BB.01", defaultMessage: "Christ Church" },
        "07": { id: "provinces.BB.07", defaultMessage: "Saint Lucy" },
    }),
    BN: defineMessages({
        BM: { id: "provinces.BN.BM", defaultMessage: "Brunei-Muara" },
        BE: { id: "provinces.BN.BE", defaultMessage: "Belait" },
        TU: { id: "provinces.BN.TU", defaultMessage: "Tutong" },
        TE: { id: "provinces.BN.TE", defaultMessage: "Temburong" },
    }),
    BT: defineMessages({
        12: { id: "provinces.BT.12", defaultMessage: "Chhukha" },
        13: { id: "provinces.BT.13", defaultMessage: "Ha" },
        14: { id: "provinces.BT.14", defaultMessage: "Samtee" },
        15: { id: "provinces.BT.15", defaultMessage: "Thimphu" },
        21: { id: "provinces.BT.21", defaultMessage: "Tsirang" },
        22: { id: "provinces.BT.22", defaultMessage: "Dagana" },
        23: { id: "provinces.BT.23", defaultMessage: "Punakha" },
        24: { id: "provinces.BT.24", defaultMessage: "Wangdue Phodrang" },
        31: { id: "provinces.BT.31", defaultMessage: "Sarpang" },
        32: { id: "provinces.BT.32", defaultMessage: "Trongsa" },
        33: { id: "provinces.BT.33", defaultMessage: "Bumthang" },
        34: { id: "provinces.BT.34", defaultMessage: "Zhemgang" },
        41: { id: "provinces.BT.41", defaultMessage: "Trashigang" },
        42: { id: "provinces.BT.42", defaultMessage: "Monggar" },
        43: { id: "provinces.BT.43", defaultMessage: "Pemagatshel" },
        44: { id: "provinces.BT.44", defaultMessage: "Lhuentse" },
        45: { id: "provinces.BT.45", defaultMessage: "Samdrup Jongkha" },
        GA: { id: "provinces.BT.GA", defaultMessage: "Gasa" },
        TY: { id: "provinces.BT.TY", defaultMessage: "Trashi Yangtse" },
        11: { id: "provinces.BT.11", defaultMessage: "Paro" },
    }),
    BV: defineMessages({}),
    BW: defineMessages({
        SE: { id: "provinces.BW.SE", defaultMessage: "South-East" },
        KG: { id: "provinces.BW.KG", defaultMessage: "Kgalagadi" },
        SO: { id: "provinces.BW.SO", defaultMessage: "Southern" },
        KL: { id: "provinces.BW.KL", defaultMessage: "Kgatleng" },
        KW: { id: "provinces.BW.KW", defaultMessage: "Kweneng" },
        CE: { id: "provinces.BW.CE", defaultMessage: "Central" },
        NE: { id: "provinces.BW.NE", defaultMessage: "North-East" },
        GH: { id: "provinces.BW.GH", defaultMessage: "Ghanzi" },
        NW: { id: "provinces.BW.NW", defaultMessage: "North-West" },
    }),
    CF: defineMessages({
        KG: { id: "provinces.CF.KG", defaultMessage: "Kémo-Gribingui" },
        BK: { id: "provinces.CF.BK", defaultMessage: "Basse-Kotto" },
        SE: { id: "provinces.CF.SE", defaultMessage: "Sangha" },
        LB: { id: "provinces.CF.LB", defaultMessage: "Lobaye" },
        HK: { id: "provinces.CF.HK", defaultMessage: "Haute-Kotto" },
        UK: { id: "provinces.CF.UK", defaultMessage: "Ouaka" },
        MB: { id: "provinces.CF.MB", defaultMessage: "Mbomou" },
        HM: { id: "provinces.CF.HM", defaultMessage: "Haut-Mbomou" },
        AC: { id: "provinces.CF.AC", defaultMessage: "Ouham" },
        VK: { id: "provinces.CF.VK", defaultMessage: "Vakaga" },
        MP: { id: "provinces.CF.MP", defaultMessage: "Ombella-M'poko" },
        HS: { id: "provinces.CF.HS", defaultMessage: "Haute-Sangha / Mambéré-Kadéï" },
        BB: { id: "provinces.CF.BB", defaultMessage: "Bamingui-Bangoran" },
        NM: { id: "provinces.CF.NM", defaultMessage: "Nana-Mambéré" },
        KB: { id: "provinces.CF.KB", defaultMessage: "Gribingui" },
        BGF: { id: "provinces.CF.BGF", defaultMessage: "Bangui" },
        OP: { id: "provinces.CF.OP", defaultMessage: "Ouham-Pendé" },
    }),
    CA: defineMessages({
        NT: { id: "provinces.CA.NT", defaultMessage: "Northwest Territories" },
        BC: { id: "provinces.CA.BC", defaultMessage: "British Columbia" },
        YT: { id: "provinces.CA.YT", defaultMessage: "Yukon Territory" },
        NU: { id: "provinces.CA.NU", defaultMessage: "Nunavut" },
        MB: { id: "provinces.CA.MB", defaultMessage: "Manitoba" },
        ON: { id: "provinces.CA.ON", defaultMessage: "Ontario" },
        NB: { id: "provinces.CA.NB", defaultMessage: "New Brunswick" },
        PE: { id: "provinces.CA.PE", defaultMessage: "Prince Edward Island" },
        NL: { id: "provinces.CA.NL", defaultMessage: "Newfoundland and Labrador" },
        QC: { id: "provinces.CA.QC", defaultMessage: "Quebec" },
        NS: { id: "provinces.CA.NS", defaultMessage: "Nova Scotia" },
        AB: { id: "provinces.CA.AB", defaultMessage: "Alberta" },
        SK: { id: "provinces.CA.SK", defaultMessage: "Saskatchewan" },
    }),
    CC: defineMessages({}),
    CH: defineMessages({
        NE: { id: "provinces.CH.NE", defaultMessage: "Neuchâtel" },
        NW: { id: "provinces.CH.NW", defaultMessage: "Nidwalden" },
        OW: { id: "provinces.CH.OW", defaultMessage: "Obwalden" },
        SG: { id: "provinces.CH.SG", defaultMessage: "Sankt Gallen" },
        SH: { id: "provinces.CH.SH", defaultMessage: "Schaffhausen" },
        SO: { id: "provinces.CH.SO", defaultMessage: "Solothurn" },
        SZ: { id: "provinces.CH.SZ", defaultMessage: "Schwyz" },
        TG: { id: "provinces.CH.TG", defaultMessage: "Thurgau" },
        TI: { id: "provinces.CH.TI", defaultMessage: "Ticino" },
        AG: { id: "provinces.CH.AG", defaultMessage: "Aargau" },
        UR: { id: "provinces.CH.UR", defaultMessage: "Uri" },
        AI: { id: "provinces.CH.AI", defaultMessage: "Appenzell Innerrhoden" },
        VD: { id: "provinces.CH.VD", defaultMessage: "Vaud" },
        AR: { id: "provinces.CH.AR", defaultMessage: "Appenzell Ausserrhoden" },
        VS: { id: "provinces.CH.VS", defaultMessage: "Valais" },
        BE: { id: "provinces.CH.BE", defaultMessage: "Bern" },
        ZG: { id: "provinces.CH.ZG", defaultMessage: "Zug" },
        BL: { id: "provinces.CH.BL", defaultMessage: "Basel-Landschaft" },
        ZH: { id: "provinces.CH.ZH", defaultMessage: "Zürich" },
        BS: { id: "provinces.CH.BS", defaultMessage: "Basel-Stadt" },
        FR: { id: "provinces.CH.FR", defaultMessage: "Fribourg" },
        GE: { id: "provinces.CH.GE", defaultMessage: "Genève" },
        GL: { id: "provinces.CH.GL", defaultMessage: "Glarus" },
        GR: { id: "provinces.CH.GR", defaultMessage: "Graubünden" },
        JU: { id: "provinces.CH.JU", defaultMessage: "Jura" },
        LU: { id: "provinces.CH.LU", defaultMessage: "Luzern" },
    }),
    CL: defineMessages({
        AT: { id: "provinces.CL.AT", defaultMessage: "Atacama" },
        VS: { id: "provinces.CL.VS", defaultMessage: "Valparaíso" },
        LR: { id: "provinces.CL.LR", defaultMessage: "Los Ríos" },
        BI: { id: "provinces.CL.BI", defaultMessage: "Bío-Bío" },
        AI: { id: "provinces.CL.AI", defaultMessage: "Aisén del General Carlos Ibáñez del Campo" },
        MA: { id: "provinces.CL.MA", defaultMessage: "Magallanes y Antártica Chilena" },
        CO: { id: "provinces.CL.CO", defaultMessage: "Coquimbo" },
        AN: { id: "provinces.CL.AN", defaultMessage: "Antofagasta" },
        ML: { id: "provinces.CL.ML", defaultMessage: "Maule" },
        LI: { id: "provinces.CL.LI", defaultMessage: "Libertador General Bernardo O'Higgins" },
        TA: { id: "provinces.CL.TA", defaultMessage: "Tarapacá" },
        AP: { id: "provinces.CL.AP", defaultMessage: "Arica y Parinacota" },
        RM: { id: "provinces.CL.RM", defaultMessage: "Región Metropolitana de Santiago" },
        LL: { id: "provinces.CL.LL", defaultMessage: "Los Lagos" },
        AR: { id: "provinces.CL.AR", defaultMessage: "Araucanía" },
    }),
    CN: defineMessages({
        GS: { id: "provinces.CN.GS", defaultMessage: "Gansu Sheng" },
        SN: { id: "provinces.CN.SN", defaultMessage: "Shaanxi Sheng" },
        GX: { id: "provinces.CN.GX", defaultMessage: "Guangxi Zhuangzu Zizhiqu" },
        SX: { id: "provinces.CN.SX", defaultMessage: "Shanxi Sheng" },
        GZ: { id: "provinces.CN.GZ", defaultMessage: "Guizhou Sheng" },
        TJ: { id: "provinces.CN.TJ", defaultMessage: "Tianjin Shi" },
        HA: { id: "provinces.CN.HA", defaultMessage: "Henan Sheng" },
        TW: { id: "provinces.CN.TW", defaultMessage: "Taiwan Sheng (see also separate country code entry under TW)" },
        HB: { id: "provinces.CN.HB", defaultMessage: "Hubei Sheng" },
        XJ: { id: "provinces.CN.XJ", defaultMessage: "Xinjiang Uygur Zizhiqu" },
        HE: { id: "provinces.CN.HE", defaultMessage: "Hebei Sheng" },
        XZ: { id: "provinces.CN.XZ", defaultMessage: "Xizang Zizhiqu" },
        HI: { id: "provinces.CN.HI", defaultMessage: "Hainan Sheng" },
        YN: { id: "provinces.CN.YN", defaultMessage: "Yunnan Sheng" },
        HK: { id: "provinces.CN.HK", defaultMessage: "Hong Kong SAR (see also separate country code entry under HK)" },
        ZJ: { id: "provinces.CN.ZJ", defaultMessage: "Zhejiang Sheng" },
        HL: { id: "provinces.CN.HL", defaultMessage: "Heilongjiang Sheng" },
        HN: { id: "provinces.CN.HN", defaultMessage: "Hunan Sheng" },
        JL: { id: "provinces.CN.JL", defaultMessage: "Jilin Sheng" },
        JS: { id: "provinces.CN.JS", defaultMessage: "Jiangsu Sheng" },
        JX: { id: "provinces.CN.JX", defaultMessage: "Jiangxi Sheng" },
        LN: { id: "provinces.CN.LN", defaultMessage: "Liaoning Sheng" },
        MO: { id: "provinces.CN.MO", defaultMessage: "Macao SAR (see also separate country code entry under MO)" },
        NM: { id: "provinces.CN.NM", defaultMessage: "Nei Mongol Zizhiqu" },
        NX: { id: "provinces.CN.NX", defaultMessage: "Ningxia Huizi Zizhiqu" },
        AH: { id: "provinces.CN.AH", defaultMessage: "Anhui Sheng" },
        QH: { id: "provinces.CN.QH", defaultMessage: "Qinghai Sheng" },
        BJ: { id: "provinces.CN.BJ", defaultMessage: "Beijing Shi" },
        SC: { id: "provinces.CN.SC", defaultMessage: "Sichuan Sheng" },
        CQ: { id: "provinces.CN.CQ", defaultMessage: "Chongqing Shi" },
        SD: { id: "provinces.CN.SD", defaultMessage: "Shandong Sheng" },
        FJ: { id: "provinces.CN.FJ", defaultMessage: "Fujian Sheng" },
        SH: { id: "provinces.CN.SH", defaultMessage: "Shanghai Shi" },
        GD: { id: "provinces.CN.GD", defaultMessage: "Guangdong Sheng" },
    }),
    CI: defineMessages({
        "08": { id: "provinces.CI.08", defaultMessage: "Zanzan (Région du)" },
        "09": { id: "provinces.CI.09", defaultMessage: "Bas-Sassandra (Région du)" },
        10: { id: "provinces.CI.10", defaultMessage: "Denguélé (Région du)" },
        11: { id: "provinces.CI.11", defaultMessage: "Nzi-Comoé (Région)" },
        12: { id: "provinces.CI.12", defaultMessage: "Marahoué (Région de la)" },
        13: { id: "provinces.CI.13", defaultMessage: "Sud-Comoé (Région du)" },
        14: { id: "provinces.CI.14", defaultMessage: "Worodouqou (Région du)" },
        15: { id: "provinces.CI.15", defaultMessage: "Sud-Bandama (Région du)" },
        16: { id: "provinces.CI.16", defaultMessage: "Agnébi (Région de l')" },
        17: { id: "provinces.CI.17", defaultMessage: "Bafing (Région du)" },
        18: { id: "provinces.CI.18", defaultMessage: "Fromager (Région du)" },
        19: { id: "provinces.CI.19", defaultMessage: "Moyen-Cavally (Région du)" },
        "01": { id: "provinces.CI.01", defaultMessage: "Lagunes (Région des)" },
        "02": { id: "provinces.CI.02", defaultMessage: "Haut-Sassandra (Région du)" },
        "03": { id: "provinces.CI.03", defaultMessage: "Savanes (Région des)" },
        "04": { id: "provinces.CI.04", defaultMessage: "Vallée du Bandama (Région de la)" },
        "05": { id: "provinces.CI.05", defaultMessage: "Moyen-Comoé (Région du)" },
        "06": { id: "provinces.CI.06", defaultMessage: "18 Montagnes (Région des)" },
        "07": { id: "provinces.CI.07", defaultMessage: "Lacs (Région des)" },
    }),
    CM: defineMessages({
        LT: { id: "provinces.CM.LT", defaultMessage: "Littoral" },
        NO: { id: "provinces.CM.NO", defaultMessage: "North" },
        AD: { id: "provinces.CM.AD", defaultMessage: "Adamaoua" },
        NW: { id: "provinces.CM.NW", defaultMessage: "North-West (Cameroon)" },
        CE: { id: "provinces.CM.CE", defaultMessage: "Centre" },
        OU: { id: "provinces.CM.OU", defaultMessage: "West" },
        EN: { id: "provinces.CM.EN", defaultMessage: "Far North" },
        SU: { id: "provinces.CM.SU", defaultMessage: "South" },
        ES: { id: "provinces.CM.ES", defaultMessage: "East" },
        SW: { id: "provinces.CM.SW", defaultMessage: "South-West" },
    }),
    CD: defineMessages({
        KE: { id: "provinces.CD.KE", defaultMessage: "Kasai-Oriental" },
        OR: { id: "provinces.CD.OR", defaultMessage: "Orientale" },
        KN: { id: "provinces.CD.KN", defaultMessage: "Kinshasa" },
        BC: { id: "provinces.CD.BC", defaultMessage: "Bas-Congo" },
        SK: { id: "provinces.CD.SK", defaultMessage: "Sud-Kivu" },
        KW: { id: "provinces.CD.KW", defaultMessage: "Kasai-Occidental" },
        BN: { id: "provinces.CD.BN", defaultMessage: "Bandundu" },
        MA: { id: "provinces.CD.MA", defaultMessage: "Maniema" },
        EQ: { id: "provinces.CD.EQ", defaultMessage: "Équateur" },
        NK: { id: "provinces.CD.NK", defaultMessage: "Nord-Kivu" },
        KA: { id: "provinces.CD.KA", defaultMessage: "Katanga" },
    }),
    CG: defineMessages({
        7: { id: "provinces.CG.7", defaultMessage: "Likouala" },
        13: { id: "provinces.CG.13", defaultMessage: "Sangha" },
        8: { id: "provinces.CG.8", defaultMessage: "Cuvette" },
        14: { id: "provinces.CG.14", defaultMessage: "Plateaux" },
        9: { id: "provinces.CG.9", defaultMessage: "Niari" },
        15: { id: "provinces.CG.15", defaultMessage: "Cuvette-Ouest" },
        BZV: { id: "provinces.CG.BZV", defaultMessage: "Brazzaville" },
        2: { id: "provinces.CG.2", defaultMessage: "Lékoumou" },
        11: { id: "provinces.CG.11", defaultMessage: "Bouenza" },
        5: { id: "provinces.CG.5", defaultMessage: "Kouilou" },
        12: { id: "provinces.CG.12", defaultMessage: "Pool" },
    }),
    CK: defineMessages({}),
    CO: defineMessages({
        CUN: { id: "provinces.CO.CUN", defaultMessage: "Cundinamarca" },
        DC: { id: "provinces.CO.DC", defaultMessage: "Distrito Capital de Bogotá" },
        GUA: { id: "provinces.CO.GUA", defaultMessage: "Guainía" },
        GUV: { id: "provinces.CO.GUV", defaultMessage: "Guaviare" },
        HUI: { id: "provinces.CO.HUI", defaultMessage: "Huila" },
        LAG: { id: "provinces.CO.LAG", defaultMessage: "La Guajira" },
        MAG: { id: "provinces.CO.MAG", defaultMessage: "Magdalena" },
        MET: { id: "provinces.CO.MET", defaultMessage: "Meta" },
        NAR: { id: "provinces.CO.NAR", defaultMessage: "Nariño" },
        AMA: { id: "provinces.CO.AMA", defaultMessage: "Amazonas" },
        NSA: { id: "provinces.CO.NSA", defaultMessage: "Norte de Santander" },
        ANT: { id: "provinces.CO.ANT", defaultMessage: "Antioquia" },
        PUT: { id: "provinces.CO.PUT", defaultMessage: "Putumayo" },
        ARA: { id: "provinces.CO.ARA", defaultMessage: "Arauca" },
        QUI: { id: "provinces.CO.QUI", defaultMessage: "Quindío" },
        ATL: { id: "provinces.CO.ATL", defaultMessage: "Atlántico" },
        RIS: { id: "provinces.CO.RIS", defaultMessage: "Risaralda" },
        BOL: { id: "provinces.CO.BOL", defaultMessage: "Bolívar" },
        SAN: { id: "provinces.CO.SAN", defaultMessage: "Santander" },
        BOY: { id: "provinces.CO.BOY", defaultMessage: "Boyacá" },
        SAP: { id: "provinces.CO.SAP", defaultMessage: "San Andrés, Providencia y Santa Catalina" },
        CAL: { id: "provinces.CO.CAL", defaultMessage: "Caldas" },
        SUC: { id: "provinces.CO.SUC", defaultMessage: "Sucre" },
        CAQ: { id: "provinces.CO.CAQ", defaultMessage: "Caquetá" },
        TOL: { id: "provinces.CO.TOL", defaultMessage: "Tolima" },
        CAS: { id: "provinces.CO.CAS", defaultMessage: "Casanare" },
        VAC: { id: "provinces.CO.VAC", defaultMessage: "Valle del Cauca" },
        CAU: { id: "provinces.CO.CAU", defaultMessage: "Cauca" },
        VAU: { id: "provinces.CO.VAU", defaultMessage: "Vaupés" },
        CES: { id: "provinces.CO.CES", defaultMessage: "Cesar" },
        VID: { id: "provinces.CO.VID", defaultMessage: "Vichada" },
        CHO: { id: "provinces.CO.CHO", defaultMessage: "Chocó" },
        COR: { id: "provinces.CO.COR", defaultMessage: "Córdoba" },
    }),
    KM: defineMessages({
        A: { id: "provinces.KM.A", defaultMessage: "Andjouân (Anjwān)" },
        M: { id: "provinces.KM.M", defaultMessage: "Moûhîlî (Mūhīlī)" },
        G: { id: "provinces.KM.G", defaultMessage: "Andjazîdja (Anjazījah)" },
    }),
    CV: defineMessages({
        B: { id: "provinces.CV.B", defaultMessage: "Ilhas de Barlavento" },
        SV: { id: "provinces.CV.SV", defaultMessage: "São Vicente" },
        BR: { id: "provinces.CV.BR", defaultMessage: "Brava" },
        TA: { id: "provinces.CV.TA", defaultMessage: "Tarrafal" },
        BV: { id: "provinces.CV.BV", defaultMessage: "Boa Vista" },
        TS: { id: "provinces.CV.TS", defaultMessage: "Tarrafal de São Nicolau" },
        CA: { id: "provinces.CV.CA", defaultMessage: "Santa Catarina" },
        CF: { id: "provinces.CV.CF", defaultMessage: "Santa Catarina de Fogo" },
        CR: { id: "provinces.CV.CR", defaultMessage: "Santa Cruz" },
        MA: { id: "provinces.CV.MA", defaultMessage: "Maio" },
        MO: { id: "provinces.CV.MO", defaultMessage: "Mosteiros" },
        PA: { id: "provinces.CV.PA", defaultMessage: "Paul" },
        PN: { id: "provinces.CV.PN", defaultMessage: "Porto Novo" },
        PR: { id: "provinces.CV.PR", defaultMessage: "Praia" },
        RB: { id: "provinces.CV.RB", defaultMessage: "Ribeira Brava" },
        RG: { id: "provinces.CV.RG", defaultMessage: "Ribeira Grande" },
        RS: { id: "provinces.CV.RS", defaultMessage: "Ribeira Grande de Santiago" },
        S: { id: "provinces.CV.S", defaultMessage: "Ilhas de Sotavento" },
        SD: { id: "provinces.CV.SD", defaultMessage: "São Domingos" },
        SF: { id: "provinces.CV.SF", defaultMessage: "São Filipe" },
        SL: { id: "provinces.CV.SL", defaultMessage: "Sal" },
        SM: { id: "provinces.CV.SM", defaultMessage: "São Miguel" },
        SO: { id: "provinces.CV.SO", defaultMessage: "São Lourenço dos Órgãos" },
        SS: { id: "provinces.CV.SS", defaultMessage: "São Salvador do Mundo" },
    }),
    CR: defineMessages({
        SJ: { id: "provinces.CR.SJ", defaultMessage: "San José" },
        C: { id: "provinces.CR.C", defaultMessage: "Cartago" },
        G: { id: "provinces.CR.G", defaultMessage: "Guanacaste" },
        H: { id: "provinces.CR.H", defaultMessage: "Heredia" },
        L: { id: "provinces.CR.L", defaultMessage: "Limón" },
        A: { id: "provinces.CR.A", defaultMessage: "Alajuela" },
        P: { id: "provinces.CR.P", defaultMessage: "Puntarenas" },
    }),
    CU: defineMessages({
        "05": { id: "provinces.CU.05", defaultMessage: "Villa Clara" },
        11: { id: "provinces.CU.11", defaultMessage: "Holguín" },
        "06": { id: "provinces.CU.06", defaultMessage: "Cienfuegos" },
        "01": { id: "provinces.CU.01", defaultMessage: "Pinar del Rio" },
        12: { id: "provinces.CU.12", defaultMessage: "Granma" },
        "07": { id: "provinces.CU.07", defaultMessage: "Sancti Spíritus" },
        "02": { id: "provinces.CU.02", defaultMessage: "La Habana" },
        13: { id: "provinces.CU.13", defaultMessage: "Santiago de Cuba" },
        "08": { id: "provinces.CU.08", defaultMessage: "Ciego de Ávila" },
        "03": { id: "provinces.CU.03", defaultMessage: "Ciudad de La Habana" },
        14: { id: "provinces.CU.14", defaultMessage: "Guantánamo" },
        "09": { id: "provinces.CU.09", defaultMessage: "Camagüey" },
        "04": { id: "provinces.CU.04", defaultMessage: "Matanzas" },
        99: { id: "provinces.CU.99", defaultMessage: "Isla de la Juventud" },
        10: { id: "provinces.CU.10", defaultMessage: "Las Tunas" },
    }),
    CW: defineMessages({}),
    CX: defineMessages({}),
    KY: defineMessages({}),
    CY: defineMessages({
        "03": { id: "provinces.CY.03", defaultMessage: "Lárnaka" },
        "04": { id: "provinces.CY.04", defaultMessage: "Ammóchostos" },
        "05": { id: "provinces.CY.05", defaultMessage: "Páfos" },
        "06": { id: "provinces.CY.06", defaultMessage: "Kerýneia" },
        "01": { id: "provinces.CY.01", defaultMessage: "Lefkosía" },
        "02": { id: "provinces.CY.02", defaultMessage: "Lemesós" },
    }),
    CZ: defineMessages({
        209: { id: "provinces.CZ.209", defaultMessage: "Praha-východ" },
        "20A": { id: "provinces.CZ.20A", defaultMessage: "Praha-západ" },
        53: { id: "provinces.CZ.53", defaultMessage: "Pardubický kraj" },
        "20B": { id: "provinces.CZ.20B", defaultMessage: "Příbram" },
        531: { id: "provinces.CZ.531", defaultMessage: "Chrudim" },
        "20C": { id: "provinces.CZ.20C", defaultMessage: "Rakovník" },
        532: { id: "provinces.CZ.532", defaultMessage: "Pardubice" },
        31: { id: "provinces.CZ.31", defaultMessage: "Jihočeský kraj" },
        533: { id: "provinces.CZ.533", defaultMessage: "Svitavy" },
        311: { id: "provinces.CZ.311", defaultMessage: "České Budějovice" },
        534: { id: "provinces.CZ.534", defaultMessage: "Ústí nad Orlicí" },
        312: { id: "provinces.CZ.312", defaultMessage: "Český Krumlov" },
        63: { id: "provinces.CZ.63", defaultMessage: "Kraj Vysočina" },
        313: { id: "provinces.CZ.313", defaultMessage: "Jindřichův Hradec" },
        631: { id: "provinces.CZ.631", defaultMessage: "Havlíčkův Brod" },
        314: { id: "provinces.CZ.314", defaultMessage: "Písek" },
        632: { id: "provinces.CZ.632", defaultMessage: "Jihlava" },
        315: { id: "provinces.CZ.315", defaultMessage: "Prachatice" },
        633: { id: "provinces.CZ.633", defaultMessage: "Pelhřimov" },
        10: { id: "provinces.CZ.10", defaultMessage: "Praha, Hlavní mešto" },
        316: { id: "provinces.CZ.316", defaultMessage: "Strakonice" },
        634: { id: "provinces.CZ.634", defaultMessage: "Třebíč" },
        101: { id: "provinces.CZ.101", defaultMessage: "Praha 1" },
        317: { id: "provinces.CZ.317", defaultMessage: "Tábor" },
        635: { id: "provinces.CZ.635", defaultMessage: "Žďár nad Sázavou" },
        102: { id: "provinces.CZ.102", defaultMessage: "Praha 2" },
        32: { id: "provinces.CZ.32", defaultMessage: "Plzeňský kraj" },
        64: { id: "provinces.CZ.64", defaultMessage: "Jihomoravský kraj" },
        103: { id: "provinces.CZ.103", defaultMessage: "Praha 3" },
        321: { id: "provinces.CZ.321", defaultMessage: "Domažlice" },
        641: { id: "provinces.CZ.641", defaultMessage: "Blansko" },
        104: { id: "provinces.CZ.104", defaultMessage: "Praha 4" },
        322: { id: "provinces.CZ.322", defaultMessage: "Klatovy" },
        642: { id: "provinces.CZ.642", defaultMessage: "Brno-město" },
        105: { id: "provinces.CZ.105", defaultMessage: "Praha 5" },
        323: { id: "provinces.CZ.323", defaultMessage: "Plzeň-město" },
        643: { id: "provinces.CZ.643", defaultMessage: "Brno-venkov" },
        106: { id: "provinces.CZ.106", defaultMessage: "Praha 6" },
        324: { id: "provinces.CZ.324", defaultMessage: "Plzeň-jih" },
        644: { id: "provinces.CZ.644", defaultMessage: "Břeclav" },
        107: { id: "provinces.CZ.107", defaultMessage: "Praha 7" },
        325: { id: "provinces.CZ.325", defaultMessage: "Plzeň-sever" },
        645: { id: "provinces.CZ.645", defaultMessage: "Hodonín" },
        108: { id: "provinces.CZ.108", defaultMessage: "Praha 8" },
        326: { id: "provinces.CZ.326", defaultMessage: "Rokycany" },
        646: { id: "provinces.CZ.646", defaultMessage: "Vyškov" },
        109: { id: "provinces.CZ.109", defaultMessage: "Praha 9" },
        327: { id: "provinces.CZ.327", defaultMessage: "Tachov" },
        647: { id: "provinces.CZ.647", defaultMessage: "Znojmo" },
        110: { id: "provinces.CZ.110", defaultMessage: "Praha 10" },
        41: { id: "provinces.CZ.41", defaultMessage: "Karlovarský kraj" },
        71: { id: "provinces.CZ.71", defaultMessage: "Olomoucký kraj" },
        411: { id: "provinces.CZ.411", defaultMessage: "Cheb" },
        111: { id: "provinces.CZ.111", defaultMessage: "Praha 11" },
        711: { id: "provinces.CZ.711", defaultMessage: "Jeseník" },
        112: { id: "provinces.CZ.112", defaultMessage: "Praha 12" },
        412: { id: "provinces.CZ.412", defaultMessage: "Karlovy Vary" },
        712: { id: "provinces.CZ.712", defaultMessage: "Olomouc" },
        113: { id: "provinces.CZ.113", defaultMessage: "Praha 13" },
        413: { id: "provinces.CZ.413", defaultMessage: "Sokolov" },
        713: { id: "provinces.CZ.713", defaultMessage: "Prostějov" },
        114: { id: "provinces.CZ.114", defaultMessage: "Praha 14" },
        42: { id: "provinces.CZ.42", defaultMessage: "Ústecký kraj" },
        714: { id: "provinces.CZ.714", defaultMessage: "Přerov" },
        115: { id: "provinces.CZ.115", defaultMessage: "Praha 15" },
        421: { id: "provinces.CZ.421", defaultMessage: "Děčín" },
        715: { id: "provinces.CZ.715", defaultMessage: "Šumperk" },
        116: { id: "provinces.CZ.116", defaultMessage: "Praha 16" },
        422: { id: "provinces.CZ.422", defaultMessage: "Chomutov" },
        72: { id: "provinces.CZ.72", defaultMessage: "Zlínský kraj" },
        117: { id: "provinces.CZ.117", defaultMessage: "Praha 17" },
        423: { id: "provinces.CZ.423", defaultMessage: "Litoměřice" },
        721: { id: "provinces.CZ.721", defaultMessage: "Kroměříž" },
        118: { id: "provinces.CZ.118", defaultMessage: "Praha 18" },
        424: { id: "provinces.CZ.424", defaultMessage: "Louny" },
        722: { id: "provinces.CZ.722", defaultMessage: "Uherské Hradiště" },
        119: { id: "provinces.CZ.119", defaultMessage: "Praha 19" },
        425: { id: "provinces.CZ.425", defaultMessage: "Most" },
        723: { id: "provinces.CZ.723", defaultMessage: "Vsetín" },
        120: { id: "provinces.CZ.120", defaultMessage: "Praha 20" },
        426: { id: "provinces.CZ.426", defaultMessage: "Teplice" },
        724: { id: "provinces.CZ.724", defaultMessage: "Zlín" },
        121: { id: "provinces.CZ.121", defaultMessage: "Praha 21" },
        427: { id: "provinces.CZ.427", defaultMessage: "Ústí nad Labem" },
        80: { id: "provinces.CZ.80", defaultMessage: "Moravskoslezský kraj" },
        122: { id: "provinces.CZ.122", defaultMessage: "Praha 22" },
        51: { id: "provinces.CZ.51", defaultMessage: "Liberecký kraj" },
        801: { id: "provinces.CZ.801", defaultMessage: "Bruntál" },
        20: { id: "provinces.CZ.20", defaultMessage: "Středočeský kraj" },
        511: { id: "provinces.CZ.511", defaultMessage: "Česká Lípa" },
        802: { id: "provinces.CZ.802", defaultMessage: "Frýdek Místek" },
        201: { id: "provinces.CZ.201", defaultMessage: "Benešov" },
        512: { id: "provinces.CZ.512", defaultMessage: "Jablonec nad Nisou" },
        803: { id: "provinces.CZ.803", defaultMessage: "Karviná" },
        202: { id: "provinces.CZ.202", defaultMessage: "Beroun" },
        513: { id: "provinces.CZ.513", defaultMessage: "Liberec" },
        804: { id: "provinces.CZ.804", defaultMessage: "Nový Jičín" },
        203: { id: "provinces.CZ.203", defaultMessage: "Kladno" },
        514: { id: "provinces.CZ.514", defaultMessage: "Semily" },
        805: { id: "provinces.CZ.805", defaultMessage: "Opava" },
        204: { id: "provinces.CZ.204", defaultMessage: "Kolín" },
        52: { id: "provinces.CZ.52", defaultMessage: "Královéhradecký kraj" },
        806: { id: "provinces.CZ.806", defaultMessage: "Ostrava-město" },
        205: { id: "provinces.CZ.205", defaultMessage: "Kutná Hora" },
        521: { id: "provinces.CZ.521", defaultMessage: "Hradec Králové" },
        206: { id: "provinces.CZ.206", defaultMessage: "Mělník" },
        522: { id: "provinces.CZ.522", defaultMessage: "Jičín" },
        207: { id: "provinces.CZ.207", defaultMessage: "Mladá Boleslav" },
        523: { id: "provinces.CZ.523", defaultMessage: "Náchod" },
        208: { id: "provinces.CZ.208", defaultMessage: "Nymburk" },
        524: { id: "provinces.CZ.524", defaultMessage: "Rychnov nad Kněžnou" },
        525: { id: "provinces.CZ.525", defaultMessage: "Trutnov" },
    }),
    DE: defineMessages({
        TH: { id: "provinces.DE.TH", defaultMessage: "Thüringen" },
        BB: { id: "provinces.DE.BB", defaultMessage: "Brandenburg" },
        RP: { id: "provinces.DE.RP", defaultMessage: "Rheinland-Pfalz" },
        HE: { id: "provinces.DE.HE", defaultMessage: "Hessen" },
        ST: { id: "provinces.DE.ST", defaultMessage: "Sachsen-Anhalt" },
        BE: { id: "provinces.DE.BE", defaultMessage: "Berlin" },
        SH: { id: "provinces.DE.SH", defaultMessage: "Schleswig-Holstein" },
        HH: { id: "provinces.DE.HH", defaultMessage: "Hamburg" },
        BW: { id: "provinces.DE.BW", defaultMessage: "Baden-Württemberg" },
        SL: { id: "provinces.DE.SL", defaultMessage: "Saarland" },
        MV: { id: "provinces.DE.MV", defaultMessage: "Mecklenburg-Vorpommern" },
        BY: { id: "provinces.DE.BY", defaultMessage: "Bayern" },
        SN: { id: "provinces.DE.SN", defaultMessage: "Sachsen" },
        NI: { id: "provinces.DE.NI", defaultMessage: "Niedersachsen" },
        HB: { id: "provinces.DE.HB", defaultMessage: "Bremen" },
        NW: { id: "provinces.DE.NW", defaultMessage: "Nordrhein-Westfalen" },
    }),
    DJ: defineMessages({
        TA: { id: "provinces.DJ.TA", defaultMessage: "Tadjourah" },
        AR: { id: "provinces.DJ.AR", defaultMessage: "Arta" },
        AS: { id: "provinces.DJ.AS", defaultMessage: "Ali Sabieh" },
        DI: { id: "provinces.DJ.DI", defaultMessage: "Dikhil" },
        DJ: { id: "provinces.DJ.DJ", defaultMessage: "Djibouti" },
        OB: { id: "provinces.DJ.OB", defaultMessage: "Obock" },
    }),
    DM: defineMessages({
        "05": { id: "provinces.DM.05", defaultMessage: "Saint John" },
        "06": { id: "provinces.DM.06", defaultMessage: "Saint Joseph" },
        "01": { id: "provinces.DM.01", defaultMessage: "Saint Peter" },
        "07": { id: "provinces.DM.07", defaultMessage: "Saint Luke" },
        "02": { id: "provinces.DM.02", defaultMessage: "Saint Andrew" },
        "08": { id: "provinces.DM.08", defaultMessage: "Saint Mark" },
        "03": { id: "provinces.DM.03", defaultMessage: "Saint David" },
        "09": { id: "provinces.DM.09", defaultMessage: "Saint Patrick" },
        "04": { id: "provinces.DM.04", defaultMessage: "Saint George" },
        10: { id: "provinces.DM.10", defaultMessage: "Saint Paul" },
    }),
    DK: defineMessages({
        85: { id: "provinces.DK.85", defaultMessage: "Sjælland" },
        81: { id: "provinces.DK.81", defaultMessage: "Nordjylland" },
        82: { id: "provinces.DK.82", defaultMessage: "Midtjylland" },
        83: { id: "provinces.DK.83", defaultMessage: "Syddanmark" },
        84: { id: "provinces.DK.84", defaultMessage: "Hovedstaden" },
    }),
    DO: defineMessages({
        11: { id: "provinces.DO.11", defaultMessage: "La Altagracia" },
        12: { id: "provinces.DO.12", defaultMessage: "La Romana" },
        13: { id: "provinces.DO.13", defaultMessage: "La Vega" },
        14: { id: "provinces.DO.14", defaultMessage: "María Trinidad Sánchez" },
        15: { id: "provinces.DO.15", defaultMessage: "Monte Cristi" },
        16: { id: "provinces.DO.16", defaultMessage: "Pedernales" },
        17: { id: "provinces.DO.17", defaultMessage: "Peravia" },
        18: { id: "provinces.DO.18", defaultMessage: "Puerto Plata" },
        19: { id: "provinces.DO.19", defaultMessage: "Salcedo" },
        20: { id: "provinces.DO.20", defaultMessage: "Samaná" },
        21: { id: "provinces.DO.21", defaultMessage: "San Cristóbal" },
        "01": { id: "provinces.DO.01", defaultMessage: "Distrito Nacional (Santo Domingo)" },
        22: { id: "provinces.DO.22", defaultMessage: "San Juan" },
        "02": { id: "provinces.DO.02", defaultMessage: "Azua" },
        23: { id: "provinces.DO.23", defaultMessage: "San Pedro de Macorís" },
        "03": { id: "provinces.DO.03", defaultMessage: "Bahoruco" },
        24: { id: "provinces.DO.24", defaultMessage: "Sánchez Ramírez" },
        "04": { id: "provinces.DO.04", defaultMessage: "Barahona" },
        25: { id: "provinces.DO.25", defaultMessage: "Santiago" },
        "05": { id: "provinces.DO.05", defaultMessage: "Dajabón" },
        26: { id: "provinces.DO.26", defaultMessage: "Santiago Rodríguez" },
        "06": { id: "provinces.DO.06", defaultMessage: "Duarte" },
        27: { id: "provinces.DO.27", defaultMessage: "Valverde" },
        "07": { id: "provinces.DO.07", defaultMessage: "La Estrelleta [Elías Piña]" },
        28: { id: "provinces.DO.28", defaultMessage: "Monseñor Nouel" },
        "08": { id: "provinces.DO.08", defaultMessage: "El Seybo [El Seibo]" },
        29: { id: "provinces.DO.29", defaultMessage: "Monte Plata" },
        "09": { id: "provinces.DO.09", defaultMessage: "Espaillat" },
        30: { id: "provinces.DO.30", defaultMessage: "Hato Mayor" },
        10: { id: "provinces.DO.10", defaultMessage: "Independencia" },
    }),
    DZ: defineMessages({
        "02": { id: "provinces.DZ.02", defaultMessage: "Chlef" },
        44: { id: "provinces.DZ.44", defaultMessage: "Aïn Defla" },
        23: { id: "provinces.DZ.23", defaultMessage: "Annaba" },
        "03": { id: "provinces.DZ.03", defaultMessage: "Laghouat" },
        45: { id: "provinces.DZ.45", defaultMessage: "Naama" },
        24: { id: "provinces.DZ.24", defaultMessage: "Guelma" },
        "04": { id: "provinces.DZ.04", defaultMessage: "Oum el Bouaghi" },
        46: { id: "provinces.DZ.46", defaultMessage: "Aïn Témouchent" },
        25: { id: "provinces.DZ.25", defaultMessage: "Constantine" },
        "05": { id: "provinces.DZ.05", defaultMessage: "Batna" },
        47: { id: "provinces.DZ.47", defaultMessage: "Ghardaïa" },
        26: { id: "provinces.DZ.26", defaultMessage: "Médéa" },
        "06": { id: "provinces.DZ.06", defaultMessage: "Béjaïa" },
        48: { id: "provinces.DZ.48", defaultMessage: "Relizane" },
        27: { id: "provinces.DZ.27", defaultMessage: "Mostaganem" },
        "07": { id: "provinces.DZ.07", defaultMessage: "Biskra" },
        43: { id: "provinces.DZ.43", defaultMessage: "Mila" },
        28: { id: "provinces.DZ.28", defaultMessage: "Msila" },
        "08": { id: "provinces.DZ.08", defaultMessage: "Béchar" },
        29: { id: "provinces.DZ.29", defaultMessage: "Mascara" },
        "09": { id: "provinces.DZ.09", defaultMessage: "Blida" },
        30: { id: "provinces.DZ.30", defaultMessage: "Ouargla" },
        10: { id: "provinces.DZ.10", defaultMessage: "Bouira" },
        31: { id: "provinces.DZ.31", defaultMessage: "Oran" },
        11: { id: "provinces.DZ.11", defaultMessage: "Tamanghasset" },
        32: { id: "provinces.DZ.32", defaultMessage: "El Bayadh" },
        12: { id: "provinces.DZ.12", defaultMessage: "Tébessa" },
        33: { id: "provinces.DZ.33", defaultMessage: "Illizi" },
        13: { id: "provinces.DZ.13", defaultMessage: "Tlemcen" },
        34: { id: "provinces.DZ.34", defaultMessage: "Bordj Bou Arréridj" },
        14: { id: "provinces.DZ.14", defaultMessage: "Tiaret" },
        35: { id: "provinces.DZ.35", defaultMessage: "Boumerdès" },
        15: { id: "provinces.DZ.15", defaultMessage: "Tizi Ouzou" },
        36: { id: "provinces.DZ.36", defaultMessage: "El Tarf" },
        16: { id: "provinces.DZ.16", defaultMessage: "Alger" },
        37: { id: "provinces.DZ.37", defaultMessage: "Tindouf" },
        17: { id: "provinces.DZ.17", defaultMessage: "Djelfa" },
        38: { id: "provinces.DZ.38", defaultMessage: "Tissemsilt" },
        18: { id: "provinces.DZ.18", defaultMessage: "Jijel" },
        39: { id: "provinces.DZ.39", defaultMessage: "El Oued" },
        19: { id: "provinces.DZ.19", defaultMessage: "Sétif" },
        40: { id: "provinces.DZ.40", defaultMessage: "Khenchela" },
        20: { id: "provinces.DZ.20", defaultMessage: "Saïda" },
        41: { id: "provinces.DZ.41", defaultMessage: "Souk Ahras" },
        21: { id: "provinces.DZ.21", defaultMessage: "Skikda" },
        42: { id: "provinces.DZ.42", defaultMessage: "Tipaza" },
        22: { id: "provinces.DZ.22", defaultMessage: "Sidi Bel Abbès" },
        "01": { id: "provinces.DZ.01", defaultMessage: "Adrar" },
    }),
    EC: defineMessages({
        SD: { id: "provinces.EC.SD", defaultMessage: "Santo Domingo de los Tsáchilas" },
        SE: { id: "provinces.EC.SE", defaultMessage: "Santa Elena" },
        T: { id: "provinces.EC.T", defaultMessage: "Tungurahua" },
        U: { id: "provinces.EC.U", defaultMessage: "Sucumbíos" },
        W: { id: "provinces.EC.W", defaultMessage: "Galápagos" },
        A: { id: "provinces.EC.A", defaultMessage: "Azuay" },
        X: { id: "provinces.EC.X", defaultMessage: "Cotopaxi" },
        B: { id: "provinces.EC.B", defaultMessage: "Bolívar" },
        Y: { id: "provinces.EC.Y", defaultMessage: "Pastaza" },
        C: { id: "provinces.EC.C", defaultMessage: "Carchi" },
        Z: { id: "provinces.EC.Z", defaultMessage: "Zamora-Chinchipe" },
        D: { id: "provinces.EC.D", defaultMessage: "Orellana" },
        E: { id: "provinces.EC.E", defaultMessage: "Esmeraldas" },
        F: { id: "provinces.EC.F", defaultMessage: "Cañar" },
        G: { id: "provinces.EC.G", defaultMessage: "Guayas" },
        H: { id: "provinces.EC.H", defaultMessage: "Chimborazo" },
        I: { id: "provinces.EC.I", defaultMessage: "Imbabura" },
        L: { id: "provinces.EC.L", defaultMessage: "Loja" },
        M: { id: "provinces.EC.M", defaultMessage: "Manabí" },
        N: { id: "provinces.EC.N", defaultMessage: "Napo" },
        O: { id: "provinces.EC.O", defaultMessage: "El Oro" },
        P: { id: "provinces.EC.P", defaultMessage: "Pichincha" },
        R: { id: "provinces.EC.R", defaultMessage: "Los Ríos" },
        S: { id: "provinces.EC.S", defaultMessage: "Morona-Santiago" },
    }),
    EG: defineMessages({
        MNF: { id: "provinces.EG.MNF", defaultMessage: "Al Minūfīyah" },
        MT: { id: "provinces.EG.MT", defaultMessage: "Matrūh" },
        ALX: { id: "provinces.EG.ALX", defaultMessage: "Al Iskandarīyah" },
        PTS: { id: "provinces.EG.PTS", defaultMessage: "Būr Sa`īd" },
        ASN: { id: "provinces.EG.ASN", defaultMessage: "Aswān" },
        SHG: { id: "provinces.EG.SHG", defaultMessage: "Sūhāj" },
        AST: { id: "provinces.EG.AST", defaultMessage: "Asyūt" },
        SHR: { id: "provinces.EG.SHR", defaultMessage: "Ash Sharqīyah" },
        BA: { id: "provinces.EG.BA", defaultMessage: "Al Bahr al Ahmar" },
        SIN: { id: "provinces.EG.SIN", defaultMessage: "Shamal Sīnā'" },
        BH: { id: "provinces.EG.BH", defaultMessage: "Al Buhayrah" },
        SU: { id: "provinces.EG.SU", defaultMessage: "As Sādis min Uktūbar" },
        BNS: { id: "provinces.EG.BNS", defaultMessage: "Banī Suwayf" },
        SUZ: { id: "provinces.EG.SUZ", defaultMessage: "As Suways" },
        C: { id: "provinces.EG.C", defaultMessage: "Al Qāhirah" },
        WAD: { id: "provinces.EG.WAD", defaultMessage: "Al Wādī al Jadīd" },
        DK: { id: "provinces.EG.DK", defaultMessage: "Ad Daqahlīyah" },
        DT: { id: "provinces.EG.DT", defaultMessage: "Dumyāt" },
        FYM: { id: "provinces.EG.FYM", defaultMessage: "Al Fayyūm" },
        GH: { id: "provinces.EG.GH", defaultMessage: "Al Gharbīyah" },
        GZ: { id: "provinces.EG.GZ", defaultMessage: "Al Jīzah" },
        HU: { id: "provinces.EG.HU", defaultMessage: "Ḩulwān" },
        IS: { id: "provinces.EG.IS", defaultMessage: "Al Ismā`īlīyah" },
        JS: { id: "provinces.EG.JS", defaultMessage: "Janūb Sīnā'" },
        KB: { id: "provinces.EG.KB", defaultMessage: "Al Qalyūbīyah" },
        KFS: { id: "provinces.EG.KFS", defaultMessage: "Kafr ash Shaykh" },
        KN: { id: "provinces.EG.KN", defaultMessage: "Qinā" },
        MN: { id: "provinces.EG.MN", defaultMessage: "Al Minyā" },
    }),
    ER: defineMessages({
        DK: { id: "provinces.ER.DK", defaultMessage: "Janūbī al Baḩrī al Aḩmar" },
        DU: { id: "provinces.ER.DU", defaultMessage: "Al Janūbī" },
        GB: { id: "provinces.ER.GB", defaultMessage: "Qāsh-Barkah" },
        MA: { id: "provinces.ER.MA", defaultMessage: "Al Awsaţ" },
        SK: { id: "provinces.ER.SK", defaultMessage: "Shimālī al Baḩrī al Aḩmar" },
        AN: { id: "provinces.ER.AN", defaultMessage: "Ansabā" },
    }),
    EH: defineMessages({}),
    ES: defineMessages({
        AV: { id: "provinces.ES.AV", defaultMessage: "Ávila" },
        P: { id: "provinces.ES.P", defaultMessage: "Palencia" },
        GI: { id: "provinces.ES.GI", defaultMessage: "Girona" },
        B: { id: "provinces.ES.B", defaultMessage: "Barcelona" },
        PM: { id: "provinces.ES.PM", defaultMessage: "Balears" },
        GR: { id: "provinces.ES.GR", defaultMessage: "Granada" },
        BA: { id: "provinces.ES.BA", defaultMessage: "Badajoz" },
        PO: { id: "provinces.ES.PO", defaultMessage: "Pontevedra" },
        GU: { id: "provinces.ES.GU", defaultMessage: "Guadalajara" },
        BI: { id: "provinces.ES.BI", defaultMessage: "Bizkaia" },
        PV: { id: "provinces.ES.PV", defaultMessage: "País Vasco / Euskal Herria" },
        H: { id: "provinces.ES.H", defaultMessage: "Huelva" },
        BU: { id: "provinces.ES.BU", defaultMessage: "Burgos" },
        RI: { id: "provinces.ES.RI", defaultMessage: "La Rioja" },
        HU: { id: "provinces.ES.HU", defaultMessage: "Huesca" },
        C: { id: "provinces.ES.C", defaultMessage: "A Coruña" },
        S: { id: "provinces.ES.S", defaultMessage: "Cantabria" },
        IB: { id: "provinces.ES.IB", defaultMessage: "Illes Balears" },
        ZA: { id: "provinces.ES.ZA", defaultMessage: "Zamora" },
        CA: { id: "provinces.ES.CA", defaultMessage: "Cádiz" },
        SA: { id: "provinces.ES.SA", defaultMessage: "Salamanca" },
        J: { id: "provinces.ES.J", defaultMessage: "Jaén" },
        OR: { id: "provinces.ES.OR", defaultMessage: "Ourense" },
        CB: { id: "provinces.ES.CB", defaultMessage: "Cantabria" },
        SE: { id: "provinces.ES.SE", defaultMessage: "Sevilla" },
        L: { id: "provinces.ES.L", defaultMessage: "Lleida" },
        CC: { id: "provinces.ES.CC", defaultMessage: "Cáceres" },
        SG: { id: "provinces.ES.SG", defaultMessage: "Segovia" },
        LE: { id: "provinces.ES.LE", defaultMessage: "León" },
        CE: { id: "provinces.ES.CE", defaultMessage: "Ceuta" },
        SO: { id: "provinces.ES.SO", defaultMessage: "Soria" },
        LO: { id: "provinces.ES.LO", defaultMessage: "La Rioja" },
        CL: { id: "provinces.ES.CL", defaultMessage: "Castilla y León" },
        SS: { id: "provinces.ES.SS", defaultMessage: "Gipuzkoa" },
        LU: { id: "provinces.ES.LU", defaultMessage: "Lugo" },
        CM: { id: "provinces.ES.CM", defaultMessage: "Castilla-La Mancha" },
        T: { id: "provinces.ES.T", defaultMessage: "Tarragona" },
        M: { id: "provinces.ES.M", defaultMessage: "Madrid" },
        CN: { id: "provinces.ES.CN", defaultMessage: "Canarias" },
        TE: { id: "provinces.ES.TE", defaultMessage: "Teruel" },
        MA: { id: "provinces.ES.MA", defaultMessage: "Málaga" },
        CO: { id: "provinces.ES.CO", defaultMessage: "Córdoba" },
        TF: { id: "provinces.ES.TF", defaultMessage: "Santa Cruz de Tenerife" },
        MC: { id: "provinces.ES.MC", defaultMessage: "Murcia, Región de" },
        CR: { id: "provinces.ES.CR", defaultMessage: "Ciudad Real" },
        TO: { id: "provinces.ES.TO", defaultMessage: "Toledo" },
        MD: { id: "provinces.ES.MD", defaultMessage: "Madrid, Comunidad de" },
        CS: { id: "provinces.ES.CS", defaultMessage: "Castellón" },
        V: { id: "provinces.ES.V", defaultMessage: "Valencia / València" },
        A: { id: "provinces.ES.A", defaultMessage: "Alicante" },
        ML: { id: "provinces.ES.ML", defaultMessage: "Melilla" },
        CT: { id: "provinces.ES.CT", defaultMessage: "Catalunya" },
        VA: { id: "provinces.ES.VA", defaultMessage: "Valladolid" },
        AB: { id: "provinces.ES.AB", defaultMessage: "Albacete" },
        MU: { id: "provinces.ES.MU", defaultMessage: "Murcia" },
        CU: { id: "provinces.ES.CU", defaultMessage: "Cuenca" },
        VC: { id: "provinces.ES.VC", defaultMessage: "Valenciana, Comunidad / Valenciana, Comunitat" },
        AL: { id: "provinces.ES.AL", defaultMessage: "Almería" },
        NA: { id: "provinces.ES.NA", defaultMessage: "Navarra / Nafarroa" },
        EX: { id: "provinces.ES.EX", defaultMessage: "Extremadura" },
        VI: { id: "provinces.ES.VI", defaultMessage: "Álava" },
        AN: { id: "provinces.ES.AN", defaultMessage: "Andalucía" },
        NC: { id: "provinces.ES.NC", defaultMessage: "Navarra, Comunidad Foral de / Nafarroako Foru Komunitatea" },
        GA: { id: "provinces.ES.GA", defaultMessage: "Galicia" },
        Z: { id: "provinces.ES.Z", defaultMessage: "Zaragoza" },
        AR: { id: "provinces.ES.AR", defaultMessage: "Aragón" },
        O: { id: "provinces.ES.O", defaultMessage: "Asturias" },
        GC: { id: "provinces.ES.GC", defaultMessage: "Las Palmas" },
        AS: { id: "provinces.ES.AS", defaultMessage: "Asturias, Principado de" },
    }),
    EE: defineMessages({
        44: { id: "provinces.EE.44", defaultMessage: "Ida-Virumaa" },
        84: { id: "provinces.EE.84", defaultMessage: "Viljandimaa" },
        67: { id: "provinces.EE.67", defaultMessage: "Pärnumaa" },
        49: { id: "provinces.EE.49", defaultMessage: "Jõgevamaa" },
        86: { id: "provinces.EE.86", defaultMessage: "Võrumaa" },
        70: { id: "provinces.EE.70", defaultMessage: "Raplamaa" },
        51: { id: "provinces.EE.51", defaultMessage: "Järvamaa" },
        74: { id: "provinces.EE.74", defaultMessage: "Saaremaa" },
        57: { id: "provinces.EE.57", defaultMessage: "Läänemaa" },
        37: { id: "provinces.EE.37", defaultMessage: "Harjumaa" },
        78: { id: "provinces.EE.78", defaultMessage: "Tartumaa" },
        59: { id: "provinces.EE.59", defaultMessage: "Lääne-Virumaa" },
        39: { id: "provinces.EE.39", defaultMessage: "Hiiumaa" },
        82: { id: "provinces.EE.82", defaultMessage: "Valgamaa" },
        65: { id: "provinces.EE.65", defaultMessage: "Põlvamaa" },
    }),
    ET: defineMessages({
        TI: { id: "provinces.ET.TI", defaultMessage: "Tigray" },
        GA: { id: "provinces.ET.GA", defaultMessage: "Gambēla Hizboch" },
        AA: { id: "provinces.ET.AA", defaultMessage: "Ādīs Ābeba" },
        HA: { id: "provinces.ET.HA", defaultMessage: "Hārerī Hizb" },
        AF: { id: "provinces.ET.AF", defaultMessage: "Āfar" },
        OR: { id: "provinces.ET.OR", defaultMessage: "Oromīya" },
        AM: { id: "provinces.ET.AM", defaultMessage: "Āmara" },
        SN: { id: "provinces.ET.SN", defaultMessage: "YeDebub Bihēroch Bihēreseboch na Hizboch" },
        BE: { id: "provinces.ET.BE", defaultMessage: "Bīnshangul Gumuz" },
        SO: { id: "provinces.ET.SO", defaultMessage: "Sumalē" },
        DD: { id: "provinces.ET.DD", defaultMessage: "Dirē Dawa" },
    }),
    FI: defineMessages({
        11: { id: "provinces.FI.11", defaultMessage: "Pirkanmaa" },
        12: { id: "provinces.FI.12", defaultMessage: "Pohjanmaa" },
        13: { id: "provinces.FI.13", defaultMessage: "Pohjois-Karjala" },
        14: { id: "provinces.FI.14", defaultMessage: "Pohjois-Pohjanmaa" },
        15: { id: "provinces.FI.15", defaultMessage: "Pohjois-Savo" },
        16: { id: "provinces.FI.16", defaultMessage: "Päijät-Häme" },
        17: { id: "provinces.FI.17", defaultMessage: "Satakunta" },
        18: { id: "provinces.FI.18", defaultMessage: "Uusimaa" },
        19: { id: "provinces.FI.19", defaultMessage: "Varsinais-Suomi" },
        "01": { id: "provinces.FI.01", defaultMessage: "Ahvenanmaan maakunta" },
        "02": { id: "provinces.FI.02", defaultMessage: "Etelä-Karjala" },
        "03": { id: "provinces.FI.03", defaultMessage: "Etelä-Pohjanmaa" },
        "04": { id: "provinces.FI.04", defaultMessage: "Etelä-Savo" },
        "05": { id: "provinces.FI.05", defaultMessage: "Kainuu" },
        "06": { id: "provinces.FI.06", defaultMessage: "Kanta-Häme" },
        "07": { id: "provinces.FI.07", defaultMessage: "Keski-Pohjanmaa" },
        "08": { id: "provinces.FI.08", defaultMessage: "Keski-Suomi" },
        "09": { id: "provinces.FI.09", defaultMessage: "Kymenlaakso" },
        10: { id: "provinces.FI.10", defaultMessage: "Lappi" },
    }),
    FJ: defineMessages({
        N: { id: "provinces.FJ.N", defaultMessage: "Northern" },
        R: { id: "provinces.FJ.R", defaultMessage: "Rotuma" },
        W: { id: "provinces.FJ.W", defaultMessage: "Western" },
        C: { id: "provinces.FJ.C", defaultMessage: "Central" },
        E: { id: "provinces.FJ.E", defaultMessage: "Eastern" },
    }),
    FK: defineMessages({}),
    FR: defineMessages({
        "04": { id: "provinces.FR.04", defaultMessage: "Alpes-de-Haute-Provence" },
        "05": { id: "provinces.FR.05", defaultMessage: "Hautes-Alpes" },
        "06": { id: "provinces.FR.06", defaultMessage: "Alpes-Maritimes" },
        "07": { id: "provinces.FR.07", defaultMessage: "Ardèche" },
        "08": { id: "provinces.FR.08", defaultMessage: "Ardennes" },
        "09": { id: "provinces.FR.09", defaultMessage: "Ariège" },
        10: { id: "provinces.FR.10", defaultMessage: "Aube" },
        11: { id: "provinces.FR.11", defaultMessage: "Aude" },
        12: { id: "provinces.FR.12", defaultMessage: "Aveyron" },
        13: { id: "provinces.FR.13", defaultMessage: "Bouches-du-Rhône" },
        14: { id: "provinces.FR.14", defaultMessage: "Calvados" },
        15: { id: "provinces.FR.15", defaultMessage: "Cantal" },
        16: { id: "provinces.FR.16", defaultMessage: "Charente" },
        17: { id: "provinces.FR.17", defaultMessage: "Charente-Maritime" },
        18: { id: "provinces.FR.18", defaultMessage: "Cher" },
        19: { id: "provinces.FR.19", defaultMessage: "Corrèze" },
        21: { id: "provinces.FR.21", defaultMessage: "Côte-d'Or" },
        22: { id: "provinces.FR.22", defaultMessage: "Côtes-d'Armor" },
        23: { id: "provinces.FR.23", defaultMessage: "Creuse" },
        24: { id: "provinces.FR.24", defaultMessage: "Dordogne" },
        25: { id: "provinces.FR.25", defaultMessage: "Doubs" },
        26: { id: "provinces.FR.26", defaultMessage: "Drôme" },
        27: { id: "provinces.FR.27", defaultMessage: "Eure" },
        28: { id: "provinces.FR.28", defaultMessage: "Eure-et-Loir" },
        29: { id: "provinces.FR.29", defaultMessage: "Finistère" },
        "2A": { id: "provinces.FR.2A", defaultMessage: "Corse-du-Sud" },
        "2B": { id: "provinces.FR.2B", defaultMessage: "Haute-Corse" },
        30: { id: "provinces.FR.30", defaultMessage: "Gard" },
        31: { id: "provinces.FR.31", defaultMessage: "Haute-Garonne" },
        32: { id: "provinces.FR.32", defaultMessage: "Gers" },
        33: { id: "provinces.FR.33", defaultMessage: "Gironde" },
        34: { id: "provinces.FR.34", defaultMessage: "Hérault" },
        35: { id: "provinces.FR.35", defaultMessage: "Ille-et-Vilaine" },
        36: { id: "provinces.FR.36", defaultMessage: "Indre" },
        37: { id: "provinces.FR.37", defaultMessage: "Indre-et-Loire" },
        38: { id: "provinces.FR.38", defaultMessage: "Isère" },
        39: { id: "provinces.FR.39", defaultMessage: "Jura" },
        40: { id: "provinces.FR.40", defaultMessage: "Landes" },
        41: { id: "provinces.FR.41", defaultMessage: "Loir-et-Cher" },
        "01": { id: "provinces.FR.01", defaultMessage: "Ain" },
        42: { id: "provinces.FR.42", defaultMessage: "Loire" },
        "02": { id: "provinces.FR.02", defaultMessage: "Aisne" },
        43: { id: "provinces.FR.43", defaultMessage: "Haute-Loire" },
        44: { id: "provinces.FR.44", defaultMessage: "Loire-Atlantique" },
        "03": { id: "provinces.FR.03", defaultMessage: "Allier" },
        45: { id: "provinces.FR.45", defaultMessage: "Loiret" },
        87: { id: "provinces.FR.87", defaultMessage: "Haute-Vienne" },
        46: { id: "provinces.FR.46", defaultMessage: "Lot" },
        88: { id: "provinces.FR.88", defaultMessage: "Vosges" },
        47: { id: "provinces.FR.47", defaultMessage: "Lot-et-Garonne" },
        89: { id: "provinces.FR.89", defaultMessage: "Yonne" },
        48: { id: "provinces.FR.48", defaultMessage: "Lozère" },
        90: { id: "provinces.FR.90", defaultMessage: "Territoire de Belfort" },
        49: { id: "provinces.FR.49", defaultMessage: "Maine-et-Loire" },
        91: { id: "provinces.FR.91", defaultMessage: "Essonne" },
        50: { id: "provinces.FR.50", defaultMessage: "Manche" },
        92: { id: "provinces.FR.92", defaultMessage: "Hauts-de-Seine" },
        51: { id: "provinces.FR.51", defaultMessage: "Marne" },
        93: { id: "provinces.FR.93", defaultMessage: "Seine-Saint-Denis" },
        52: { id: "provinces.FR.52", defaultMessage: "Haute-Marne" },
        94: { id: "provinces.FR.94", defaultMessage: "Val-de-Marne" },
        53: { id: "provinces.FR.53", defaultMessage: "Mayenne" },
        95: { id: "provinces.FR.95", defaultMessage: "Val-d'Oise" },
        54: { id: "provinces.FR.54", defaultMessage: "Meurthe-et-Moselle" },
        ARA: { id: "provinces.FR.ARA", defaultMessage: "Auvergne-Rhône-Alpes" },
        55: { id: "provinces.FR.55", defaultMessage: "Meuse" },
        BFC: { id: "provinces.FR.BFC", defaultMessage: "Bourgogne-Franche-Comté" },
        56: { id: "provinces.FR.56", defaultMessage: "Morbihan" },
        BL: { id: "provinces.FR.BL", defaultMessage: "Saint-Barthélemy" },
        57: { id: "provinces.FR.57", defaultMessage: "Moselle" },
        BRE: { id: "provinces.FR.BRE", defaultMessage: "Bretagne" },
        58: { id: "provinces.FR.58", defaultMessage: "Nièvre" },
        COR: { id: "provinces.FR.COR", defaultMessage: "Corse" },
        59: { id: "provinces.FR.59", defaultMessage: "Nord" },
        CP: { id: "provinces.FR.CP", defaultMessage: "Clipperton" },
        60: { id: "provinces.FR.60", defaultMessage: "Oise" },
        CVL: { id: "provinces.FR.CVL", defaultMessage: "Centre-Val de Loire" },
        61: { id: "provinces.FR.61", defaultMessage: "Orne" },
        GES: { id: "provinces.FR.GES", defaultMessage: "Grand-Est" },
        62: { id: "provinces.FR.62", defaultMessage: "Pas-de-Calais" },
        GF: { id: "provinces.FR.GF", defaultMessage: "Guyane (française)" },
        63: { id: "provinces.FR.63", defaultMessage: "Puy-de-Dôme" },
        GP: { id: "provinces.FR.GP", defaultMessage: "Guadeloupe" },
        64: { id: "provinces.FR.64", defaultMessage: "Pyrénées-Atlantiques" },
        GUA: { id: "provinces.FR.GUA", defaultMessage: "Guadeloupe" },
        65: { id: "provinces.FR.65", defaultMessage: "Hautes-Pyrénées" },
        HDF: { id: "provinces.FR.HDF", defaultMessage: "Hauts-de-France" },
        66: { id: "provinces.FR.66", defaultMessage: "Pyrénées-Orientales" },
        IDF: { id: "provinces.FR.IDF", defaultMessage: "Île-de-France" },
        67: { id: "provinces.FR.67", defaultMessage: "Bas-Rhin" },
        LRE: { id: "provinces.FR.LRE", defaultMessage: "La Réunion" },
        68: { id: "provinces.FR.68", defaultMessage: "Haut-Rhin" },
        MAY: { id: "provinces.FR.MAY", defaultMessage: "Mayotte" },
        69: { id: "provinces.FR.69", defaultMessage: "Rhône" },
        MF: { id: "provinces.FR.MF", defaultMessage: "Saint-Martin" },
        70: { id: "provinces.FR.70", defaultMessage: "Haute-Saône" },
        MQ: { id: "provinces.FR.MQ", defaultMessage: "Martinique" },
        71: { id: "provinces.FR.71", defaultMessage: "Saône-et-Loire" },
        NAQ: { id: "provinces.FR.NAQ", defaultMessage: "Nouvelle-Aquitaine" },
        72: { id: "provinces.FR.72", defaultMessage: "Sarthe" },
        NC: { id: "provinces.FR.NC", defaultMessage: "Nouvelle-Calédonie" },
        73: { id: "provinces.FR.73", defaultMessage: "Savoie" },
        NOR: { id: "provinces.FR.NOR", defaultMessage: "Normandie" },
        74: { id: "provinces.FR.74", defaultMessage: "Haute-Savoie" },
        OCC: { id: "provinces.FR.OCC", defaultMessage: "Occitanie" },
        75: { id: "provinces.FR.75", defaultMessage: "Paris" },
        PAC: { id: "provinces.FR.PAC", defaultMessage: "Provence-Alpes-Côte-d’Azur" },
        76: { id: "provinces.FR.76", defaultMessage: "Seine-Maritime" },
        PDL: { id: "provinces.FR.PDL", defaultMessage: "Pays-de-la-Loire" },
        77: { id: "provinces.FR.77", defaultMessage: "Seine-et-Marne" },
        PF: { id: "provinces.FR.PF", defaultMessage: "Polynésie française" },
        78: { id: "provinces.FR.78", defaultMessage: "Yvelines" },
        PM: { id: "provinces.FR.PM", defaultMessage: "Saint-Pierre-et-Miquelon" },
        79: { id: "provinces.FR.79", defaultMessage: "Deux-Sèvres" },
        RE: { id: "provinces.FR.RE", defaultMessage: "La Réunion" },
        80: { id: "provinces.FR.80", defaultMessage: "Somme" },
        TF: { id: "provinces.FR.TF", defaultMessage: "Terres australes françaises" },
        81: { id: "provinces.FR.81", defaultMessage: "Tarn" },
        WF: { id: "provinces.FR.WF", defaultMessage: "Wallis-et-Futuna" },
        82: { id: "provinces.FR.82", defaultMessage: "Tarn-et-Garonne" },
        YT: { id: "provinces.FR.YT", defaultMessage: "Mayotte" },
        83: { id: "provinces.FR.83", defaultMessage: "Var" },
        84: { id: "provinces.FR.84", defaultMessage: "Vaucluse" },
        85: { id: "provinces.FR.85", defaultMessage: "Vendée" },
        86: { id: "provinces.FR.86", defaultMessage: "Vienne" },
    }),
    FO: defineMessages({}),
    FM: defineMessages({
        YAP: { id: "provinces.FM.YAP", defaultMessage: "Yap" },
        TRK: { id: "provinces.FM.TRK", defaultMessage: "Chuuk" },
        PNI: { id: "provinces.FM.PNI", defaultMessage: "Pohnpei" },
        KSA: { id: "provinces.FM.KSA", defaultMessage: "Kosrae" },
    }),
    GA: defineMessages({
        5: { id: "provinces.GA.5", defaultMessage: "Nyanga" },
        1: { id: "provinces.GA.1", defaultMessage: "Estuaire" },
        6: { id: "provinces.GA.6", defaultMessage: "Ogooué-Ivindo" },
        2: { id: "provinces.GA.2", defaultMessage: "Haut-Ogooué" },
        7: { id: "provinces.GA.7", defaultMessage: "Ogooué-Lolo" },
        3: { id: "provinces.GA.3", defaultMessage: "Moyen-Ogooué" },
        8: { id: "provinces.GA.8", defaultMessage: "Ogooué-Maritime" },
        4: { id: "provinces.GA.4", defaultMessage: "Ngounié" },
        9: { id: "provinces.GA.9", defaultMessage: "Woleu-Ntem" },
    }),
    GB: defineMessages({
        CLD: { id: "provinces.GB.CLD", defaultMessage: "Calderdale" },
        GLS: { id: "provinces.GB.GLS", defaultMessage: "Gloucestershire" },
        MLN: { id: "provinces.GB.MLN", defaultMessage: "Midlothian" },
        CLK: { id: "provinces.GB.CLK", defaultMessage: "Clackmannanshire" },
        GRE: { id: "provinces.GB.GRE", defaultMessage: "Greenwich" },
        MON: { id: "provinces.GB.MON", defaultMessage: "Monmouthshire; Sir Fynwy" },
        CMA: { id: "provinces.GB.CMA", defaultMessage: "Cumbria" },
        GWN: { id: "provinces.GB.GWN", defaultMessage: "Gwynedd" },
        MRT: { id: "provinces.GB.MRT", defaultMessage: "Merton" },
        CMD: { id: "provinces.GB.CMD", defaultMessage: "Camden" },
        HAL: { id: "provinces.GB.HAL", defaultMessage: "Halton" },
        MRY: { id: "provinces.GB.MRY", defaultMessage: "Moray" },
        CMN: { id: "provinces.GB.CMN", defaultMessage: "Carmarthenshire; Sir Gaerfyrddin" },
        HAM: { id: "provinces.GB.HAM", defaultMessage: "Hampshire" },
        MTY: { id: "provinces.GB.MTY", defaultMessage: "Merthyr Tydfil; Merthyr Tudful" },
        CON: { id: "provinces.GB.CON", defaultMessage: "Cornwall" },
        HAV: { id: "provinces.GB.HAV", defaultMessage: "Havering" },
        MUL: { id: "provinces.GB.MUL", defaultMessage: "Mid Ulster" },
        WDU: { id: "provinces.GB.WDU", defaultMessage: "West Dunbartonshire" },
        COV: { id: "provinces.GB.COV", defaultMessage: "Coventry" },
        HCK: { id: "provinces.GB.HCK", defaultMessage: "Hackney" },
        NAY: { id: "provinces.GB.NAY", defaultMessage: "North Ayrshire" },
        CRF: { id: "provinces.GB.CRF", defaultMessage: "Cardiff; Caerdydd" },
        HEF: { id: "provinces.GB.HEF", defaultMessage: "Herefordshire" },
        NBL: { id: "provinces.GB.NBL", defaultMessage: "Northumberland" },
        CRY: { id: "provinces.GB.CRY", defaultMessage: "Croydon" },
        HIL: { id: "provinces.GB.HIL", defaultMessage: "Hillingdon" },
        NEL: { id: "provinces.GB.NEL", defaultMessage: "North East Lincolnshire" },
        CWY: { id: "provinces.GB.CWY", defaultMessage: "Conwy" },
        HLD: { id: "provinces.GB.HLD", defaultMessage: "Highland" },
        NET: { id: "provinces.GB.NET", defaultMessage: "Newcastle upon Tyne" },
        DAL: { id: "provinces.GB.DAL", defaultMessage: "Darlington" },
        HMF: { id: "provinces.GB.HMF", defaultMessage: "Hammersmith and Fulham" },
        NFK: { id: "provinces.GB.NFK", defaultMessage: "Norfolk" },
        DBY: { id: "provinces.GB.DBY", defaultMessage: "Derbyshire" },
        HNS: { id: "provinces.GB.HNS", defaultMessage: "Hounslow" },
        NGM: { id: "provinces.GB.NGM", defaultMessage: "Nottingham" },
        DEN: { id: "provinces.GB.DEN", defaultMessage: "Denbighshire; Sir Ddinbych" },
        HPL: { id: "provinces.GB.HPL", defaultMessage: "Hartlepool" },
        NIR: { id: "provinces.GB.NIR", defaultMessage: "Northern Ireland" },
        DER: { id: "provinces.GB.DER", defaultMessage: "Derby" },
        HRT: { id: "provinces.GB.HRT", defaultMessage: "Hertfordshire" },
        NLK: { id: "provinces.GB.NLK", defaultMessage: "North Lanarkshire" },
        DEV: { id: "provinces.GB.DEV", defaultMessage: "Devon" },
        HRW: { id: "provinces.GB.HRW", defaultMessage: "Harrow" },
        NLN: { id: "provinces.GB.NLN", defaultMessage: "North Lincolnshire" },
        DGY: { id: "provinces.GB.DGY", defaultMessage: "Dumfries and Galloway" },
        HRY: { id: "provinces.GB.HRY", defaultMessage: "Haringey" },
        NMD: { id: "provinces.GB.NMD", defaultMessage: "Newry, Mourne and Down" },
        DNC: { id: "provinces.GB.DNC", defaultMessage: "Doncaster" },
        IOS: { id: "provinces.GB.IOS", defaultMessage: "Isles of Scilly" },
        NSM: { id: "provinces.GB.NSM", defaultMessage: "North Somerset" },
        DND: { id: "provinces.GB.DND", defaultMessage: "Dundee City" },
        IOW: { id: "provinces.GB.IOW", defaultMessage: "Isle of Wight" },
        NTH: { id: "provinces.GB.NTH", defaultMessage: "Northamptonshire" },
        DOR: { id: "provinces.GB.DOR", defaultMessage: "Dorset" },
        ISL: { id: "provinces.GB.ISL", defaultMessage: "Islington" },
        NTL: { id: "provinces.GB.NTL", defaultMessage: "Neath Port Talbot; Castell-nedd Port Talbot" },
        DRS: { id: "provinces.GB.DRS", defaultMessage: "Derry and Strabane" },
        IVC: { id: "provinces.GB.IVC", defaultMessage: "Inverclyde" },
        NTT: { id: "provinces.GB.NTT", defaultMessage: "Nottinghamshire" },
        DUD: { id: "provinces.GB.DUD", defaultMessage: "Dudley" },
        KEC: { id: "provinces.GB.KEC", defaultMessage: "Kensington and Chelsea" },
        NTY: { id: "provinces.GB.NTY", defaultMessage: "North Tyneside" },
        DUR: { id: "provinces.GB.DUR", defaultMessage: "Durham County" },
        KEN: { id: "provinces.GB.KEN", defaultMessage: "Kent" },
        NWM: { id: "provinces.GB.NWM", defaultMessage: "Newham" },
        EAL: { id: "provinces.GB.EAL", defaultMessage: "Ealing" },
        KHL: { id: "provinces.GB.KHL", defaultMessage: "Kingston upon Hull" },
        NWP: { id: "provinces.GB.NWP", defaultMessage: "Newport; Casnewydd" },
        EAW: { id: "provinces.GB.EAW", defaultMessage: "England and Wales" },
        KIR: { id: "provinces.GB.KIR", defaultMessage: "Kirklees" },
        NYK: { id: "provinces.GB.NYK", defaultMessage: "North Yorkshire" },
        EAY: { id: "provinces.GB.EAY", defaultMessage: "East Ayrshire" },
        KTT: { id: "provinces.GB.KTT", defaultMessage: "Kingston upon Thames" },
        OLD: { id: "provinces.GB.OLD", defaultMessage: "Oldham" },
        EDH: { id: "provinces.GB.EDH", defaultMessage: "Edinburgh, City of" },
        KWL: { id: "provinces.GB.KWL", defaultMessage: "Knowsley" },
        ORK: { id: "provinces.GB.ORK", defaultMessage: "Orkney Islands" },
        EDU: { id: "provinces.GB.EDU", defaultMessage: "East Dunbartonshire" },
        LAN: { id: "provinces.GB.LAN", defaultMessage: "Lancashire" },
        OXF: { id: "provinces.GB.OXF", defaultMessage: "Oxfordshire" },
        ELN: { id: "provinces.GB.ELN", defaultMessage: "East Lothian" },
        LBC: { id: "provinces.GB.LBC", defaultMessage: "Lisburn and Castlereagh" },
        PEM: { id: "provinces.GB.PEM", defaultMessage: "Pembrokeshire; Sir Benfro" },
        ELS: { id: "provinces.GB.ELS", defaultMessage: "Eilean Siar" },
        LBH: { id: "provinces.GB.LBH", defaultMessage: "Lambeth" },
        PKN: { id: "provinces.GB.PKN", defaultMessage: "Perth and Kinross" },
        ENF: { id: "provinces.GB.ENF", defaultMessage: "Enfield" },
        LCE: { id: "provinces.GB.LCE", defaultMessage: "Leicester" },
        PLY: { id: "provinces.GB.PLY", defaultMessage: "Plymouth" },
        ENG: { id: "provinces.GB.ENG", defaultMessage: "England" },
        LDS: { id: "provinces.GB.LDS", defaultMessage: "Leeds" },
        POL: { id: "provinces.GB.POL", defaultMessage: "Poole" },
        ERW: { id: "provinces.GB.ERW", defaultMessage: "East Renfrewshire" },
        LEC: { id: "provinces.GB.LEC", defaultMessage: "Leicestershire" },
        POR: { id: "provinces.GB.POR", defaultMessage: "Portsmouth" },
        ERY: { id: "provinces.GB.ERY", defaultMessage: "East Riding of Yorkshire" },
        LEW: { id: "provinces.GB.LEW", defaultMessage: "Lewisham" },
        POW: { id: "provinces.GB.POW", defaultMessage: "Powys" },
        ESS: { id: "provinces.GB.ESS", defaultMessage: "Essex" },
        LIN: { id: "provinces.GB.LIN", defaultMessage: "Lincolnshire" },
        PTE: { id: "provinces.GB.PTE", defaultMessage: "Peterborough" },
        ESX: { id: "provinces.GB.ESX", defaultMessage: "East Sussex" },
        LIV: { id: "provinces.GB.LIV", defaultMessage: "Liverpool" },
        RCC: { id: "provinces.GB.RCC", defaultMessage: "Redcar and Cleveland" },
        FAL: { id: "provinces.GB.FAL", defaultMessage: "Falkirk" },
        LND: { id: "provinces.GB.LND", defaultMessage: "London, City of" },
        RCH: { id: "provinces.GB.RCH", defaultMessage: "Rochdale" },
        FIF: { id: "provinces.GB.FIF", defaultMessage: "Fife" },
        LUT: { id: "provinces.GB.LUT", defaultMessage: "Luton" },
        RCT: { id: "provinces.GB.RCT", defaultMessage: "Rhondda, Cynon, Taff; Rhondda, Cynon, Taf" },
        FLN: { id: "provinces.GB.FLN", defaultMessage: "Flintshire; Sir y Fflint" },
        MAN: { id: "provinces.GB.MAN", defaultMessage: "Manchester" },
        RDB: { id: "provinces.GB.RDB", defaultMessage: "Redbridge" },
        FMO: { id: "provinces.GB.FMO", defaultMessage: "Fermanagh and Omagh" },
        MDB: { id: "provinces.GB.MDB", defaultMessage: "Middlesbrough" },
        RDG: { id: "provinces.GB.RDG", defaultMessage: "Reading" },
        GAT: { id: "provinces.GB.GAT", defaultMessage: "Gateshead" },
        MDW: { id: "provinces.GB.MDW", defaultMessage: "Medway" },
        RFW: { id: "provinces.GB.RFW", defaultMessage: "Renfrewshire" },
        GBN: { id: "provinces.GB.GBN", defaultMessage: "Great Britain" },
        MEA: { id: "provinces.GB.MEA", defaultMessage: "Mid and East Antrim" },
        RIC: { id: "provinces.GB.RIC", defaultMessage: "Richmond upon Thames" },
        GLG: { id: "provinces.GB.GLG", defaultMessage: "Glasgow City" },
        MIK: { id: "provinces.GB.MIK", defaultMessage: "Milton Keynes" },
        ROT: { id: "provinces.GB.ROT", defaultMessage: "Rotherham" },
        RUT: { id: "provinces.GB.RUT", defaultMessage: "Rutland" },
        WFT: { id: "provinces.GB.WFT", defaultMessage: "Waltham Forest" },
        SAW: { id: "provinces.GB.SAW", defaultMessage: "Sandwell" },
        WGN: { id: "provinces.GB.WGN", defaultMessage: "Wigan" },
        SAY: { id: "provinces.GB.SAY", defaultMessage: "South Ayrshire" },
        WIL: { id: "provinces.GB.WIL", defaultMessage: "Wiltshire" },
        SCB: { id: "provinces.GB.SCB", defaultMessage: "Scottish Borders, The" },
        WKF: { id: "provinces.GB.WKF", defaultMessage: "Wakefield" },
        SCT: { id: "provinces.GB.SCT", defaultMessage: "Scotland" },
        WLL: { id: "provinces.GB.WLL", defaultMessage: "Walsall" },
        ABC: { id: "provinces.GB.ABC", defaultMessage: "Armagh, Banbridge and Craigavon" },
        SFK: { id: "provinces.GB.SFK", defaultMessage: "Suffolk" },
        WLN: { id: "provinces.GB.WLN", defaultMessage: "West Lothian" },
        ABD: { id: "provinces.GB.ABD", defaultMessage: "Aberdeenshire" },
        SFT: { id: "provinces.GB.SFT", defaultMessage: "Sefton" },
        WLS: { id: "provinces.GB.WLS", defaultMessage: "Wales; Cymru" },
        ABE: { id: "provinces.GB.ABE", defaultMessage: "Aberdeen City" },
        SGC: { id: "provinces.GB.SGC", defaultMessage: "South Gloucestershire" },
        WLV: { id: "provinces.GB.WLV", defaultMessage: "Wolverhampton" },
        AGB: { id: "provinces.GB.AGB", defaultMessage: "Argyll and Bute" },
        SHF: { id: "provinces.GB.SHF", defaultMessage: "Sheffield" },
        WND: { id: "provinces.GB.WND", defaultMessage: "Wandsworth" },
        AGY: { id: "provinces.GB.AGY", defaultMessage: "Isle of Anglesey; Sir Ynys Môn" },
        SHN: { id: "provinces.GB.SHN", defaultMessage: "St. Helens" },
        WNM: { id: "provinces.GB.WNM", defaultMessage: "Windsor and Maidenhead" },
        AND: { id: "provinces.GB.AND", defaultMessage: "Ards and North Down" },
        SHR: { id: "provinces.GB.SHR", defaultMessage: "Shropshire" },
        WOK: { id: "provinces.GB.WOK", defaultMessage: "Wokingham" },
        ANN: { id: "provinces.GB.ANN", defaultMessage: "Antrim and Newtownabbey" },
        SKP: { id: "provinces.GB.SKP", defaultMessage: "Stockport" },
        WOR: { id: "provinces.GB.WOR", defaultMessage: "Worcestershire" },
        ANS: { id: "provinces.GB.ANS", defaultMessage: "Angus" },
        SLF: { id: "provinces.GB.SLF", defaultMessage: "Salford" },
        WRL: { id: "provinces.GB.WRL", defaultMessage: "Wirral" },
        BAS: { id: "provinces.GB.BAS", defaultMessage: "Bath and North East Somerset" },
        SLG: { id: "provinces.GB.SLG", defaultMessage: "Slough" },
        WRT: { id: "provinces.GB.WRT", defaultMessage: "Warrington" },
        BBD: { id: "provinces.GB.BBD", defaultMessage: "Blackburn with Darwen" },
        SLK: { id: "provinces.GB.SLK", defaultMessage: "South Lanarkshire" },
        WRX: { id: "provinces.GB.WRX", defaultMessage: "Wrexham; Wrecsam" },
        BDF: { id: "provinces.GB.BDF", defaultMessage: "Bedford" },
        SND: { id: "provinces.GB.SND", defaultMessage: "Sunderland" },
        WSM: { id: "provinces.GB.WSM", defaultMessage: "Westminster" },
        BDG: { id: "provinces.GB.BDG", defaultMessage: "Barking and Dagenham" },
        SOL: { id: "provinces.GB.SOL", defaultMessage: "Solihull" },
        WSX: { id: "provinces.GB.WSX", defaultMessage: "West Sussex" },
        BEN: { id: "provinces.GB.BEN", defaultMessage: "Brent" },
        SOM: { id: "provinces.GB.SOM", defaultMessage: "Somerset" },
        YOR: { id: "provinces.GB.YOR", defaultMessage: "York" },
        BEX: { id: "provinces.GB.BEX", defaultMessage: "Bexley" },
        SOS: { id: "provinces.GB.SOS", defaultMessage: "Southend-on-Sea" },
        ZET: { id: "provinces.GB.ZET", defaultMessage: "Shetland Islands" },
        BFS: { id: "provinces.GB.BFS", defaultMessage: "Belfast" },
        SRY: { id: "provinces.GB.SRY", defaultMessage: "Surrey" },
        BGE: { id: "provinces.GB.BGE", defaultMessage: "Bridgend; Pen-y-bont ar Ogwr" },
        STE: { id: "provinces.GB.STE", defaultMessage: "Stoke-on-Trent" },
        BGW: { id: "provinces.GB.BGW", defaultMessage: "Blaenau Gwent" },
        STG: { id: "provinces.GB.STG", defaultMessage: "Stirling" },
        BIR: { id: "provinces.GB.BIR", defaultMessage: "Birmingham" },
        STH: { id: "provinces.GB.STH", defaultMessage: "Southampton" },
        BKM: { id: "provinces.GB.BKM", defaultMessage: "Buckinghamshire" },
        STN: { id: "provinces.GB.STN", defaultMessage: "Sutton" },
        BMH: { id: "provinces.GB.BMH", defaultMessage: "Bournemouth" },
        STS: { id: "provinces.GB.STS", defaultMessage: "Staffordshire" },
        BNE: { id: "provinces.GB.BNE", defaultMessage: "Barnet" },
        STT: { id: "provinces.GB.STT", defaultMessage: "Stockton-on-Tees" },
        BNH: { id: "provinces.GB.BNH", defaultMessage: "Brighton and Hove" },
        STY: { id: "provinces.GB.STY", defaultMessage: "South Tyneside" },
        BNS: { id: "provinces.GB.BNS", defaultMessage: "Barnsley" },
        SWA: { id: "provinces.GB.SWA", defaultMessage: "Swansea; Abertawe" },
        BOL: { id: "provinces.GB.BOL", defaultMessage: "Bolton" },
        SWD: { id: "provinces.GB.SWD", defaultMessage: "Swindon" },
        BPL: { id: "provinces.GB.BPL", defaultMessage: "Blackpool" },
        SWK: { id: "provinces.GB.SWK", defaultMessage: "Southwark" },
        BRC: { id: "provinces.GB.BRC", defaultMessage: "Bracknell Forest" },
        TAM: { id: "provinces.GB.TAM", defaultMessage: "Tameside" },
        BRD: { id: "provinces.GB.BRD", defaultMessage: "Bradford" },
        TFW: { id: "provinces.GB.TFW", defaultMessage: "Telford and Wrekin" },
        BRY: { id: "provinces.GB.BRY", defaultMessage: "Bromley" },
        THR: { id: "provinces.GB.THR", defaultMessage: "Thurrock" },
        BST: { id: "provinces.GB.BST", defaultMessage: "Bristol, City of" },
        TOB: { id: "provinces.GB.TOB", defaultMessage: "Torbay" },
        BUR: { id: "provinces.GB.BUR", defaultMessage: "Bury" },
        TOF: { id: "provinces.GB.TOF", defaultMessage: "Torfaen; Tor-faen" },
        CAM: { id: "provinces.GB.CAM", defaultMessage: "Cambridgeshire" },
        TRF: { id: "provinces.GB.TRF", defaultMessage: "Trafford" },
        CAY: { id: "provinces.GB.CAY", defaultMessage: "Caerphilly; Caerffili" },
        TWH: { id: "provinces.GB.TWH", defaultMessage: "Tower Hamlets" },
        CBF: { id: "provinces.GB.CBF", defaultMessage: "Central Bedfordshire" },
        UKM: { id: "provinces.GB.UKM", defaultMessage: "United Kingdom" },
        CCG: { id: "provinces.GB.CCG", defaultMessage: "Causeway Coast and Glens" },
        VGL: { id: "provinces.GB.VGL", defaultMessage: "Vale of Glamorgan, The; Bro Morgannwg" },
        CGN: { id: "provinces.GB.CGN", defaultMessage: "Ceredigion; Sir Ceredigion" },
        WAR: { id: "provinces.GB.WAR", defaultMessage: "Warwickshire" },
        CHE: { id: "provinces.GB.CHE", defaultMessage: "Cheshire East" },
        WBK: { id: "provinces.GB.WBK", defaultMessage: "West Berkshire" },
        CHW: { id: "provinces.GB.CHW", defaultMessage: "Cheshire West and Chester" },
    }),
    GE: defineMessages({
        AB: { id: "provinces.GE.AB", defaultMessage: "Abkhazia" },
        TB: { id: "provinces.GE.TB", defaultMessage: "T’bilisi" },
        MM: { id: "provinces.GE.MM", defaultMessage: "Mts’khet’a-Mt’ianet’i" },
        AJ: { id: "provinces.GE.AJ", defaultMessage: "Ajaria" },
        RL: { id: "provinces.GE.RL", defaultMessage: "Racha-Lech’khumi-K’vemo Svanet’i" },
        GU: { id: "provinces.GE.GU", defaultMessage: "Guria" },
        SJ: { id: "provinces.GE.SJ", defaultMessage: "Samts’khe-Javakhet’i" },
        IM: { id: "provinces.GE.IM", defaultMessage: "Imeret’i" },
        SK: { id: "provinces.GE.SK", defaultMessage: "Shida K’art’li" },
        KA: { id: "provinces.GE.KA", defaultMessage: "Kakhet’i" },
        SZ: { id: "provinces.GE.SZ", defaultMessage: "Samegrelo-Zemo Svanet’i" },
        KK: { id: "provinces.GE.KK", defaultMessage: "K’vemo K’art’li" },
    }),
    GG: defineMessages({}),
    GH: defineMessages({
        WP: { id: "provinces.GH.WP", defaultMessage: "Western" },
        EP: { id: "provinces.GH.EP", defaultMessage: "Eastern" },
        AA: { id: "provinces.GH.AA", defaultMessage: "Greater Accra" },
        NP: { id: "provinces.GH.NP", defaultMessage: "Northern" },
        AH: { id: "provinces.GH.AH", defaultMessage: "Ashanti" },
        TV: { id: "provinces.GH.TV", defaultMessage: "Volta" },
        BA: { id: "provinces.GH.BA", defaultMessage: "Brong-Ahafo" },
        UE: { id: "provinces.GH.UE", defaultMessage: "Upper East" },
        CP: { id: "provinces.GH.CP", defaultMessage: "Central" },
        UW: { id: "provinces.GH.UW", defaultMessage: "Upper West" },
    }),
    GI: defineMessages({}),
    GN: defineMessages({
        CO: { id: "provinces.GN.CO", defaultMessage: "Coyah" },
        LA: { id: "provinces.GN.LA", defaultMessage: "Labé" },
        D: { id: "provinces.GN.D", defaultMessage: "Kindia" },
        LE: { id: "provinces.GN.LE", defaultMessage: "Lélouma" },
        DB: { id: "provinces.GN.DB", defaultMessage: "Dabola" },
        LO: { id: "provinces.GN.LO", defaultMessage: "Lola" },
        DI: { id: "provinces.GN.DI", defaultMessage: "Dinguiraye" },
        M: { id: "provinces.GN.M", defaultMessage: "Mamou" },
        DL: { id: "provinces.GN.DL", defaultMessage: "Dalaba" },
        MC: { id: "provinces.GN.MC", defaultMessage: "Macenta" },
        DU: { id: "provinces.GN.DU", defaultMessage: "Dubréka" },
        MD: { id: "provinces.GN.MD", defaultMessage: "Mandiana" },
        F: { id: "provinces.GN.F", defaultMessage: "Faranah" },
        ML: { id: "provinces.GN.ML", defaultMessage: "Mali" },
        FA: { id: "provinces.GN.FA", defaultMessage: "Faranah" },
        MM: { id: "provinces.GN.MM", defaultMessage: "Mamou" },
        FO: { id: "provinces.GN.FO", defaultMessage: "Forécariah" },
        N: { id: "provinces.GN.N", defaultMessage: "Nzérékoré" },
        FR: { id: "provinces.GN.FR", defaultMessage: "Fria" },
        NZ: { id: "provinces.GN.NZ", defaultMessage: "Nzérékoré" },
        GA: { id: "provinces.GN.GA", defaultMessage: "Gaoual" },
        PI: { id: "provinces.GN.PI", defaultMessage: "Pita" },
        GU: { id: "provinces.GN.GU", defaultMessage: "Guékédou" },
        SI: { id: "provinces.GN.SI", defaultMessage: "Siguiri" },
        K: { id: "provinces.GN.K", defaultMessage: "Kankan" },
        TE: { id: "provinces.GN.TE", defaultMessage: "Télimélé" },
        KA: { id: "provinces.GN.KA", defaultMessage: "Kankan" },
        TO: { id: "provinces.GN.TO", defaultMessage: "Tougué" },
        KB: { id: "provinces.GN.KB", defaultMessage: "Koubia" },
        YO: { id: "provinces.GN.YO", defaultMessage: "Yomou" },
        KD: { id: "provinces.GN.KD", defaultMessage: "Kindia" },
        KE: { id: "provinces.GN.KE", defaultMessage: "Kérouané" },
        B: { id: "provinces.GN.B", defaultMessage: "Boké" },
        KN: { id: "provinces.GN.KN", defaultMessage: "Koundara" },
        BE: { id: "provinces.GN.BE", defaultMessage: "Beyla" },
        KO: { id: "provinces.GN.KO", defaultMessage: "Kouroussa" },
        BF: { id: "provinces.GN.BF", defaultMessage: "Boffa" },
        KS: { id: "provinces.GN.KS", defaultMessage: "Kissidougou" },
        BK: { id: "provinces.GN.BK", defaultMessage: "Boké" },
        L: { id: "provinces.GN.L", defaultMessage: "Labé" },
        C: { id: "provinces.GN.C", defaultMessage: "Conakry" },
    }),
    GP: defineMessages({}),
    GM: defineMessages({
        B: { id: "provinces.GM.B", defaultMessage: "Banjul" },
        L: { id: "provinces.GM.L", defaultMessage: "Lower River" },
        M: { id: "provinces.GM.M", defaultMessage: "Central River" },
        N: { id: "provinces.GM.N", defaultMessage: "North Bank" },
        U: { id: "provinces.GM.U", defaultMessage: "Upper River" },
        W: { id: "provinces.GM.W", defaultMessage: "Western" },
    }),
    GW: defineMessages({
        S: { id: "provinces.GW.S", defaultMessage: "Sul" },
        GA: { id: "provinces.GW.GA", defaultMessage: "Gabú" },
        BA: { id: "provinces.GW.BA", defaultMessage: "Bafatá" },
        TO: { id: "provinces.GW.TO", defaultMessage: "Tombali" },
        L: { id: "provinces.GW.L", defaultMessage: "Leste" },
        BL: { id: "provinces.GW.BL", defaultMessage: "Bolama" },
        N: { id: "provinces.GW.N", defaultMessage: "Norte" },
        BM: { id: "provinces.GW.BM", defaultMessage: "Biombo" },
        OI: { id: "provinces.GW.OI", defaultMessage: "Oio" },
        BS: { id: "provinces.GW.BS", defaultMessage: "Bissau" },
        QU: { id: "provinces.GW.QU", defaultMessage: "Quinara" },
        CA: { id: "provinces.GW.CA", defaultMessage: "Cacheu" },
    }),
    GQ: defineMessages({
        KN: { id: "provinces.GQ.KN", defaultMessage: "Kié-Ntem" },
        BN: { id: "provinces.GQ.BN", defaultMessage: "Bioko Norte" },
        LI: { id: "provinces.GQ.LI", defaultMessage: "Litoral" },
        BS: { id: "provinces.GQ.BS", defaultMessage: "Bioko Sur" },
        WN: { id: "provinces.GQ.WN", defaultMessage: "Wele-Nzas" },
        C: { id: "provinces.GQ.C", defaultMessage: "Región Continental" },
        CS: { id: "provinces.GQ.CS", defaultMessage: "Centro Sur" },
        I: { id: "provinces.GQ.I", defaultMessage: "Región Insular" },
        AN: { id: "provinces.GQ.AN", defaultMessage: "Annobón" },
    }),
    GR: defineMessages({
        71: { id: "provinces.GR.71", defaultMessage: "Evros" },
        32: { id: "provinces.GR.32", defaultMessage: "Thesprotia" },
        I: { id: "provinces.GR.I", defaultMessage: "Attiki" },
        72: { id: "provinces.GR.72", defaultMessage: "Xanthi" },
        33: { id: "provinces.GR.33", defaultMessage: "Ioannina" },
        J: { id: "provinces.GR.J", defaultMessage: "Peloponnisos" },
        73: { id: "provinces.GR.73", defaultMessage: "Rodopi" },
        34: { id: "provinces.GR.34", defaultMessage: "Preveza" },
        K: { id: "provinces.GR.K", defaultMessage: "Voreio Aigaio" },
        "01": { id: "provinces.GR.01", defaultMessage: "Aitolia kai Akarnania" },
        81: { id: "provinces.GR.81", defaultMessage: "Dodekanisos" },
        41: { id: "provinces.GR.41", defaultMessage: "Karditsa" },
        L: { id: "provinces.GR.L", defaultMessage: "Notio Aigaio" },
        "03": { id: "provinces.GR.03", defaultMessage: "Voiotia" },
        82: { id: "provinces.GR.82", defaultMessage: "Kyklades" },
        42: { id: "provinces.GR.42", defaultMessage: "Larisa" },
        M: { id: "provinces.GR.M", defaultMessage: "Kriti" },
        "04": { id: "provinces.GR.04", defaultMessage: "Evvoias" },
        83: { id: "provinces.GR.83", defaultMessage: "Lesvos" },
        43: { id: "provinces.GR.43", defaultMessage: "Magnisia" },
        "05": { id: "provinces.GR.05", defaultMessage: "Evrytania" },
        84: { id: "provinces.GR.84", defaultMessage: "Samos" },
        44: { id: "provinces.GR.44", defaultMessage: "Trikala" },
        "06": { id: "provinces.GR.06", defaultMessage: "Fthiotida" },
        85: { id: "provinces.GR.85", defaultMessage: "Chios" },
        51: { id: "provinces.GR.51", defaultMessage: "Grevena" },
        "07": { id: "provinces.GR.07", defaultMessage: "Fokida" },
        91: { id: "provinces.GR.91", defaultMessage: "Irakleio" },
        52: { id: "provinces.GR.52", defaultMessage: "Drama" },
        11: { id: "provinces.GR.11", defaultMessage: "Argolida" },
        92: { id: "provinces.GR.92", defaultMessage: "Lasithi" },
        53: { id: "provinces.GR.53", defaultMessage: "Imathia" },
        12: { id: "provinces.GR.12", defaultMessage: "Arkadia" },
        93: { id: "provinces.GR.93", defaultMessage: "Rethymno" },
        54: { id: "provinces.GR.54", defaultMessage: "Thessaloniki" },
        13: { id: "provinces.GR.13", defaultMessage: "Achaïa" },
        94: { id: "provinces.GR.94", defaultMessage: "Chania" },
        55: { id: "provinces.GR.55", defaultMessage: "Kavala" },
        14: { id: "provinces.GR.14", defaultMessage: "Ileia" },
        A: { id: "provinces.GR.A", defaultMessage: "Anatoliki Makedonia kai Thraki" },
        56: { id: "provinces.GR.56", defaultMessage: "Kastoria" },
        15: { id: "provinces.GR.15", defaultMessage: "Korinthia" },
        A1: { id: "provinces.GR.A1", defaultMessage: "Attiki" },
        57: { id: "provinces.GR.57", defaultMessage: "Kilkis" },
        16: { id: "provinces.GR.16", defaultMessage: "Lakonia" },
        B: { id: "provinces.GR.B", defaultMessage: "Kentriki Makedonia" },
        58: { id: "provinces.GR.58", defaultMessage: "Kozani" },
        17: { id: "provinces.GR.17", defaultMessage: "Messinia" },
        C: { id: "provinces.GR.C", defaultMessage: "Dytiki Makedonia" },
        59: { id: "provinces.GR.59", defaultMessage: "Pella" },
        21: { id: "provinces.GR.21", defaultMessage: "Zakynthos" },
        D: { id: "provinces.GR.D", defaultMessage: "Ipeiros" },
        61: { id: "provinces.GR.61", defaultMessage: "Pieria" },
        22: { id: "provinces.GR.22", defaultMessage: "Kerkyra" },
        E: { id: "provinces.GR.E", defaultMessage: "Thessalia" },
        62: { id: "provinces.GR.62", defaultMessage: "Serres" },
        23: { id: "provinces.GR.23", defaultMessage: "Kefallonia" },
        F: { id: "provinces.GR.F", defaultMessage: "Ionia Nisia" },
        63: { id: "provinces.GR.63", defaultMessage: "Florina" },
        24: { id: "provinces.GR.24", defaultMessage: "Lefkada" },
        G: { id: "provinces.GR.G", defaultMessage: "Dytiki Ellada" },
        64: { id: "provinces.GR.64", defaultMessage: "Chalkidiki" },
        31: { id: "provinces.GR.31", defaultMessage: "Arta" },
        H: { id: "provinces.GR.H", defaultMessage: "Sterea Ellada" },
        69: { id: "provinces.GR.69", defaultMessage: "Agio Oros" },
    }),
    GD: defineMessages({
        "03": { id: "provinces.GD.03", defaultMessage: "Saint George" },
        "04": { id: "provinces.GD.04", defaultMessage: "Saint John" },
        "05": { id: "provinces.GD.05", defaultMessage: "Saint Mark" },
        "06": { id: "provinces.GD.06", defaultMessage: "Saint Patrick" },
        "01": { id: "provinces.GD.01", defaultMessage: "Saint Andrew" },
        10: { id: "provinces.GD.10", defaultMessage: "Southern Grenadine Islands" },
        "02": { id: "provinces.GD.02", defaultMessage: "Saint David" },
    }),
    GL: defineMessages({
        KU: { id: "provinces.GL.KU", defaultMessage: "Kommune Kujalleq" },
        SM: { id: "provinces.GL.SM", defaultMessage: "Kommuneqarfik Sermersooq" },
        QE: { id: "provinces.GL.QE", defaultMessage: "Qeqqata Kommunia" },
        QA: { id: "provinces.GL.QA", defaultMessage: "Qaasuitsup Kommunia" },
    }),
    GT: defineMessages({
        SM: { id: "provinces.GT.SM", defaultMessage: "San Marcos" },
        SO: { id: "provinces.GT.SO", defaultMessage: "Sololá" },
        SR: { id: "provinces.GT.SR", defaultMessage: "Santa Rosa" },
        SU: { id: "provinces.GT.SU", defaultMessage: "Suchitepéquez" },
        TO: { id: "provinces.GT.TO", defaultMessage: "Totonicapán" },
        ZA: { id: "provinces.GT.ZA", defaultMessage: "Zacapa" },
        AV: { id: "provinces.GT.AV", defaultMessage: "Alta Verapaz" },
        BV: { id: "provinces.GT.BV", defaultMessage: "Baja Verapaz" },
        CM: { id: "provinces.GT.CM", defaultMessage: "Chimaltenango" },
        CQ: { id: "provinces.GT.CQ", defaultMessage: "Chiquimula" },
        ES: { id: "provinces.GT.ES", defaultMessage: "Escuintla" },
        GU: { id: "provinces.GT.GU", defaultMessage: "Guatemala" },
        HU: { id: "provinces.GT.HU", defaultMessage: "Huehuetenango" },
        IZ: { id: "provinces.GT.IZ", defaultMessage: "Izabal" },
        JA: { id: "provinces.GT.JA", defaultMessage: "Jalapa" },
        JU: { id: "provinces.GT.JU", defaultMessage: "Jutiapa" },
        PE: { id: "provinces.GT.PE", defaultMessage: "Petén" },
        PR: { id: "provinces.GT.PR", defaultMessage: "El Progreso" },
        QC: { id: "provinces.GT.QC", defaultMessage: "Quiché" },
        QZ: { id: "provinces.GT.QZ", defaultMessage: "Quetzaltenango" },
        RE: { id: "provinces.GT.RE", defaultMessage: "Retalhuleu" },
        SA: { id: "provinces.GT.SA", defaultMessage: "Sacatepéquez" },
    }),
    GF: defineMessages({}),
    GU: defineMessages({}),
    GY: defineMessages({
        UD: { id: "provinces.GY.UD", defaultMessage: "Upper Demerara-Berbice" },
        EB: { id: "provinces.GY.EB", defaultMessage: "East Berbice-Corentyne" },
        UT: { id: "provinces.GY.UT", defaultMessage: "Upper Takutu-Upper Essequibo" },
        ES: { id: "provinces.GY.ES", defaultMessage: "Essequibo Islands-West Demerara" },
        BA: { id: "provinces.GY.BA", defaultMessage: "Barima-Waini" },
        MA: { id: "provinces.GY.MA", defaultMessage: "Mahaica-Berbice" },
        CU: { id: "provinces.GY.CU", defaultMessage: "Cuyuni-Mazaruni" },
        PM: { id: "provinces.GY.PM", defaultMessage: "Pomeroon-Supenaam" },
        DE: { id: "provinces.GY.DE", defaultMessage: "Demerara-Mahaica" },
        PT: { id: "provinces.GY.PT", defaultMessage: "Potaro-Siparuni" },
    }),
    HK: defineMessages({}),
    HM: defineMessages({}),
    HN: defineMessages({
        CM: { id: "provinces.HN.CM", defaultMessage: "Comayagua" },
        OL: { id: "provinces.HN.OL", defaultMessage: "Olancho" },
        IB: { id: "provinces.HN.IB", defaultMessage: "Islas de la Bahía" },
        CP: { id: "provinces.HN.CP", defaultMessage: "Copán" },
        SB: { id: "provinces.HN.SB", defaultMessage: "Santa Bárbara" },
        IN: { id: "provinces.HN.IN", defaultMessage: "Intibucá" },
        CR: { id: "provinces.HN.CR", defaultMessage: "Cortés" },
        AT: { id: "provinces.HN.AT", defaultMessage: "Atlántida" },
        VA: { id: "provinces.HN.VA", defaultMessage: "Valle" },
        LE: { id: "provinces.HN.LE", defaultMessage: "Lempira" },
        EP: { id: "provinces.HN.EP", defaultMessage: "El Paraíso" },
        CH: { id: "provinces.HN.CH", defaultMessage: "Choluteca" },
        YO: { id: "provinces.HN.YO", defaultMessage: "Yoro" },
        LP: { id: "provinces.HN.LP", defaultMessage: "La Paz" },
        FM: { id: "provinces.HN.FM", defaultMessage: "Francisco Morazán" },
        CL: { id: "provinces.HN.CL", defaultMessage: "Colón" },
        OC: { id: "provinces.HN.OC", defaultMessage: "Ocotepeque" },
        GD: { id: "provinces.HN.GD", defaultMessage: "Gracias a Dios" },
    }),
    HR: defineMessages({
        18: { id: "provinces.HR.18", defaultMessage: "Istarska županija" },
        19: { id: "provinces.HR.19", defaultMessage: "Dubrovačko-neretvanska županija" },
        20: { id: "provinces.HR.20", defaultMessage: "Međimurska županija" },
        21: { id: "provinces.HR.21", defaultMessage: "Grad Zagreb" },
        "01": { id: "provinces.HR.01", defaultMessage: "Zagrebačka županija" },
        "02": { id: "provinces.HR.02", defaultMessage: "Krapinsko-zagorska županija" },
        "03": { id: "provinces.HR.03", defaultMessage: "Sisačko-moslavačka županija" },
        "04": { id: "provinces.HR.04", defaultMessage: "Karlovačka županija" },
        "05": { id: "provinces.HR.05", defaultMessage: "Varaždinska županija" },
        "06": { id: "provinces.HR.06", defaultMessage: "Koprivničko-križevačka županija" },
        "07": { id: "provinces.HR.07", defaultMessage: "Bjelovarsko-bilogorska županija" },
        "08": { id: "provinces.HR.08", defaultMessage: "Primorsko-goranska županija" },
        "09": { id: "provinces.HR.09", defaultMessage: "Ličko-senjska županija" },
        10: { id: "provinces.HR.10", defaultMessage: "Virovitičko-podravska županija" },
        11: { id: "provinces.HR.11", defaultMessage: "Požeško-slavonska županija" },
        12: { id: "provinces.HR.12", defaultMessage: "Brodsko-posavska županija" },
        13: { id: "provinces.HR.13", defaultMessage: "Zadarska županija" },
        14: { id: "provinces.HR.14", defaultMessage: "Osječko-baranjska županija" },
        15: { id: "provinces.HR.15", defaultMessage: "Šibensko-kninska županija" },
        16: { id: "provinces.HR.16", defaultMessage: "Vukovarsko-srijemska županija" },
        17: { id: "provinces.HR.17", defaultMessage: "Splitsko-dalmatinska županija" },
    }),
    HT: defineMessages({
        OU: { id: "provinces.HT.OU", defaultMessage: "Ouest" },
        CE: { id: "provinces.HT.CE", defaultMessage: "Centre" },
        SD: { id: "provinces.HT.SD", defaultMessage: "Sud" },
        GA: { id: "provinces.HT.GA", defaultMessage: "Grande-Anse" },
        SE: { id: "provinces.HT.SE", defaultMessage: "Sud-Est" },
        ND: { id: "provinces.HT.ND", defaultMessage: "Nord" },
        NE: { id: "provinces.HT.NE", defaultMessage: "Nord-Est" },
        NO: { id: "provinces.HT.NO", defaultMessage: "Nord-Ouest" },
        AR: { id: "provinces.HT.AR", defaultMessage: "Artibonite" },
    }),
    HU: defineMessages({
        DU: { id: "provinces.HU.DU", defaultMessage: "Dunaújváros" },
        SH: { id: "provinces.HU.SH", defaultMessage: "Szombathely" },
        EG: { id: "provinces.HU.EG", defaultMessage: "Eger" },
        SK: { id: "provinces.HU.SK", defaultMessage: "Szolnok" },
        ER: { id: "provinces.HU.ER", defaultMessage: "Érd" },
        SN: { id: "provinces.HU.SN", defaultMessage: "Sopron" },
        FE: { id: "provinces.HU.FE", defaultMessage: "Fejér" },
        SO: { id: "provinces.HU.SO", defaultMessage: "Somogy" },
        GS: { id: "provinces.HU.GS", defaultMessage: "Győr-Moson-Sopron" },
        SS: { id: "provinces.HU.SS", defaultMessage: "Szekszárd" },
        GY: { id: "provinces.HU.GY", defaultMessage: "Győr" },
        ST: { id: "provinces.HU.ST", defaultMessage: "Salgótarján" },
        HB: { id: "provinces.HU.HB", defaultMessage: "Hajdú-Bihar" },
        SZ: { id: "provinces.HU.SZ", defaultMessage: "Szabolcs-Szatmár-Bereg" },
        HE: { id: "provinces.HU.HE", defaultMessage: "Heves" },
        TB: { id: "provinces.HU.TB", defaultMessage: "Tatabánya" },
        HV: { id: "provinces.HU.HV", defaultMessage: "Hódmezővásárhely" },
        TO: { id: "provinces.HU.TO", defaultMessage: "Tolna" },
        JN: { id: "provinces.HU.JN", defaultMessage: "Jász-Nagykun-Szolnok" },
        VA: { id: "provinces.HU.VA", defaultMessage: "Vas" },
        KE: { id: "provinces.HU.KE", defaultMessage: "Komárom-Esztergom" },
        VE: { id: "provinces.HU.VE", defaultMessage: "Veszprém (county)" },
        KM: { id: "provinces.HU.KM", defaultMessage: "Kecskemét" },
        VM: { id: "provinces.HU.VM", defaultMessage: "Veszprém" },
        KV: { id: "provinces.HU.KV", defaultMessage: "Kaposvár" },
        ZA: { id: "provinces.HU.ZA", defaultMessage: "Zala" },
        MI: { id: "provinces.HU.MI", defaultMessage: "Miskolc" },
        BA: { id: "provinces.HU.BA", defaultMessage: "Baranya" },
        ZE: { id: "provinces.HU.ZE", defaultMessage: "Zalaegerszeg" },
        NK: { id: "provinces.HU.NK", defaultMessage: "Nagykanizsa" },
        BC: { id: "provinces.HU.BC", defaultMessage: "Békéscsaba" },
        NO: { id: "provinces.HU.NO", defaultMessage: "Nógrád" },
        BE: { id: "provinces.HU.BE", defaultMessage: "Békés" },
        NY: { id: "provinces.HU.NY", defaultMessage: "Nyíregyháza" },
        BK: { id: "provinces.HU.BK", defaultMessage: "Bács-Kiskun" },
        PE: { id: "provinces.HU.PE", defaultMessage: "Pest" },
        BU: { id: "provinces.HU.BU", defaultMessage: "Budapest" },
        PS: { id: "provinces.HU.PS", defaultMessage: "Pécs" },
        BZ: { id: "provinces.HU.BZ", defaultMessage: "Borsod-Abaúj-Zemplén" },
        SD: { id: "provinces.HU.SD", defaultMessage: "Szeged" },
        CS: { id: "provinces.HU.CS", defaultMessage: "Csongrád" },
        SF: { id: "provinces.HU.SF", defaultMessage: "Székesfehérvár" },
        DE: { id: "provinces.HU.DE", defaultMessage: "Debrecen" },
    }),
    ID: defineMessages({
        JA: { id: "provinces.ID.JA", defaultMessage: "Jambi" },
        RI: { id: "provinces.ID.RI", defaultMessage: "Riau" },
        JB: { id: "provinces.ID.JB", defaultMessage: "Jawa Barat" },
        SA: { id: "provinces.ID.SA", defaultMessage: "Sulawesi Utara" },
        JI: { id: "provinces.ID.JI", defaultMessage: "Jawa Timur" },
        SB: { id: "provinces.ID.SB", defaultMessage: "Sumatra Barat" },
        JK: { id: "provinces.ID.JK", defaultMessage: "Jakarta Raya" },
        SG: { id: "provinces.ID.SG", defaultMessage: "Sulawesi Tenggara" },
        JT: { id: "provinces.ID.JT", defaultMessage: "Jawa Tengah" },
        SL: { id: "provinces.ID.SL", defaultMessage: "Sulawesi" },
        JW: { id: "provinces.ID.JW", defaultMessage: "Jawa" },
        SM: { id: "provinces.ID.SM", defaultMessage: "Sumatera" },
        KA: { id: "provinces.ID.KA", defaultMessage: "Kalimantan" },
        SN: { id: "provinces.ID.SN", defaultMessage: "Sulawesi Selatan" },
        KB: { id: "provinces.ID.KB", defaultMessage: "Kalimantan Barat" },
        SR: { id: "provinces.ID.SR", defaultMessage: "Sulawesi Barat" },
        KI: { id: "provinces.ID.KI", defaultMessage: "Kalimantan Timur" },
        SS: { id: "provinces.ID.SS", defaultMessage: "Sumatra Selatan" },
        KR: { id: "provinces.ID.KR", defaultMessage: "Kepulauan Riau" },
        ST: { id: "provinces.ID.ST", defaultMessage: "Sulawesi Tengah" },
        KS: { id: "provinces.ID.KS", defaultMessage: "Kalimantan Selatan" },
        SU: { id: "provinces.ID.SU", defaultMessage: "Sumatera Utara" },
        KT: { id: "provinces.ID.KT", defaultMessage: "Kalimantan Tengah" },
        YO: { id: "provinces.ID.YO", defaultMessage: "Yogyakarta" },
        LA: { id: "provinces.ID.LA", defaultMessage: "Lampung" },
        MA: { id: "provinces.ID.MA", defaultMessage: "Maluku" },
        ML: { id: "provinces.ID.ML", defaultMessage: "Maluku" },
        AC: { id: "provinces.ID.AC", defaultMessage: "Aceh" },
        MU: { id: "provinces.ID.MU", defaultMessage: "Maluku Utara" },
        BA: { id: "provinces.ID.BA", defaultMessage: "Bali" },
        NB: { id: "provinces.ID.NB", defaultMessage: "Nusa Tenggara Barat" },
        BB: { id: "provinces.ID.BB", defaultMessage: "Bangka Belitung" },
        NT: { id: "provinces.ID.NT", defaultMessage: "Nusa Tenggara Timur" },
        BE: { id: "provinces.ID.BE", defaultMessage: "Bengkulu" },
        NU: { id: "provinces.ID.NU", defaultMessage: "Nusa Tenggara" },
        BT: { id: "provinces.ID.BT", defaultMessage: "Banten" },
        PA: { id: "provinces.ID.PA", defaultMessage: "Papua" },
        GO: { id: "provinces.ID.GO", defaultMessage: "Gorontalo" },
        PB: { id: "provinces.ID.PB", defaultMessage: "Papua Barat" },
        IJ: { id: "provinces.ID.IJ", defaultMessage: "Papua" },
    }),
    IM: defineMessages({}),
    IN: defineMessages({
        JK: { id: "provinces.IN.JK", defaultMessage: "Jammu and Kashmir" },
        KA: { id: "provinces.IN.KA", defaultMessage: "Karnataka" },
        KL: { id: "provinces.IN.KL", defaultMessage: "Kerala" },
        LD: { id: "provinces.IN.LD", defaultMessage: "Lakshadweep" },
        MH: { id: "provinces.IN.MH", defaultMessage: "Maharashtra" },
        ML: { id: "provinces.IN.ML", defaultMessage: "Meghalaya" },
        MN: { id: "provinces.IN.MN", defaultMessage: "Manipur" },
        AN: { id: "provinces.IN.AN", defaultMessage: "Andaman and Nicobar Islands" },
        MP: { id: "provinces.IN.MP", defaultMessage: "Madhya Pradesh" },
        AP: { id: "provinces.IN.AP", defaultMessage: "Andhra Pradesh" },
        MZ: { id: "provinces.IN.MZ", defaultMessage: "Mizoram" },
        AR: { id: "provinces.IN.AR", defaultMessage: "Arunachal Pradesh" },
        NL: { id: "provinces.IN.NL", defaultMessage: "Nagaland" },
        AS: { id: "provinces.IN.AS", defaultMessage: "Assam" },
        OR: { id: "provinces.IN.OR", defaultMessage: "Odisha" },
        BR: { id: "provinces.IN.BR", defaultMessage: "Bihar" },
        PB: { id: "provinces.IN.PB", defaultMessage: "Punjab" },
        CH: { id: "provinces.IN.CH", defaultMessage: "Chandigarh" },
        PY: { id: "provinces.IN.PY", defaultMessage: "Puducherry" },
        CT: { id: "provinces.IN.CT", defaultMessage: "Chhattisgarh" },
        RJ: { id: "provinces.IN.RJ", defaultMessage: "Rajasthan" },
        DD: { id: "provinces.IN.DD", defaultMessage: "Daman and Diu" },
        SK: { id: "provinces.IN.SK", defaultMessage: "Sikkim" },
        DL: { id: "provinces.IN.DL", defaultMessage: "Delhi" },
        TG: { id: "provinces.IN.TG", defaultMessage: "Telangana" },
        DN: { id: "provinces.IN.DN", defaultMessage: "Dadra and Nagar Haveli" },
        TN: { id: "provinces.IN.TN", defaultMessage: "Tamil Nadu" },
        GA: { id: "provinces.IN.GA", defaultMessage: "Goa" },
        TR: { id: "provinces.IN.TR", defaultMessage: "Tripura" },
        GJ: { id: "provinces.IN.GJ", defaultMessage: "Gujarat" },
        UP: { id: "provinces.IN.UP", defaultMessage: "Uttar Pradesh" },
        HP: { id: "provinces.IN.HP", defaultMessage: "Himachal Pradesh" },
        UT: { id: "provinces.IN.UT", defaultMessage: "Uttarakhand" },
        HR: { id: "provinces.IN.HR", defaultMessage: "Haryana" },
        WB: { id: "provinces.IN.WB", defaultMessage: "West Bengal" },
        JH: { id: "provinces.IN.JH", defaultMessage: "Jharkhand" },
    }),
    IO: defineMessages({}),
    IE: defineMessages({
        KK: { id: "provinces.IE.KK", defaultMessage: "Kilkenny" },
        KY: { id: "provinces.IE.KY", defaultMessage: "Kerry" },
        L: { id: "provinces.IE.L", defaultMessage: "Leinster" },
        LD: { id: "provinces.IE.LD", defaultMessage: "Longford" },
        LH: { id: "provinces.IE.LH", defaultMessage: "Louth" },
        LK: { id: "provinces.IE.LK", defaultMessage: "Limerick" },
        LM: { id: "provinces.IE.LM", defaultMessage: "Leitrim" },
        LS: { id: "provinces.IE.LS", defaultMessage: "Laois" },
        M: { id: "provinces.IE.M", defaultMessage: "Munster" },
        MH: { id: "provinces.IE.MH", defaultMessage: "Meath" },
        MN: { id: "provinces.IE.MN", defaultMessage: "Monaghan" },
        MO: { id: "provinces.IE.MO", defaultMessage: "Mayo" },
        OY: { id: "provinces.IE.OY", defaultMessage: "Offaly" },
        C: { id: "provinces.IE.C", defaultMessage: "Connacht" },
        RN: { id: "provinces.IE.RN", defaultMessage: "Roscommon" },
        CE: { id: "provinces.IE.CE", defaultMessage: "Clare" },
        SO: { id: "provinces.IE.SO", defaultMessage: "Sligo" },
        CN: { id: "provinces.IE.CN", defaultMessage: "Cavan" },
        TA: { id: "provinces.IE.TA", defaultMessage: "Tipperary" },
        CO: { id: "provinces.IE.CO", defaultMessage: "Cork" },
        U: { id: "provinces.IE.U", defaultMessage: "Ulster" },
        CW: { id: "provinces.IE.CW", defaultMessage: "Carlow" },
        WD: { id: "provinces.IE.WD", defaultMessage: "Waterford" },
        D: { id: "provinces.IE.D", defaultMessage: "Dublin" },
        WH: { id: "provinces.IE.WH", defaultMessage: "Westmeath" },
        DL: { id: "provinces.IE.DL", defaultMessage: "Donegal" },
        WW: { id: "provinces.IE.WW", defaultMessage: "Wicklow" },
        G: { id: "provinces.IE.G", defaultMessage: "Galway" },
        WX: { id: "provinces.IE.WX", defaultMessage: "Wexford" },
        KE: { id: "provinces.IE.KE", defaultMessage: "Kildare" },
    }),
    IR: defineMessages({
        "04": { id: "provinces.IR.04", defaultMessage: "Eşfahān" },
        26: { id: "provinces.IR.26", defaultMessage: "Qom" },
        "05": { id: "provinces.IR.05", defaultMessage: "Īlām" },
        27: { id: "provinces.IR.27", defaultMessage: "Golestān" },
        "06": { id: "provinces.IR.06", defaultMessage: "Būshehr" },
        28: { id: "provinces.IR.28", defaultMessage: "Qazvīn" },
        "07": { id: "provinces.IR.07", defaultMessage: "Tehrān" },
        29: { id: "provinces.IR.29", defaultMessage: "Khorāsān-e Janūbī" },
        "08": { id: "provinces.IR.08", defaultMessage: "Chahār Mahāll va Bakhtīārī" },
        30: { id: "provinces.IR.30", defaultMessage: "Khorāsān-e Razavī" },
        10: { id: "provinces.IR.10", defaultMessage: "Khūzestān" },
        31: { id: "provinces.IR.31", defaultMessage: "Khorāsān-e Shemālī" },
        11: { id: "provinces.IR.11", defaultMessage: "Zanjān" },
        12: { id: "provinces.IR.12", defaultMessage: "Semnān" },
        13: { id: "provinces.IR.13", defaultMessage: "Sīstān va Balūchestān" },
        14: { id: "provinces.IR.14", defaultMessage: "Fārs" },
        15: { id: "provinces.IR.15", defaultMessage: "Kermān" },
        16: { id: "provinces.IR.16", defaultMessage: "Kordestān" },
        17: { id: "provinces.IR.17", defaultMessage: "Kermānshāh" },
        18: { id: "provinces.IR.18", defaultMessage: "Kohgīlūyeh va Būyer Ahmad" },
        19: { id: "provinces.IR.19", defaultMessage: "Gīlān" },
        20: { id: "provinces.IR.20", defaultMessage: "Lorestān" },
        21: { id: "provinces.IR.21", defaultMessage: "Māzandarān" },
        22: { id: "provinces.IR.22", defaultMessage: "Markazī" },
        23: { id: "provinces.IR.23", defaultMessage: "Hormozgān" },
        "01": { id: "provinces.IR.01", defaultMessage: "Āzarbāyjān-e Sharqī" },
        24: { id: "provinces.IR.24", defaultMessage: "Hamadān" },
        "02": { id: "provinces.IR.02", defaultMessage: "Āzarbāyjān-e Gharbī" },
        25: { id: "provinces.IR.25", defaultMessage: "Yazd" },
        "03": { id: "provinces.IR.03", defaultMessage: "Ardabīl" },
    }),
    IQ: defineMessages({
        MU: { id: "provinces.IQ.MU", defaultMessage: "Al Muthanna" },
        DA: { id: "provinces.IQ.DA", defaultMessage: "Dahuk" },
        AN: { id: "provinces.IQ.AN", defaultMessage: "Al Anbar" },
        TS: { id: "provinces.IQ.TS", defaultMessage: "At Ta'mim" },
        NA: { id: "provinces.IQ.NA", defaultMessage: "An Najef" },
        DI: { id: "provinces.IQ.DI", defaultMessage: "Diyala" },
        AR: { id: "provinces.IQ.AR", defaultMessage: "Arbil" },
        WA: { id: "provinces.IQ.WA", defaultMessage: "Wasit" },
        NI: { id: "provinces.IQ.NI", defaultMessage: "Ninawa" },
        DQ: { id: "provinces.IQ.DQ", defaultMessage: "Dhi Qar" },
        BA: { id: "provinces.IQ.BA", defaultMessage: "Al Basrah" },
        QA: { id: "provinces.IQ.QA", defaultMessage: "Al Qadisiyah" },
        KA: { id: "provinces.IQ.KA", defaultMessage: "Karbala'" },
        BB: { id: "provinces.IQ.BB", defaultMessage: "Babil" },
        SD: { id: "provinces.IQ.SD", defaultMessage: "Salah ad Din" },
        MA: { id: "provinces.IQ.MA", defaultMessage: "Maysan" },
        BG: { id: "provinces.IQ.BG", defaultMessage: "Baghdad" },
        SW: { id: "provinces.IQ.SW", defaultMessage: "As Sulaymaniyah" },
    }),
    IS: defineMessages({
        4: { id: "provinces.IS.4", defaultMessage: "Vestfirðir" },
        5: { id: "provinces.IS.5", defaultMessage: "Norðurland vestra" },
        0: { id: "provinces.IS.0", defaultMessage: "Reykjavík" },
        6: { id: "provinces.IS.6", defaultMessage: "Norðurland eystra" },
        1: { id: "provinces.IS.1", defaultMessage: "Höfuðborgarsvæðið" },
        7: { id: "provinces.IS.7", defaultMessage: "Austurland" },
        2: { id: "provinces.IS.2", defaultMessage: "Suðurnes" },
        8: { id: "provinces.IS.8", defaultMessage: "Suðurland" },
        3: { id: "provinces.IS.3", defaultMessage: "Vesturland" },
    }),
    IL: defineMessages({
        Z: { id: "provinces.IL.Z", defaultMessage: "HaZafon" },
        D: { id: "provinces.IL.D", defaultMessage: "HaDarom" },
        HA: { id: "provinces.IL.HA", defaultMessage: "Hefa" },
        JM: { id: "provinces.IL.JM", defaultMessage: "Yerushalayim Al Quds" },
        M: { id: "provinces.IL.M", defaultMessage: "HaMerkaz" },
        TA: { id: "provinces.IL.TA", defaultMessage: "Tel-Aviv" },
    }),
    IT: defineMessages({
        AT: { id: "provinces.IT.AT", defaultMessage: "Asti" },
        AV: { id: "provinces.IT.AV", defaultMessage: "Avellino" },
        BA: { id: "provinces.IT.BA", defaultMessage: "Bari" },
        BG: { id: "provinces.IT.BG", defaultMessage: "Bergamo" },
        BI: { id: "provinces.IT.BI", defaultMessage: "Biella" },
        BL: { id: "provinces.IT.BL", defaultMessage: "Belluno" },
        BN: { id: "provinces.IT.BN", defaultMessage: "Benevento" },
        BO: { id: "provinces.IT.BO", defaultMessage: "Bologna" },
        BR: { id: "provinces.IT.BR", defaultMessage: "Brindisi" },
        BS: { id: "provinces.IT.BS", defaultMessage: "Brescia" },
        BT: { id: "provinces.IT.BT", defaultMessage: "Barletta-Andria-Trani" },
        BZ: { id: "provinces.IT.BZ", defaultMessage: "Bolzano" },
        CA: { id: "provinces.IT.CA", defaultMessage: "Cagliari" },
        CB: { id: "provinces.IT.CB", defaultMessage: "Campobasso" },
        CE: { id: "provinces.IT.CE", defaultMessage: "Caserta" },
        CH: { id: "provinces.IT.CH", defaultMessage: "Chieti" },
        CI: { id: "provinces.IT.CI", defaultMessage: "Carbonia-Iglesias" },
        CL: { id: "provinces.IT.CL", defaultMessage: "Caltanissetta" },
        CN: { id: "provinces.IT.CN", defaultMessage: "Cuneo" },
        CO: { id: "provinces.IT.CO", defaultMessage: "Como" },
        CR: { id: "provinces.IT.CR", defaultMessage: "Cremona" },
        CS: { id: "provinces.IT.CS", defaultMessage: "Cosenza" },
        CT: { id: "provinces.IT.CT", defaultMessage: "Catania" },
        CZ: { id: "provinces.IT.CZ", defaultMessage: "Catanzaro" },
        EN: { id: "provinces.IT.EN", defaultMessage: "Enna" },
        FC: { id: "provinces.IT.FC", defaultMessage: "Forlì-Cesena" },
        FE: { id: "provinces.IT.FE", defaultMessage: "Ferrara" },
        FG: { id: "provinces.IT.FG", defaultMessage: "Foggia" },
        FI: { id: "provinces.IT.FI", defaultMessage: "Firenze" },
        FM: { id: "provinces.IT.FM", defaultMessage: "Fermo" },
        FR: { id: "provinces.IT.FR", defaultMessage: "Frosinone" },
        GE: { id: "provinces.IT.GE", defaultMessage: "Genova" },
        GO: { id: "provinces.IT.GO", defaultMessage: "Gorizia" },
        GR: { id: "provinces.IT.GR", defaultMessage: "Grosseto" },
        IM: { id: "provinces.IT.IM", defaultMessage: "Imperia" },
        IS: { id: "provinces.IT.IS", defaultMessage: "Isernia" },
        KR: { id: "provinces.IT.KR", defaultMessage: "Crotone" },
        LC: { id: "provinces.IT.LC", defaultMessage: "Lecco" },
        LE: { id: "provinces.IT.LE", defaultMessage: "Lecce" },
        LI: { id: "provinces.IT.LI", defaultMessage: "Livorno" },
        SS: { id: "provinces.IT.SS", defaultMessage: "Sassari" },
        LO: { id: "provinces.IT.LO", defaultMessage: "Lodi" },
        LT: { id: "provinces.IT.LT", defaultMessage: "Latina" },
        LU: { id: "provinces.IT.LU", defaultMessage: "Lucca" },
        SV: { id: "provinces.IT.SV", defaultMessage: "Savona" },
        MB: { id: "provinces.IT.MB", defaultMessage: "Monza e Brianza" },
        TA: { id: "provinces.IT.TA", defaultMessage: "Taranto" },
        MC: { id: "provinces.IT.MC", defaultMessage: "Macerata" },
        TE: { id: "provinces.IT.TE", defaultMessage: "Teramo" },
        ME: { id: "provinces.IT.ME", defaultMessage: "Messina" },
        TN: { id: "provinces.IT.TN", defaultMessage: "Trento" },
        MI: { id: "provinces.IT.MI", defaultMessage: "Milano" },
        TO: { id: "provinces.IT.TO", defaultMessage: "Torino" },
        MN: { id: "provinces.IT.MN", defaultMessage: "Mantova" },
        TP: { id: "provinces.IT.TP", defaultMessage: "Trapani" },
        MO: { id: "provinces.IT.MO", defaultMessage: "Modena" },
        TR: { id: "provinces.IT.TR", defaultMessage: "Terni" },
        MS: { id: "provinces.IT.MS", defaultMessage: "Massa-Carrara" },
        TS: { id: "provinces.IT.TS", defaultMessage: "Trieste" },
        MT: { id: "provinces.IT.MT", defaultMessage: "Matera" },
        TV: { id: "provinces.IT.TV", defaultMessage: "Treviso" },
        NA: { id: "provinces.IT.NA", defaultMessage: "Napoli" },
        UD: { id: "provinces.IT.UD", defaultMessage: "Udine" },
        NO: { id: "provinces.IT.NO", defaultMessage: "Novara" },
        VA: { id: "provinces.IT.VA", defaultMessage: "Varese" },
        NU: { id: "provinces.IT.NU", defaultMessage: "Nuoro" },
        VB: { id: "provinces.IT.VB", defaultMessage: "Verbano-Cusio-Ossola" },
        OG: { id: "provinces.IT.OG", defaultMessage: "Ogliastra" },
        VC: { id: "provinces.IT.VC", defaultMessage: "Vercelli" },
        OR: { id: "provinces.IT.OR", defaultMessage: "Oristano" },
        VE: { id: "provinces.IT.VE", defaultMessage: "Venezia" },
        OT: { id: "provinces.IT.OT", defaultMessage: "Olbia-Tempio" },
        VI: { id: "provinces.IT.VI", defaultMessage: "Vicenza" },
        21: { id: "provinces.IT.21", defaultMessage: "Piemonte" },
        PA: { id: "provinces.IT.PA", defaultMessage: "Palermo" },
        VR: { id: "provinces.IT.VR", defaultMessage: "Verona" },
        23: { id: "provinces.IT.23", defaultMessage: "Valle d'Aosta" },
        PC: { id: "provinces.IT.PC", defaultMessage: "Piacenza" },
        VS: { id: "provinces.IT.VS", defaultMessage: "Medio Campidano" },
        25: { id: "provinces.IT.25", defaultMessage: "Lombardia" },
        PD: { id: "provinces.IT.PD", defaultMessage: "Padova" },
        VT: { id: "provinces.IT.VT", defaultMessage: "Viterbo" },
        32: { id: "provinces.IT.32", defaultMessage: "Trentino-Alto Adige" },
        PE: { id: "provinces.IT.PE", defaultMessage: "Pescara" },
        VV: { id: "provinces.IT.VV", defaultMessage: "Vibo Valentia" },
        34: { id: "provinces.IT.34", defaultMessage: "Veneto" },
        PG: { id: "provinces.IT.PG", defaultMessage: "Perugia" },
        36: { id: "provinces.IT.36", defaultMessage: "Friuli-Venezia Giulia" },
        PI: { id: "provinces.IT.PI", defaultMessage: "Pisa" },
        42: { id: "provinces.IT.42", defaultMessage: "Liguria" },
        PN: { id: "provinces.IT.PN", defaultMessage: "Pordenone" },
        45: { id: "provinces.IT.45", defaultMessage: "Emilia-Romagna" },
        PO: { id: "provinces.IT.PO", defaultMessage: "Prato" },
        52: { id: "provinces.IT.52", defaultMessage: "Toscana" },
        PR: { id: "provinces.IT.PR", defaultMessage: "Parma" },
        55: { id: "provinces.IT.55", defaultMessage: "Umbria" },
        PT: { id: "provinces.IT.PT", defaultMessage: "Pistoia" },
        57: { id: "provinces.IT.57", defaultMessage: "Marche" },
        PU: { id: "provinces.IT.PU", defaultMessage: "Pesaro e Urbino" },
        62: { id: "provinces.IT.62", defaultMessage: "Lazio" },
        PV: { id: "provinces.IT.PV", defaultMessage: "Pavia" },
        65: { id: "provinces.IT.65", defaultMessage: "Abruzzo" },
        PZ: { id: "provinces.IT.PZ", defaultMessage: "Potenza" },
        67: { id: "provinces.IT.67", defaultMessage: "Molise" },
        RA: { id: "provinces.IT.RA", defaultMessage: "Ravenna" },
        72: { id: "provinces.IT.72", defaultMessage: "Campania" },
        RC: { id: "provinces.IT.RC", defaultMessage: "Reggio Calabria" },
        75: { id: "provinces.IT.75", defaultMessage: "Puglia" },
        RE: { id: "provinces.IT.RE", defaultMessage: "Reggio Emilia" },
        77: { id: "provinces.IT.77", defaultMessage: "Basilicata" },
        RG: { id: "provinces.IT.RG", defaultMessage: "Ragusa" },
        78: { id: "provinces.IT.78", defaultMessage: "Calabria" },
        RI: { id: "provinces.IT.RI", defaultMessage: "Rieti" },
        82: { id: "provinces.IT.82", defaultMessage: "Sicilia" },
        RM: { id: "provinces.IT.RM", defaultMessage: "Roma" },
        88: { id: "provinces.IT.88", defaultMessage: "Sardegna" },
        RN: { id: "provinces.IT.RN", defaultMessage: "Rimini" },
        AG: { id: "provinces.IT.AG", defaultMessage: "Agrigento" },
        RO: { id: "provinces.IT.RO", defaultMessage: "Rovigo" },
        AL: { id: "provinces.IT.AL", defaultMessage: "Alessandria" },
        SA: { id: "provinces.IT.SA", defaultMessage: "Salerno" },
        AN: { id: "provinces.IT.AN", defaultMessage: "Ancona" },
        SI: { id: "provinces.IT.SI", defaultMessage: "Siena" },
        AO: { id: "provinces.IT.AO", defaultMessage: "Aosta" },
        SO: { id: "provinces.IT.SO", defaultMessage: "Sondrio" },
        AP: { id: "provinces.IT.AP", defaultMessage: "Ascoli Piceno" },
        SP: { id: "provinces.IT.SP", defaultMessage: "La Spezia" },
        AQ: { id: "provinces.IT.AQ", defaultMessage: "L'Aquila" },
        SR: { id: "provinces.IT.SR", defaultMessage: "Siracusa" },
        AR: { id: "provinces.IT.AR", defaultMessage: "Arezzo" },
    }),
    JM: defineMessages({
        "08": { id: "provinces.JM.08", defaultMessage: "Saint James" },
        "03": { id: "provinces.JM.03", defaultMessage: "Saint Thomas" },
        14: { id: "provinces.JM.14", defaultMessage: "Saint Catherine" },
        "09": { id: "provinces.JM.09", defaultMessage: "Hanover" },
        "04": { id: "provinces.JM.04", defaultMessage: "Portland" },
        10: { id: "provinces.JM.10", defaultMessage: "Westmoreland" },
        "05": { id: "provinces.JM.05", defaultMessage: "Saint Mary" },
        11: { id: "provinces.JM.11", defaultMessage: "Saint Elizabeth" },
        "06": { id: "provinces.JM.06", defaultMessage: "Saint Ann" },
        "01": { id: "provinces.JM.01", defaultMessage: "Kingston" },
        12: { id: "provinces.JM.12", defaultMessage: "Manchester" },
        "07": { id: "provinces.JM.07", defaultMessage: "Trelawny" },
        "02": { id: "provinces.JM.02", defaultMessage: "Saint Andrew" },
        13: { id: "provinces.JM.13", defaultMessage: "Clarendon" },
    }),
    JE: defineMessages({}),
    JO: defineMessages({
        AZ: { id: "provinces.JO.AZ", defaultMessage: "Az Zarqā'" },
        MA: { id: "provinces.JO.MA", defaultMessage: "Al Mafraq" },
        BA: { id: "provinces.JO.BA", defaultMessage: "Al Balqā'" },
        AJ: { id: "provinces.JO.AJ", defaultMessage: "‘Ajlūn" },
        MD: { id: "provinces.JO.MD", defaultMessage: "Mādabā" },
        IR: { id: "provinces.JO.IR", defaultMessage: "Irbid" },
        AM: { id: "provinces.JO.AM", defaultMessage: "‘Ammān (Al ‘Aşimah)" },
        MN: { id: "provinces.JO.MN", defaultMessage: "Ma‘ān" },
        JA: { id: "provinces.JO.JA", defaultMessage: "Jarash" },
        AQ: { id: "provinces.JO.AQ", defaultMessage: "Al ‘Aqabah" },
        KA: { id: "provinces.JO.KA", defaultMessage: "Al Karak" },
        AT: { id: "provinces.JO.AT", defaultMessage: "Aţ Ţafīlah" },
    }),
    JP: defineMessages({
        19: { id: "provinces.JP.19", defaultMessage: "Yamanashi" },
        40: { id: "provinces.JP.40", defaultMessage: "Fukuoka" },
        20: { id: "provinces.JP.20", defaultMessage: "Nagano" },
        41: { id: "provinces.JP.41", defaultMessage: "Saga" },
        21: { id: "provinces.JP.21", defaultMessage: "Gifu" },
        42: { id: "provinces.JP.42", defaultMessage: "Nagasaki" },
        22: { id: "provinces.JP.22", defaultMessage: "Shizuoka" },
        "01": { id: "provinces.JP.01", defaultMessage: "Hokkaido" },
        43: { id: "provinces.JP.43", defaultMessage: "Kumamoto" },
        23: { id: "provinces.JP.23", defaultMessage: "Aichi" },
        "02": { id: "provinces.JP.02", defaultMessage: "Aomori" },
        44: { id: "provinces.JP.44", defaultMessage: "Oita" },
        24: { id: "provinces.JP.24", defaultMessage: "Mie" },
        "03": { id: "provinces.JP.03", defaultMessage: "Iwate" },
        45: { id: "provinces.JP.45", defaultMessage: "Miyazaki" },
        25: { id: "provinces.JP.25", defaultMessage: "Shiga" },
        "04": { id: "provinces.JP.04", defaultMessage: "Miyagi" },
        46: { id: "provinces.JP.46", defaultMessage: "Kagoshima" },
        26: { id: "provinces.JP.26", defaultMessage: "Kyoto" },
        "05": { id: "provinces.JP.05", defaultMessage: "Akita" },
        47: { id: "provinces.JP.47", defaultMessage: "Okinawa" },
        27: { id: "provinces.JP.27", defaultMessage: "Osaka" },
        "06": { id: "provinces.JP.06", defaultMessage: "Yamagata" },
        28: { id: "provinces.JP.28", defaultMessage: "Hyogo" },
        "07": { id: "provinces.JP.07", defaultMessage: "Fukushima" },
        29: { id: "provinces.JP.29", defaultMessage: "Nara" },
        "08": { id: "provinces.JP.08", defaultMessage: "Ibaraki" },
        30: { id: "provinces.JP.30", defaultMessage: "Wakayama" },
        "09": { id: "provinces.JP.09", defaultMessage: "Tochigi" },
        31: { id: "provinces.JP.31", defaultMessage: "Tottori" },
        10: { id: "provinces.JP.10", defaultMessage: "Gunma" },
        32: { id: "provinces.JP.32", defaultMessage: "Shimane" },
        11: { id: "provinces.JP.11", defaultMessage: "Saitama" },
        33: { id: "provinces.JP.33", defaultMessage: "Okayama" },
        12: { id: "provinces.JP.12", defaultMessage: "Chiba" },
        34: { id: "provinces.JP.34", defaultMessage: "Hiroshima" },
        13: { id: "provinces.JP.13", defaultMessage: "Tokyo" },
        35: { id: "provinces.JP.35", defaultMessage: "Yamaguchi" },
        14: { id: "provinces.JP.14", defaultMessage: "Kanagawa" },
        36: { id: "provinces.JP.36", defaultMessage: "Tokushima" },
        15: { id: "provinces.JP.15", defaultMessage: "Niigata" },
        37: { id: "provinces.JP.37", defaultMessage: "Kagawa" },
        16: { id: "provinces.JP.16", defaultMessage: "Toyama" },
        38: { id: "provinces.JP.38", defaultMessage: "Ehime" },
        17: { id: "provinces.JP.17", defaultMessage: "Ishikawa" },
        39: { id: "provinces.JP.39", defaultMessage: "Kochi" },
        18: { id: "provinces.JP.18", defaultMessage: "Fukui" },
    }),
    KZ: defineMessages({
        PAV: { id: "provinces.KZ.PAV", defaultMessage: "Pavlodar oblysy" },
        ATY: { id: "provinces.KZ.ATY", defaultMessage: "Atyraū oblysy" },
        AKM: { id: "provinces.KZ.AKM", defaultMessage: "Aqmola oblysy" },
        SEV: { id: "provinces.KZ.SEV", defaultMessage: "Soltüstik Quzaqstan oblysy" },
        KAR: { id: "provinces.KZ.KAR", defaultMessage: "Qaraghandy oblysy" },
        AKT: { id: "provinces.KZ.AKT", defaultMessage: "Aqtöbe oblysy" },
        VOS: { id: "provinces.KZ.VOS", defaultMessage: "Shyghys Qazaqstan oblysy" },
        KUS: { id: "provinces.KZ.KUS", defaultMessage: "Qostanay oblysy" },
        ALA: { id: "provinces.KZ.ALA", defaultMessage: "Almaty" },
        YUZ: { id: "provinces.KZ.YUZ", defaultMessage: "Ongtüstik Qazaqstan oblysy" },
        KZY: { id: "provinces.KZ.KZY", defaultMessage: "Qyzylorda oblysy" },
        ALM: { id: "provinces.KZ.ALM", defaultMessage: "Almaty oblysy" },
        ZAP: { id: "provinces.KZ.ZAP", defaultMessage: "Batys Quzaqstan oblysy" },
        MAN: { id: "provinces.KZ.MAN", defaultMessage: "Mangghystaū oblysy" },
        AST: { id: "provinces.KZ.AST", defaultMessage: "Astana" },
        ZHA: { id: "provinces.KZ.ZHA", defaultMessage: "Zhambyl oblysy" },
    }),
    KE: defineMessages({
        14: { id: "provinces.KE.14", defaultMessage: "Kilifi" },
        35: { id: "provinces.KE.35", defaultMessage: "Nyandarua" },
        15: { id: "provinces.KE.15", defaultMessage: "Kirinyaga" },
        36: { id: "provinces.KE.36", defaultMessage: "Nyeri" },
        16: { id: "provinces.KE.16", defaultMessage: "Kisii" },
        37: { id: "provinces.KE.37", defaultMessage: "Samburu" },
        17: { id: "provinces.KE.17", defaultMessage: "Kisumu" },
        38: { id: "provinces.KE.38", defaultMessage: "Siaya" },
        18: { id: "provinces.KE.18", defaultMessage: "Kitui" },
        39: { id: "provinces.KE.39", defaultMessage: "Taita/Taveta" },
        19: { id: "provinces.KE.19", defaultMessage: "Kwale" },
        40: { id: "provinces.KE.40", defaultMessage: "Tana River" },
        20: { id: "provinces.KE.20", defaultMessage: "Laikipia" },
        41: { id: "provinces.KE.41", defaultMessage: "Tharaka-Nithi" },
        21: { id: "provinces.KE.21", defaultMessage: "Lamu" },
        42: { id: "provinces.KE.42", defaultMessage: "Trans Nzoia" },
        22: { id: "provinces.KE.22", defaultMessage: "Machakos" },
        "01": { id: "provinces.KE.01", defaultMessage: "Baringo" },
        43: { id: "provinces.KE.43", defaultMessage: "Turkana" },
        23: { id: "provinces.KE.23", defaultMessage: "Makueni" },
        "02": { id: "provinces.KE.02", defaultMessage: "Bomet" },
        44: { id: "provinces.KE.44", defaultMessage: "Uasin Gishu" },
        24: { id: "provinces.KE.24", defaultMessage: "Mandera" },
        "03": { id: "provinces.KE.03", defaultMessage: "Bungoma" },
        45: { id: "provinces.KE.45", defaultMessage: "Vihiga" },
        25: { id: "provinces.KE.25", defaultMessage: "Marsabit" },
        "04": { id: "provinces.KE.04", defaultMessage: "Busia" },
        46: { id: "provinces.KE.46", defaultMessage: "Wajir" },
        26: { id: "provinces.KE.26", defaultMessage: "Meru" },
        "05": { id: "provinces.KE.05", defaultMessage: "Elgeyo/Marakwet" },
        47: { id: "provinces.KE.47", defaultMessage: "West Pokot" },
        27: { id: "provinces.KE.27", defaultMessage: "Migori" },
        "06": { id: "provinces.KE.06", defaultMessage: "Embu" },
        28: { id: "provinces.KE.28", defaultMessage: "Mombasa" },
        "07": { id: "provinces.KE.07", defaultMessage: "Garissa" },
        29: { id: "provinces.KE.29", defaultMessage: "Murang'a" },
        "08": { id: "provinces.KE.08", defaultMessage: "Homa Bay" },
        30: { id: "provinces.KE.30", defaultMessage: "Nairobi City" },
        "09": { id: "provinces.KE.09", defaultMessage: "Isiolo" },
        31: { id: "provinces.KE.31", defaultMessage: "Nakuru" },
        10: { id: "provinces.KE.10", defaultMessage: "Kajiado" },
        32: { id: "provinces.KE.32", defaultMessage: "Nandi" },
        11: { id: "provinces.KE.11", defaultMessage: "Kakamega" },
        33: { id: "provinces.KE.33", defaultMessage: "Narok" },
        12: { id: "provinces.KE.12", defaultMessage: "Kericho" },
        34: { id: "provinces.KE.34", defaultMessage: "Nyamira" },
        13: { id: "provinces.KE.13", defaultMessage: "Kiambu" },
    }),
    KG: defineMessages({
        J: { id: "provinces.KG.J", defaultMessage: "Jalal-Abad" },
        N: { id: "provinces.KG.N", defaultMessage: "Naryn" },
        O: { id: "provinces.KG.O", defaultMessage: "Osh" },
        B: { id: "provinces.KG.B", defaultMessage: "Batken" },
        T: { id: "provinces.KG.T", defaultMessage: "Talas" },
        C: { id: "provinces.KG.C", defaultMessage: "Chü" },
        Y: { id: "provinces.KG.Y", defaultMessage: "Ysyk-Köl" },
        GB: { id: "provinces.KG.GB", defaultMessage: "Bishkek" },
    }),
    KH: defineMessages({
        1: { id: "provinces.KH.1", defaultMessage: "Banteay Mean Chey" },
        7: { id: "provinces.KH.7", defaultMessage: "Kampot" },
        10: { id: "provinces.KH.10", defaultMessage: "Krachoh" },
        8: { id: "provinces.KH.8", defaultMessage: "Kandal" },
        11: { id: "provinces.KH.11", defaultMessage: "Mondol Kiri" },
        9: { id: "provinces.KH.9", defaultMessage: "Kach Kong" },
        12: { id: "provinces.KH.12", defaultMessage: "Phnom Penh" },
        13: { id: "provinces.KH.13", defaultMessage: "Preah Vihear" },
        14: { id: "provinces.KH.14", defaultMessage: "Prey Veaeng" },
        15: { id: "provinces.KH.15", defaultMessage: "Pousaat" },
        16: { id: "provinces.KH.16", defaultMessage: "Rotanak Kiri" },
        17: { id: "provinces.KH.17", defaultMessage: "Siem Reab" },
        18: { id: "provinces.KH.18", defaultMessage: "Krong Preah Sihanouk" },
        19: { id: "provinces.KH.19", defaultMessage: "Stueng Traeng" },
        2: { id: "provinces.KH.2", defaultMessage: "Battambang" },
        20: { id: "provinces.KH.20", defaultMessage: "Svaay Rieng" },
        21: { id: "provinces.KH.21", defaultMessage: "Taakaev" },
        22: { id: "provinces.KH.22", defaultMessage: "Otdar Mean Chey" },
        23: { id: "provinces.KH.23", defaultMessage: "Krong Kaeb" },
        24: { id: "provinces.KH.24", defaultMessage: "Krong Pailin" },
        3: { id: "provinces.KH.3", defaultMessage: "Kampong Cham" },
        4: { id: "provinces.KH.4", defaultMessage: "Kampong Chhnang" },
        5: { id: "provinces.KH.5", defaultMessage: "Kampong Speu" },
        6: { id: "provinces.KH.6", defaultMessage: "Kampong Thom" },
    }),
    KI: defineMessages({
        P: { id: "provinces.KI.P", defaultMessage: "Phoenix Islands" },
        L: { id: "provinces.KI.L", defaultMessage: "Line Islands" },
        G: { id: "provinces.KI.G", defaultMessage: "Gilbert Islands" },
    }),
    KN: defineMessages({
        "02": { id: "provinces.KN.02", defaultMessage: "Saint Anne Sandy Point" },
        13: { id: "provinces.KN.13", defaultMessage: "Saint Thomas Middle Island" },
        "08": { id: "provinces.KN.08", defaultMessage: "Saint Mary Cayon" },
        "03": { id: "provinces.KN.03", defaultMessage: "Saint George Basseterre" },
        15: { id: "provinces.KN.15", defaultMessage: "Trinity Palmetto Point" },
        "09": { id: "provinces.KN.09", defaultMessage: "Saint Paul Capisterre" },
        "04": { id: "provinces.KN.04", defaultMessage: "Saint George Gingerland" },
        K: { id: "provinces.KN.K", defaultMessage: "Saint Kitts" },
        10: { id: "provinces.KN.10", defaultMessage: "Saint Paul Charlestown" },
        "05": { id: "provinces.KN.05", defaultMessage: "Saint James Windward" },
        N: { id: "provinces.KN.N", defaultMessage: "Nevis" },
        11: { id: "provinces.KN.11", defaultMessage: "Saint Peter Basseterre" },
        "06": { id: "provinces.KN.06", defaultMessage: "Saint John Capisterre" },
        "01": { id: "provinces.KN.01", defaultMessage: "Christ Church Nichola Town" },
        12: { id: "provinces.KN.12", defaultMessage: "Saint Thomas Lowland" },
        "07": { id: "provinces.KN.07", defaultMessage: "Saint John Figtree" },
    }),
    KR: defineMessages({
        26: { id: "provinces.KR.26", defaultMessage: "Busan Gwang'yeogsi" },
        45: { id: "provinces.KR.45", defaultMessage: "Jeonrabukdo" },
        31: { id: "provinces.KR.31", defaultMessage: "Ulsan Gwang'yeogsi" },
        27: { id: "provinces.KR.27", defaultMessage: "Daegu Gwang'yeogsi" },
        46: { id: "provinces.KR.46", defaultMessage: "Jeonranamdo" },
        41: { id: "provinces.KR.41", defaultMessage: "Gyeonggido" },
        28: { id: "provinces.KR.28", defaultMessage: "Incheon Gwang'yeogsi" },
        47: { id: "provinces.KR.47", defaultMessage: "Gyeongsangbukdo" },
        42: { id: "provinces.KR.42", defaultMessage: "Gang'weondo" },
        29: { id: "provinces.KR.29", defaultMessage: "Gwangju Gwang'yeogsi" },
        48: { id: "provinces.KR.48", defaultMessage: "Gyeongsangnamdo" },
        43: { id: "provinces.KR.43", defaultMessage: "Chungcheongbukdo" },
        49: { id: "provinces.KR.49", defaultMessage: "Jejudo" },
        30: { id: "provinces.KR.30", defaultMessage: "Daejeon Gwang'yeogsi" },
        11: { id: "provinces.KR.11", defaultMessage: "Seoul Teugbyeolsi" },
        44: { id: "provinces.KR.44", defaultMessage: "Chungcheongnamdo" },
    }),
    KW: defineMessages({
        AH: { id: "provinces.KW.AH", defaultMessage: "Al Ahmadi" },
        FA: { id: "provinces.KW.FA", defaultMessage: "Al Farwānīyah" },
        HA: { id: "provinces.KW.HA", defaultMessage: "Hawallī" },
        JA: { id: "provinces.KW.JA", defaultMessage: "Al Jahrrā’" },
        KU: { id: "provinces.KW.KU", defaultMessage: "Al Kuwayt (Al ‘Āşimah)" },
        MU: { id: "provinces.KW.MU", defaultMessage: "Mubārak al Kabīr" },
    }),
    LA: defineMessages({
        KH: { id: "provinces.LA.KH", defaultMessage: "Khammouan" },
        XE: { id: "provinces.LA.XE", defaultMessage: "Xékong" },
        AT: { id: "provinces.LA.AT", defaultMessage: "Attapu" },
        SL: { id: "provinces.LA.SL", defaultMessage: "Salavan" },
        XA: { id: "provinces.LA.XA", defaultMessage: "Xaignabouli" },
        LM: { id: "provinces.LA.LM", defaultMessage: "Louang Namtha" },
        XI: { id: "provinces.LA.XI", defaultMessage: "Xiangkhouang" },
        BK: { id: "provinces.LA.BK", defaultMessage: "Bokèo" },
        SV: { id: "provinces.LA.SV", defaultMessage: "Savannakhét" },
        LP: { id: "provinces.LA.LP", defaultMessage: "Louangphabang" },
        XS: { id: "provinces.LA.XS", defaultMessage: "Xaisômboun" },
        BL: { id: "provinces.LA.BL", defaultMessage: "Bolikhamxai" },
        VI: { id: "provinces.LA.VI", defaultMessage: "Vientiane" },
        OU: { id: "provinces.LA.OU", defaultMessage: "Oudômxai" },
        CH: { id: "provinces.LA.CH", defaultMessage: "Champasak" },
        VT: { id: "provinces.LA.VT", defaultMessage: "Vientiane" },
        PH: { id: "provinces.LA.PH", defaultMessage: "Phôngsali" },
        HO: { id: "provinces.LA.HO", defaultMessage: "Houaphan" },
    }),
    LB: defineMessages({
        BA: { id: "provinces.LB.BA", defaultMessage: "Beyrouth" },
        BH: { id: "provinces.LB.BH", defaultMessage: "Baalbek-Hermel" },
        BI: { id: "provinces.LB.BI", defaultMessage: "Béqaa" },
        JA: { id: "provinces.LB.JA", defaultMessage: "Liban-Sud" },
        AK: { id: "provinces.LB.AK", defaultMessage: "Aakkâr" },
        JL: { id: "provinces.LB.JL", defaultMessage: "Mont-Liban" },
        AS: { id: "provinces.LB.AS", defaultMessage: "Liban-Nord" },
        NA: { id: "provinces.LB.NA", defaultMessage: "Nabatîyé" },
    }),
    LR: defineMessages({
        MG: { id: "provinces.LR.MG", defaultMessage: "Margibi" },
        CM: { id: "provinces.LR.CM", defaultMessage: "Grand Cape Mount" },
        MO: { id: "provinces.LR.MO", defaultMessage: "Montserrado" },
        GB: { id: "provinces.LR.GB", defaultMessage: "Grand Bassa" },
        MY: { id: "provinces.LR.MY", defaultMessage: "Maryland" },
        GG: { id: "provinces.LR.GG", defaultMessage: "Grand Gedeh" },
        NI: { id: "provinces.LR.NI", defaultMessage: "Nimba" },
        GK: { id: "provinces.LR.GK", defaultMessage: "Grand Kru" },
        BG: { id: "provinces.LR.BG", defaultMessage: "Bong" },
        RI: { id: "provinces.LR.RI", defaultMessage: "Rivercess" },
        LO: { id: "provinces.LR.LO", defaultMessage: "Lofa" },
        BM: { id: "provinces.LR.BM", defaultMessage: "Bomi" },
        SI: { id: "provinces.LR.SI", defaultMessage: "Sinoe" },
    }),
    LY: defineMessages({
        NQ: { id: "provinces.LY.NQ", defaultMessage: "An Nuqaţ al Khams" },
        SB: { id: "provinces.LY.SB", defaultMessage: "Sabhā" },
        SR: { id: "provinces.LY.SR", defaultMessage: "Surt" },
        TB: { id: "provinces.LY.TB", defaultMessage: "Ţarābulus" },
        WA: { id: "provinces.LY.WA", defaultMessage: "Al Wāḩāt" },
        WD: { id: "provinces.LY.WD", defaultMessage: "Wādī al Ḩayāt" },
        WS: { id: "provinces.LY.WS", defaultMessage: "Wādī ash Shāţiʾ" },
        BA: { id: "provinces.LY.BA", defaultMessage: "Banghāzī" },
        ZA: { id: "provinces.LY.ZA", defaultMessage: "Az Zāwiyah" },
        BU: { id: "provinces.LY.BU", defaultMessage: "Al Buţnān" },
        DR: { id: "provinces.LY.DR", defaultMessage: "Darnah" },
        GT: { id: "provinces.LY.GT", defaultMessage: "Ghāt" },
        JA: { id: "provinces.LY.JA", defaultMessage: "Al Jabal al Akhḑar" },
        JB: { id: "provinces.LY.JB", defaultMessage: "Jaghbūb" },
        JG: { id: "provinces.LY.JG", defaultMessage: "Al Jabal al Gharbī" },
        JI: { id: "provinces.LY.JI", defaultMessage: "Al Jifārah" },
        JU: { id: "provinces.LY.JU", defaultMessage: "Al Jufrah" },
        KF: { id: "provinces.LY.KF", defaultMessage: "Al Kufrah" },
        MB: { id: "provinces.LY.MB", defaultMessage: "Al Marqab" },
        MI: { id: "provinces.LY.MI", defaultMessage: "Mişrātah" },
        MJ: { id: "provinces.LY.MJ", defaultMessage: "Al Marj" },
        MQ: { id: "provinces.LY.MQ", defaultMessage: "Murzuq" },
        NL: { id: "provinces.LY.NL", defaultMessage: "Nālūt" },
    }),
    LC: defineMessages({}),
    LI: defineMessages({
        11: { id: "provinces.LI.11", defaultMessage: "Vaduz" },
        "06": { id: "provinces.LI.06", defaultMessage: "Ruggell" },
        "01": { id: "provinces.LI.01", defaultMessage: "Balzers" },
        "07": { id: "provinces.LI.07", defaultMessage: "Schaan" },
        "02": { id: "provinces.LI.02", defaultMessage: "Eschen" },
        "08": { id: "provinces.LI.08", defaultMessage: "Schellenberg" },
        "03": { id: "provinces.LI.03", defaultMessage: "Gamprin" },
        "09": { id: "provinces.LI.09", defaultMessage: "Triesen" },
        "04": { id: "provinces.LI.04", defaultMessage: "Mauren" },
        10: { id: "provinces.LI.10", defaultMessage: "Triesenberg" },
        "05": { id: "provinces.LI.05", defaultMessage: "Planken" },
    }),
    LK: defineMessages({
        43: { id: "provinces.LK.43", defaultMessage: "Mannārama" },
        44: { id: "provinces.LK.44", defaultMessage: "Vavuniyāva" },
        45: { id: "provinces.LK.45", defaultMessage: "Mulativ" },
        5: { id: "provinces.LK.5", defaultMessage: "Næ̆gĕnahira paḷāta" },
        51: { id: "provinces.LK.51", defaultMessage: "Maḍakalapuva" },
        52: { id: "provinces.LK.52", defaultMessage: "Ampāara" },
        1: { id: "provinces.LK.1", defaultMessage: "Basnāhira paḷāta" },
        53: { id: "provinces.LK.53", defaultMessage: "Trikuṇāmalaya" },
        11: { id: "provinces.LK.11", defaultMessage: "Kŏḷamba" },
        6: { id: "provinces.LK.6", defaultMessage: "Vayamba paḷāta" },
        12: { id: "provinces.LK.12", defaultMessage: "Gampaha" },
        61: { id: "provinces.LK.61", defaultMessage: "Kuruṇægala" },
        13: { id: "provinces.LK.13", defaultMessage: "Kaḷutara" },
        62: { id: "provinces.LK.62", defaultMessage: "Puttalama" },
        2: { id: "provinces.LK.2", defaultMessage: "Madhyama paḷāta" },
        7: { id: "provinces.LK.7", defaultMessage: "Uturumæ̆da paḷāta" },
        21: { id: "provinces.LK.21", defaultMessage: "Mahanuvara" },
        71: { id: "provinces.LK.71", defaultMessage: "Anurādhapura" },
        22: { id: "provinces.LK.22", defaultMessage: "Mātale" },
        72: { id: "provinces.LK.72", defaultMessage: "Pŏḷŏnnaruva" },
        23: { id: "provinces.LK.23", defaultMessage: "Nuvara Ĕliya" },
        8: { id: "provinces.LK.8", defaultMessage: "Ūva paḷāta" },
        3: { id: "provinces.LK.3", defaultMessage: "Dakuṇu paḷāta" },
        81: { id: "provinces.LK.81", defaultMessage: "Badulla" },
        31: { id: "provinces.LK.31", defaultMessage: "Gālla" },
        82: { id: "provinces.LK.82", defaultMessage: "Mŏṇarāgala" },
        32: { id: "provinces.LK.32", defaultMessage: "Mātara" },
        9: { id: "provinces.LK.9", defaultMessage: "Sabaragamuva paḷāta" },
        33: { id: "provinces.LK.33", defaultMessage: "Hambantŏṭa" },
        91: { id: "provinces.LK.91", defaultMessage: "Ratnapura" },
        4: { id: "provinces.LK.4", defaultMessage: "Uturu paḷāta" },
        92: { id: "provinces.LK.92", defaultMessage: "Kægalla" },
        41: { id: "provinces.LK.41", defaultMessage: "Yāpanaya" },
        42: { id: "provinces.LK.42", defaultMessage: "Kilinŏchchi" },
    }),
    LS: defineMessages({
        F: { id: "provinces.LS.F", defaultMessage: "Mohale's Hoek" },
        A: { id: "provinces.LS.A", defaultMessage: "Maseru" },
        G: { id: "provinces.LS.G", defaultMessage: "Quthing" },
        B: { id: "provinces.LS.B", defaultMessage: "Butha-Buthe" },
        H: { id: "provinces.LS.H", defaultMessage: "Qacha's Nek" },
        C: { id: "provinces.LS.C", defaultMessage: "Leribe" },
        J: { id: "provinces.LS.J", defaultMessage: "Mokhotlong" },
        D: { id: "provinces.LS.D", defaultMessage: "Berea" },
        K: { id: "provinces.LS.K", defaultMessage: "Thaba-Tseka" },
        E: { id: "provinces.LS.E", defaultMessage: "Mafeteng" },
    }),
    LT: defineMessages({
        SA: { id: "provinces.LT.SA", defaultMessage: "Šiaulių Apskritis" },
        AL: { id: "provinces.LT.AL", defaultMessage: "Alytaus Apskritis" },
        TA: { id: "provinces.LT.TA", defaultMessage: "Tauragés Apskritis" },
        KL: { id: "provinces.LT.KL", defaultMessage: "Klaipėdos Apskritis" },
        TE: { id: "provinces.LT.TE", defaultMessage: "Telšių Apskritis" },
        KU: { id: "provinces.LT.KU", defaultMessage: "Kauno Apskritis" },
        UT: { id: "provinces.LT.UT", defaultMessage: "Utenos Apskritis" },
        MR: { id: "provinces.LT.MR", defaultMessage: "Marijampolės Apskritis" },
        VL: { id: "provinces.LT.VL", defaultMessage: "Vilniaus Apskritis" },
        PN: { id: "provinces.LT.PN", defaultMessage: "Panevėžio Apskritis" },
    }),
    LU: defineMessages({
        D: { id: "provinces.LU.D", defaultMessage: "Diekirch" },
        L: { id: "provinces.LU.L", defaultMessage: "Luxembourg" },
        G: { id: "provinces.LU.G", defaultMessage: "Grevenmacher" },
    }),
    LV: defineMessages({
        "072": { id: "provinces.LV.072", defaultMessage: "Pļaviņu novads" },
        JUR: { id: "provinces.LV.JUR", defaultMessage: "Jūrmala" },
        "073": { id: "provinces.LV.073", defaultMessage: "Preiļu novads" },
        LPX: { id: "provinces.LV.LPX", defaultMessage: "Liepāja" },
        "074": { id: "provinces.LV.074", defaultMessage: "Priekules novads" },
        REZ: { id: "provinces.LV.REZ", defaultMessage: "Rēzekne" },
        "075": { id: "provinces.LV.075", defaultMessage: "Priekuļu novads" },
        RIX: { id: "provinces.LV.RIX", defaultMessage: "Rīga" },
        "076": { id: "provinces.LV.076", defaultMessage: "Raunas novads" },
        VEN: { id: "provinces.LV.VEN", defaultMessage: "Ventspils" },
        "077": { id: "provinces.LV.077", defaultMessage: "Rēzeknes novads" },
        VMR: { id: "provinces.LV.VMR", defaultMessage: "Valmiera" },
        "078": { id: "provinces.LV.078", defaultMessage: "Riebiņu novads" },
        "079": { id: "provinces.LV.079", defaultMessage: "Rojas novads" },
        "080": { id: "provinces.LV.080", defaultMessage: "Ropažu novads" },
        "081": { id: "provinces.LV.081", defaultMessage: "Rucavas novads" },
        "082": { id: "provinces.LV.082", defaultMessage: "Rugāju novads" },
        "083": { id: "provinces.LV.083", defaultMessage: "Rundāles novads" },
        "084": { id: "provinces.LV.084", defaultMessage: "Rūjienas novads" },
        "001": { id: "provinces.LV.001", defaultMessage: "Aglonas novads" },
        "085": { id: "provinces.LV.085", defaultMessage: "Salas novads" },
        "002": { id: "provinces.LV.002", defaultMessage: "Aizkraukles novads" },
        "086": { id: "provinces.LV.086", defaultMessage: "Salacgrīvas novads" },
        "003": { id: "provinces.LV.003", defaultMessage: "Aizputes novads" },
        "087": { id: "provinces.LV.087", defaultMessage: "Salaspils novads" },
        "004": { id: "provinces.LV.004", defaultMessage: "Aknīstes novads" },
        "088": { id: "provinces.LV.088", defaultMessage: "Saldus novads" },
        "005": { id: "provinces.LV.005", defaultMessage: "Alojas novads" },
        "089": { id: "provinces.LV.089", defaultMessage: "Saulkrastu novads" },
        "006": { id: "provinces.LV.006", defaultMessage: "Alsungas novads" },
        "090": { id: "provinces.LV.090", defaultMessage: "Sējas novads" },
        "007": { id: "provinces.LV.007", defaultMessage: "Alūksnes novads" },
        "091": { id: "provinces.LV.091", defaultMessage: "Siguldas novads" },
        "008": { id: "provinces.LV.008", defaultMessage: "Amatas novads" },
        "092": { id: "provinces.LV.092", defaultMessage: "Skrīveru novads" },
        "009": { id: "provinces.LV.009", defaultMessage: "Apes novads" },
        "093": { id: "provinces.LV.093", defaultMessage: "Skrundas novads" },
        "010": { id: "provinces.LV.010", defaultMessage: "Auces novads" },
        "094": { id: "provinces.LV.094", defaultMessage: "Smiltenes novads" },
        "011": { id: "provinces.LV.011", defaultMessage: "Ādažu novads" },
        "095": { id: "provinces.LV.095", defaultMessage: "Stopiņu novads" },
        "012": { id: "provinces.LV.012", defaultMessage: "Babītes novads" },
        "096": { id: "provinces.LV.096", defaultMessage: "Strenču novads" },
        "013": { id: "provinces.LV.013", defaultMessage: "Baldones novads" },
        "097": { id: "provinces.LV.097", defaultMessage: "Talsu novads" },
        "014": { id: "provinces.LV.014", defaultMessage: "Baltinavas novads" },
        "098": { id: "provinces.LV.098", defaultMessage: "Tērvetes novads" },
        "015": { id: "provinces.LV.015", defaultMessage: "Balvu novads" },
        "099": { id: "provinces.LV.099", defaultMessage: "Tukuma novads" },
        "016": { id: "provinces.LV.016", defaultMessage: "Bauskas novads" },
        100: { id: "provinces.LV.100", defaultMessage: "Vaiņodes novads" },
        "017": { id: "provinces.LV.017", defaultMessage: "Beverīnas novads" },
        101: { id: "provinces.LV.101", defaultMessage: "Valkas novads" },
        "018": { id: "provinces.LV.018", defaultMessage: "Brocēnu novads" },
        102: { id: "provinces.LV.102", defaultMessage: "Varakļānu novads" },
        "019": { id: "provinces.LV.019", defaultMessage: "Burtnieku novads" },
        103: { id: "provinces.LV.103", defaultMessage: "Vārkavas novads" },
        "020": { id: "provinces.LV.020", defaultMessage: "Carnikavas novads" },
        104: { id: "provinces.LV.104", defaultMessage: "Vecpiebalgas novads" },
        "021": { id: "provinces.LV.021", defaultMessage: "Cesvaines novads" },
        105: { id: "provinces.LV.105", defaultMessage: "Vecumnieku novads" },
        "022": { id: "provinces.LV.022", defaultMessage: "Cēsu novads" },
        106: { id: "provinces.LV.106", defaultMessage: "Ventspils novads" },
        "023": { id: "provinces.LV.023", defaultMessage: "Ciblas novads" },
        107: { id: "provinces.LV.107", defaultMessage: "Viesītes novads" },
        "024": { id: "provinces.LV.024", defaultMessage: "Dagdas novads" },
        108: { id: "provinces.LV.108", defaultMessage: "Viļakas novads" },
        "025": { id: "provinces.LV.025", defaultMessage: "Daugavpils novads" },
        109: { id: "provinces.LV.109", defaultMessage: "Viļānu novads" },
        "026": { id: "provinces.LV.026", defaultMessage: "Dobeles novads" },
        110: { id: "provinces.LV.110", defaultMessage: "Zilupes novads" },
        "027": { id: "provinces.LV.027", defaultMessage: "Dundagas novads" },
        DGV: { id: "provinces.LV.DGV", defaultMessage: "Daugavpils" },
        "028": { id: "provinces.LV.028", defaultMessage: "Durbes novads" },
        JEL: { id: "provinces.LV.JEL", defaultMessage: "Jelgava" },
        "029": { id: "provinces.LV.029", defaultMessage: "Engures novads" },
        JKB: { id: "provinces.LV.JKB", defaultMessage: "Jēkabpils" },
        "030": { id: "provinces.LV.030", defaultMessage: "Ērgļu novads" },
        "031": { id: "provinces.LV.031", defaultMessage: "Garkalnes novads" },
        "032": { id: "provinces.LV.032", defaultMessage: "Grobiņas novads" },
        "033": { id: "provinces.LV.033", defaultMessage: "Gulbenes novads" },
        "034": { id: "provinces.LV.034", defaultMessage: "Iecavas novads" },
        "035": { id: "provinces.LV.035", defaultMessage: "Ikšķiles novads" },
        "036": { id: "provinces.LV.036", defaultMessage: "Ilūkstes novads" },
        "037": { id: "provinces.LV.037", defaultMessage: "Inčukalna novads" },
        "038": { id: "provinces.LV.038", defaultMessage: "Jaunjelgavas novads" },
        "039": { id: "provinces.LV.039", defaultMessage: "Jaunpiebalgas novads" },
        "040": { id: "provinces.LV.040", defaultMessage: "Jaunpils novads" },
        "041": { id: "provinces.LV.041", defaultMessage: "Jelgavas novads" },
        "042": { id: "provinces.LV.042", defaultMessage: "Jēkabpils novads" },
        "043": { id: "provinces.LV.043", defaultMessage: "Kandavas novads" },
        "044": { id: "provinces.LV.044", defaultMessage: "Kārsavas novads" },
        "045": { id: "provinces.LV.045", defaultMessage: "Kocēnu novads" },
        "046": { id: "provinces.LV.046", defaultMessage: "Kokneses novads" },
        "047": { id: "provinces.LV.047", defaultMessage: "Krāslavas novads" },
        "048": { id: "provinces.LV.048", defaultMessage: "Krimuldas novads" },
        "049": { id: "provinces.LV.049", defaultMessage: "Krustpils novads" },
        "050": { id: "provinces.LV.050", defaultMessage: "Kuldīgas novads" },
        "051": { id: "provinces.LV.051", defaultMessage: "Ķeguma novads" },
        "052": { id: "provinces.LV.052", defaultMessage: "Ķekavas novads" },
        "053": { id: "provinces.LV.053", defaultMessage: "Lielvārdes novads" },
        "054": { id: "provinces.LV.054", defaultMessage: "Limbažu novads" },
        "055": { id: "provinces.LV.055", defaultMessage: "Līgatnes novads" },
        "056": { id: "provinces.LV.056", defaultMessage: "Līvānu novads" },
        "057": { id: "provinces.LV.057", defaultMessage: "Lubānas novads" },
        "058": { id: "provinces.LV.058", defaultMessage: "Ludzas novads" },
        "059": { id: "provinces.LV.059", defaultMessage: "Madonas novads" },
        "060": { id: "provinces.LV.060", defaultMessage: "Mazsalacas novads" },
        "061": { id: "provinces.LV.061", defaultMessage: "Mālpils novads" },
        "062": { id: "provinces.LV.062", defaultMessage: "Mārupes novads" },
        "063": { id: "provinces.LV.063", defaultMessage: "Mērsraga novads" },
        "064": { id: "provinces.LV.064", defaultMessage: "Naukšēnu novads" },
        "065": { id: "provinces.LV.065", defaultMessage: "Neretas novads" },
        "066": { id: "provinces.LV.066", defaultMessage: "Nīcas novads" },
        "067": { id: "provinces.LV.067", defaultMessage: "Ogres novads" },
        "068": { id: "provinces.LV.068", defaultMessage: "Olaines novads" },
        "069": { id: "provinces.LV.069", defaultMessage: "Ozolnieku novads" },
        "070": { id: "provinces.LV.070", defaultMessage: "Pārgaujas novads" },
        "071": { id: "provinces.LV.071", defaultMessage: "Pāvilostas novads" },
    }),
    MO: defineMessages({}),
    MF: defineMessages({}),
    MA: defineMessages({
        AOU: { id: "provinces.MA.AOU", defaultMessage: "Aousserd (EH)" },
        ASZ: { id: "provinces.MA.ASZ", defaultMessage: "Assa-Zag (EH-partial)" },
        AZI: { id: "provinces.MA.AZI", defaultMessage: "Azilal" },
        BEM: { id: "provinces.MA.BEM", defaultMessage: "Béni Mellal" },
        BER: { id: "provinces.MA.BER", defaultMessage: "Berkane" },
        BES: { id: "provinces.MA.BES", defaultMessage: "Benslimane" },
        BOD: { id: "provinces.MA.BOD", defaultMessage: "Boujdour (EH)" },
        BOM: { id: "provinces.MA.BOM", defaultMessage: "Boulemane" },
        BRR: { id: "provinces.MA.BRR", defaultMessage: "Berrechid" },
        CAS: { id: "provinces.MA.CAS", defaultMessage: "Casablanca" },
        CHE: { id: "provinces.MA.CHE", defaultMessage: "Chefchaouen" },
        CHI: { id: "provinces.MA.CHI", defaultMessage: "Chichaoua" },
        CHT: { id: "provinces.MA.CHT", defaultMessage: "Chtouka-Ait Baha" },
        DRI: { id: "provinces.MA.DRI", defaultMessage: "Driouch" },
        ERR: { id: "provinces.MA.ERR", defaultMessage: "Errachidia" },
        ESI: { id: "provinces.MA.ESI", defaultMessage: "Essaouira" },
        ESM: { id: "provinces.MA.ESM", defaultMessage: "Es-Semara (EH-partial)" },
        FAH: { id: "provinces.MA.FAH", defaultMessage: "Fahs-Anjra" },
        FES: { id: "provinces.MA.FES", defaultMessage: "Fès" },
        FIG: { id: "provinces.MA.FIG", defaultMessage: "Figuig" },
        FQH: { id: "provinces.MA.FQH", defaultMessage: "Fquih Ben Salah" },
        GUE: { id: "provinces.MA.GUE", defaultMessage: "Guelmim" },
        GUF: { id: "provinces.MA.GUF", defaultMessage: "Guercif" },
        HAJ: { id: "provinces.MA.HAJ", defaultMessage: "El Hajeb" },
        HAO: { id: "provinces.MA.HAO", defaultMessage: "Al Haouz" },
        HOC: { id: "provinces.MA.HOC", defaultMessage: "Al Hoceïma" },
        IFR: { id: "provinces.MA.IFR", defaultMessage: "Ifrane" },
        INE: { id: "provinces.MA.INE", defaultMessage: "Inezgane-Ait Melloul" },
        JDI: { id: "provinces.MA.JDI", defaultMessage: "El Jadida" },
        "01": { id: "provinces.MA.01", defaultMessage: "Tanger-Tétouan-Al Hoceïma" },
        JRA: { id: "provinces.MA.JRA", defaultMessage: "Jerada" },
        "02": { id: "provinces.MA.02", defaultMessage: "L'Oriental" },
        KEN: { id: "provinces.MA.KEN", defaultMessage: "Kénitra" },
        "03": { id: "provinces.MA.03", defaultMessage: "Fès-Meknès" },
        KES: { id: "provinces.MA.KES", defaultMessage: "El Kelâa des Sraghna" },
        "04": { id: "provinces.MA.04", defaultMessage: "Rabat-Salé-Kénitra" },
        KHE: { id: "provinces.MA.KHE", defaultMessage: "Khemisset" },
        "05": { id: "provinces.MA.05", defaultMessage: "Béni Mellal-Khénifra" },
        KHN: { id: "provinces.MA.KHN", defaultMessage: "Khenifra" },
        "06": { id: "provinces.MA.06", defaultMessage: "Casablanca-Settat" },
        KHO: { id: "provinces.MA.KHO", defaultMessage: "Khouribga" },
        "07": { id: "provinces.MA.07", defaultMessage: "Marrakech-Safi" },
        LAA: { id: "provinces.MA.LAA", defaultMessage: "Laâyoune (EH)" },
        "08": { id: "provinces.MA.08", defaultMessage: "Drâa-Tafilalet" },
        LAR: { id: "provinces.MA.LAR", defaultMessage: "Larache" },
        "09": { id: "provinces.MA.09", defaultMessage: "Souss-Massa" },
        MAR: { id: "provinces.MA.MAR", defaultMessage: "Marrakech" },
        10: { id: "provinces.MA.10", defaultMessage: "Guelmim-Oued Noun (EH-partial)" },
        MDF: { id: "provinces.MA.MDF", defaultMessage: "M’diq-Fnideq" },
        11: { id: "provinces.MA.11", defaultMessage: "Laâyoune-Sakia El Hamra (EH-partial)" },
        MED: { id: "provinces.MA.MED", defaultMessage: "Médiouna" },
        12: { id: "provinces.MA.12", defaultMessage: "Dakhla-Oued Ed-Dahab (EH)" },
        MEK: { id: "provinces.MA.MEK", defaultMessage: "Meknès" },
        MID: { id: "provinces.MA.MID", defaultMessage: "Midelt" },
        AGD: { id: "provinces.MA.AGD", defaultMessage: "Agadir-Ida-Ou-Tanane" },
        MOH: { id: "provinces.MA.MOH", defaultMessage: "Mohammadia" },
        MOU: { id: "provinces.MA.MOU", defaultMessage: "Moulay Yacoub" },
        NAD: { id: "provinces.MA.NAD", defaultMessage: "Nador" },
        NOU: { id: "provinces.MA.NOU", defaultMessage: "Nouaceur" },
        OUA: { id: "provinces.MA.OUA", defaultMessage: "Ouarzazate" },
        OUD: { id: "provinces.MA.OUD", defaultMessage: "Oued Ed-Dahab (EH)" },
        OUJ: { id: "provinces.MA.OUJ", defaultMessage: "Oujda-Angad" },
        OUZ: { id: "provinces.MA.OUZ", defaultMessage: "Ouezzane" },
        RAB: { id: "provinces.MA.RAB", defaultMessage: "Rabat" },
        REH: { id: "provinces.MA.REH", defaultMessage: "Rehamna" },
        SAF: { id: "provinces.MA.SAF", defaultMessage: "Safi" },
        SAL: { id: "provinces.MA.SAL", defaultMessage: "Salé" },
        SEF: { id: "provinces.MA.SEF", defaultMessage: "Sefrou" },
        SET: { id: "provinces.MA.SET", defaultMessage: "Settat" },
        SIB: { id: "provinces.MA.SIB", defaultMessage: "Sidi Bennour" },
        SIF: { id: "provinces.MA.SIF", defaultMessage: "Sidi Ifni" },
        SIK: { id: "provinces.MA.SIK", defaultMessage: "Sidi Kacem" },
        SIL: { id: "provinces.MA.SIL", defaultMessage: "Sidi Slimane" },
        SKH: { id: "provinces.MA.SKH", defaultMessage: "Skhirate-Témara" },
        TAF: { id: "provinces.MA.TAF", defaultMessage: "Tarfaya (EH-partial)" },
        TAI: { id: "provinces.MA.TAI", defaultMessage: "Taourirt" },
        TAO: { id: "provinces.MA.TAO", defaultMessage: "Taounate" },
        TAR: { id: "provinces.MA.TAR", defaultMessage: "Taroudant" },
        TAT: { id: "provinces.MA.TAT", defaultMessage: "Tata" },
        TAZ: { id: "provinces.MA.TAZ", defaultMessage: "Taza" },
        TET: { id: "provinces.MA.TET", defaultMessage: "Tétouan" },
        TIN: { id: "provinces.MA.TIN", defaultMessage: "Tinghir" },
        TIZ: { id: "provinces.MA.TIZ", defaultMessage: "Tiznit" },
        TNG: { id: "provinces.MA.TNG", defaultMessage: "Tanger-Assilah" },
        TNT: { id: "provinces.MA.TNT", defaultMessage: "Tan-Tan (EH-partial)" },
        YUS: { id: "provinces.MA.YUS", defaultMessage: "Youssoufia" },
        ZAG: { id: "provinces.MA.ZAG", defaultMessage: "Zagora" },
    }),
    MC: defineMessages({
        LA: { id: "provinces.MC.LA", defaultMessage: "Larvotto" },
        SR: { id: "provinces.MC.SR", defaultMessage: "Saint-Roman" },
        CL: { id: "provinces.MC.CL", defaultMessage: "La Colle" },
        MU: { id: "provinces.MC.MU", defaultMessage: "Moulins" },
        MA: { id: "provinces.MC.MA", defaultMessage: "Malbousquet" },
        VR: { id: "provinces.MC.VR", defaultMessage: "Vallon de la Rousse" },
        CO: { id: "provinces.MC.CO", defaultMessage: "La Condamine" },
        PH: { id: "provinces.MC.PH", defaultMessage: "Port-Hercule" },
        SP: { id: "provinces.MC.SP", defaultMessage: "Spélugues" },
        MC: { id: "provinces.MC.MC", defaultMessage: "Monte-Carlo" },
        FO: { id: "provinces.MC.FO", defaultMessage: "Fontvieille" },
        SD: { id: "provinces.MC.SD", defaultMessage: "Sainte-Dévote" },
        MG: { id: "provinces.MC.MG", defaultMessage: "Moneghetti" },
        GA: { id: "provinces.MC.GA", defaultMessage: "La Gare" },
        SO: { id: "provinces.MC.SO", defaultMessage: "La Source" },
        MO: { id: "provinces.MC.MO", defaultMessage: "Monaco-Ville" },
        JE: { id: "provinces.MC.JE", defaultMessage: "Jardin Exotique" },
    }),
    MD: defineMessages({
        DU: { id: "provinces.MD.DU", defaultMessage: "Dubăsari" },
        TE: { id: "provinces.MD.TE", defaultMessage: "Telenești" },
        ED: { id: "provinces.MD.ED", defaultMessage: "Edineț" },
        UN: { id: "provinces.MD.UN", defaultMessage: "Ungheni" },
        FA: { id: "provinces.MD.FA", defaultMessage: "Fălești" },
        FL: { id: "provinces.MD.FL", defaultMessage: "Florești" },
        GA: { id: "provinces.MD.GA", defaultMessage: "Găgăuzia, Unitatea teritorială autonomă" },
        GL: { id: "provinces.MD.GL", defaultMessage: "Glodeni" },
        HI: { id: "provinces.MD.HI", defaultMessage: "Hîncești" },
        IA: { id: "provinces.MD.IA", defaultMessage: "Ialoveni" },
        AN: { id: "provinces.MD.AN", defaultMessage: "Anenii Noi" },
        LE: { id: "provinces.MD.LE", defaultMessage: "Leova" },
        BA: { id: "provinces.MD.BA", defaultMessage: "Bălți" },
        NI: { id: "provinces.MD.NI", defaultMessage: "Nisporeni" },
        BD: { id: "provinces.MD.BD", defaultMessage: "Tighina" },
        OC: { id: "provinces.MD.OC", defaultMessage: "Ocnița" },
        BR: { id: "provinces.MD.BR", defaultMessage: "Briceni" },
        OR: { id: "provinces.MD.OR", defaultMessage: "Orhei" },
        BS: { id: "provinces.MD.BS", defaultMessage: "Basarabeasca" },
        RE: { id: "provinces.MD.RE", defaultMessage: "Rezina" },
        CA: { id: "provinces.MD.CA", defaultMessage: "Cahul" },
        RI: { id: "provinces.MD.RI", defaultMessage: "Rîșcani" },
        CL: { id: "provinces.MD.CL", defaultMessage: "Călărași" },
        SD: { id: "provinces.MD.SD", defaultMessage: "Șoldănești" },
        CM: { id: "provinces.MD.CM", defaultMessage: "Cimișlia" },
        SI: { id: "provinces.MD.SI", defaultMessage: "Sîngerei" },
        CR: { id: "provinces.MD.CR", defaultMessage: "Criuleni" },
        SN: { id: "provinces.MD.SN", defaultMessage: "Stînga Nistrului, unitatea teritorială din" },
        CS: { id: "provinces.MD.CS", defaultMessage: "Căușeni" },
        SO: { id: "provinces.MD.SO", defaultMessage: "Soroca" },
        CT: { id: "provinces.MD.CT", defaultMessage: "Cantemir" },
        ST: { id: "provinces.MD.ST", defaultMessage: "Strășeni" },
        CU: { id: "provinces.MD.CU", defaultMessage: "Chișinău" },
        SV: { id: "provinces.MD.SV", defaultMessage: "Ștefan Vodă" },
        DO: { id: "provinces.MD.DO", defaultMessage: "Dondușeni" },
        TA: { id: "provinces.MD.TA", defaultMessage: "Taraclia" },
        DR: { id: "provinces.MD.DR", defaultMessage: "Drochia" },
    }),
    MG: defineMessages({
        M: { id: "provinces.MG.M", defaultMessage: "Mahajanga" },
        T: { id: "provinces.MG.T", defaultMessage: "Antananarivo" },
        U: { id: "provinces.MG.U", defaultMessage: "Toliara" },
        A: { id: "provinces.MG.A", defaultMessage: "Toamasina" },
        D: { id: "provinces.MG.D", defaultMessage: "Antsiranana" },
        F: { id: "provinces.MG.F", defaultMessage: "Fianarantsoa" },
    }),
    MV: defineMessages({
        20: { id: "provinces.MV.20", defaultMessage: "Baa" },
        23: { id: "provinces.MV.23", defaultMessage: "Haa Dhaalu" },
        24: { id: "provinces.MV.24", defaultMessage: "Shaviyani" },
        25: { id: "provinces.MV.25", defaultMessage: "Noonu" },
        26: { id: "provinces.MV.26", defaultMessage: "Kaafu" },
        27: { id: "provinces.MV.27", defaultMessage: "Gaafu Alifu" },
        28: { id: "provinces.MV.28", defaultMessage: "Gaafu Dhaalu" },
        29: { id: "provinces.MV.29", defaultMessage: "Gnaviyani" },
        CE: { id: "provinces.MV.CE", defaultMessage: "Central" },
        "00": { id: "provinces.MV.00", defaultMessage: "Alifu Dhaalu" },
        MLE: { id: "provinces.MV.MLE", defaultMessage: "Male" },
        "01": { id: "provinces.MV.01", defaultMessage: "Seenu" },
        NC: { id: "provinces.MV.NC", defaultMessage: "North Central" },
        "02": { id: "provinces.MV.02", defaultMessage: "Alifu Alifu" },
        NO: { id: "provinces.MV.NO", defaultMessage: "North" },
        "03": { id: "provinces.MV.03", defaultMessage: "Lhaviyani" },
        SC: { id: "provinces.MV.SC", defaultMessage: "South Central" },
        "04": { id: "provinces.MV.04", defaultMessage: "Vaavu" },
        SU: { id: "provinces.MV.SU", defaultMessage: "South" },
        "05": { id: "provinces.MV.05", defaultMessage: "Laamu" },
        UN: { id: "provinces.MV.UN", defaultMessage: "Upper North" },
        "07": { id: "provinces.MV.07", defaultMessage: "Haa Alifu" },
        US: { id: "provinces.MV.US", defaultMessage: "Upper South" },
        "08": { id: "provinces.MV.08", defaultMessage: "Thaa" },
        12: { id: "provinces.MV.12", defaultMessage: "Meemu" },
        13: { id: "provinces.MV.13", defaultMessage: "Raa" },
        14: { id: "provinces.MV.14", defaultMessage: "Faafu" },
        17: { id: "provinces.MV.17", defaultMessage: "Dhaalu" },
    }),
    MX: defineMessages({
        MOR: { id: "provinces.MX.MOR", defaultMessage: "Morelos" },
        NAY: { id: "provinces.MX.NAY", defaultMessage: "Nayarit" },
        NLE: { id: "provinces.MX.NLE", defaultMessage: "Nuevo León" },
        OAX: { id: "provinces.MX.OAX", defaultMessage: "Oaxaca" },
        PUE: { id: "provinces.MX.PUE", defaultMessage: "Puebla" },
        QUE: { id: "provinces.MX.QUE", defaultMessage: "Querétaro" },
        AGU: { id: "provinces.MX.AGU", defaultMessage: "Aguascalientes" },
        ROO: { id: "provinces.MX.ROO", defaultMessage: "Quintana Roo" },
        BCN: { id: "provinces.MX.BCN", defaultMessage: "Baja California" },
        SIN: { id: "provinces.MX.SIN", defaultMessage: "Sinaloa" },
        BCS: { id: "provinces.MX.BCS", defaultMessage: "Baja California Sur" },
        SLP: { id: "provinces.MX.SLP", defaultMessage: "San Luis Potosí" },
        CAM: { id: "provinces.MX.CAM", defaultMessage: "Campeche" },
        SON: { id: "provinces.MX.SON", defaultMessage: "Sonora" },
        CHH: { id: "provinces.MX.CHH", defaultMessage: "Chihuahua" },
        TAB: { id: "provinces.MX.TAB", defaultMessage: "Tabasco" },
        CHP: { id: "provinces.MX.CHP", defaultMessage: "Chiapas" },
        TAM: { id: "provinces.MX.TAM", defaultMessage: "Tamaulipas" },
        CMX: { id: "provinces.MX.CMX", defaultMessage: "Ciudad de México" },
        TLA: { id: "provinces.MX.TLA", defaultMessage: "Tlaxcala" },
        COA: { id: "provinces.MX.COA", defaultMessage: "Coahuila de Zaragoza" },
        VER: { id: "provinces.MX.VER", defaultMessage: "Veracruz de Ignacio de la Llave" },
        COL: { id: "provinces.MX.COL", defaultMessage: "Colima" },
        YUC: { id: "provinces.MX.YUC", defaultMessage: "Yucatán" },
        DUR: { id: "provinces.MX.DUR", defaultMessage: "Durango" },
        ZAC: { id: "provinces.MX.ZAC", defaultMessage: "Zacatecas" },
        GRO: { id: "provinces.MX.GRO", defaultMessage: "Guerrero" },
        GUA: { id: "provinces.MX.GUA", defaultMessage: "Guanajuato" },
        HID: { id: "provinces.MX.HID", defaultMessage: "Hidalgo" },
        JAL: { id: "provinces.MX.JAL", defaultMessage: "Jalisco" },
        MEX: { id: "provinces.MX.MEX", defaultMessage: "México" },
        MIC: { id: "provinces.MX.MIC", defaultMessage: "Michoacán de Ocampo" },
    }),
    MH: defineMessages({
        LIK: { id: "provinces.MH.LIK", defaultMessage: "Likiep" },
        MAJ: { id: "provinces.MH.MAJ", defaultMessage: "Majuro" },
        MAL: { id: "provinces.MH.MAL", defaultMessage: "Maloelap" },
        MEJ: { id: "provinces.MH.MEJ", defaultMessage: "Mejit" },
        MIL: { id: "provinces.MH.MIL", defaultMessage: "Mili" },
        NMK: { id: "provinces.MH.NMK", defaultMessage: "Namdrik" },
        NMU: { id: "provinces.MH.NMU", defaultMessage: "Namu" },
        RON: { id: "provinces.MH.RON", defaultMessage: "Rongelap" },
        ALK: { id: "provinces.MH.ALK", defaultMessage: "Ailuk" },
        T: { id: "provinces.MH.T", defaultMessage: "Ratak chain" },
        ALL: { id: "provinces.MH.ALL", defaultMessage: "Ailinglaplap" },
        UJA: { id: "provinces.MH.UJA", defaultMessage: "Ujae" },
        ARN: { id: "provinces.MH.ARN", defaultMessage: "Arno" },
        UTI: { id: "provinces.MH.UTI", defaultMessage: "Utirik" },
        AUR: { id: "provinces.MH.AUR", defaultMessage: "Aur" },
        WTJ: { id: "provinces.MH.WTJ", defaultMessage: "Wotje" },
        EBO: { id: "provinces.MH.EBO", defaultMessage: "Ebon" },
        WTN: { id: "provinces.MH.WTN", defaultMessage: "Wotho" },
        ENI: { id: "provinces.MH.ENI", defaultMessage: "Enewetak" },
        JAB: { id: "provinces.MH.JAB", defaultMessage: "Jabat" },
        JAL: { id: "provinces.MH.JAL", defaultMessage: "Jaluit" },
        KIL: { id: "provinces.MH.KIL", defaultMessage: "Kili" },
        KWA: { id: "provinces.MH.KWA", defaultMessage: "Kwajalein" },
        L: { id: "provinces.MH.L", defaultMessage: "Ralik chain" },
        LAE: { id: "provinces.MH.LAE", defaultMessage: "Lae" },
        LIB: { id: "provinces.MH.LIB", defaultMessage: "Lib" },
    }),
    MK: defineMessages({
        30: { id: "provinces.MK.30", defaultMessage: "Želino" },
        31: { id: "provinces.MK.31", defaultMessage: "Zajas" },
        32: { id: "provinces.MK.32", defaultMessage: "Zelenikovo" },
        33: { id: "provinces.MK.33", defaultMessage: "Zrnovci" },
        34: { id: "provinces.MK.34", defaultMessage: "Ilinden" },
        35: { id: "provinces.MK.35", defaultMessage: "Jegunovce" },
        36: { id: "provinces.MK.36", defaultMessage: "Kavadarci" },
        37: { id: "provinces.MK.37", defaultMessage: "Karbinci" },
        38: { id: "provinces.MK.38", defaultMessage: "Karpoš" },
        39: { id: "provinces.MK.39", defaultMessage: "Kisela Voda" },
        40: { id: "provinces.MK.40", defaultMessage: "Kičevo" },
        41: { id: "provinces.MK.41", defaultMessage: "Konče" },
        42: { id: "provinces.MK.42", defaultMessage: "Kočani" },
        "01": { id: "provinces.MK.01", defaultMessage: "Aerodrom" },
        43: { id: "provinces.MK.43", defaultMessage: "Kratovo" },
        "02": { id: "provinces.MK.02", defaultMessage: "Aračinovo" },
        44: { id: "provinces.MK.44", defaultMessage: "Kriva Palanka" },
        "03": { id: "provinces.MK.03", defaultMessage: "Berovo" },
        45: { id: "provinces.MK.45", defaultMessage: "Krivogaštani" },
        "04": { id: "provinces.MK.04", defaultMessage: "Bitola" },
        46: { id: "provinces.MK.46", defaultMessage: "Kruševo" },
        "05": { id: "provinces.MK.05", defaultMessage: "Bogdanci" },
        47: { id: "provinces.MK.47", defaultMessage: "Kumanovo" },
        "06": { id: "provinces.MK.06", defaultMessage: "Bogovinje" },
        48: { id: "provinces.MK.48", defaultMessage: "Lipkovo" },
        "07": { id: "provinces.MK.07", defaultMessage: "Bosilovo" },
        49: { id: "provinces.MK.49", defaultMessage: "Lozovo" },
        50: { id: "provinces.MK.50", defaultMessage: "Mavrovo-i-Rostuša" },
        "08": { id: "provinces.MK.08", defaultMessage: "Brvenica" },
        "09": { id: "provinces.MK.09", defaultMessage: "Butel" },
        51: { id: "provinces.MK.51", defaultMessage: "Makedonska Kamenica" },
        10: { id: "provinces.MK.10", defaultMessage: "Valandovo" },
        52: { id: "provinces.MK.52", defaultMessage: "Makedonski Brod" },
        11: { id: "provinces.MK.11", defaultMessage: "Vasilevo" },
        53: { id: "provinces.MK.53", defaultMessage: "Mogila" },
        12: { id: "provinces.MK.12", defaultMessage: "Vevčani" },
        54: { id: "provinces.MK.54", defaultMessage: "Negotino" },
        13: { id: "provinces.MK.13", defaultMessage: "Veles" },
        55: { id: "provinces.MK.55", defaultMessage: "Novaci" },
        14: { id: "provinces.MK.14", defaultMessage: "Vinica" },
        56: { id: "provinces.MK.56", defaultMessage: "Novo Selo" },
        15: { id: "provinces.MK.15", defaultMessage: "Vraneštica" },
        57: { id: "provinces.MK.57", defaultMessage: "Oslomej" },
        16: { id: "provinces.MK.16", defaultMessage: "Vrapčište" },
        58: { id: "provinces.MK.58", defaultMessage: "Ohrid" },
        17: { id: "provinces.MK.17", defaultMessage: "Gazi Baba" },
        59: { id: "provinces.MK.59", defaultMessage: "Petrovec" },
        18: { id: "provinces.MK.18", defaultMessage: "Gevgelija" },
        60: { id: "provinces.MK.60", defaultMessage: "Pehčevo" },
        19: { id: "provinces.MK.19", defaultMessage: "Gostivar" },
        61: { id: "provinces.MK.61", defaultMessage: "Plasnica" },
        20: { id: "provinces.MK.20", defaultMessage: "Gradsko" },
        62: { id: "provinces.MK.62", defaultMessage: "Prilep" },
        21: { id: "provinces.MK.21", defaultMessage: "Debar" },
        63: { id: "provinces.MK.63", defaultMessage: "Probištip" },
        22: { id: "provinces.MK.22", defaultMessage: "Debarca" },
        64: { id: "provinces.MK.64", defaultMessage: "Radoviš" },
        23: { id: "provinces.MK.23", defaultMessage: "Delčevo" },
        65: { id: "provinces.MK.65", defaultMessage: "Rankovce" },
        24: { id: "provinces.MK.24", defaultMessage: "Demir Kapija" },
        66: { id: "provinces.MK.66", defaultMessage: "Resen" },
        25: { id: "provinces.MK.25", defaultMessage: "Demir Hisar" },
        67: { id: "provinces.MK.67", defaultMessage: "Rosoman" },
        26: { id: "provinces.MK.26", defaultMessage: "Dojran" },
        68: { id: "provinces.MK.68", defaultMessage: "Saraj" },
        27: { id: "provinces.MK.27", defaultMessage: "Dolneni" },
        69: { id: "provinces.MK.69", defaultMessage: "Sveti Nikole" },
        28: { id: "provinces.MK.28", defaultMessage: "Drugovo" },
        70: { id: "provinces.MK.70", defaultMessage: "Sopište" },
        71: { id: "provinces.MK.71", defaultMessage: "Staro Nagoričane" },
        29: { id: "provinces.MK.29", defaultMessage: "Gjorče Petrov" },
        72: { id: "provinces.MK.72", defaultMessage: "Struga" },
        73: { id: "provinces.MK.73", defaultMessage: "Strumica" },
        74: { id: "provinces.MK.74", defaultMessage: "Studeničani" },
        75: { id: "provinces.MK.75", defaultMessage: "Tearce" },
        76: { id: "provinces.MK.76", defaultMessage: "Tetovo" },
        77: { id: "provinces.MK.77", defaultMessage: "Centar" },
        78: { id: "provinces.MK.78", defaultMessage: "Centar Župa" },
        79: { id: "provinces.MK.79", defaultMessage: "Čair" },
        80: { id: "provinces.MK.80", defaultMessage: "Čaška" },
        81: { id: "provinces.MK.81", defaultMessage: "Češinovo-Obleševo" },
        82: { id: "provinces.MK.82", defaultMessage: "Čučer Sandevo" },
        83: { id: "provinces.MK.83", defaultMessage: "Štip" },
        84: { id: "provinces.MK.84", defaultMessage: "Šuto Orizari" },
    }),
    ML: defineMessages({
        BK0: { id: "provinces.ML.BK0", defaultMessage: "Bamako" },
        4: { id: "provinces.ML.4", defaultMessage: "Ségou" },
        5: { id: "provinces.ML.5", defaultMessage: "Mopti" },
        6: { id: "provinces.ML.6", defaultMessage: "Tombouctou" },
        1: { id: "provinces.ML.1", defaultMessage: "Kayes" },
        7: { id: "provinces.ML.7", defaultMessage: "Gao" },
        2: { id: "provinces.ML.2", defaultMessage: "Koulikoro" },
        8: { id: "provinces.ML.8", defaultMessage: "Kidal" },
        3: { id: "provinces.ML.3", defaultMessage: "Sikasso" },
    }),
    MT: defineMessages({
        35: { id: "provinces.MT.35", defaultMessage: "Mtarfa" },
        14: { id: "provinces.MT.14", defaultMessage: "Għarb" },
        56: { id: "provinces.MT.56", defaultMessage: "Sliema" },
        36: { id: "provinces.MT.36", defaultMessage: "Munxar" },
        15: { id: "provinces.MT.15", defaultMessage: "Għargħur" },
        57: { id: "provinces.MT.57", defaultMessage: "Swieqi" },
        37: { id: "provinces.MT.37", defaultMessage: "Nadur" },
        16: { id: "provinces.MT.16", defaultMessage: "Għasri" },
        58: { id: "provinces.MT.58", defaultMessage: "Ta’ Xbiex" },
        38: { id: "provinces.MT.38", defaultMessage: "Naxxar" },
        17: { id: "provinces.MT.17", defaultMessage: "Għaxaq" },
        59: { id: "provinces.MT.59", defaultMessage: "Tarxien" },
        39: { id: "provinces.MT.39", defaultMessage: "Paola" },
        18: { id: "provinces.MT.18", defaultMessage: "Ħamrun" },
        60: { id: "provinces.MT.60", defaultMessage: "Valletta" },
        40: { id: "provinces.MT.40", defaultMessage: "Pembroke" },
        19: { id: "provinces.MT.19", defaultMessage: "Iklin" },
        61: { id: "provinces.MT.61", defaultMessage: "Xagħra" },
        41: { id: "provinces.MT.41", defaultMessage: "Pietà" },
        20: { id: "provinces.MT.20", defaultMessage: "Isla" },
        62: { id: "provinces.MT.62", defaultMessage: "Xewkija" },
        42: { id: "provinces.MT.42", defaultMessage: "Qala" },
        21: { id: "provinces.MT.21", defaultMessage: "Kalkara" },
        63: { id: "provinces.MT.63", defaultMessage: "Xgħajra" },
        "01": { id: "provinces.MT.01", defaultMessage: "Attard" },
        43: { id: "provinces.MT.43", defaultMessage: "Qormi" },
        22: { id: "provinces.MT.22", defaultMessage: "Kerċem" },
        64: { id: "provinces.MT.64", defaultMessage: "Żabbar" },
        "02": { id: "provinces.MT.02", defaultMessage: "Balzan" },
        44: { id: "provinces.MT.44", defaultMessage: "Qrendi" },
        23: { id: "provinces.MT.23", defaultMessage: "Kirkop" },
        65: { id: "provinces.MT.65", defaultMessage: "Żebbuġ Għawdex" },
        "03": { id: "provinces.MT.03", defaultMessage: "Birgu" },
        45: { id: "provinces.MT.45", defaultMessage: "Rabat Għawdex" },
        24: { id: "provinces.MT.24", defaultMessage: "Lija" },
        66: { id: "provinces.MT.66", defaultMessage: "Żebbuġ Malta" },
        "04": { id: "provinces.MT.04", defaultMessage: "Birkirkara" },
        46: { id: "provinces.MT.46", defaultMessage: "Rabat Malta" },
        25: { id: "provinces.MT.25", defaultMessage: "Luqa" },
        67: { id: "provinces.MT.67", defaultMessage: "Żejtun" },
        "05": { id: "provinces.MT.05", defaultMessage: "Birżebbuġa" },
        47: { id: "provinces.MT.47", defaultMessage: "Safi" },
        26: { id: "provinces.MT.26", defaultMessage: "Marsa" },
        68: { id: "provinces.MT.68", defaultMessage: "Żurrieq" },
        "06": { id: "provinces.MT.06", defaultMessage: "Bormla" },
        48: { id: "provinces.MT.48", defaultMessage: "San Ġiljan" },
        27: { id: "provinces.MT.27", defaultMessage: "Marsaskala" },
        "07": { id: "provinces.MT.07", defaultMessage: "Dingli" },
        49: { id: "provinces.MT.49", defaultMessage: "San Ġwann" },
        28: { id: "provinces.MT.28", defaultMessage: "Marsaxlokk" },
        55: { id: "provinces.MT.55", defaultMessage: "Siġġiewi" },
        "08": { id: "provinces.MT.08", defaultMessage: "Fgura" },
        50: { id: "provinces.MT.50", defaultMessage: "San Lawrenz" },
        29: { id: "provinces.MT.29", defaultMessage: "Mdina" },
        "09": { id: "provinces.MT.09", defaultMessage: "Floriana" },
        51: { id: "provinces.MT.51", defaultMessage: "San Pawl il-Baħar" },
        30: { id: "provinces.MT.30", defaultMessage: "Mellieħa" },
        10: { id: "provinces.MT.10", defaultMessage: "Fontana" },
        52: { id: "provinces.MT.52", defaultMessage: "Sannat" },
        31: { id: "provinces.MT.31", defaultMessage: "Mġarr" },
        11: { id: "provinces.MT.11", defaultMessage: "Gudja" },
        53: { id: "provinces.MT.53", defaultMessage: "Santa Luċija" },
        32: { id: "provinces.MT.32", defaultMessage: "Mosta" },
        12: { id: "provinces.MT.12", defaultMessage: "Gżira" },
        54: { id: "provinces.MT.54", defaultMessage: "Santa Venera" },
        33: { id: "provinces.MT.33", defaultMessage: "Mqabba" },
        13: { id: "provinces.MT.13", defaultMessage: "Għajnsielem" },
        34: { id: "provinces.MT.34", defaultMessage: "Msida" },
    }),
    MM: defineMessages({
        "05": { id: "provinces.MM.05", defaultMessage: "Tanintharyi" },
        14: { id: "provinces.MM.14", defaultMessage: "Chin" },
        "06": { id: "provinces.MM.06", defaultMessage: "Yangon" },
        "01": { id: "provinces.MM.01", defaultMessage: "Sagaing" },
        15: { id: "provinces.MM.15", defaultMessage: "Mon" },
        "07": { id: "provinces.MM.07", defaultMessage: "Ayeyarwady" },
        "02": { id: "provinces.MM.02", defaultMessage: "Bago" },
        16: { id: "provinces.MM.16", defaultMessage: "Rakhine" },
        11: { id: "provinces.MM.11", defaultMessage: "Kachin" },
        "03": { id: "provinces.MM.03", defaultMessage: "Magway" },
        17: { id: "provinces.MM.17", defaultMessage: "Shan" },
        12: { id: "provinces.MM.12", defaultMessage: "Kayah" },
        "04": { id: "provinces.MM.04", defaultMessage: "Mandalay" },
        13: { id: "provinces.MM.13", defaultMessage: "Kayin" },
    }),
    ME: defineMessages({
        20: { id: "provinces.ME.20", defaultMessage: "Ulcinj" },
        21: { id: "provinces.ME.21", defaultMessage: "Žabljak" },
        "01": { id: "provinces.ME.01", defaultMessage: "Andrijevica" },
        "02": { id: "provinces.ME.02", defaultMessage: "Bar" },
        "03": { id: "provinces.ME.03", defaultMessage: "Berane" },
        "04": { id: "provinces.ME.04", defaultMessage: "Bijelo Polje" },
        "05": { id: "provinces.ME.05", defaultMessage: "Budva" },
        "06": { id: "provinces.ME.06", defaultMessage: "Cetinje" },
        "07": { id: "provinces.ME.07", defaultMessage: "Danilovgrad" },
        "08": { id: "provinces.ME.08", defaultMessage: "Herceg-Novi" },
        "09": { id: "provinces.ME.09", defaultMessage: "Kolašin" },
        10: { id: "provinces.ME.10", defaultMessage: "Kotor" },
        11: { id: "provinces.ME.11", defaultMessage: "Mojkovac" },
        12: { id: "provinces.ME.12", defaultMessage: "Nikšić" },
        13: { id: "provinces.ME.13", defaultMessage: "Plav" },
        14: { id: "provinces.ME.14", defaultMessage: "Pljevlja" },
        15: { id: "provinces.ME.15", defaultMessage: "Plužine" },
        16: { id: "provinces.ME.16", defaultMessage: "Podgorica" },
        17: { id: "provinces.ME.17", defaultMessage: "Rožaje" },
        18: { id: "provinces.ME.18", defaultMessage: "Šavnik" },
        19: { id: "provinces.ME.19", defaultMessage: "Tivat" },
    }),
    MN: defineMessages({
        "047": { id: "provinces.MN.047", defaultMessage: "Töv" },
        "049": { id: "provinces.MN.049", defaultMessage: "Selenge" },
        "051": { id: "provinces.MN.051", defaultMessage: "Sühbaatar" },
        "053": { id: "provinces.MN.053", defaultMessage: "Ömnögovi" },
        "055": { id: "provinces.MN.055", defaultMessage: "Övörhangay" },
        "057": { id: "provinces.MN.057", defaultMessage: "Dzavhan" },
        "059": { id: "provinces.MN.059", defaultMessage: "Dundgovi" },
        "061": { id: "provinces.MN.061", defaultMessage: "Dornod" },
        "063": { id: "provinces.MN.063", defaultMessage: "Dornogovi" },
        "064": { id: "provinces.MN.064", defaultMessage: "Govi-Sumber" },
        "065": { id: "provinces.MN.065", defaultMessage: "Govi-Altay" },
        "067": { id: "provinces.MN.067", defaultMessage: "Bulgan" },
        "069": { id: "provinces.MN.069", defaultMessage: "Bayanhongor" },
        "071": { id: "provinces.MN.071", defaultMessage: "Bayan-Ölgiy" },
        "073": { id: "provinces.MN.073", defaultMessage: "Arhangay" },
        "035": { id: "provinces.MN.035", defaultMessage: "Orhon" },
        1: { id: "provinces.MN.1", defaultMessage: "Ulanbaatar" },
        "037": { id: "provinces.MN.037", defaultMessage: "Darhan uul" },
        "039": { id: "provinces.MN.039", defaultMessage: "Hentiy" },
        "041": { id: "provinces.MN.041", defaultMessage: "Hövsgöl" },
        "043": { id: "provinces.MN.043", defaultMessage: "Hovd" },
        "046": { id: "provinces.MN.046", defaultMessage: "Uvs" },
    }),
    MP: defineMessages({}),
    MZ: defineMessages({
        T: { id: "provinces.MZ.T", defaultMessage: "Tete" },
        MPM: { id: "provinces.MZ.MPM", defaultMessage: "Maputo (city)" },
        A: { id: "provinces.MZ.A", defaultMessage: "Niassa" },
        N: { id: "provinces.MZ.N", defaultMessage: "Numpula" },
        B: { id: "provinces.MZ.B", defaultMessage: "Manica" },
        P: { id: "provinces.MZ.P", defaultMessage: "Cabo Delgado" },
        G: { id: "provinces.MZ.G", defaultMessage: "Gaza" },
        Q: { id: "provinces.MZ.Q", defaultMessage: "Zambezia" },
        I: { id: "provinces.MZ.I", defaultMessage: "Inhambane" },
        S: { id: "provinces.MZ.S", defaultMessage: "Sofala" },
        L: { id: "provinces.MZ.L", defaultMessage: "Maputo" },
    }),
    MR: defineMessages({
        11: { id: "provinces.MR.11", defaultMessage: "Tiris Zemmour" },
        "06": { id: "provinces.MR.06", defaultMessage: "Trarza" },
        "01": { id: "provinces.MR.01", defaultMessage: "Hodh ech Chargui" },
        12: { id: "provinces.MR.12", defaultMessage: "Inchiri" },
        "07": { id: "provinces.MR.07", defaultMessage: "Adrar" },
        "02": { id: "provinces.MR.02", defaultMessage: "Hodh el Charbi" },
        NKC: { id: "provinces.MR.NKC", defaultMessage: "Nouakchott" },
        "08": { id: "provinces.MR.08", defaultMessage: "Dakhlet Nouadhibou" },
        "03": { id: "provinces.MR.03", defaultMessage: "Assaba" },
        "09": { id: "provinces.MR.09", defaultMessage: "Tagant" },
        "04": { id: "provinces.MR.04", defaultMessage: "Gorgol" },
        10: { id: "provinces.MR.10", defaultMessage: "Guidimaka" },
        "05": { id: "provinces.MR.05", defaultMessage: "Brakna" },
    }),
    MS: defineMessages({}),
    MQ: defineMessages({}),
    MU: defineMessages({
        RO: { id: "provinces.MU.RO", defaultMessage: "Rodrigues Island" },
        PA: { id: "provinces.MU.PA", defaultMessage: "Pamplemousses" },
        CC: { id: "provinces.MU.CC", defaultMessage: "Cargados Carajos Shoals" },
        RP: { id: "provinces.MU.RP", defaultMessage: "Rivière du Rempart" },
        PL: { id: "provinces.MU.PL", defaultMessage: "Port Louis" },
        CU: { id: "provinces.MU.CU", defaultMessage: "Curepipe" },
        SA: { id: "provinces.MU.SA", defaultMessage: "Savanne" },
        PU: { id: "provinces.MU.PU", defaultMessage: "Port Louis" },
        FL: { id: "provinces.MU.FL", defaultMessage: "Flacq" },
        AG: { id: "provinces.MU.AG", defaultMessage: "Agalega Islands" },
        VP: { id: "provinces.MU.VP", defaultMessage: "Vacoas-Phoenix" },
        PW: { id: "provinces.MU.PW", defaultMessage: "Plaines Wilhems" },
        GP: { id: "provinces.MU.GP", defaultMessage: "Grand Port" },
        BL: { id: "provinces.MU.BL", defaultMessage: "Black River" },
        QB: { id: "provinces.MU.QB", defaultMessage: "Quatre Bornes" },
        MO: { id: "provinces.MU.MO", defaultMessage: "Moka" },
        BR: { id: "provinces.MU.BR", defaultMessage: "Beau Bassin-Rose Hill" },
    }),
    MW: defineMessages({
        CT: { id: "provinces.MW.CT", defaultMessage: "Chitipa" },
        RU: { id: "provinces.MW.RU", defaultMessage: "Rumphi" },
        DE: { id: "provinces.MW.DE", defaultMessage: "Dedza" },
        S: { id: "provinces.MW.S", defaultMessage: "Southern Region" },
        DO: { id: "provinces.MW.DO", defaultMessage: "Dowa" },
        SA: { id: "provinces.MW.SA", defaultMessage: "Salima" },
        KR: { id: "provinces.MW.KR", defaultMessage: "Karonga" },
        TH: { id: "provinces.MW.TH", defaultMessage: "Thyolo" },
        KS: { id: "provinces.MW.KS", defaultMessage: "Kasungu" },
        ZO: { id: "provinces.MW.ZO", defaultMessage: "Zomba" },
        LI: { id: "provinces.MW.LI", defaultMessage: "Lilongwe" },
        LK: { id: "provinces.MW.LK", defaultMessage: "Likoma" },
        MC: { id: "provinces.MW.MC", defaultMessage: "Mchinji" },
        MG: { id: "provinces.MW.MG", defaultMessage: "Mangochi" },
        MH: { id: "provinces.MW.MH", defaultMessage: "Machinga" },
        MU: { id: "provinces.MW.MU", defaultMessage: "Mulanje" },
        MW: { id: "provinces.MW.MW", defaultMessage: "Mwanza" },
        MZ: { id: "provinces.MW.MZ", defaultMessage: "Mzimba" },
        N: { id: "provinces.MW.N", defaultMessage: "Northern Region" },
        NB: { id: "provinces.MW.NB", defaultMessage: "Nkhata Bay" },
        NE: { id: "provinces.MW.NE", defaultMessage: "Neno" },
        NI: { id: "provinces.MW.NI", defaultMessage: "Ntchisi" },
        BA: { id: "provinces.MW.BA", defaultMessage: "Balaka" },
        NK: { id: "provinces.MW.NK", defaultMessage: "Nkhotakota" },
        BL: { id: "provinces.MW.BL", defaultMessage: "Blantyre" },
        NS: { id: "provinces.MW.NS", defaultMessage: "Nsanje" },
        C: { id: "provinces.MW.C", defaultMessage: "Central Region" },
        NU: { id: "provinces.MW.NU", defaultMessage: "Ntcheu" },
        CK: { id: "provinces.MW.CK", defaultMessage: "Chikwawa" },
        PH: { id: "provinces.MW.PH", defaultMessage: "Phalombe" },
        CR: { id: "provinces.MW.CR", defaultMessage: "Chiradzulu" },
    }),
    MY: defineMessages({
        16: { id: "provinces.MY.16", defaultMessage: "Wilayah Persekutuan Putrajaya" },
        "01": { id: "provinces.MY.01", defaultMessage: "Johor" },
        11: { id: "provinces.MY.11", defaultMessage: "Terengganu" },
        "06": { id: "provinces.MY.06", defaultMessage: "Pahang" },
        "02": { id: "provinces.MY.02", defaultMessage: "Kedah" },
        12: { id: "provinces.MY.12", defaultMessage: "Sabah" },
        "07": { id: "provinces.MY.07", defaultMessage: "Pulau Pinang" },
        "03": { id: "provinces.MY.03", defaultMessage: "Kelantan" },
        13: { id: "provinces.MY.13", defaultMessage: "Sarawak" },
        "08": { id: "provinces.MY.08", defaultMessage: "Perak" },
        "04": { id: "provinces.MY.04", defaultMessage: "Melaka" },
        14: { id: "provinces.MY.14", defaultMessage: "Wilayah Persekutuan Kuala Lumpur" },
        "09": { id: "provinces.MY.09", defaultMessage: "Perlis" },
        15: { id: "provinces.MY.15", defaultMessage: "Wilayah Persekutuan Labuan" },
        "05": { id: "provinces.MY.05", defaultMessage: "Negeri Sembilan" },
        10: { id: "provinces.MY.10", defaultMessage: "Selangor" },
    }),
    YT: defineMessages({}),
    NA: defineMessages({
        KH: { id: "provinces.NA.KH", defaultMessage: "Khomas" },
        OS: { id: "provinces.NA.OS", defaultMessage: "Omusati" },
        KU: { id: "provinces.NA.KU", defaultMessage: "Kunene" },
        CA: { id: "provinces.NA.CA", defaultMessage: "Caprivi" },
        OT: { id: "provinces.NA.OT", defaultMessage: "Oshikoto" },
        OD: { id: "provinces.NA.OD", defaultMessage: "Otjozondjupa" },
        ER: { id: "provinces.NA.ER", defaultMessage: "Erongo" },
        OW: { id: "provinces.NA.OW", defaultMessage: "Ohangwena" },
        OH: { id: "provinces.NA.OH", defaultMessage: "Omaheke" },
        HA: { id: "provinces.NA.HA", defaultMessage: "Hardap" },
        OK: { id: "provinces.NA.OK", defaultMessage: "Okavango" },
        KA: { id: "provinces.NA.KA", defaultMessage: "Karas" },
        ON: { id: "provinces.NA.ON", defaultMessage: "Oshana" },
    }),
    NC: defineMessages({}),
    NE: defineMessages({
        3: { id: "provinces.NE.3", defaultMessage: "Dosso" },
        8: { id: "provinces.NE.8", defaultMessage: "Niamey" },
        4: { id: "provinces.NE.4", defaultMessage: "Maradi" },
        5: { id: "provinces.NE.5", defaultMessage: "Tahoua" },
        6: { id: "provinces.NE.6", defaultMessage: "Tillabéri" },
        1: { id: "provinces.NE.1", defaultMessage: "Agadez" },
        7: { id: "provinces.NE.7", defaultMessage: "Zinder" },
        2: { id: "provinces.NE.2", defaultMessage: "Diffa" },
    }),
    NF: defineMessages({}),
    NG: defineMessages({
        KN: { id: "provinces.NG.KN", defaultMessage: "Kano" },
        KO: { id: "provinces.NG.KO", defaultMessage: "Kogi" },
        AB: { id: "provinces.NG.AB", defaultMessage: "Abia" },
        KT: { id: "provinces.NG.KT", defaultMessage: "Katsina" },
        AD: { id: "provinces.NG.AD", defaultMessage: "Adamawa" },
        KW: { id: "provinces.NG.KW", defaultMessage: "Kwara" },
        AK: { id: "provinces.NG.AK", defaultMessage: "Akwa Ibom" },
        LA: { id: "provinces.NG.LA", defaultMessage: "Lagos" },
        AN: { id: "provinces.NG.AN", defaultMessage: "Anambra" },
        NA: { id: "provinces.NG.NA", defaultMessage: "Nassarawa" },
        BA: { id: "provinces.NG.BA", defaultMessage: "Bauchi" },
        NI: { id: "provinces.NG.NI", defaultMessage: "Niger" },
        BE: { id: "provinces.NG.BE", defaultMessage: "Benue" },
        OG: { id: "provinces.NG.OG", defaultMessage: "Ogun" },
        BO: { id: "provinces.NG.BO", defaultMessage: "Borno" },
        ON: { id: "provinces.NG.ON", defaultMessage: "Ondo" },
        BY: { id: "provinces.NG.BY", defaultMessage: "Bayelsa" },
        OS: { id: "provinces.NG.OS", defaultMessage: "Osun" },
        CR: { id: "provinces.NG.CR", defaultMessage: "Cross River" },
        OY: { id: "provinces.NG.OY", defaultMessage: "Oyo" },
        DE: { id: "provinces.NG.DE", defaultMessage: "Delta" },
        PL: { id: "provinces.NG.PL", defaultMessage: "Plateau" },
        EB: { id: "provinces.NG.EB", defaultMessage: "Ebonyi" },
        RI: { id: "provinces.NG.RI", defaultMessage: "Rivers" },
        ED: { id: "provinces.NG.ED", defaultMessage: "Edo" },
        SO: { id: "provinces.NG.SO", defaultMessage: "Sokoto" },
        EK: { id: "provinces.NG.EK", defaultMessage: "Ekiti" },
        TA: { id: "provinces.NG.TA", defaultMessage: "Taraba" },
        EN: { id: "provinces.NG.EN", defaultMessage: "Enugu" },
        YO: { id: "provinces.NG.YO", defaultMessage: "Yobe" },
        FC: { id: "provinces.NG.FC", defaultMessage: "Abuja Capital Territory" },
        ZA: { id: "provinces.NG.ZA", defaultMessage: "Zamfara" },
        GO: { id: "provinces.NG.GO", defaultMessage: "Gombe" },
        IM: { id: "provinces.NG.IM", defaultMessage: "Imo" },
        JI: { id: "provinces.NG.JI", defaultMessage: "Jigawa" },
        KD: { id: "provinces.NG.KD", defaultMessage: "Kaduna" },
        KE: { id: "provinces.NG.KE", defaultMessage: "Kebbi" },
    }),
    NI: defineMessages({
        NS: { id: "provinces.NI.NS", defaultMessage: "Nueva Segovia" },
        LE: { id: "provinces.NI.LE", defaultMessage: "León" },
        CI: { id: "provinces.NI.CI", defaultMessage: "Chinandega" },
        RI: { id: "provinces.NI.RI", defaultMessage: "Rivas" },
        AN: { id: "provinces.NI.AN", defaultMessage: "Atlántico Norte" },
        MD: { id: "provinces.NI.MD", defaultMessage: "Madriz" },
        CO: { id: "provinces.NI.CO", defaultMessage: "Chontales" },
        MT: { id: "provinces.NI.MT", defaultMessage: "Matagalpa" },
        SJ: { id: "provinces.NI.SJ", defaultMessage: "Río San Juan" },
        AS: { id: "provinces.NI.AS", defaultMessage: "Atlántico Sur" },
        MN: { id: "provinces.NI.MN", defaultMessage: "Managua" },
        ES: { id: "provinces.NI.ES", defaultMessage: "Estelí" },
        BO: { id: "provinces.NI.BO", defaultMessage: "Boaco" },
        MS: { id: "provinces.NI.MS", defaultMessage: "Masaya" },
        GR: { id: "provinces.NI.GR", defaultMessage: "Granada" },
        CA: { id: "provinces.NI.CA", defaultMessage: "Carazo" },
        JI: { id: "provinces.NI.JI", defaultMessage: "Jinotega" },
    }),
    NU: defineMessages({}),
    NL: defineMessages({
        OV: { id: "provinces.NL.OV", defaultMessage: "Overijssel" },
        GE: { id: "provinces.NL.GE", defaultMessage: "Gelderland" },
        BQ3: { id: "provinces.NL.BQ3", defaultMessage: "Sint Eustatius" },
        SX: { id: "provinces.NL.SX", defaultMessage: "Sint Maarten" },
        ZH: { id: "provinces.NL.ZH", defaultMessage: "Zuid-Holland" },
        GR: { id: "provinces.NL.GR", defaultMessage: "Groningen" },
        CW: { id: "provinces.NL.CW", defaultMessage: "Curaçao" },
        UT: { id: "provinces.NL.UT", defaultMessage: "Utrecht" },
        LI: { id: "provinces.NL.LI", defaultMessage: "Limburg" },
        DR: { id: "provinces.NL.DR", defaultMessage: "Drenthe" },
        AW: { id: "provinces.NL.AW", defaultMessage: "Aruba" },
        ZE: { id: "provinces.NL.ZE", defaultMessage: "Zeeland" },
        NB: { id: "provinces.NL.NB", defaultMessage: "Noord-Brabant" },
        FL: { id: "provinces.NL.FL", defaultMessage: "Flevoland" },
        BQ1: { id: "provinces.NL.BQ1", defaultMessage: "Bonaire" },
        NH: { id: "provinces.NL.NH", defaultMessage: "Noord-Holland" },
        FR: { id: "provinces.NL.FR", defaultMessage: "Friesland" },
        BQ2: { id: "provinces.NL.BQ2", defaultMessage: "Saba" },
    }),
    NO: defineMessages({
        12: { id: "provinces.NO.12", defaultMessage: "Hordaland" },
        14: { id: "provinces.NO.14", defaultMessage: "Sogn og Fjordane" },
        15: { id: "provinces.NO.15", defaultMessage: "Møre og Romsdal" },
        18: { id: "provinces.NO.18", defaultMessage: "Nordland" },
        19: { id: "provinces.NO.19", defaultMessage: "Troms" },
        20: { id: "provinces.NO.20", defaultMessage: "Finnmark" },
        21: { id: "provinces.NO.21", defaultMessage: "Svalbard (Arctic Region)" },
        22: { id: "provinces.NO.22", defaultMessage: "Jan Mayen (Arctic Region)" },
        50: { id: "provinces.NO.50", defaultMessage: "Trøndelag" },
        "01": { id: "provinces.NO.01", defaultMessage: "Østfold" },
        "02": { id: "provinces.NO.02", defaultMessage: "Akershus" },
        "03": { id: "provinces.NO.03", defaultMessage: "Oslo" },
        "04": { id: "provinces.NO.04", defaultMessage: "Hedmark" },
        "05": { id: "provinces.NO.05", defaultMessage: "Oppland" },
        "06": { id: "provinces.NO.06", defaultMessage: "Buskerud" },
        "07": { id: "provinces.NO.07", defaultMessage: "Vestfold" },
        "08": { id: "provinces.NO.08", defaultMessage: "Telemark" },
        "09": { id: "provinces.NO.09", defaultMessage: "Aust-Agder" },
        10: { id: "provinces.NO.10", defaultMessage: "Vest-Agder" },
        11: { id: "provinces.NO.11", defaultMessage: "Rogaland" },
    }),
    NP: defineMessages({
        LU: { id: "provinces.NP.LU", defaultMessage: "Lumbini" },
        MA: { id: "provinces.NP.MA", defaultMessage: "Mahakali" },
        ME: { id: "provinces.NP.ME", defaultMessage: "Mechi" },
        NA: { id: "provinces.NP.NA", defaultMessage: "Narayani" },
        RA: { id: "provinces.NP.RA", defaultMessage: "Rapti" },
        SA: { id: "provinces.NP.SA", defaultMessage: "Sagarmatha" },
        SE: { id: "provinces.NP.SE", defaultMessage: "Seti" },
        1: { id: "provinces.NP.1", defaultMessage: "Madhyamanchal" },
        2: { id: "provinces.NP.2", defaultMessage: "Madhya Pashchimanchal" },
        3: { id: "provinces.NP.3", defaultMessage: "Pashchimanchal" },
        4: { id: "provinces.NP.4", defaultMessage: "Purwanchal" },
        5: { id: "provinces.NP.5", defaultMessage: "Sudur Pashchimanchal" },
        BA: { id: "provinces.NP.BA", defaultMessage: "Bagmati" },
        BH: { id: "provinces.NP.BH", defaultMessage: "Bheri" },
        DH: { id: "provinces.NP.DH", defaultMessage: "Dhawalagiri" },
        GA: { id: "provinces.NP.GA", defaultMessage: "Gandaki" },
        JA: { id: "provinces.NP.JA", defaultMessage: "Janakpur" },
        KA: { id: "provinces.NP.KA", defaultMessage: "Karnali" },
        KO: { id: "provinces.NP.KO", defaultMessage: "Kosi" },
    }),
    NR: defineMessages({
        10: { id: "provinces.NR.10", defaultMessage: "Ijuw" },
        "05": { id: "provinces.NR.05", defaultMessage: "Baiti" },
        11: { id: "provinces.NR.11", defaultMessage: "Meneng" },
        "06": { id: "provinces.NR.06", defaultMessage: "Boe" },
        "01": { id: "provinces.NR.01", defaultMessage: "Aiwo" },
        12: { id: "provinces.NR.12", defaultMessage: "Nibok" },
        "07": { id: "provinces.NR.07", defaultMessage: "Buada" },
        "02": { id: "provinces.NR.02", defaultMessage: "Anabar" },
        13: { id: "provinces.NR.13", defaultMessage: "Uaboe" },
        "08": { id: "provinces.NR.08", defaultMessage: "Denigomodu" },
        "03": { id: "provinces.NR.03", defaultMessage: "Anetan" },
        14: { id: "provinces.NR.14", defaultMessage: "Yaren" },
        "09": { id: "provinces.NR.09", defaultMessage: "Ewa" },
        "04": { id: "provinces.NR.04", defaultMessage: "Anibare" },
    }),
    NZ: defineMessages({
        AUK: { id: "provinces.NZ.AUK", defaultMessage: "Auckland" },
        BOP: { id: "provinces.NZ.BOP", defaultMessage: "Bay of Plenty" },
        CAN: { id: "provinces.NZ.CAN", defaultMessage: "Canterbury" },
        CIT: { id: "provinces.NZ.CIT", defaultMessage: "Chatham Islands Territory" },
        GIS: { id: "provinces.NZ.GIS", defaultMessage: "Gisborne District" },
        HKB: { id: "provinces.NZ.HKB", defaultMessage: "Hawke's Bay" },
        MBH: { id: "provinces.NZ.MBH", defaultMessage: "Marlborough District" },
        MWT: { id: "provinces.NZ.MWT", defaultMessage: "Manawatu-Wanganui" },
        N: { id: "provinces.NZ.N", defaultMessage: "North Island" },
        NSN: { id: "provinces.NZ.NSN", defaultMessage: "Nelson City" },
        NTL: { id: "provinces.NZ.NTL", defaultMessage: "Northland" },
        OTA: { id: "provinces.NZ.OTA", defaultMessage: "Otago" },
        S: { id: "provinces.NZ.S", defaultMessage: "South Island" },
        STL: { id: "provinces.NZ.STL", defaultMessage: "Southland" },
        TAS: { id: "provinces.NZ.TAS", defaultMessage: "Tasman District" },
        TKI: { id: "provinces.NZ.TKI", defaultMessage: "Taranaki" },
        WGN: { id: "provinces.NZ.WGN", defaultMessage: "Wellington" },
        WKO: { id: "provinces.NZ.WKO", defaultMessage: "Waikato" },
        WTC: { id: "provinces.NZ.WTC", defaultMessage: "West Coast" },
    }),
    OM: defineMessages({
        ZA: { id: "provinces.OM.ZA", defaultMessage: "Az̧ Z̧āhirah" },
        DA: { id: "provinces.OM.DA", defaultMessage: "Ad Dākhilīya" },
        ZU: { id: "provinces.OM.ZU", defaultMessage: "Z̧ufār" },
        MA: { id: "provinces.OM.MA", defaultMessage: "Masqaţ" },
        MU: { id: "provinces.OM.MU", defaultMessage: "Musandam" },
        SH: { id: "provinces.OM.SH", defaultMessage: "Ash Sharqīyah" },
        BA: { id: "provinces.OM.BA", defaultMessage: "Al Bāţinah" },
        WU: { id: "provinces.OM.WU", defaultMessage: "Al Wusţá" },
        BU: { id: "provinces.OM.BU", defaultMessage: "Al Buraymī" },
    }),
    PK: defineMessages({
        SD: { id: "provinces.PK.SD", defaultMessage: "Sindh" },
        GB: { id: "provinces.PK.GB", defaultMessage: "Gilgit-Baltistan" },
        TA: { id: "provinces.PK.TA", defaultMessage: "Federally Administered Tribal Areas" },
        IS: { id: "provinces.PK.IS", defaultMessage: "Islamabad" },
        JK: { id: "provinces.PK.JK", defaultMessage: "Azad Kashmir" },
        KP: { id: "provinces.PK.KP", defaultMessage: "Khyber Pakhtunkhwa" },
        PB: { id: "provinces.PK.PB", defaultMessage: "Punjab" },
        BA: { id: "provinces.PK.BA", defaultMessage: "Balochistan" },
    }),
    PA: defineMessages({
        EM: { id: "provinces.PA.EM", defaultMessage: "Emberá" },
        5: { id: "provinces.PA.5", defaultMessage: "Darién" },
        KY: { id: "provinces.PA.KY", defaultMessage: "Kuna Yala" },
        6: { id: "provinces.PA.6", defaultMessage: "Herrera" },
        1: { id: "provinces.PA.1", defaultMessage: "Bocas del Toro" },
        NB: { id: "provinces.PA.NB", defaultMessage: "Ngöbe-Buglé" },
        7: { id: "provinces.PA.7", defaultMessage: "Los Santos" },
        2: { id: "provinces.PA.2", defaultMessage: "Coclé" },
        8: { id: "provinces.PA.8", defaultMessage: "Panamá" },
        3: { id: "provinces.PA.3", defaultMessage: "Colón" },
        9: { id: "provinces.PA.9", defaultMessage: "Veraguas" },
        4: { id: "provinces.PA.4", defaultMessage: "Chiriquí" },
    }),
    PN: defineMessages({}),
    PE: defineMessages({
        TAC: { id: "provinces.PE.TAC", defaultMessage: "Tacna" },
        APU: { id: "provinces.PE.APU", defaultMessage: "Apurímac" },
        TUM: { id: "provinces.PE.TUM", defaultMessage: "Tumbes" },
        ARE: { id: "provinces.PE.ARE", defaultMessage: "Arequipa" },
        UCA: { id: "provinces.PE.UCA", defaultMessage: "Ucayali" },
        AYA: { id: "provinces.PE.AYA", defaultMessage: "Ayacucho" },
        CAJ: { id: "provinces.PE.CAJ", defaultMessage: "Cajamarca" },
        CAL: { id: "provinces.PE.CAL", defaultMessage: "El Callao" },
        CUS: { id: "provinces.PE.CUS", defaultMessage: "Cusco [Cuzco]" },
        HUC: { id: "provinces.PE.HUC", defaultMessage: "Huánuco" },
        HUV: { id: "provinces.PE.HUV", defaultMessage: "Huancavelica" },
        ICA: { id: "provinces.PE.ICA", defaultMessage: "Ica" },
        JUN: { id: "provinces.PE.JUN", defaultMessage: "Junín" },
        LAL: { id: "provinces.PE.LAL", defaultMessage: "La Libertad" },
        LAM: { id: "provinces.PE.LAM", defaultMessage: "Lambayeque" },
        LIM: { id: "provinces.PE.LIM", defaultMessage: "Lima" },
        LMA: { id: "provinces.PE.LMA", defaultMessage: "Municipalidad Metropolitana de Lima" },
        LOR: { id: "provinces.PE.LOR", defaultMessage: "Loreto" },
        MDD: { id: "provinces.PE.MDD", defaultMessage: "Madre de Dios" },
        MOQ: { id: "provinces.PE.MOQ", defaultMessage: "Moquegua" },
        PAS: { id: "provinces.PE.PAS", defaultMessage: "Pasco" },
        PIU: { id: "provinces.PE.PIU", defaultMessage: "Piura" },
        AMA: { id: "provinces.PE.AMA", defaultMessage: "Amazonas" },
        PUN: { id: "provinces.PE.PUN", defaultMessage: "Puno" },
        ANC: { id: "provinces.PE.ANC", defaultMessage: "Ancash" },
        SAM: { id: "provinces.PE.SAM", defaultMessage: "San Martín" },
    }),
    PH: defineMessages({
        CAV: { id: "provinces.PH.CAV", defaultMessage: "Cavite" },
        CEB: { id: "provinces.PH.CEB", defaultMessage: "Cebu" },
        COM: { id: "provinces.PH.COM", defaultMessage: "Compostela Valley" },
        DAO: { id: "provinces.PH.DAO", defaultMessage: "Davao Oriental" },
        DAS: { id: "provinces.PH.DAS", defaultMessage: "Davao del Sur" },
        DAV: { id: "provinces.PH.DAV", defaultMessage: "Davao del Norte" },
        DIN: { id: "provinces.PH.DIN", defaultMessage: "Dinagat Islands" },
        EAS: { id: "provinces.PH.EAS", defaultMessage: "Eastern Samar" },
        GUI: { id: "provinces.PH.GUI", defaultMessage: "Guimaras" },
        IFU: { id: "provinces.PH.IFU", defaultMessage: "Ifugao" },
        ILI: { id: "provinces.PH.ILI", defaultMessage: "Iloilo" },
        ILN: { id: "provinces.PH.ILN", defaultMessage: "Ilocos Norte" },
        ILS: { id: "provinces.PH.ILS", defaultMessage: "Ilocos Sur" },
        ISA: { id: "provinces.PH.ISA", defaultMessage: "Isabela" },
        KAL: { id: "provinces.PH.KAL", defaultMessage: "Kalinga-Apayso" },
        LAG: { id: "provinces.PH.LAG", defaultMessage: "Laguna" },
        LAN: { id: "provinces.PH.LAN", defaultMessage: "Lanao del Norte" },
        LAS: { id: "provinces.PH.LAS", defaultMessage: "Lanao del Sur" },
        LEY: { id: "provinces.PH.LEY", defaultMessage: "Leyte" },
        LUN: { id: "provinces.PH.LUN", defaultMessage: "La Union" },
        MAD: { id: "provinces.PH.MAD", defaultMessage: "Marinduque" },
        MAG: { id: "provinces.PH.MAG", defaultMessage: "Maguindanao" },
        MAS: { id: "provinces.PH.MAS", defaultMessage: "Masbate" },
        MDC: { id: "provinces.PH.MDC", defaultMessage: "Mindoro Occidental" },
        MDR: { id: "provinces.PH.MDR", defaultMessage: "Mindoro Oriental" },
        MOU: { id: "provinces.PH.MOU", defaultMessage: "Mountain Province" },
        MSC: { id: "provinces.PH.MSC", defaultMessage: "Misamis Occidental" },
        MSR: { id: "provinces.PH.MSR", defaultMessage: "Misamis Oriental" },
        NCO: { id: "provinces.PH.NCO", defaultMessage: "North Cotabato" },
        NEC: { id: "provinces.PH.NEC", defaultMessage: "Negros Occidental" },
        NER: { id: "provinces.PH.NER", defaultMessage: "Negros Oriental" },
        NSA: { id: "provinces.PH.NSA", defaultMessage: "Northern Samar" },
        NUE: { id: "provinces.PH.NUE", defaultMessage: "Nueva Ecija" },
        NUV: { id: "provinces.PH.NUV", defaultMessage: "Nueva Vizcaya" },
        PAM: { id: "provinces.PH.PAM", defaultMessage: "Pampanga" },
        PAN: { id: "provinces.PH.PAN", defaultMessage: "Pangasinan" },
        PLW: { id: "provinces.PH.PLW", defaultMessage: "Palawan" },
        QUE: { id: "provinces.PH.QUE", defaultMessage: "Quezon" },
        QUI: { id: "provinces.PH.QUI", defaultMessage: "Quirino" },
        RIZ: { id: "provinces.PH.RIZ", defaultMessage: "Rizal" },
        ROM: { id: "provinces.PH.ROM", defaultMessage: "Romblon" },
        SAR: { id: "provinces.PH.SAR", defaultMessage: "Sarangani" },
        SCO: { id: "provinces.PH.SCO", defaultMessage: "South Cotabato" },
        SIG: { id: "provinces.PH.SIG", defaultMessage: "Siquijor" },
        "00": { id: "provinces.PH.00", defaultMessage: "National Capital Region" },
        SLE: { id: "provinces.PH.SLE", defaultMessage: "Southern Leyte" },
        "01": { id: "provinces.PH.01", defaultMessage: "Ilocos (Region I)" },
        SLU: { id: "provinces.PH.SLU", defaultMessage: "Sulu" },
        "02": { id: "provinces.PH.02", defaultMessage: "Cagayan Valley (Region II)" },
        SOR: { id: "provinces.PH.SOR", defaultMessage: "Sorsogon" },
        "03": { id: "provinces.PH.03", defaultMessage: "Central Luzon (Region III)" },
        SUK: { id: "provinces.PH.SUK", defaultMessage: "Sultan Kudarat" },
        "05": { id: "provinces.PH.05", defaultMessage: "Bicol (Region V)" },
        SUN: { id: "provinces.PH.SUN", defaultMessage: "Surigao del Norte" },
        "06": { id: "provinces.PH.06", defaultMessage: "Western Visayas (Region VI)" },
        SUR: { id: "provinces.PH.SUR", defaultMessage: "Surigao del Sur" },
        "07": { id: "provinces.PH.07", defaultMessage: "Central Visayas (Region VII)" },
        TAR: { id: "provinces.PH.TAR", defaultMessage: "Tarlac" },
        "08": { id: "provinces.PH.08", defaultMessage: "Eastern Visayas (Region VIII)" },
        TAW: { id: "provinces.PH.TAW", defaultMessage: "Tawi-Tawi" },
        "09": { id: "provinces.PH.09", defaultMessage: "Zamboanga Peninsula (Region IX)" },
        WSA: { id: "provinces.PH.WSA", defaultMessage: "Western Samar" },
        10: { id: "provinces.PH.10", defaultMessage: "Northern Mindanao (Region X)" },
        ZAN: { id: "provinces.PH.ZAN", defaultMessage: "Zamboanga del Norte" },
        11: { id: "provinces.PH.11", defaultMessage: "Davao (Region XI)" },
        ZAS: { id: "provinces.PH.ZAS", defaultMessage: "Zamboanga del Sur" },
        12: { id: "provinces.PH.12", defaultMessage: "Soccsksargen (Region XII)" },
        ZMB: { id: "provinces.PH.ZMB", defaultMessage: "Zambales" },
        13: { id: "provinces.PH.13", defaultMessage: "Caraga (Region XIII)" },
        ZSI: { id: "provinces.PH.ZSI", defaultMessage: "Zamboanga Sibugay" },
        14: { id: "provinces.PH.14", defaultMessage: "Autonomous Region in Muslim Mindanao (ARMM)" },
        15: { id: "provinces.PH.15", defaultMessage: "Cordillera Administrative Region (CAR)" },
        40: { id: "provinces.PH.40", defaultMessage: "CALABARZON (Region IV-A)" },
        41: { id: "provinces.PH.41", defaultMessage: "MIMAROPA (Region IV-B)" },
        ABR: { id: "provinces.PH.ABR", defaultMessage: "Abra" },
        AGN: { id: "provinces.PH.AGN", defaultMessage: "Agusan del Norte" },
        AGS: { id: "provinces.PH.AGS", defaultMessage: "Agusan del Sur" },
        AKL: { id: "provinces.PH.AKL", defaultMessage: "Aklan" },
        ALB: { id: "provinces.PH.ALB", defaultMessage: "Albay" },
        ANT: { id: "provinces.PH.ANT", defaultMessage: "Antique" },
        APA: { id: "provinces.PH.APA", defaultMessage: "Apayao" },
        AUR: { id: "provinces.PH.AUR", defaultMessage: "Aurora" },
        BAN: { id: "provinces.PH.BAN", defaultMessage: "Batasn" },
        BAS: { id: "provinces.PH.BAS", defaultMessage: "Basilan" },
        BEN: { id: "provinces.PH.BEN", defaultMessage: "Benguet" },
        BIL: { id: "provinces.PH.BIL", defaultMessage: "Biliran" },
        BOH: { id: "provinces.PH.BOH", defaultMessage: "Bohol" },
        BTG: { id: "provinces.PH.BTG", defaultMessage: "Batangas" },
        BTN: { id: "provinces.PH.BTN", defaultMessage: "Batanes" },
        BUK: { id: "provinces.PH.BUK", defaultMessage: "Bukidnon" },
        BUL: { id: "provinces.PH.BUL", defaultMessage: "Bulacan" },
        CAG: { id: "provinces.PH.CAG", defaultMessage: "Cagayan" },
        CAM: { id: "provinces.PH.CAM", defaultMessage: "Camiguin" },
        CAN: { id: "provinces.PH.CAN", defaultMessage: "Camarines Norte" },
        CAP: { id: "provinces.PH.CAP", defaultMessage: "Capiz" },
        CAS: { id: "provinces.PH.CAS", defaultMessage: "Camarines Sur" },
        CAT: { id: "provinces.PH.CAT", defaultMessage: "Catanduanes" },
    }),
    PW: defineMessages({
        100: { id: "provinces.PW.100", defaultMessage: "Kayangel" },
        350: { id: "provinces.PW.350", defaultMessage: "Peleliu" },
        222: { id: "provinces.PW.222", defaultMessage: "Ngardmau" },
        228: { id: "provinces.PW.228", defaultMessage: "Ngiwal" },
        150: { id: "provinces.PW.150", defaultMessage: "Koror" },
        370: { id: "provinces.PW.370", defaultMessage: "Sonsorol" },
        "002": { id: "provinces.PW.002", defaultMessage: "Aimeliik" },
        224: { id: "provinces.PW.224", defaultMessage: "Ngatpang" },
        212: { id: "provinces.PW.212", defaultMessage: "Melekeok" },
        "004": { id: "provinces.PW.004", defaultMessage: "Airai" },
        226: { id: "provinces.PW.226", defaultMessage: "Ngchesar" },
        214: { id: "provinces.PW.214", defaultMessage: "Ngaraard" },
        "010": { id: "provinces.PW.010", defaultMessage: "Angaur" },
        227: { id: "provinces.PW.227", defaultMessage: "Ngeremlengui" },
        218: { id: "provinces.PW.218", defaultMessage: "Ngarchelong" },
        "050": { id: "provinces.PW.050", defaultMessage: "Hatobohei" },
    }),
    PG: defineMessages({
        WHM: { id: "provinces.PG.WHM", defaultMessage: "Western Highlands" },
        WPD: { id: "provinces.PG.WPD", defaultMessage: "Western" },
        CPK: { id: "provinces.PG.CPK", defaultMessage: "Chimbu" },
        CPM: { id: "provinces.PG.CPM", defaultMessage: "Central" },
        EBR: { id: "provinces.PG.EBR", defaultMessage: "East New Britain" },
        EHG: { id: "provinces.PG.EHG", defaultMessage: "Eastern Highlands" },
        EPW: { id: "provinces.PG.EPW", defaultMessage: "Enga" },
        ESW: { id: "provinces.PG.ESW", defaultMessage: "East Sepik" },
        GPK: { id: "provinces.PG.GPK", defaultMessage: "Gulf" },
        MBA: { id: "provinces.PG.MBA", defaultMessage: "Milne Bay" },
        MPL: { id: "provinces.PG.MPL", defaultMessage: "Morobe" },
        MPM: { id: "provinces.PG.MPM", defaultMessage: "Madang" },
        MRL: { id: "provinces.PG.MRL", defaultMessage: "Manus" },
        NCD: { id: "provinces.PG.NCD", defaultMessage: "National Capital District (Port Moresby)" },
        NIK: { id: "provinces.PG.NIK", defaultMessage: "New Ireland" },
        NPP: { id: "provinces.PG.NPP", defaultMessage: "Northern" },
        NSB: { id: "provinces.PG.NSB", defaultMessage: "Bougainville" },
        SAN: { id: "provinces.PG.SAN", defaultMessage: "Sandaun" },
        SHM: { id: "provinces.PG.SHM", defaultMessage: "Southern Highlands" },
        WBK: { id: "provinces.PG.WBK", defaultMessage: "West New Britain" },
    }),
    PL: defineMessages({
        LD: { id: "provinces.PL.LD", defaultMessage: "Łódzkie" },
        WP: { id: "provinces.PL.WP", defaultMessage: "Wielkopolskie" },
        PK: { id: "provinces.PL.PK", defaultMessage: "Podkarpackie" },
        LU: { id: "provinces.PL.LU", defaultMessage: "Lubelskie" },
        ZP: { id: "provinces.PL.ZP", defaultMessage: "Zachodniopomorskie" },
        PM: { id: "provinces.PL.PM", defaultMessage: "Pomorskie" },
        MA: { id: "provinces.PL.MA", defaultMessage: "Małopolskie" },
        DS: { id: "provinces.PL.DS", defaultMessage: "Dolnośląskie" },
        SK: { id: "provinces.PL.SK", defaultMessage: "Świętokrzyskie" },
        MZ: { id: "provinces.PL.MZ", defaultMessage: "Mazowieckie" },
        KP: { id: "provinces.PL.KP", defaultMessage: "Kujawsko-pomorskie" },
        SL: { id: "provinces.PL.SL", defaultMessage: "Śląskie" },
        OP: { id: "provinces.PL.OP", defaultMessage: "Opolskie" },
        LB: { id: "provinces.PL.LB", defaultMessage: "Lubuskie" },
        WN: { id: "provinces.PL.WN", defaultMessage: "Warmińsko-mazurskie" },
        PD: { id: "provinces.PL.PD", defaultMessage: "Podlaskie" },
    }),
    PR: defineMessages({}),
    KP: defineMessages({
        "02": { id: "provinces.KP.02", defaultMessage: "P’yŏngan-namdo" },
        "08": { id: "provinces.KP.08", defaultMessage: "Hamgyŏng-namdo" },
        "03": { id: "provinces.KP.03", defaultMessage: "P’yŏngan-bukto" },
        "09": { id: "provinces.KP.09", defaultMessage: "Hamgyŏng-bukto" },
        "04": { id: "provinces.KP.04", defaultMessage: "Chagang-do" },
        10: { id: "provinces.KP.10", defaultMessage: "Yanggang-do" },
        "05": { id: "provinces.KP.05", defaultMessage: "Hwanghae-namdo" },
        13: { id: "provinces.KP.13", defaultMessage: "Nasŏn (Najin-Sŏnbong)" },
        "06": { id: "provinces.KP.06", defaultMessage: "Hwanghae-bukto" },
        "01": { id: "provinces.KP.01", defaultMessage: "P’yŏngyang" },
        "07": { id: "provinces.KP.07", defaultMessage: "Kangwŏn-do" },
    }),
    PT: defineMessages({
        "09": { id: "provinces.PT.09", defaultMessage: "Guarda" },
        10: { id: "provinces.PT.10", defaultMessage: "Leiria" },
        11: { id: "provinces.PT.11", defaultMessage: "Lisboa" },
        12: { id: "provinces.PT.12", defaultMessage: "Portalegre" },
        13: { id: "provinces.PT.13", defaultMessage: "Porto" },
        14: { id: "provinces.PT.14", defaultMessage: "Santarém" },
        15: { id: "provinces.PT.15", defaultMessage: "Setúbal" },
        16: { id: "provinces.PT.16", defaultMessage: "Viana do Castelo" },
        17: { id: "provinces.PT.17", defaultMessage: "Vila Real" },
        18: { id: "provinces.PT.18", defaultMessage: "Viseu" },
        20: { id: "provinces.PT.20", defaultMessage: "Região Autónoma dos Açores" },
        30: { id: "provinces.PT.30", defaultMessage: "Região Autónoma da Madeira" },
        "01": { id: "provinces.PT.01", defaultMessage: "Aveiro" },
        "02": { id: "provinces.PT.02", defaultMessage: "Beja" },
        "03": { id: "provinces.PT.03", defaultMessage: "Braga" },
        "04": { id: "provinces.PT.04", defaultMessage: "Bragança" },
        "05": { id: "provinces.PT.05", defaultMessage: "Castelo Branco" },
        "06": { id: "provinces.PT.06", defaultMessage: "Coimbra" },
        "07": { id: "provinces.PT.07", defaultMessage: "Évora" },
        "08": { id: "provinces.PT.08", defaultMessage: "Faro" },
    }),
    PY: defineMessages({
        12: { id: "provinces.PY.12", defaultMessage: "Ñeembucú" },
        7: { id: "provinces.PY.7", defaultMessage: "Itapúa" },
        2: { id: "provinces.PY.2", defaultMessage: "San Pedro" },
        13: { id: "provinces.PY.13", defaultMessage: "Amambay" },
        8: { id: "provinces.PY.8", defaultMessage: "Misiones" },
        3: { id: "provinces.PY.3", defaultMessage: "Cordillera" },
        14: { id: "provinces.PY.14", defaultMessage: "Canindeyú" },
        1: { id: "provinces.PY.1", defaultMessage: "Concepción" },
        9: { id: "provinces.PY.9", defaultMessage: "Paraguarí" },
        4: { id: "provinces.PY.4", defaultMessage: "Guairá" },
        15: { id: "provinces.PY.15", defaultMessage: "Presidente Hayes" },
        10: { id: "provinces.PY.10", defaultMessage: "Alto Paraná" },
        ASU: { id: "provinces.PY.ASU", defaultMessage: "Asunción" },
        5: { id: "provinces.PY.5", defaultMessage: "Caaguazú" },
        16: { id: "provinces.PY.16", defaultMessage: "Alto Paraguay" },
        11: { id: "provinces.PY.11", defaultMessage: "Central" },
        6: { id: "provinces.PY.6", defaultMessage: "Caazapá" },
        19: { id: "provinces.PY.19", defaultMessage: "Boquerón" },
    }),
    PS: defineMessages({
        SLT: { id: "provinces.PS.SLT", defaultMessage: "Salfit" },
        NBS: { id: "provinces.PS.NBS", defaultMessage: "Nablus" },
        HBN: { id: "provinces.PS.HBN", defaultMessage: "Hebron" },
        TBS: { id: "provinces.PS.TBS", defaultMessage: "Tubas" },
        NGZ: { id: "provinces.PS.NGZ", defaultMessage: "North Gaza" },
        JEM: { id: "provinces.PS.JEM", defaultMessage: "Jerusalem" },
        TKM: { id: "provinces.PS.TKM", defaultMessage: "Tulkarm" },
        BTH: { id: "provinces.PS.BTH", defaultMessage: "Bethlehem" },
        QQA: { id: "provinces.PS.QQA", defaultMessage: "Qalqilya" },
        JEN: { id: "provinces.PS.JEN", defaultMessage: "Jenin" },
        DEB: { id: "provinces.PS.DEB", defaultMessage: "Deir El Balah" },
        RBH: { id: "provinces.PS.RBH", defaultMessage: "Ramallah" },
        JRH: { id: "provinces.PS.JRH", defaultMessage: "Jericho - Al Aghwar" },
        RFH: { id: "provinces.PS.RFH", defaultMessage: "Rafah" },
        GZA: { id: "provinces.PS.GZA", defaultMessage: "Gaza" },
        KYS: { id: "provinces.PS.KYS", defaultMessage: "Khan Yunis" },
    }),
    PF: defineMessages({}),
    QA: defineMessages({
        KH: { id: "provinces.QA.KH", defaultMessage: "Al Khawr wa adh Dhakhīrah" },
        MS: { id: "provinces.QA.MS", defaultMessage: "Ash Shamal" },
        RA: { id: "provinces.QA.RA", defaultMessage: "Ar Rayyan" },
        US: { id: "provinces.QA.US", defaultMessage: "Umm Salal" },
        WA: { id: "provinces.QA.WA", defaultMessage: "Al Wakrah" },
        DA: { id: "provinces.QA.DA", defaultMessage: "Ad Dawhah" },
        ZA: { id: "provinces.QA.ZA", defaultMessage: "Az̧ Z̧a‘āyin" },
    }),
    RE: defineMessages({}),
    RO: defineMessages({
        PH: { id: "provinces.RO.PH", defaultMessage: "Prahova" },
        BZ: { id: "provinces.RO.BZ", defaultMessage: "Buzău" },
        SB: { id: "provinces.RO.SB", defaultMessage: "Sibiu" },
        CJ: { id: "provinces.RO.CJ", defaultMessage: "Cluj" },
        SJ: { id: "provinces.RO.SJ", defaultMessage: "Sălaj" },
        CL: { id: "provinces.RO.CL", defaultMessage: "Călărași" },
        SM: { id: "provinces.RO.SM", defaultMessage: "Satu Mare" },
        CS: { id: "provinces.RO.CS", defaultMessage: "Caraș-Severin" },
        SV: { id: "provinces.RO.SV", defaultMessage: "Suceava" },
        CT: { id: "provinces.RO.CT", defaultMessage: "Constanța" },
        TL: { id: "provinces.RO.TL", defaultMessage: "Tulcea" },
        CV: { id: "provinces.RO.CV", defaultMessage: "Covasna" },
        TM: { id: "provinces.RO.TM", defaultMessage: "Timiș" },
        DB: { id: "provinces.RO.DB", defaultMessage: "Dâmbovița" },
        TR: { id: "provinces.RO.TR", defaultMessage: "Teleorman" },
        DJ: { id: "provinces.RO.DJ", defaultMessage: "Dolj" },
        VL: { id: "provinces.RO.VL", defaultMessage: "Vâlcea" },
        GJ: { id: "provinces.RO.GJ", defaultMessage: "Gorj" },
        VN: { id: "provinces.RO.VN", defaultMessage: "Vrancea" },
        GL: { id: "provinces.RO.GL", defaultMessage: "Galați" },
        VS: { id: "provinces.RO.VS", defaultMessage: "Vaslui" },
        GR: { id: "provinces.RO.GR", defaultMessage: "Giurgiu" },
        AB: { id: "provinces.RO.AB", defaultMessage: "Alba" },
        HD: { id: "provinces.RO.HD", defaultMessage: "Hunedoara" },
        AG: { id: "provinces.RO.AG", defaultMessage: "Argeș" },
        HR: { id: "provinces.RO.HR", defaultMessage: "Harghita" },
        AR: { id: "provinces.RO.AR", defaultMessage: "Arad" },
        IF: { id: "provinces.RO.IF", defaultMessage: "Ilfov" },
        B: { id: "provinces.RO.B", defaultMessage: "București" },
        IL: { id: "provinces.RO.IL", defaultMessage: "Ialomița" },
        BC: { id: "provinces.RO.BC", defaultMessage: "Bacău" },
        IS: { id: "provinces.RO.IS", defaultMessage: "Iași" },
        BH: { id: "provinces.RO.BH", defaultMessage: "Bihor" },
        MH: { id: "provinces.RO.MH", defaultMessage: "Mehedinți" },
        BN: { id: "provinces.RO.BN", defaultMessage: "Bistrița-Năsăud" },
        MM: { id: "provinces.RO.MM", defaultMessage: "Maramureș" },
        BR: { id: "provinces.RO.BR", defaultMessage: "Brăila" },
        MS: { id: "provinces.RO.MS", defaultMessage: "Mureș" },
        BT: { id: "provinces.RO.BT", defaultMessage: "Botoșani" },
        NT: { id: "provinces.RO.NT", defaultMessage: "Neamț" },
        BV: { id: "provinces.RO.BV", defaultMessage: "Brașov" },
        OT: { id: "provinces.RO.OT", defaultMessage: "Olt" },
    }),
    RU: defineMessages({
        KC: { id: "provinces.RU.KC", defaultMessage: "Karachayevo-Cherkesskaya Respublika" },
        KDA: { id: "provinces.RU.KDA", defaultMessage: "Krasnodarskiy kray" },
        KEM: { id: "provinces.RU.KEM", defaultMessage: "Kemerovskaya oblast'" },
        KGD: { id: "provinces.RU.KGD", defaultMessage: "Kaliningradskaya oblast'" },
        KGN: { id: "provinces.RU.KGN", defaultMessage: "Kurganskaya oblast'" },
        KHA: { id: "provinces.RU.KHA", defaultMessage: "Khabarovskiy kray" },
        KHM: { id: "provinces.RU.KHM", defaultMessage: "Khanty-Mansiysky avtonomnyy okrug-Yugra" },
        KIR: { id: "provinces.RU.KIR", defaultMessage: "Kirovskaya oblast'" },
        KK: { id: "provinces.RU.KK", defaultMessage: "Khakasiya, Respublika" },
        KL: { id: "provinces.RU.KL", defaultMessage: "Kalmykiya, Respublika" },
        KLU: { id: "provinces.RU.KLU", defaultMessage: "Kaluzhskaya oblast'" },
        KO: { id: "provinces.RU.KO", defaultMessage: "Komi, Respublika" },
        KOS: { id: "provinces.RU.KOS", defaultMessage: "Kostromskaya oblast'" },
        KR: { id: "provinces.RU.KR", defaultMessage: "Kareliya, Respublika" },
        KRS: { id: "provinces.RU.KRS", defaultMessage: "Kurskaya oblast'" },
        KYA: { id: "provinces.RU.KYA", defaultMessage: "Krasnoyarskiy kray" },
        LEN: { id: "provinces.RU.LEN", defaultMessage: "Leningradskaya oblast'" },
        LIP: { id: "provinces.RU.LIP", defaultMessage: "Lipetskaya oblast'" },
        MAG: { id: "provinces.RU.MAG", defaultMessage: "Magadanskaya oblast'" },
        ME: { id: "provinces.RU.ME", defaultMessage: "Mariy El, Respublika" },
        MO: { id: "provinces.RU.MO", defaultMessage: "Mordoviya, Respublika" },
        MOS: { id: "provinces.RU.MOS", defaultMessage: "Moskovskaya oblast'" },
        AD: { id: "provinces.RU.AD", defaultMessage: "Adygeya, Respublika" },
        MOW: { id: "provinces.RU.MOW", defaultMessage: "Moskva" },
        AL: { id: "provinces.RU.AL", defaultMessage: "Altay, Respublika" },
        MUR: { id: "provinces.RU.MUR", defaultMessage: "Murmanskaya oblast'" },
        ALT: { id: "provinces.RU.ALT", defaultMessage: "Altayskiy kray" },
        NEN: { id: "provinces.RU.NEN", defaultMessage: "Nenetskiy avtonomnyy okrug" },
        AMU: { id: "provinces.RU.AMU", defaultMessage: "Amurskaya oblast'" },
        NGR: { id: "provinces.RU.NGR", defaultMessage: "Novgorodskaya oblast'" },
        ARK: { id: "provinces.RU.ARK", defaultMessage: "Arkhangel'skaya oblast'" },
        NIZ: { id: "provinces.RU.NIZ", defaultMessage: "Nizhegorodskaya oblast'" },
        AST: { id: "provinces.RU.AST", defaultMessage: "Astrakhanskaya oblast'" },
        NVS: { id: "provinces.RU.NVS", defaultMessage: "Novosibirskaya oblast'" },
        BA: { id: "provinces.RU.BA", defaultMessage: "Bashkortostan, Respublika" },
        OMS: { id: "provinces.RU.OMS", defaultMessage: "Omskaya oblast'" },
        BEL: { id: "provinces.RU.BEL", defaultMessage: "Belgorodskaya oblast'" },
        ORE: { id: "provinces.RU.ORE", defaultMessage: "Orenburgskaya oblast'" },
        BRY: { id: "provinces.RU.BRY", defaultMessage: "Bryanskaya oblast'" },
        ORL: { id: "provinces.RU.ORL", defaultMessage: "Orlovskaya oblast'" },
        BU: { id: "provinces.RU.BU", defaultMessage: "Buryatiya, Respublika" },
        PER: { id: "provinces.RU.PER", defaultMessage: "Permskiy kray" },
        CE: { id: "provinces.RU.CE", defaultMessage: "Chechenskaya Respublika" },
        PNZ: { id: "provinces.RU.PNZ", defaultMessage: "Penzenskaya oblast'" },
        CHE: { id: "provinces.RU.CHE", defaultMessage: "Chelyabinskaya oblast'" },
        PRI: { id: "provinces.RU.PRI", defaultMessage: "Primorskiy kray" },
        CHU: { id: "provinces.RU.CHU", defaultMessage: "Chukotskiy avtonomnyy okrug" },
        PSK: { id: "provinces.RU.PSK", defaultMessage: "Pskovskaya oblast'" },
        CU: { id: "provinces.RU.CU", defaultMessage: "Chuvashskaya Respublika" },
        ROS: { id: "provinces.RU.ROS", defaultMessage: "Rostovskaya oblast'" },
        DA: { id: "provinces.RU.DA", defaultMessage: "Dagestan, Respublika" },
        RYA: { id: "provinces.RU.RYA", defaultMessage: "Ryazanskaya oblast'" },
        IN: { id: "provinces.RU.IN", defaultMessage: "Respublika Ingushetiya" },
        SA: { id: "provinces.RU.SA", defaultMessage: "Sakha, Respublika [Yakutiya]" },
        IRK: { id: "provinces.RU.IRK", defaultMessage: "Irkutiskaya oblast'" },
        SAK: { id: "provinces.RU.SAK", defaultMessage: "Sakhalinskaya oblast'" },
        IVA: { id: "provinces.RU.IVA", defaultMessage: "Ivanovskaya oblast'" },
        SAM: { id: "provinces.RU.SAM", defaultMessage: "Samaraskaya oblast'" },
        KAM: { id: "provinces.RU.KAM", defaultMessage: "Kamchatskiy kray" },
        SAR: { id: "provinces.RU.SAR", defaultMessage: "Saratovskaya oblast'" },
        SE: { id: "provinces.RU.SE", defaultMessage: "Severnaya Osetiya-Alaniya, Respublika" },
        KB: { id: "provinces.RU.KB", defaultMessage: "Kabardino-Balkarskaya Respublika" },
        SMO: { id: "provinces.RU.SMO", defaultMessage: "Smolenskaya oblast'" },
        SPE: { id: "provinces.RU.SPE", defaultMessage: "Sankt-Peterburg" },
        STA: { id: "provinces.RU.STA", defaultMessage: "Stavropol'skiy kray" },
        SVE: { id: "provinces.RU.SVE", defaultMessage: "Sverdlovskaya oblast'" },
        TA: { id: "provinces.RU.TA", defaultMessage: "Tatarstan, Respublika" },
        TAM: { id: "provinces.RU.TAM", defaultMessage: "Tambovskaya oblast'" },
        TOM: { id: "provinces.RU.TOM", defaultMessage: "Tomskaya oblast'" },
        TUL: { id: "provinces.RU.TUL", defaultMessage: "Tul'skaya oblast'" },
        TVE: { id: "provinces.RU.TVE", defaultMessage: "Tverskaya oblast'" },
        TY: { id: "provinces.RU.TY", defaultMessage: "Tyva, Respublika [Tuva]" },
        TYU: { id: "provinces.RU.TYU", defaultMessage: "Tyumenskaya oblast'" },
        UD: { id: "provinces.RU.UD", defaultMessage: "Udmurtskaya Respublika" },
        ULY: { id: "provinces.RU.ULY", defaultMessage: "Ul'yanovskaya oblast'" },
        VGG: { id: "provinces.RU.VGG", defaultMessage: "Volgogradskaya oblast'" },
        VLA: { id: "provinces.RU.VLA", defaultMessage: "Vladimirskaya oblast'" },
        VLG: { id: "provinces.RU.VLG", defaultMessage: "Vologodskaya oblast'" },
        VOR: { id: "provinces.RU.VOR", defaultMessage: "Voronezhskaya oblast'" },
        YAN: { id: "provinces.RU.YAN", defaultMessage: "Yamalo-Nenetskiy avtonomnyy okrug" },
        YAR: { id: "provinces.RU.YAR", defaultMessage: "Yaroslavskaya oblast'" },
        YEV: { id: "provinces.RU.YEV", defaultMessage: "Yevreyskaya avtonomnaya oblast'" },
        ZAB: { id: "provinces.RU.ZAB", defaultMessage: "Zabajkal'skij kraj" },
    }),
    RW: defineMessages({
        "01": { id: "provinces.RW.01", defaultMessage: "Ville de Kigali" },
        "02": { id: "provinces.RW.02", defaultMessage: "Est" },
        "03": { id: "provinces.RW.03", defaultMessage: "Nord" },
        "04": { id: "provinces.RW.04", defaultMessage: "Ouest" },
        "05": { id: "provinces.RW.05", defaultMessage: "Sud" },
    }),
    SA: defineMessages({
        "01": { id: "provinces.SA.01", defaultMessage: "Ar Riyāḍ" },
        12: { id: "provinces.SA.12", defaultMessage: "Al Jawf" },
        "07": { id: "provinces.SA.07", defaultMessage: "Tabūk" },
        "02": { id: "provinces.SA.02", defaultMessage: "Makkah" },
        14: { id: "provinces.SA.14", defaultMessage: "`Asīr" },
        "08": { id: "provinces.SA.08", defaultMessage: "Al Ḥudūd ash Shamāliyah" },
        "03": { id: "provinces.SA.03", defaultMessage: "Al Madīnah" },
        "09": { id: "provinces.SA.09", defaultMessage: "Jīzan" },
        "04": { id: "provinces.SA.04", defaultMessage: "Ash Sharqīyah" },
        10: { id: "provinces.SA.10", defaultMessage: "Najrān" },
        "05": { id: "provinces.SA.05", defaultMessage: "Al Qaşīm" },
        11: { id: "provinces.SA.11", defaultMessage: "Al Bāhah" },
        "06": { id: "provinces.SA.06", defaultMessage: "Ḥā'il" },
    }),
    SD: defineMessages({
        GD: { id: "provinces.SD.GD", defaultMessage: "Al Qaḑārif" },
        RS: { id: "provinces.SD.RS", defaultMessage: "Al Baḩr al Aḩmar" },
        DC: { id: "provinces.SD.DC", defaultMessage: "Zalingei" },
        KS: { id: "provinces.SD.KS", defaultMessage: "Janūb Kurdufān" },
        GZ: { id: "provinces.SD.GZ", defaultMessage: "Al Jazīrah" },
        SI: { id: "provinces.SD.SI", defaultMessage: "Sinnār" },
        DE: { id: "provinces.SD.DE", defaultMessage: "Sharq Dārfūr" },
        NB: { id: "provinces.SD.NB", defaultMessage: "An Nīl al Azraq" },
        NW: { id: "provinces.SD.NW", defaultMessage: "An Nīl al Abyaḑ" },
        KA: { id: "provinces.SD.KA", defaultMessage: "Kassalā" },
        DN: { id: "provinces.SD.DN", defaultMessage: "Shamāl Dārfūr" },
        NO: { id: "provinces.SD.NO", defaultMessage: "Ash Shamālīyah" },
        KH: { id: "provinces.SD.KH", defaultMessage: "Al Kharţūm" },
        DS: { id: "provinces.SD.DS", defaultMessage: "Janūb Dārfūr" },
        NR: { id: "provinces.SD.NR", defaultMessage: "An Nīl" },
        KN: { id: "provinces.SD.KN", defaultMessage: "Shamāl Kurdufān" },
        DW: { id: "provinces.SD.DW", defaultMessage: "Gharb Dārfūr" },
    }),
    SN: defineMessages({
        TC: { id: "provinces.SN.TC", defaultMessage: "Tambacounda" },
        KL: { id: "provinces.SN.KL", defaultMessage: "Kaolack" },
        DK: { id: "provinces.SN.DK", defaultMessage: "Dakar" },
        TH: { id: "provinces.SN.TH", defaultMessage: "Thiès" },
        LG: { id: "provinces.SN.LG", defaultMessage: "Louga" },
        FK: { id: "provinces.SN.FK", defaultMessage: "Fatick" },
        ZG: { id: "provinces.SN.ZG", defaultMessage: "Ziguinchor" },
        MT: { id: "provinces.SN.MT", defaultMessage: "Matam" },
        KA: { id: "provinces.SN.KA", defaultMessage: "Kaffrine" },
        SE: { id: "provinces.SN.SE", defaultMessage: "Sédhiou" },
        KD: { id: "provinces.SN.KD", defaultMessage: "Kolda" },
        SL: { id: "provinces.SN.SL", defaultMessage: "Saint-Louis" },
        KE: { id: "provinces.SN.KE", defaultMessage: "Kédougou" },
        DB: { id: "provinces.SN.DB", defaultMessage: "Diourbel" },
    }),
    SG: defineMessages({
        "05": { id: "provinces.SG.05", defaultMessage: "South West" },
        "01": { id: "provinces.SG.01", defaultMessage: "Central Singapore" },
        "02": { id: "provinces.SG.02", defaultMessage: "North East" },
        "03": { id: "provinces.SG.03", defaultMessage: "North West" },
        "04": { id: "provinces.SG.04", defaultMessage: "South East" },
    }),
    GS: defineMessages({}),
    SH: defineMessages({
        HL: { id: "provinces.SH.HL", defaultMessage: "Saint Helena" },
        AC: { id: "provinces.SH.AC", defaultMessage: "Ascension" },
        TA: { id: "provinces.SH.TA", defaultMessage: "Tristan da Cunha" },
    }),
    SJ: defineMessages({}),
    SB: defineMessages({
        TE: { id: "provinces.SB.TE", defaultMessage: "Temotu" },
        GU: { id: "provinces.SB.GU", defaultMessage: "Guadalcanal" },
        WE: { id: "provinces.SB.WE", defaultMessage: "Western" },
        IS: { id: "provinces.SB.IS", defaultMessage: "Isabel" },
        CE: { id: "provinces.SB.CE", defaultMessage: "Central" },
        MK: { id: "provinces.SB.MK", defaultMessage: "Makira" },
        CH: { id: "provinces.SB.CH", defaultMessage: "Choiseul" },
        ML: { id: "provinces.SB.ML", defaultMessage: "Malaita" },
        CT: { id: "provinces.SB.CT", defaultMessage: "Capital Territory (Honiara)" },
        RB: { id: "provinces.SB.RB", defaultMessage: "Rennell and Bellona" },
    }),
    SL: defineMessages({
        E: { id: "provinces.SL.E", defaultMessage: "Eastern" },
        W: { id: "provinces.SL.W", defaultMessage: "Western Area (Freetown)" },
        S: { id: "provinces.SL.S", defaultMessage: "Southern (Sierra Leone)" },
        N: { id: "provinces.SL.N", defaultMessage: "Northern" },
    }),
    SV: defineMessages({
        SS: { id: "provinces.SV.SS", defaultMessage: "San Salvador" },
        MO: { id: "provinces.SV.MO", defaultMessage: "Morazán" },
        AH: { id: "provinces.SV.AH", defaultMessage: "Ahuachapán" },
        SV: { id: "provinces.SV.SV", defaultMessage: "San Vicente" },
        PA: { id: "provinces.SV.PA", defaultMessage: "La Paz" },
        CA: { id: "provinces.SV.CA", defaultMessage: "Cabañas" },
        UN: { id: "provinces.SV.UN", defaultMessage: "La Unión" },
        SA: { id: "provinces.SV.SA", defaultMessage: "Santa Ana" },
        CH: { id: "provinces.SV.CH", defaultMessage: "Chalatenango" },
        US: { id: "provinces.SV.US", defaultMessage: "Usulután" },
        SM: { id: "provinces.SV.SM", defaultMessage: "San Miguel" },
        CU: { id: "provinces.SV.CU", defaultMessage: "Cuscatlán" },
        SO: { id: "provinces.SV.SO", defaultMessage: "Sonsonate" },
        LI: { id: "provinces.SV.LI", defaultMessage: "La Libertad" },
    }),
    SM: defineMessages({
        "05": { id: "provinces.SM.05", defaultMessage: "Fiorentino" },
        "06": { id: "provinces.SM.06", defaultMessage: "Borgo Maggiore" },
        "01": { id: "provinces.SM.01", defaultMessage: "Acquaviva" },
        "07": { id: "provinces.SM.07", defaultMessage: "San Marino" },
        "02": { id: "provinces.SM.02", defaultMessage: "Chiesanuova" },
        "08": { id: "provinces.SM.08", defaultMessage: "Montegiardino" },
        "03": { id: "provinces.SM.03", defaultMessage: "Domagnano" },
        "09": { id: "provinces.SM.09", defaultMessage: "Serravalle" },
        "04": { id: "provinces.SM.04", defaultMessage: "Faetano" },
    }),
    SO: defineMessages({
        JD: { id: "provinces.SO.JD", defaultMessage: "Jubbada Dhexe" },
        BR: { id: "provinces.SO.BR", defaultMessage: "Bari" },
        SD: { id: "provinces.SO.SD", defaultMessage: "Shabeellaha Dhexe" },
        JH: { id: "provinces.SO.JH", defaultMessage: "Jubbada Hoose" },
        BY: { id: "provinces.SO.BY", defaultMessage: "Bay" },
        SH: { id: "provinces.SO.SH", defaultMessage: "Shabeellaha Hoose" },
        MU: { id: "provinces.SO.MU", defaultMessage: "Mudug" },
        GA: { id: "provinces.SO.GA", defaultMessage: "Galguduud" },
        SO: { id: "provinces.SO.SO", defaultMessage: "Sool" },
        AW: { id: "provinces.SO.AW", defaultMessage: "Awdal" },
        NU: { id: "provinces.SO.NU", defaultMessage: "Nugaal" },
        GE: { id: "provinces.SO.GE", defaultMessage: "Gedo" },
        TO: { id: "provinces.SO.TO", defaultMessage: "Togdheer" },
        BK: { id: "provinces.SO.BK", defaultMessage: "Bakool" },
        SA: { id: "provinces.SO.SA", defaultMessage: "Saneag" },
        HI: { id: "provinces.SO.HI", defaultMessage: "Hiirsan" },
        WO: { id: "provinces.SO.WO", defaultMessage: "Woqooyi Galbeed" },
        BN: { id: "provinces.SO.BN", defaultMessage: "Banaadir" },
    }),
    PM: defineMessages({}),
    RS: defineMessages({
        VO: { id: "provinces.RS.VO", defaultMessage: "Vojvodina" },
        10: { id: "provinces.RS.10", defaultMessage: "Podunavski okrug" },
        11: { id: "provinces.RS.11", defaultMessage: "Braničevski okrug" },
        12: { id: "provinces.RS.12", defaultMessage: "Šumadijski okrug" },
        13: { id: "provinces.RS.13", defaultMessage: "Pomoravski okrug" },
        14: { id: "provinces.RS.14", defaultMessage: "Borski okrug" },
        15: { id: "provinces.RS.15", defaultMessage: "Zaječarski okrug" },
        16: { id: "provinces.RS.16", defaultMessage: "Zlatiborski okrug" },
        17: { id: "provinces.RS.17", defaultMessage: "Moravički okrug" },
        18: { id: "provinces.RS.18", defaultMessage: "Raški okrug" },
        19: { id: "provinces.RS.19", defaultMessage: "Rasinski okrug" },
        20: { id: "provinces.RS.20", defaultMessage: "Nišavski okrug" },
        "00": { id: "provinces.RS.00", defaultMessage: "Beograd" },
        21: { id: "provinces.RS.21", defaultMessage: "Toplički okrug" },
        "01": { id: "provinces.RS.01", defaultMessage: "Severnobački okrug" },
        22: { id: "provinces.RS.22", defaultMessage: "Pirotski okrug" },
        "02": { id: "provinces.RS.02", defaultMessage: "Srednjebanatski okrug" },
        23: { id: "provinces.RS.23", defaultMessage: "Jablanički okrug" },
        "03": { id: "provinces.RS.03", defaultMessage: "Severnobanatski okrug" },
        24: { id: "provinces.RS.24", defaultMessage: "Pčinjski okrug" },
        "04": { id: "provinces.RS.04", defaultMessage: "Južnobanatski okrug" },
        25: { id: "provinces.RS.25", defaultMessage: "Kosovski okrug" },
        "05": { id: "provinces.RS.05", defaultMessage: "Zapadnobački okrug" },
        26: { id: "provinces.RS.26", defaultMessage: "Pećki okrug" },
        "06": { id: "provinces.RS.06", defaultMessage: "Južnobački okrug" },
        27: { id: "provinces.RS.27", defaultMessage: "Prizrenski okrug" },
        "07": { id: "provinces.RS.07", defaultMessage: "Sremski okrug" },
        28: { id: "provinces.RS.28", defaultMessage: "Kosovsko-Mitrovački okrug" },
        "08": { id: "provinces.RS.08", defaultMessage: "Mačvanski okrug" },
        29: { id: "provinces.RS.29", defaultMessage: "Kosovsko-Pomoravski okrug" },
        "09": { id: "provinces.RS.09", defaultMessage: "Kolubarski okrug" },
        KM: { id: "provinces.RS.KM", defaultMessage: "Kosovo-Metohija" },
    }),
    SS: defineMessages({
        LK: { id: "provinces.SS.LK", defaultMessage: "Lakes" },
        BW: { id: "provinces.SS.BW", defaultMessage: "Western Bahr el Ghazal" },
        NU: { id: "provinces.SS.NU", defaultMessage: "Upper Nile" },
        EC: { id: "provinces.SS.EC", defaultMessage: "Central Equatoria" },
        UY: { id: "provinces.SS.UY", defaultMessage: "Unity" },
        EE: { id: "provinces.SS.EE", defaultMessage: "Eastern Equatoria" },
        WR: { id: "provinces.SS.WR", defaultMessage: "Warrap" },
        EW: { id: "provinces.SS.EW", defaultMessage: "Western Equatoria" },
        JG: { id: "provinces.SS.JG", defaultMessage: "Jonglei" },
        BN: { id: "provinces.SS.BN", defaultMessage: "Northern Bahr el Ghazal" },
    }),
    ST: defineMessages({
        S: { id: "provinces.ST.S", defaultMessage: "São Tomé" },
        P: { id: "provinces.ST.P", defaultMessage: "Príncipe" },
    }),
    SR: defineMessages({
        PM: { id: "provinces.SR.PM", defaultMessage: "Paramaribo" },
        BR: { id: "provinces.SR.BR", defaultMessage: "Brokopondo" },
        PR: { id: "provinces.SR.PR", defaultMessage: "Para" },
        CM: { id: "provinces.SR.CM", defaultMessage: "Commewijne" },
        SA: { id: "provinces.SR.SA", defaultMessage: "Saramacca" },
        CR: { id: "provinces.SR.CR", defaultMessage: "Coronie" },
        SI: { id: "provinces.SR.SI", defaultMessage: "Sipaliwini" },
        MA: { id: "provinces.SR.MA", defaultMessage: "Marowijne" },
        WA: { id: "provinces.SR.WA", defaultMessage: "Wanica" },
        NI: { id: "provinces.SR.NI", defaultMessage: "Nickerie" },
    }),
    SK: defineMessages({
        BL: { id: "provinces.SK.BL", defaultMessage: "Bratislavský kraj" },
        TC: { id: "provinces.SK.TC", defaultMessage: "Trenčiansky kraj" },
        KI: { id: "provinces.SK.KI", defaultMessage: "Košický kraj" },
        ZI: { id: "provinces.SK.ZI", defaultMessage: "Žilinský kraj" },
        NI: { id: "provinces.SK.NI", defaultMessage: "Nitriansky kraj" },
        PV: { id: "provinces.SK.PV", defaultMessage: "Prešovský kraj" },
        TA: { id: "provinces.SK.TA", defaultMessage: "Trnavský kraj" },
        BC: { id: "provinces.SK.BC", defaultMessage: "Banskobystrický kraj" },
    }),
    SI: defineMessages({
        133: { id: "provinces.SI.133", defaultMessage: "Velenje" },
        176: { id: "provinces.SI.176", defaultMessage: "Razkrižje" },
        134: { id: "provinces.SI.134", defaultMessage: "Velike Lašče" },
        177: { id: "provinces.SI.177", defaultMessage: "Ribnica na Pohorju" },
        135: { id: "provinces.SI.135", defaultMessage: "Videm" },
        178: { id: "provinces.SI.178", defaultMessage: "Selnica ob Dravi" },
        136: { id: "provinces.SI.136", defaultMessage: "Vipava" },
        179: { id: "provinces.SI.179", defaultMessage: "Sodražica" },
        137: { id: "provinces.SI.137", defaultMessage: "Vitanje" },
        180: { id: "provinces.SI.180", defaultMessage: "Solčava" },
        138: { id: "provinces.SI.138", defaultMessage: "Vodice" },
        181: { id: "provinces.SI.181", defaultMessage: "Sveta Ana" },
        139: { id: "provinces.SI.139", defaultMessage: "Vojnik" },
        182: { id: "provinces.SI.182", defaultMessage: "Sveta Andraž v Slovenskih Goricah" },
        140: { id: "provinces.SI.140", defaultMessage: "Vrhnika" },
        183: { id: "provinces.SI.183", defaultMessage: "Šempeter-Vrtojba" },
        141: { id: "provinces.SI.141", defaultMessage: "Vuzenica" },
        184: { id: "provinces.SI.184", defaultMessage: "Tabor" },
        142: { id: "provinces.SI.142", defaultMessage: "Zagorje ob Savi" },
        185: { id: "provinces.SI.185", defaultMessage: "Trnovska vas" },
        143: { id: "provinces.SI.143", defaultMessage: "Zavrč" },
        186: { id: "provinces.SI.186", defaultMessage: "Trzin" },
        144: { id: "provinces.SI.144", defaultMessage: "Zreče" },
        187: { id: "provinces.SI.187", defaultMessage: "Velika Polana" },
        146: { id: "provinces.SI.146", defaultMessage: "Železniki" },
        188: { id: "provinces.SI.188", defaultMessage: "Veržej" },
        147: { id: "provinces.SI.147", defaultMessage: "Žiri" },
        189: { id: "provinces.SI.189", defaultMessage: "Vransko" },
        148: { id: "provinces.SI.148", defaultMessage: "Benedikt" },
        190: { id: "provinces.SI.190", defaultMessage: "Žalec" },
        149: { id: "provinces.SI.149", defaultMessage: "Bistrica ob Sotli" },
        191: { id: "provinces.SI.191", defaultMessage: "Žetale" },
        150: { id: "provinces.SI.150", defaultMessage: "Bloke" },
        192: { id: "provinces.SI.192", defaultMessage: "Žirovnica" },
        151: { id: "provinces.SI.151", defaultMessage: "Braslovče" },
        193: { id: "provinces.SI.193", defaultMessage: "Žužemberk" },
        152: { id: "provinces.SI.152", defaultMessage: "Cankova" },
        194: { id: "provinces.SI.194", defaultMessage: "Šmartno pri Litiji" },
        153: { id: "provinces.SI.153", defaultMessage: "Cerkvenjak" },
        195: { id: "provinces.SI.195", defaultMessage: "Apače" },
        154: { id: "provinces.SI.154", defaultMessage: "Dobje" },
        196: { id: "provinces.SI.196", defaultMessage: "Cirkulane" },
        155: { id: "provinces.SI.155", defaultMessage: "Dobrna" },
        197: { id: "provinces.SI.197", defaultMessage: "Kosanjevica na Krki" },
        156: { id: "provinces.SI.156", defaultMessage: "Dobrovnik/Dobronak" },
        198: { id: "provinces.SI.198", defaultMessage: "Makole" },
        157: { id: "provinces.SI.157", defaultMessage: "Dolenjske Toplice" },
        199: { id: "provinces.SI.199", defaultMessage: "Mokronog-Trebelno" },
        158: { id: "provinces.SI.158", defaultMessage: "Grad" },
        200: { id: "provinces.SI.200", defaultMessage: "Poljčane" },
        159: { id: "provinces.SI.159", defaultMessage: "Hajdina" },
        201: { id: "provinces.SI.201", defaultMessage: "Renče-Vogrsko" },
        160: { id: "provinces.SI.160", defaultMessage: "Hoče-Slivnica" },
        202: { id: "provinces.SI.202", defaultMessage: "Središče ob Dravi" },
        161: { id: "provinces.SI.161", defaultMessage: "Hodoš/Hodos" },
        203: { id: "provinces.SI.203", defaultMessage: "Straža" },
        162: { id: "provinces.SI.162", defaultMessage: "Horjul" },
        204: { id: "provinces.SI.204", defaultMessage: "Sveta Trojica v Slovenskih Goricah" },
        163: { id: "provinces.SI.163", defaultMessage: "Jezersko" },
        205: { id: "provinces.SI.205", defaultMessage: "Sveti Tomaž" },
        164: { id: "provinces.SI.164", defaultMessage: "Komenda" },
        206: { id: "provinces.SI.206", defaultMessage: "Šmarjeske Topliče" },
        165: { id: "provinces.SI.165", defaultMessage: "Kostel" },
        207: { id: "provinces.SI.207", defaultMessage: "Gorje" },
        166: { id: "provinces.SI.166", defaultMessage: "Križevci" },
        208: { id: "provinces.SI.208", defaultMessage: "Log-Dragomer" },
        167: { id: "provinces.SI.167", defaultMessage: "Lovrenc na Pohorju" },
        209: { id: "provinces.SI.209", defaultMessage: "Rečica ob Savinji" },
        168: { id: "provinces.SI.168", defaultMessage: "Markovci" },
        210: { id: "provinces.SI.210", defaultMessage: "Sveti Jurij v Slovenskih Goricah" },
        169: { id: "provinces.SI.169", defaultMessage: "Miklavž na Dravskem polju" },
        211: { id: "provinces.SI.211", defaultMessage: "Šentrupert" },
        "001": { id: "provinces.SI.001", defaultMessage: "Ajdovščina" },
        170: { id: "provinces.SI.170", defaultMessage: "Mirna Peč" },
        "002": { id: "provinces.SI.002", defaultMessage: "Beltinci" },
        "090": { id: "provinces.SI.090", defaultMessage: "Piran/Pirano" },
        171: { id: "provinces.SI.171", defaultMessage: "Oplotnica" },
        "003": { id: "provinces.SI.003", defaultMessage: "Bled" },
        172: { id: "provinces.SI.172", defaultMessage: "Podlehnik" },
        "004": { id: "provinces.SI.004", defaultMessage: "Bohinj" },
        173: { id: "provinces.SI.173", defaultMessage: "Polzela" },
        "005": { id: "provinces.SI.005", defaultMessage: "Borovnica" },
        174: { id: "provinces.SI.174", defaultMessage: "Prebold" },
        "006": { id: "provinces.SI.006", defaultMessage: "Bovec" },
        175: { id: "provinces.SI.175", defaultMessage: "Prevalje" },
        "007": { id: "provinces.SI.007", defaultMessage: "Brda" },
        "049": { id: "provinces.SI.049", defaultMessage: "Komen" },
        "091": { id: "provinces.SI.091", defaultMessage: "Pivka" },
        "008": { id: "provinces.SI.008", defaultMessage: "Brezovica" },
        "050": { id: "provinces.SI.050", defaultMessage: "Koper/Capodistria" },
        "092": { id: "provinces.SI.092", defaultMessage: "Podčetrtek" },
        "009": { id: "provinces.SI.009", defaultMessage: "Brežice" },
        "051": { id: "provinces.SI.051", defaultMessage: "Kozje" },
        "093": { id: "provinces.SI.093", defaultMessage: "Podvelka" },
        132: { id: "provinces.SI.132", defaultMessage: "Turnišče" },
        "010": { id: "provinces.SI.010", defaultMessage: "Tišina" },
        "052": { id: "provinces.SI.052", defaultMessage: "Kranj" },
        "094": { id: "provinces.SI.094", defaultMessage: "Postojna" },
        "011": { id: "provinces.SI.011", defaultMessage: "Celje" },
        "053": { id: "provinces.SI.053", defaultMessage: "Kranjska Gora" },
        "095": { id: "provinces.SI.095", defaultMessage: "Preddvor" },
        "012": { id: "provinces.SI.012", defaultMessage: "Cerklje na Gorenjskem" },
        "054": { id: "provinces.SI.054", defaultMessage: "Krško" },
        "096": { id: "provinces.SI.096", defaultMessage: "Ptuj" },
        "013": { id: "provinces.SI.013", defaultMessage: "Cerknica" },
        "055": { id: "provinces.SI.055", defaultMessage: "Kungota" },
        "097": { id: "provinces.SI.097", defaultMessage: "Puconci" },
        "014": { id: "provinces.SI.014", defaultMessage: "Cerkno" },
        "056": { id: "provinces.SI.056", defaultMessage: "Kuzma" },
        "098": { id: "provinces.SI.098", defaultMessage: "Rače-Fram" },
        "015": { id: "provinces.SI.015", defaultMessage: "Črenšovci" },
        "057": { id: "provinces.SI.057", defaultMessage: "Laško" },
        "099": { id: "provinces.SI.099", defaultMessage: "Radeče" },
        "016": { id: "provinces.SI.016", defaultMessage: "Črna na Koroškem" },
        "058": { id: "provinces.SI.058", defaultMessage: "Lenart" },
        100: { id: "provinces.SI.100", defaultMessage: "Radenci" },
        "017": { id: "provinces.SI.017", defaultMessage: "Črnomelj" },
        "059": { id: "provinces.SI.059", defaultMessage: "Lendava/Lendva" },
        101: { id: "provinces.SI.101", defaultMessage: "Radlje ob Dravi" },
        "018": { id: "provinces.SI.018", defaultMessage: "Destrnik" },
        "060": { id: "provinces.SI.060", defaultMessage: "Litija" },
        102: { id: "provinces.SI.102", defaultMessage: "Radovljica" },
        "019": { id: "provinces.SI.019", defaultMessage: "Divača" },
        "061": { id: "provinces.SI.061", defaultMessage: "Ljubljana" },
        103: { id: "provinces.SI.103", defaultMessage: "Ravne na Koroškem" },
        "020": { id: "provinces.SI.020", defaultMessage: "Dobrepolje" },
        "062": { id: "provinces.SI.062", defaultMessage: "Ljubno" },
        104: { id: "provinces.SI.104", defaultMessage: "Ribnica" },
        "021": { id: "provinces.SI.021", defaultMessage: "Dobrova-Polhov Gradec" },
        "063": { id: "provinces.SI.063", defaultMessage: "Ljutomer" },
        105: { id: "provinces.SI.105", defaultMessage: "Rogašovci" },
        "022": { id: "provinces.SI.022", defaultMessage: "Dol pri Ljubljani" },
        "064": { id: "provinces.SI.064", defaultMessage: "Logatec" },
        106: { id: "provinces.SI.106", defaultMessage: "Rogaška Slatina" },
        "023": { id: "provinces.SI.023", defaultMessage: "Domžale" },
        "065": { id: "provinces.SI.065", defaultMessage: "Loška dolina" },
        107: { id: "provinces.SI.107", defaultMessage: "Rogatec" },
        "024": { id: "provinces.SI.024", defaultMessage: "Dornava" },
        "066": { id: "provinces.SI.066", defaultMessage: "Loški Potok" },
        108: { id: "provinces.SI.108", defaultMessage: "Ruše" },
        "025": { id: "provinces.SI.025", defaultMessage: "Dravograd" },
        "067": { id: "provinces.SI.067", defaultMessage: "Luče" },
        109: { id: "provinces.SI.109", defaultMessage: "Semič" },
        "026": { id: "provinces.SI.026", defaultMessage: "Duplek" },
        "068": { id: "provinces.SI.068", defaultMessage: "Lukovica" },
        110: { id: "provinces.SI.110", defaultMessage: "Sevnica" },
        "069": { id: "provinces.SI.069", defaultMessage: "Majšperk" },
        "027": { id: "provinces.SI.027", defaultMessage: "Gorenja vas-Poljane" },
        111: { id: "provinces.SI.111", defaultMessage: "Sežana" },
        "028": { id: "provinces.SI.028", defaultMessage: "Gorišnica" },
        "070": { id: "provinces.SI.070", defaultMessage: "Maribor" },
        112: { id: "provinces.SI.112", defaultMessage: "Slovenj Gradec" },
        "029": { id: "provinces.SI.029", defaultMessage: "Gornja Radgona" },
        "071": { id: "provinces.SI.071", defaultMessage: "Medvode" },
        113: { id: "provinces.SI.113", defaultMessage: "Slovenska Bistrica" },
        "030": { id: "provinces.SI.030", defaultMessage: "Gornji Grad" },
        "072": { id: "provinces.SI.072", defaultMessage: "Mengeš" },
        114: { id: "provinces.SI.114", defaultMessage: "Slovenske Konjice" },
        "031": { id: "provinces.SI.031", defaultMessage: "Gornji Petrovci" },
        "073": { id: "provinces.SI.073", defaultMessage: "Metlika" },
        115: { id: "provinces.SI.115", defaultMessage: "Starče" },
        "032": { id: "provinces.SI.032", defaultMessage: "Grosuplje" },
        "074": { id: "provinces.SI.074", defaultMessage: "Mežica" },
        116: { id: "provinces.SI.116", defaultMessage: "Sveti Jurij" },
        "033": { id: "provinces.SI.033", defaultMessage: "Šalovci" },
        "075": { id: "provinces.SI.075", defaultMessage: "Miren-Kostanjevica" },
        117: { id: "provinces.SI.117", defaultMessage: "Šenčur" },
        "034": { id: "provinces.SI.034", defaultMessage: "Hrastnik" },
        "076": { id: "provinces.SI.076", defaultMessage: "Mislinja" },
        118: { id: "provinces.SI.118", defaultMessage: "Šentilj" },
        "035": { id: "provinces.SI.035", defaultMessage: "Hrpelje-Kozina" },
        "077": { id: "provinces.SI.077", defaultMessage: "Moravče" },
        119: { id: "provinces.SI.119", defaultMessage: "Šentjernej" },
        "036": { id: "provinces.SI.036", defaultMessage: "Idrija" },
        "078": { id: "provinces.SI.078", defaultMessage: "Moravske Toplice" },
        120: { id: "provinces.SI.120", defaultMessage: "Šentjur" },
        "037": { id: "provinces.SI.037", defaultMessage: "Ig" },
        "079": { id: "provinces.SI.079", defaultMessage: "Mozirje" },
        121: { id: "provinces.SI.121", defaultMessage: "Škocjan" },
        "038": { id: "provinces.SI.038", defaultMessage: "Ilirska Bistrica" },
        "080": { id: "provinces.SI.080", defaultMessage: "Murska Sobota" },
        122: { id: "provinces.SI.122", defaultMessage: "Škofja Loka" },
        "039": { id: "provinces.SI.039", defaultMessage: "Ivančna Gorica" },
        "081": { id: "provinces.SI.081", defaultMessage: "Muta" },
        123: { id: "provinces.SI.123", defaultMessage: "Škofljica" },
        "040": { id: "provinces.SI.040", defaultMessage: "Izola/Isola" },
        "082": { id: "provinces.SI.082", defaultMessage: "Naklo" },
        124: { id: "provinces.SI.124", defaultMessage: "Šmarje pri Jelšah" },
        "041": { id: "provinces.SI.041", defaultMessage: "Jesenice" },
        "083": { id: "provinces.SI.083", defaultMessage: "Nazarje" },
        125: { id: "provinces.SI.125", defaultMessage: "Šmartno ob Paki" },
        "042": { id: "provinces.SI.042", defaultMessage: "Juršinci" },
        "084": { id: "provinces.SI.084", defaultMessage: "Nova Gorica" },
        126: { id: "provinces.SI.126", defaultMessage: "Šoštanj" },
        "043": { id: "provinces.SI.043", defaultMessage: "Kamnik" },
        "085": { id: "provinces.SI.085", defaultMessage: "Novo mesto" },
        127: { id: "provinces.SI.127", defaultMessage: "Štore" },
        "044": { id: "provinces.SI.044", defaultMessage: "Kanal" },
        "086": { id: "provinces.SI.086", defaultMessage: "Odranci" },
        128: { id: "provinces.SI.128", defaultMessage: "Tolmin" },
        "045": { id: "provinces.SI.045", defaultMessage: "Kidričevo" },
        "087": { id: "provinces.SI.087", defaultMessage: "Ormož" },
        129: { id: "provinces.SI.129", defaultMessage: "Trbovlje" },
        "046": { id: "provinces.SI.046", defaultMessage: "Kobarid" },
        "088": { id: "provinces.SI.088", defaultMessage: "Osilnica" },
        130: { id: "provinces.SI.130", defaultMessage: "Trebnje" },
        "047": { id: "provinces.SI.047", defaultMessage: "Kobilje" },
        "089": { id: "provinces.SI.089", defaultMessage: "Pesnica" },
        131: { id: "provinces.SI.131", defaultMessage: "Tržič" },
        "048": { id: "provinces.SI.048", defaultMessage: "Kočevje" },
    }),
    SE: defineMessages({
        S: { id: "provinces.SE.S", defaultMessage: "Värmlands län" },
        T: { id: "provinces.SE.T", defaultMessage: "Örebro län" },
        U: { id: "provinces.SE.U", defaultMessage: "Västmanlands län" },
        W: { id: "provinces.SE.W", defaultMessage: "Dalarnas län" },
        X: { id: "provinces.SE.X", defaultMessage: "Gävleborgs län" },
        Y: { id: "provinces.SE.Y", defaultMessage: "Västernorrlands län" },
        Z: { id: "provinces.SE.Z", defaultMessage: "Jämtlands län" },
        AB: { id: "provinces.SE.AB", defaultMessage: "Stockholms län" },
        AC: { id: "provinces.SE.AC", defaultMessage: "Västerbottens län" },
        BD: { id: "provinces.SE.BD", defaultMessage: "Norrbottens län" },
        C: { id: "provinces.SE.C", defaultMessage: "Uppsala län" },
        D: { id: "provinces.SE.D", defaultMessage: "Södermanlands län" },
        E: { id: "provinces.SE.E", defaultMessage: "Östergötlands län" },
        F: { id: "provinces.SE.F", defaultMessage: "Jönköpings län" },
        G: { id: "provinces.SE.G", defaultMessage: "Kronobergs län" },
        H: { id: "provinces.SE.H", defaultMessage: "Kalmar län" },
        I: { id: "provinces.SE.I", defaultMessage: "Gotlands län" },
        K: { id: "provinces.SE.K", defaultMessage: "Blekinge län" },
        M: { id: "provinces.SE.M", defaultMessage: "Skåne län" },
        N: { id: "provinces.SE.N", defaultMessage: "Hallands län" },
        O: { id: "provinces.SE.O", defaultMessage: "Västra Götalands län" },
    }),
    SZ: defineMessages({
        LU: { id: "provinces.SZ.LU", defaultMessage: "Lubombo" },
        HH: { id: "provinces.SZ.HH", defaultMessage: "Hhohho" },
        SH: { id: "provinces.SZ.SH", defaultMessage: "Shiselweni" },
        MA: { id: "provinces.SZ.MA", defaultMessage: "Manzini" },
    }),
    SX: defineMessages({}),
    SC: defineMessages({
        15: { id: "provinces.SC.15", defaultMessage: "La Digue" },
        16: { id: "provinces.SC.16", defaultMessage: "English River" },
        17: { id: "provinces.SC.17", defaultMessage: "Mont Buxton" },
        18: { id: "provinces.SC.18", defaultMessage: "Mont Fleuri" },
        19: { id: "provinces.SC.19", defaultMessage: "Plaisance" },
        20: { id: "provinces.SC.20", defaultMessage: "Pointe Larue" },
        21: { id: "provinces.SC.21", defaultMessage: "Port Glaud" },
        22: { id: "provinces.SC.22", defaultMessage: "Saint Louis" },
        "01": { id: "provinces.SC.01", defaultMessage: "Anse aux Pins" },
        23: { id: "provinces.SC.23", defaultMessage: "Takamaka" },
        "02": { id: "provinces.SC.02", defaultMessage: "Anse Boileau" },
        24: { id: "provinces.SC.24", defaultMessage: "Les Mamelles" },
        "03": { id: "provinces.SC.03", defaultMessage: "Anse Etoile" },
        25: { id: "provinces.SC.25", defaultMessage: "Roche Caiman" },
        "04": { id: "provinces.SC.04", defaultMessage: "Anse Louis" },
        "05": { id: "provinces.SC.05", defaultMessage: "Anse Royale" },
        "06": { id: "provinces.SC.06", defaultMessage: "Baie Lazare" },
        "07": { id: "provinces.SC.07", defaultMessage: "Baie Sainte Anne" },
        "08": { id: "provinces.SC.08", defaultMessage: "Beau Vallon" },
        "09": { id: "provinces.SC.09", defaultMessage: "Bel Air" },
        10: { id: "provinces.SC.10", defaultMessage: "Bel Ombre" },
        11: { id: "provinces.SC.11", defaultMessage: "Cascade" },
        12: { id: "provinces.SC.12", defaultMessage: "Glacis" },
        13: { id: "provinces.SC.13", defaultMessage: "Grand Anse Mahe" },
        14: { id: "provinces.SC.14", defaultMessage: "Grand Anse Praslin" },
    }),
    SY: defineMessages({
        RD: { id: "provinces.SY.RD", defaultMessage: "Rif Dimashq" },
        HM: { id: "provinces.SY.HM", defaultMessage: "Hamah" },
        DR: { id: "provinces.SY.DR", defaultMessage: "Dar'a" },
        RA: { id: "provinces.SY.RA", defaultMessage: "Ar Raqqah" },
        SU: { id: "provinces.SY.SU", defaultMessage: "As Suwayda'" },
        ID: { id: "provinces.SY.ID", defaultMessage: "Idlib" },
        DY: { id: "provinces.SY.DY", defaultMessage: "Dayr az Zawr" },
        TA: { id: "provinces.SY.TA", defaultMessage: "Tartus" },
        LA: { id: "provinces.SY.LA", defaultMessage: "Al Ladhiqiyah" },
        HA: { id: "provinces.SY.HA", defaultMessage: "Al Hasakah" },
        QU: { id: "provinces.SY.QU", defaultMessage: "Al Qunaytirah" },
        HI: { id: "provinces.SY.HI", defaultMessage: "Homs" },
        DI: { id: "provinces.SY.DI", defaultMessage: "Dimashq" },
        HL: { id: "provinces.SY.HL", defaultMessage: "Halab" },
    }),
    TC: defineMessages({}),
    TD: defineMessages({
        EN: { id: "provinces.TD.EN", defaultMessage: "Innīdī" },
        GR: { id: "provinces.TD.GR", defaultMessage: "Qīrā" },
        HL: { id: "provinces.TD.HL", defaultMessage: "Ḥajjar Lamīs" },
        KA: { id: "provinces.TD.KA", defaultMessage: "Kānim" },
        LC: { id: "provinces.TD.LC", defaultMessage: "Al Buḩayrah" },
        LO: { id: "provinces.TD.LO", defaultMessage: "Lūqūn al Gharbī" },
        LR: { id: "provinces.TD.LR", defaultMessage: "Lūqūn ash Sharqī" },
        MA: { id: "provinces.TD.MA", defaultMessage: "Māndūl" },
        MC: { id: "provinces.TD.MC", defaultMessage: "Shārī al Awsaṭ" },
        ME: { id: "provinces.TD.ME", defaultMessage: "Māyū Kībbī ash Sharqī" },
        MO: { id: "provinces.TD.MO", defaultMessage: "Māyū Kībbī al Gharbī" },
        ND: { id: "provinces.TD.ND", defaultMessage: "Madīnat Injamīnā" },
        OD: { id: "provinces.TD.OD", defaultMessage: "Waddāy" },
        SA: { id: "provinces.TD.SA", defaultMessage: "Salāmāt" },
        SI: { id: "provinces.TD.SI", defaultMessage: "Sīlā" },
        TA: { id: "provinces.TD.TA", defaultMessage: "Tānjilī" },
        TI: { id: "provinces.TD.TI", defaultMessage: "Tibastī" },
        WF: { id: "provinces.TD.WF", defaultMessage: "Wādī Fīrā" },
        BA: { id: "provinces.TD.BA", defaultMessage: "Al Baṭḩah" },
        BG: { id: "provinces.TD.BG", defaultMessage: "Baḩr al Ghazāl" },
        BO: { id: "provinces.TD.BO", defaultMessage: "Būrkū" },
        CB: { id: "provinces.TD.CB", defaultMessage: "Shārī Bāqirmī" },
    }),
    TG: defineMessages({
        P: { id: "provinces.TG.P", defaultMessage: "Région des Plateaux" },
        S: { id: "provinces.TG.S", defaultMessage: "Région des Savannes" },
        C: { id: "provinces.TG.C", defaultMessage: "Région du Centre" },
        K: { id: "provinces.TG.K", defaultMessage: "Région de la Kara" },
        M: { id: "provinces.TG.M", defaultMessage: "Région Maritime" },
    }),
    TH: defineMessages({
        10: { id: "provinces.TH.10", defaultMessage: "Krung Thep Maha Nakhon Bangkok" },
        11: { id: "provinces.TH.11", defaultMessage: "Samut Prakan" },
        12: { id: "provinces.TH.12", defaultMessage: "Nonthaburi" },
        13: { id: "provinces.TH.13", defaultMessage: "Pathum Thani" },
        14: { id: "provinces.TH.14", defaultMessage: "Phra Nakhon Si Ayutthaya" },
        15: { id: "provinces.TH.15", defaultMessage: "Ang Thong" },
        16: { id: "provinces.TH.16", defaultMessage: "Lop Buri" },
        17: { id: "provinces.TH.17", defaultMessage: "Sing Buri" },
        18: { id: "provinces.TH.18", defaultMessage: "Chai Nat" },
        19: { id: "provinces.TH.19", defaultMessage: "Saraburi" },
        20: { id: "provinces.TH.20", defaultMessage: "Chon Buri" },
        21: { id: "provinces.TH.21", defaultMessage: "Rayong" },
        22: { id: "provinces.TH.22", defaultMessage: "Chanthaburi" },
        23: { id: "provinces.TH.23", defaultMessage: "Trat" },
        24: { id: "provinces.TH.24", defaultMessage: "Chachoengsao" },
        25: { id: "provinces.TH.25", defaultMessage: "Prachin Buri" },
        26: { id: "provinces.TH.26", defaultMessage: "Nakhon Nayok" },
        27: { id: "provinces.TH.27", defaultMessage: "Sa Kaeo" },
        30: { id: "provinces.TH.30", defaultMessage: "Nakhon Ratchasima" },
        31: { id: "provinces.TH.31", defaultMessage: "Buri Ram" },
        32: { id: "provinces.TH.32", defaultMessage: "Surin" },
        33: { id: "provinces.TH.33", defaultMessage: "Si Sa Ket" },
        34: { id: "provinces.TH.34", defaultMessage: "Ubon Ratchathani" },
        35: { id: "provinces.TH.35", defaultMessage: "Yasothon" },
        36: { id: "provinces.TH.36", defaultMessage: "Chaiyaphum" },
        37: { id: "provinces.TH.37", defaultMessage: "Amnat Charoen" },
        39: { id: "provinces.TH.39", defaultMessage: "Nong Bua Lam Phu" },
        40: { id: "provinces.TH.40", defaultMessage: "Khon Kaen" },
        41: { id: "provinces.TH.41", defaultMessage: "Udon Thani" },
        42: { id: "provinces.TH.42", defaultMessage: "Loei" },
        43: { id: "provinces.TH.43", defaultMessage: "Nong Khai" },
        44: { id: "provinces.TH.44", defaultMessage: "Maha Sarakham" },
        45: { id: "provinces.TH.45", defaultMessage: "Roi Et" },
        46: { id: "provinces.TH.46", defaultMessage: "Kalasin" },
        47: { id: "provinces.TH.47", defaultMessage: "Sakon Nakhon" },
        48: { id: "provinces.TH.48", defaultMessage: "Nakhon Phanom" },
        49: { id: "provinces.TH.49", defaultMessage: "Mukdahan" },
        50: { id: "provinces.TH.50", defaultMessage: "Chiang Mai" },
        51: { id: "provinces.TH.51", defaultMessage: "Lamphun" },
        52: { id: "provinces.TH.52", defaultMessage: "Lampang" },
        53: { id: "provinces.TH.53", defaultMessage: "Uttaradit" },
        54: { id: "provinces.TH.54", defaultMessage: "Phrae" },
        55: { id: "provinces.TH.55", defaultMessage: "Nan" },
        56: { id: "provinces.TH.56", defaultMessage: "Phayao" },
        57: { id: "provinces.TH.57", defaultMessage: "Chiang Rai" },
        58: { id: "provinces.TH.58", defaultMessage: "Mae Hong Son" },
        60: { id: "provinces.TH.60", defaultMessage: "Nakhon Sawan" },
        61: { id: "provinces.TH.61", defaultMessage: "Uthai Thani" },
        62: { id: "provinces.TH.62", defaultMessage: "Kamphaeng Phet" },
        63: { id: "provinces.TH.63", defaultMessage: "Tak" },
        64: { id: "provinces.TH.64", defaultMessage: "Sukhothai" },
        65: { id: "provinces.TH.65", defaultMessage: "Phitsanulok" },
        66: { id: "provinces.TH.66", defaultMessage: "Phichit" },
        67: { id: "provinces.TH.67", defaultMessage: "Phetchabun" },
        70: { id: "provinces.TH.70", defaultMessage: "Ratchaburi" },
        71: { id: "provinces.TH.71", defaultMessage: "Kanchanaburi" },
        72: { id: "provinces.TH.72", defaultMessage: "Suphan Buri" },
        73: { id: "provinces.TH.73", defaultMessage: "Nakhon Pathom" },
        74: { id: "provinces.TH.74", defaultMessage: "Samut Sakhon" },
        75: { id: "provinces.TH.75", defaultMessage: "Samut Songkhram" },
        76: { id: "provinces.TH.76", defaultMessage: "Phetchaburi" },
        77: { id: "provinces.TH.77", defaultMessage: "Prachuap Khiri Khan" },
        80: { id: "provinces.TH.80", defaultMessage: "Nakhon Si Thammarat" },
        81: { id: "provinces.TH.81", defaultMessage: "Krabi" },
        82: { id: "provinces.TH.82", defaultMessage: "Phangnga" },
        83: { id: "provinces.TH.83", defaultMessage: "Phuket" },
        84: { id: "provinces.TH.84", defaultMessage: "Surat Thani" },
        85: { id: "provinces.TH.85", defaultMessage: "Ranong" },
        86: { id: "provinces.TH.86", defaultMessage: "Chumphon" },
        90: { id: "provinces.TH.90", defaultMessage: "Songkhla" },
        91: { id: "provinces.TH.91", defaultMessage: "Satun" },
        92: { id: "provinces.TH.92", defaultMessage: "Trang" },
        93: { id: "provinces.TH.93", defaultMessage: "Phatthalung" },
        94: { id: "provinces.TH.94", defaultMessage: "Pattani" },
        95: { id: "provinces.TH.95", defaultMessage: "Yala" },
        96: { id: "provinces.TH.96", defaultMessage: "Narathiwat" },
        S: { id: "provinces.TH.S", defaultMessage: "Phatthaya" },
    }),
    TJ: defineMessages({
        KT: { id: "provinces.TJ.KT", defaultMessage: "Khatlon" },
        GB: { id: "provinces.TJ.GB", defaultMessage: "Gorno-Badakhshan" },
        SU: { id: "provinces.TJ.SU", defaultMessage: "Sughd" },
    }),
    TK: defineMessages({}),
    TM: defineMessages({
        S: { id: "provinces.TM.S", defaultMessage: "Aşgabat" },
        A: { id: "provinces.TM.A", defaultMessage: "Ahal" },
        B: { id: "provinces.TM.B", defaultMessage: "Balkan" },
        D: { id: "provinces.TM.D", defaultMessage: "Daşoguz" },
        L: { id: "provinces.TM.L", defaultMessage: "Lebap" },
        M: { id: "provinces.TM.M", defaultMessage: "Mary" },
    }),
    TL: defineMessages({
        VI: { id: "provinces.TL.VI", defaultMessage: "Viqueque" },
        LA: { id: "provinces.TL.LA", defaultMessage: "Lautem" },
        BA: { id: "provinces.TL.BA", defaultMessage: "Baucau" },
        OE: { id: "provinces.TL.OE", defaultMessage: "Oecussi" },
        LI: { id: "provinces.TL.LI", defaultMessage: "Liquiça" },
        BO: { id: "provinces.TL.BO", defaultMessage: "Bobonaro" },
        MF: { id: "provinces.TL.MF", defaultMessage: "Manufahi" },
        CO: { id: "provinces.TL.CO", defaultMessage: "Cova Lima" },
        AL: { id: "provinces.TL.AL", defaultMessage: "Aileu" },
        MT: { id: "provinces.TL.MT", defaultMessage: "Manatuto" },
        DI: { id: "provinces.TL.DI", defaultMessage: "Díli" },
        AN: { id: "provinces.TL.AN", defaultMessage: "Ainaro" },
        ER: { id: "provinces.TL.ER", defaultMessage: "Ermera" },
    }),
    TO: defineMessages({
        "02": { id: "provinces.TO.02", defaultMessage: "Ha'apai" },
        "03": { id: "provinces.TO.03", defaultMessage: "Niuas" },
        "04": { id: "provinces.TO.04", defaultMessage: "Tongatapu" },
        "05": { id: "provinces.TO.05", defaultMessage: "Vava'u" },
        "01": { id: "provinces.TO.01", defaultMessage: "'Eua" },
    }),
    TT: defineMessages({
        RCM: { id: "provinces.TT.RCM", defaultMessage: "Rio Claro-Mayaro" },
        ETO: { id: "provinces.TT.ETO", defaultMessage: "Eastern Tobago" },
        WTO: { id: "provinces.TT.WTO", defaultMessage: "Western Tobago" },
        SFO: { id: "provinces.TT.SFO", defaultMessage: "San Fernando" },
        PED: { id: "provinces.TT.PED", defaultMessage: "Penal-Debe" },
        ARI: { id: "provinces.TT.ARI", defaultMessage: "Arima" },
        SGE: { id: "provinces.TT.SGE", defaultMessage: "Sangre Grande" },
        POS: { id: "provinces.TT.POS", defaultMessage: "Port of Spain" },
        CHA: { id: "provinces.TT.CHA", defaultMessage: "Chaguanas" },
        SIP: { id: "provinces.TT.SIP", defaultMessage: "Siparia" },
        PRT: { id: "provinces.TT.PRT", defaultMessage: "Princes Town" },
        CTT: { id: "provinces.TT.CTT", defaultMessage: "Couva-Tabaquite-Talparo" },
        SJL: { id: "provinces.TT.SJL", defaultMessage: "San Juan-Laventille" },
        PTF: { id: "provinces.TT.PTF", defaultMessage: "Point Fortin" },
        DMN: { id: "provinces.TT.DMN", defaultMessage: "Diego Martin" },
        TUP: { id: "provinces.TT.TUP", defaultMessage: "Tunapuna-Piarco" },
    }),
    TN: defineMessages({
        21: { id: "provinces.TN.21", defaultMessage: "Nabeul" },
        22: { id: "provinces.TN.22", defaultMessage: "Zaghouan" },
        23: { id: "provinces.TN.23", defaultMessage: "Bizerte" },
        31: { id: "provinces.TN.31", defaultMessage: "Béja" },
        32: { id: "provinces.TN.32", defaultMessage: "Jendouba" },
        33: { id: "provinces.TN.33", defaultMessage: "Le Kef" },
        34: { id: "provinces.TN.34", defaultMessage: "Siliana" },
        41: { id: "provinces.TN.41", defaultMessage: "Kairouan" },
        42: { id: "provinces.TN.42", defaultMessage: "Kasserine" },
        43: { id: "provinces.TN.43", defaultMessage: "Sidi Bouzid" },
        51: { id: "provinces.TN.51", defaultMessage: "Sousse" },
        52: { id: "provinces.TN.52", defaultMessage: "Monastir" },
        53: { id: "provinces.TN.53", defaultMessage: "Mahdia" },
        61: { id: "provinces.TN.61", defaultMessage: "Sfax" },
        71: { id: "provinces.TN.71", defaultMessage: "Gafsa" },
        72: { id: "provinces.TN.72", defaultMessage: "Tozeur" },
        73: { id: "provinces.TN.73", defaultMessage: "Kebili" },
        81: { id: "provinces.TN.81", defaultMessage: "Gabès" },
        11: { id: "provinces.TN.11", defaultMessage: "Tunis" },
        82: { id: "provinces.TN.82", defaultMessage: "Medenine" },
        12: { id: "provinces.TN.12", defaultMessage: "Ariana" },
        83: { id: "provinces.TN.83", defaultMessage: "Tataouine" },
        13: { id: "provinces.TN.13", defaultMessage: "Ben Arous" },
        14: { id: "provinces.TN.14", defaultMessage: "La Manouba" },
    }),
    TR: defineMessages({
        "01": { id: "provinces.TR.01", defaultMessage: "Adana" },
        "02": { id: "provinces.TR.02", defaultMessage: "Adıyaman" },
        "03": { id: "provinces.TR.03", defaultMessage: "Afyonkarahisar" },
        "04": { id: "provinces.TR.04", defaultMessage: "Ağrı" },
        "05": { id: "provinces.TR.05", defaultMessage: "Amasya" },
        "06": { id: "provinces.TR.06", defaultMessage: "Ankara" },
        "07": { id: "provinces.TR.07", defaultMessage: "Antalya" },
        "08": { id: "provinces.TR.08", defaultMessage: "Artvin" },
        "09": { id: "provinces.TR.09", defaultMessage: "Aydın" },
        10: { id: "provinces.TR.10", defaultMessage: "Balıkesir" },
        11: { id: "provinces.TR.11", defaultMessage: "Bilecik" },
        12: { id: "provinces.TR.12", defaultMessage: "Bingöl" },
        13: { id: "provinces.TR.13", defaultMessage: "Bitlis" },
        14: { id: "provinces.TR.14", defaultMessage: "Bolu" },
        15: { id: "provinces.TR.15", defaultMessage: "Burdur" },
        16: { id: "provinces.TR.16", defaultMessage: "Bursa" },
        17: { id: "provinces.TR.17", defaultMessage: "Çanakkale" },
        18: { id: "provinces.TR.18", defaultMessage: "Çankırı" },
        19: { id: "provinces.TR.19", defaultMessage: "Çorum" },
        20: { id: "provinces.TR.20", defaultMessage: "Denizli" },
        21: { id: "provinces.TR.21", defaultMessage: "Diyarbakır" },
        22: { id: "provinces.TR.22", defaultMessage: "Edirne" },
        23: { id: "provinces.TR.23", defaultMessage: "Elazığ" },
        24: { id: "provinces.TR.24", defaultMessage: "Erzincan" },
        25: { id: "provinces.TR.25", defaultMessage: "Erzurum" },
        26: { id: "provinces.TR.26", defaultMessage: "Eskişehir" },
        27: { id: "provinces.TR.27", defaultMessage: "Gaziantep" },
        28: { id: "provinces.TR.28", defaultMessage: "Giresun" },
        29: { id: "provinces.TR.29", defaultMessage: "Gümüşhane" },
        30: { id: "provinces.TR.30", defaultMessage: "Hakkâri" },
        31: { id: "provinces.TR.31", defaultMessage: "Hatay" },
        32: { id: "provinces.TR.32", defaultMessage: "Isparta" },
        33: { id: "provinces.TR.33", defaultMessage: "Mersin" },
        34: { id: "provinces.TR.34", defaultMessage: "İstanbul" },
        35: { id: "provinces.TR.35", defaultMessage: "İzmir" },
        36: { id: "provinces.TR.36", defaultMessage: "Kars" },
        37: { id: "provinces.TR.37", defaultMessage: "Kastamonu" },
        38: { id: "provinces.TR.38", defaultMessage: "Kayseri" },
        39: { id: "provinces.TR.39", defaultMessage: "Kırklareli" },
        81: { id: "provinces.TR.81", defaultMessage: "Düzce" },
        40: { id: "provinces.TR.40", defaultMessage: "Kırşehir" },
        41: { id: "provinces.TR.41", defaultMessage: "Kocaeli" },
        42: { id: "provinces.TR.42", defaultMessage: "Konya" },
        43: { id: "provinces.TR.43", defaultMessage: "Kütahya" },
        44: { id: "provinces.TR.44", defaultMessage: "Malatya" },
        45: { id: "provinces.TR.45", defaultMessage: "Manisa" },
        46: { id: "provinces.TR.46", defaultMessage: "Kahramanmaraş" },
        47: { id: "provinces.TR.47", defaultMessage: "Mardin" },
        48: { id: "provinces.TR.48", defaultMessage: "Muğla" },
        49: { id: "provinces.TR.49", defaultMessage: "Muş" },
        50: { id: "provinces.TR.50", defaultMessage: "Nevşehir" },
        51: { id: "provinces.TR.51", defaultMessage: "Niğde" },
        52: { id: "provinces.TR.52", defaultMessage: "Ordu" },
        53: { id: "provinces.TR.53", defaultMessage: "Rize" },
        54: { id: "provinces.TR.54", defaultMessage: "Sakarya" },
        55: { id: "provinces.TR.55", defaultMessage: "Samsun" },
        56: { id: "provinces.TR.56", defaultMessage: "Siirt" },
        57: { id: "provinces.TR.57", defaultMessage: "Sinop" },
        58: { id: "provinces.TR.58", defaultMessage: "Sivas" },
        59: { id: "provinces.TR.59", defaultMessage: "Tekirdağ" },
        60: { id: "provinces.TR.60", defaultMessage: "Tokat" },
        61: { id: "provinces.TR.61", defaultMessage: "Trabzon" },
        62: { id: "provinces.TR.62", defaultMessage: "Tunceli" },
        63: { id: "provinces.TR.63", defaultMessage: "Şanlıurfa" },
        64: { id: "provinces.TR.64", defaultMessage: "Uşak" },
        65: { id: "provinces.TR.65", defaultMessage: "Van" },
        66: { id: "provinces.TR.66", defaultMessage: "Yozgat" },
        67: { id: "provinces.TR.67", defaultMessage: "Zonguldak" },
        68: { id: "provinces.TR.68", defaultMessage: "Aksaray" },
        69: { id: "provinces.TR.69", defaultMessage: "Bayburt" },
        70: { id: "provinces.TR.70", defaultMessage: "Karaman" },
        71: { id: "provinces.TR.71", defaultMessage: "Kırıkkale" },
        72: { id: "provinces.TR.72", defaultMessage: "Batman" },
        73: { id: "provinces.TR.73", defaultMessage: "Şırnak" },
        74: { id: "provinces.TR.74", defaultMessage: "Bartın" },
        75: { id: "provinces.TR.75", defaultMessage: "Ardahan" },
        76: { id: "provinces.TR.76", defaultMessage: "Iğdır" },
        77: { id: "provinces.TR.77", defaultMessage: "Yalova" },
        78: { id: "provinces.TR.78", defaultMessage: "Karabük" },
        79: { id: "provinces.TR.79", defaultMessage: "Kilis" },
        80: { id: "provinces.TR.80", defaultMessage: "Osmaniye" },
    }),
    TV: defineMessages({
        NMA: { id: "provinces.TV.NMA", defaultMessage: "Nanumea" },
        NMG: { id: "provinces.TV.NMG", defaultMessage: "Nanumanga" },
        FUN: { id: "provinces.TV.FUN", defaultMessage: "Funafuti" },
        NUI: { id: "provinces.TV.NUI", defaultMessage: "Nui" },
        NIT: { id: "provinces.TV.NIT", defaultMessage: "Niutao" },
        VAI: { id: "provinces.TV.VAI", defaultMessage: "Vaitupu" },
        NKF: { id: "provinces.TV.NKF", defaultMessage: "Nukufetau" },
        NKL: { id: "provinces.TV.NKL", defaultMessage: "Nukulaelae" },
    }),
    TW: defineMessages({
        TPE: { id: "provinces.TW.TPE", defaultMessage: "Taipei City" },
        TPQ: { id: "provinces.TW.TPQ", defaultMessage: "Taipei" },
        TTT: { id: "provinces.TW.TTT", defaultMessage: "Taitung" },
        TXG: { id: "provinces.TW.TXG", defaultMessage: "Taichung City" },
        CHA: { id: "provinces.TW.CHA", defaultMessage: "Changhua" },
        TXQ: { id: "provinces.TW.TXQ", defaultMessage: "Taichung" },
        CYI: { id: "provinces.TW.CYI", defaultMessage: "Chiay City" },
        YUN: { id: "provinces.TW.YUN", defaultMessage: "Yunlin" },
        CYQ: { id: "provinces.TW.CYQ", defaultMessage: "Chiayi" },
        HSQ: { id: "provinces.TW.HSQ", defaultMessage: "Hsinchu" },
        HSZ: { id: "provinces.TW.HSZ", defaultMessage: "Hsinchui City" },
        HUA: { id: "provinces.TW.HUA", defaultMessage: "Hualien" },
        ILA: { id: "provinces.TW.ILA", defaultMessage: "Ilan" },
        KEE: { id: "provinces.TW.KEE", defaultMessage: "Keelung City" },
        KHH: { id: "provinces.TW.KHH", defaultMessage: "Kaohsiung City" },
        KHQ: { id: "provinces.TW.KHQ", defaultMessage: "Kaohsiung" },
        MIA: { id: "provinces.TW.MIA", defaultMessage: "Miaoli" },
        NAN: { id: "provinces.TW.NAN", defaultMessage: "Nantou" },
        PEN: { id: "provinces.TW.PEN", defaultMessage: "Penghu" },
        PIF: { id: "provinces.TW.PIF", defaultMessage: "Pingtung" },
        TAO: { id: "provinces.TW.TAO", defaultMessage: "Taoyuan" },
        TNN: { id: "provinces.TW.TNN", defaultMessage: "Tainan City" },
        TNQ: { id: "provinces.TW.TNQ", defaultMessage: "Tainan" },
    }),
    TZ: defineMessages({
        16: { id: "provinces.TZ.16", defaultMessage: "Morogoro" },
        17: { id: "provinces.TZ.17", defaultMessage: "Mtwara" },
        18: { id: "provinces.TZ.18", defaultMessage: "Mwanza" },
        19: { id: "provinces.TZ.19", defaultMessage: "Pwani" },
        20: { id: "provinces.TZ.20", defaultMessage: "Rukwa" },
        21: { id: "provinces.TZ.21", defaultMessage: "Ruvuma" },
        22: { id: "provinces.TZ.22", defaultMessage: "Shinyanga" },
        "01": { id: "provinces.TZ.01", defaultMessage: "Arusha" },
        23: { id: "provinces.TZ.23", defaultMessage: "Singida" },
        "02": { id: "provinces.TZ.02", defaultMessage: "Dar-es-Salaam" },
        24: { id: "provinces.TZ.24", defaultMessage: "Tabora" },
        "03": { id: "provinces.TZ.03", defaultMessage: "Dodoma" },
        25: { id: "provinces.TZ.25", defaultMessage: "Tanga" },
        "04": { id: "provinces.TZ.04", defaultMessage: "Iringa" },
        26: { id: "provinces.TZ.26", defaultMessage: "Manyara" },
        "05": { id: "provinces.TZ.05", defaultMessage: "Kagera" },
        "06": { id: "provinces.TZ.06", defaultMessage: "Kaskazini Pemba" },
        "07": { id: "provinces.TZ.07", defaultMessage: "Kaskazini Unguja" },
        "08": { id: "provinces.TZ.08", defaultMessage: "Kigoma" },
        "09": { id: "provinces.TZ.09", defaultMessage: "Kilimanjaro" },
        10: { id: "provinces.TZ.10", defaultMessage: "Kusini Pemba" },
        11: { id: "provinces.TZ.11", defaultMessage: "Kusini Unguja" },
        12: { id: "provinces.TZ.12", defaultMessage: "Lindi" },
        13: { id: "provinces.TZ.13", defaultMessage: "Mara" },
        14: { id: "provinces.TZ.14", defaultMessage: "Mbeya" },
        15: { id: "provinces.TZ.15", defaultMessage: "Mjini Magharibi" },
    }),
    UG: defineMessages({
        210: { id: "provinces.UG.210", defaultMessage: "Pallisa" },
        407: { id: "provinces.UG.407", defaultMessage: "Kibaale" },
        211: { id: "provinces.UG.211", defaultMessage: "Soroti" },
        408: { id: "provinces.UG.408", defaultMessage: "Kisoro" },
        212: { id: "provinces.UG.212", defaultMessage: "Tororo" },
        409: { id: "provinces.UG.409", defaultMessage: "Masindi" },
        213: { id: "provinces.UG.213", defaultMessage: "Kaberamaido" },
        410: { id: "provinces.UG.410", defaultMessage: "Mbarara" },
        214: { id: "provinces.UG.214", defaultMessage: "Mayuge" },
        411: { id: "provinces.UG.411", defaultMessage: "Ntungamo" },
        215: { id: "provinces.UG.215", defaultMessage: "Sironko" },
        412: { id: "provinces.UG.412", defaultMessage: "Rukungiri" },
        216: { id: "provinces.UG.216", defaultMessage: "Amuria" },
        413: { id: "provinces.UG.413", defaultMessage: "Kamwenge" },
        217: { id: "provinces.UG.217", defaultMessage: "Budaka" },
        414: { id: "provinces.UG.414", defaultMessage: "Kanungu" },
        218: { id: "provinces.UG.218", defaultMessage: "Bukwa" },
        415: { id: "provinces.UG.415", defaultMessage: "Kyenjojo" },
        219: { id: "provinces.UG.219", defaultMessage: "Butaleja" },
        416: { id: "provinces.UG.416", defaultMessage: "Ibanda" },
        220: { id: "provinces.UG.220", defaultMessage: "Kaliro" },
        417: { id: "provinces.UG.417", defaultMessage: "Isingiro" },
        221: { id: "provinces.UG.221", defaultMessage: "Manafwa" },
        418: { id: "provinces.UG.418", defaultMessage: "Kiruhura" },
        222: { id: "provinces.UG.222", defaultMessage: "Namutumba" },
        419: { id: "provinces.UG.419", defaultMessage: "Buliisa" },
        223: { id: "provinces.UG.223", defaultMessage: "Bududa" },
        C: { id: "provinces.UG.C", defaultMessage: "Central" },
        224: { id: "provinces.UG.224", defaultMessage: "Bukedea" },
        E: { id: "provinces.UG.E", defaultMessage: "Eastern" },
        301: { id: "provinces.UG.301", defaultMessage: "Adjumani" },
        N: { id: "provinces.UG.N", defaultMessage: "Northern" },
        302: { id: "provinces.UG.302", defaultMessage: "Apac" },
        W: { id: "provinces.UG.W", defaultMessage: "Western" },
        101: { id: "provinces.UG.101", defaultMessage: "Kalangala" },
        303: { id: "provinces.UG.303", defaultMessage: "Arua" },
        102: { id: "provinces.UG.102", defaultMessage: "Kampala" },
        304: { id: "provinces.UG.304", defaultMessage: "Gulu" },
        103: { id: "provinces.UG.103", defaultMessage: "Kiboga" },
        305: { id: "provinces.UG.305", defaultMessage: "Kitgum" },
        306: { id: "provinces.UG.306", defaultMessage: "Kotido" },
        104: { id: "provinces.UG.104", defaultMessage: "Luwero" },
        105: { id: "provinces.UG.105", defaultMessage: "Masaka" },
        307: { id: "provinces.UG.307", defaultMessage: "Lira" },
        106: { id: "provinces.UG.106", defaultMessage: "Mpigi" },
        308: { id: "provinces.UG.308", defaultMessage: "Moroto" },
        107: { id: "provinces.UG.107", defaultMessage: "Mubende" },
        309: { id: "provinces.UG.309", defaultMessage: "Moyo" },
        108: { id: "provinces.UG.108", defaultMessage: "Mukono" },
        310: { id: "provinces.UG.310", defaultMessage: "Nebbi" },
        109: { id: "provinces.UG.109", defaultMessage: "Nakasongola" },
        311: { id: "provinces.UG.311", defaultMessage: "Nakapiripirit" },
        110: { id: "provinces.UG.110", defaultMessage: "Rakai" },
        312: { id: "provinces.UG.312", defaultMessage: "Pader" },
        111: { id: "provinces.UG.111", defaultMessage: "Sembabule" },
        313: { id: "provinces.UG.313", defaultMessage: "Yumbe" },
        112: { id: "provinces.UG.112", defaultMessage: "Kayunga" },
        314: { id: "provinces.UG.314", defaultMessage: "Amolatar" },
        113: { id: "provinces.UG.113", defaultMessage: "Wakiso" },
        315: { id: "provinces.UG.315", defaultMessage: "Kaabong" },
        114: { id: "provinces.UG.114", defaultMessage: "Mityana" },
        316: { id: "provinces.UG.316", defaultMessage: "Koboko" },
        115: { id: "provinces.UG.115", defaultMessage: "Nakaseke" },
        317: { id: "provinces.UG.317", defaultMessage: "Abim" },
        116: { id: "provinces.UG.116", defaultMessage: "Lyantonde" },
        318: { id: "provinces.UG.318", defaultMessage: "Dokolo" },
        201: { id: "provinces.UG.201", defaultMessage: "Bugiri" },
        319: { id: "provinces.UG.319", defaultMessage: "Amuru" },
        202: { id: "provinces.UG.202", defaultMessage: "Busia" },
        320: { id: "provinces.UG.320", defaultMessage: "Maracha" },
        203: { id: "provinces.UG.203", defaultMessage: "Iganga" },
        321: { id: "provinces.UG.321", defaultMessage: "Oyam" },
        204: { id: "provinces.UG.204", defaultMessage: "Jinja" },
        401: { id: "provinces.UG.401", defaultMessage: "Bundibugyo" },
        205: { id: "provinces.UG.205", defaultMessage: "Kamuli" },
        402: { id: "provinces.UG.402", defaultMessage: "Bushenyi" },
        206: { id: "provinces.UG.206", defaultMessage: "Kapchorwa" },
        403: { id: "provinces.UG.403", defaultMessage: "Hoima" },
        207: { id: "provinces.UG.207", defaultMessage: "Katakwi" },
        404: { id: "provinces.UG.404", defaultMessage: "Kabale" },
        208: { id: "provinces.UG.208", defaultMessage: "Kumi" },
        405: { id: "provinces.UG.405", defaultMessage: "Kabarole" },
        406: { id: "provinces.UG.406", defaultMessage: "Kasese" },
        209: { id: "provinces.UG.209", defaultMessage: "Mbale" },
    }),
    UA: defineMessages({
        32: { id: "provinces.UA.32", defaultMessage: "Kyïvs'ka Oblast'" },
        35: { id: "provinces.UA.35", defaultMessage: "Kirovohrads'ka Oblast'" },
        40: { id: "provinces.UA.40", defaultMessage: "Sevastopol" },
        43: { id: "provinces.UA.43", defaultMessage: "Respublika Krym" },
        46: { id: "provinces.UA.46", defaultMessage: "L'vivs'ka Oblast'" },
        48: { id: "provinces.UA.48", defaultMessage: "Mykolaïvs'ka Oblast'" },
        51: { id: "provinces.UA.51", defaultMessage: "Odes'ka Oblast'" },
        53: { id: "provinces.UA.53", defaultMessage: "Poltavs'ka Oblast'" },
        56: { id: "provinces.UA.56", defaultMessage: "Rivnens'ka Oblast'" },
        59: { id: "provinces.UA.59", defaultMessage: "Sums 'ka Oblast'" },
        61: { id: "provinces.UA.61", defaultMessage: "Ternopil's'ka Oblast'" },
        "05": { id: "provinces.UA.05", defaultMessage: "Vinnyts'ka Oblast'" },
        63: { id: "provinces.UA.63", defaultMessage: "Kharkivs'ka Oblast'" },
        "07": { id: "provinces.UA.07", defaultMessage: "Volyns'ka Oblast'" },
        65: { id: "provinces.UA.65", defaultMessage: "Khersons'ka Oblast'" },
        "09": { id: "provinces.UA.09", defaultMessage: "Luhans'ka Oblast'" },
        68: { id: "provinces.UA.68", defaultMessage: "Khmel'nyts'ka Oblast'" },
        12: { id: "provinces.UA.12", defaultMessage: "Dnipropetrovs'ka Oblast'" },
        71: { id: "provinces.UA.71", defaultMessage: "Cherkas'ka Oblast'" },
        14: { id: "provinces.UA.14", defaultMessage: "Donets'ka Oblast'" },
        74: { id: "provinces.UA.74", defaultMessage: "Chernihivs'ka Oblast'" },
        18: { id: "provinces.UA.18", defaultMessage: "Zhytomyrs'ka Oblast'" },
        77: { id: "provinces.UA.77", defaultMessage: "Chernivets'ka Oblast'" },
        21: { id: "provinces.UA.21", defaultMessage: "Zakarpats'ka Oblast'" },
        23: { id: "provinces.UA.23", defaultMessage: "Zaporiz'ka Oblast'" },
        26: { id: "provinces.UA.26", defaultMessage: "Ivano-Frankivs'ka Oblast'" },
        30: { id: "provinces.UA.30", defaultMessage: "Kyïvs'ka mis'ka rada" },
    }),
    UM: defineMessages({
        81: { id: "provinces.UM.81", defaultMessage: "Baker Island" },
        84: { id: "provinces.UM.84", defaultMessage: "Howland Island" },
        67: { id: "provinces.UM.67", defaultMessage: "Johnston Atoll" },
        86: { id: "provinces.UM.86", defaultMessage: "Jarvis Island" },
        71: { id: "provinces.UM.71", defaultMessage: "Midway Islands" },
        89: { id: "provinces.UM.89", defaultMessage: "Kingman Reef" },
        76: { id: "provinces.UM.76", defaultMessage: "Navassa Island" },
        95: { id: "provinces.UM.95", defaultMessage: "Palmyra Atoll" },
        79: { id: "provinces.UM.79", defaultMessage: "Wake Island" },
    }),
    UY: defineMessages({
        CA: { id: "provinces.UY.CA", defaultMessage: "Canelones" },
        CL: { id: "provinces.UY.CL", defaultMessage: "Cerro Largo" },
        CO: { id: "provinces.UY.CO", defaultMessage: "Colonia" },
        DU: { id: "provinces.UY.DU", defaultMessage: "Durazno" },
        FD: { id: "provinces.UY.FD", defaultMessage: "Florida" },
        FS: { id: "provinces.UY.FS", defaultMessage: "Flores" },
        LA: { id: "provinces.UY.LA", defaultMessage: "Lavalleja" },
        MA: { id: "provinces.UY.MA", defaultMessage: "Maldonado" },
        MO: { id: "provinces.UY.MO", defaultMessage: "Montevideo" },
        PA: { id: "provinces.UY.PA", defaultMessage: "Paysandú" },
        RN: { id: "provinces.UY.RN", defaultMessage: "Río Negro" },
        RO: { id: "provinces.UY.RO", defaultMessage: "Rocha" },
        RV: { id: "provinces.UY.RV", defaultMessage: "Rivera" },
        SA: { id: "provinces.UY.SA", defaultMessage: "Salto" },
        SJ: { id: "provinces.UY.SJ", defaultMessage: "San José" },
        SO: { id: "provinces.UY.SO", defaultMessage: "Soriano" },
        TA: { id: "provinces.UY.TA", defaultMessage: "Tacuarembó" },
        TT: { id: "provinces.UY.TT", defaultMessage: "Treinta y Tres" },
        AR: { id: "provinces.UY.AR", defaultMessage: "Artigas" },
    }),
    US: defineMessages({
        NY: { id: "provinces.US.NY", defaultMessage: "New York" },
        IL: { id: "provinces.US.IL", defaultMessage: "Illinois" },
        OH: { id: "provinces.US.OH", defaultMessage: "Ohio" },
        IN: { id: "provinces.US.IN", defaultMessage: "Indiana" },
        OK: { id: "provinces.US.OK", defaultMessage: "Oklahoma" },
        KS: { id: "provinces.US.KS", defaultMessage: "Kansas" },
        OR: { id: "provinces.US.OR", defaultMessage: "Oregon" },
        KY: { id: "provinces.US.KY", defaultMessage: "Kentucky" },
        PA: { id: "provinces.US.PA", defaultMessage: "Pennsylvania" },
        LA: { id: "provinces.US.LA", defaultMessage: "Louisiana" },
        AK: { id: "provinces.US.AK", defaultMessage: "Alaska" },
        PR: { id: "provinces.US.PR", defaultMessage: "Puerto Rico" },
        MA: { id: "provinces.US.MA", defaultMessage: "Massachusetts" },
        AL: { id: "provinces.US.AL", defaultMessage: "Alabama" },
        RI: { id: "provinces.US.RI", defaultMessage: "Rhode Island" },
        MD: { id: "provinces.US.MD", defaultMessage: "Maryland" },
        AR: { id: "provinces.US.AR", defaultMessage: "Arkansas" },
        SC: { id: "provinces.US.SC", defaultMessage: "South Carolina" },
        ME: { id: "provinces.US.ME", defaultMessage: "Maine" },
        AS: { id: "provinces.US.AS", defaultMessage: "American Samoa" },
        SD: { id: "provinces.US.SD", defaultMessage: "South Dakota" },
        MI: { id: "provinces.US.MI", defaultMessage: "Michigan" },
        AZ: { id: "provinces.US.AZ", defaultMessage: "Arizona" },
        TN: { id: "provinces.US.TN", defaultMessage: "Tennessee" },
        MN: { id: "provinces.US.MN", defaultMessage: "Minnesota" },
        CA: { id: "provinces.US.CA", defaultMessage: "California" },
        TX: { id: "provinces.US.TX", defaultMessage: "Texas" },
        MO: { id: "provinces.US.MO", defaultMessage: "Missouri" },
        CO: { id: "provinces.US.CO", defaultMessage: "Colorado" },
        UM: { id: "provinces.US.UM", defaultMessage: "United States Minor Outlying Islands" },
        MP: { id: "provinces.US.MP", defaultMessage: "Northern Mariana Islands" },
        CT: { id: "provinces.US.CT", defaultMessage: "Connecticut" },
        UT: { id: "provinces.US.UT", defaultMessage: "Utah" },
        MS: { id: "provinces.US.MS", defaultMessage: "Mississippi" },
        DC: { id: "provinces.US.DC", defaultMessage: "District of Columbia" },
        VA: { id: "provinces.US.VA", defaultMessage: "Virginia" },
        MT: { id: "provinces.US.MT", defaultMessage: "Montana" },
        DE: { id: "provinces.US.DE", defaultMessage: "Delaware" },
        VI: { id: "provinces.US.VI", defaultMessage: "Virgin Islands" },
        NC: { id: "provinces.US.NC", defaultMessage: "North Carolina" },
        FL: { id: "provinces.US.FL", defaultMessage: "Florida" },
        VT: { id: "provinces.US.VT", defaultMessage: "Vermont" },
        ND: { id: "provinces.US.ND", defaultMessage: "North Dakota" },
        GA: { id: "provinces.US.GA", defaultMessage: "Georgia" },
        WA: { id: "provinces.US.WA", defaultMessage: "Washington" },
        NE: { id: "provinces.US.NE", defaultMessage: "Nebraska" },
        GU: { id: "provinces.US.GU", defaultMessage: "Guam" },
        WI: { id: "provinces.US.WI", defaultMessage: "Wisconsin" },
        NH: { id: "provinces.US.NH", defaultMessage: "New Hampshire" },
        HI: { id: "provinces.US.HI", defaultMessage: "Hawaii" },
        WV: { id: "provinces.US.WV", defaultMessage: "West Virginia" },
        NJ: { id: "provinces.US.NJ", defaultMessage: "New Jersey" },
        IA: { id: "provinces.US.IA", defaultMessage: "Iowa" },
        WY: { id: "provinces.US.WY", defaultMessage: "Wyoming" },
        NM: { id: "provinces.US.NM", defaultMessage: "New Mexico" },
        ID: { id: "provinces.US.ID", defaultMessage: "Idaho" },
        NV: { id: "provinces.US.NV", defaultMessage: "Nevada" },
    }),
    UZ: defineMessages({
        SA: { id: "provinces.UZ.SA", defaultMessage: "Samarqand" },
        JI: { id: "provinces.UZ.JI", defaultMessage: "Jizzax" },
        SI: { id: "provinces.UZ.SI", defaultMessage: "Sirdaryo" },
        NG: { id: "provinces.UZ.NG", defaultMessage: "Namangan" },
        SU: { id: "provinces.UZ.SU", defaultMessage: "Surxondaryo" },
        NW: { id: "provinces.UZ.NW", defaultMessage: "Navoiy" },
        AN: { id: "provinces.UZ.AN", defaultMessage: "Andijon" },
        TK: { id: "provinces.UZ.TK", defaultMessage: "Toshkent" },
        QA: { id: "provinces.UZ.QA", defaultMessage: "Qashqadaryo" },
        BU: { id: "provinces.UZ.BU", defaultMessage: "Buxoro" },
        TO: { id: "provinces.UZ.TO", defaultMessage: "Toshkent" },
        QR: { id: "provinces.UZ.QR", defaultMessage: "Qoraqalpog'iston Respublikasi" },
        FA: { id: "provinces.UZ.FA", defaultMessage: "Farg'ona" },
        XO: { id: "provinces.UZ.XO", defaultMessage: "Xorazm" },
    }),
    VA: defineMessages({}),
    VC: defineMessages({
        "06": { id: "provinces.VC.06", defaultMessage: "Grenadines" },
        "01": { id: "provinces.VC.01", defaultMessage: "Charlotte" },
        "02": { id: "provinces.VC.02", defaultMessage: "Saint Andrew" },
        "03": { id: "provinces.VC.03", defaultMessage: "Saint David" },
        "04": { id: "provinces.VC.04", defaultMessage: "Saint George" },
        "05": { id: "provinces.VC.05", defaultMessage: "Saint Patrick" },
    }),
    VE: defineMessages({
        E: { id: "provinces.VE.E", defaultMessage: "Barinas" },
        F: { id: "provinces.VE.F", defaultMessage: "Bolívar" },
        G: { id: "provinces.VE.G", defaultMessage: "Carabobo" },
        H: { id: "provinces.VE.H", defaultMessage: "Cojedes" },
        I: { id: "provinces.VE.I", defaultMessage: "Falcón" },
        J: { id: "provinces.VE.J", defaultMessage: "Guárico" },
        K: { id: "provinces.VE.K", defaultMessage: "Lara" },
        L: { id: "provinces.VE.L", defaultMessage: "Mérida" },
        M: { id: "provinces.VE.M", defaultMessage: "Miranda" },
        N: { id: "provinces.VE.N", defaultMessage: "Monagas" },
        O: { id: "provinces.VE.O", defaultMessage: "Nueva Esparta" },
        P: { id: "provinces.VE.P", defaultMessage: "Portuguesa" },
        R: { id: "provinces.VE.R", defaultMessage: "Sucre" },
        S: { id: "provinces.VE.S", defaultMessage: "Táchira" },
        T: { id: "provinces.VE.T", defaultMessage: "Trujillo" },
        U: { id: "provinces.VE.U", defaultMessage: "Yaracuy" },
        V: { id: "provinces.VE.V", defaultMessage: "Zulia" },
        A: { id: "provinces.VE.A", defaultMessage: "Distrito Federal" },
        W: { id: "provinces.VE.W", defaultMessage: "Dependencias Federales" },
        B: { id: "provinces.VE.B", defaultMessage: "Anzoátegui" },
        X: { id: "provinces.VE.X", defaultMessage: "Vargas" },
        C: { id: "provinces.VE.C", defaultMessage: "Apure" },
        Y: { id: "provinces.VE.Y", defaultMessage: "Delta Amacuro" },
        D: { id: "provinces.VE.D", defaultMessage: "Aragua" },
        Z: { id: "provinces.VE.Z", defaultMessage: "Amazonas" },
    }),
    VG: defineMessages({}),
    VI: defineMessages({}),
    VN: defineMessages({
        "01": { id: "provinces.VN.01", defaultMessage: "Lai Châu" },
        53: { id: "provinces.VN.53", defaultMessage: "Bắc Kạn" },
        29: { id: "provinces.VN.29", defaultMessage: "Quảng Ngãi" },
        SG: { id: "provinces.VN.SG", defaultMessage: "Hồ Chí Minh [Sài Gòn]" },
        HP: { id: "provinces.VN.HP", defaultMessage: "Hải Phòng" },
        "02": { id: "provinces.VN.02", defaultMessage: "Lào Cai" },
        54: { id: "provinces.VN.54", defaultMessage: "Bắc Giang" },
        30: { id: "provinces.VN.30", defaultMessage: "Gia Lai" },
        "03": { id: "provinces.VN.03", defaultMessage: "Hà Giang" },
        55: { id: "provinces.VN.55", defaultMessage: "Bạc Liêu" },
        31: { id: "provinces.VN.31", defaultMessage: "Bình Định" },
        "04": { id: "provinces.VN.04", defaultMessage: "Cao Bằng" },
        56: { id: "provinces.VN.56", defaultMessage: "Bắc Ninh" },
        32: { id: "provinces.VN.32", defaultMessage: "Phú Yên" },
        "05": { id: "provinces.VN.05", defaultMessage: "Sơn La" },
        57: { id: "provinces.VN.57", defaultMessage: "Bình Dương" },
        33: { id: "provinces.VN.33", defaultMessage: "Đắc Lắk" },
        "06": { id: "provinces.VN.06", defaultMessage: "Yên Bái" },
        58: { id: "provinces.VN.58", defaultMessage: "Bình Phước" },
        34: { id: "provinces.VN.34", defaultMessage: "Khánh Hòa" },
        "07": { id: "provinces.VN.07", defaultMessage: "Tuyên Quang" },
        59: { id: "provinces.VN.59", defaultMessage: "Cà Mau" },
        35: { id: "provinces.VN.35", defaultMessage: "Lâm Đồng" },
        "09": { id: "provinces.VN.09", defaultMessage: "Lạng Sơn" },
        61: { id: "provinces.VN.61", defaultMessage: "Hải Duong" },
        36: { id: "provinces.VN.36", defaultMessage: "Ninh Thuận" },
        13: { id: "provinces.VN.13", defaultMessage: "Quảng Ninh" },
        63: { id: "provinces.VN.63", defaultMessage: "Hà Nam" },
        37: { id: "provinces.VN.37", defaultMessage: "Tây Ninh" },
        14: { id: "provinces.VN.14", defaultMessage: "Hoà Bình" },
        66: { id: "provinces.VN.66", defaultMessage: "Hưng Yên" },
        39: { id: "provinces.VN.39", defaultMessage: "Đồng Nai" },
        15: { id: "provinces.VN.15", defaultMessage: "Hà Tây" },
        67: { id: "provinces.VN.67", defaultMessage: "Nam Định" },
        40: { id: "provinces.VN.40", defaultMessage: "Bình Thuận" },
        18: { id: "provinces.VN.18", defaultMessage: "Ninh Bình" },
        68: { id: "provinces.VN.68", defaultMessage: "Phú Thọ" },
        41: { id: "provinces.VN.41", defaultMessage: "Long An" },
        20: { id: "provinces.VN.20", defaultMessage: "Thái Bình" },
        69: { id: "provinces.VN.69", defaultMessage: "Thái Nguyên" },
        43: { id: "provinces.VN.43", defaultMessage: "Bà Rịa-Vũng Tàu" },
        21: { id: "provinces.VN.21", defaultMessage: "Thanh Hóa" },
        70: { id: "provinces.VN.70", defaultMessage: "Vĩnh Phúc" },
        44: { id: "provinces.VN.44", defaultMessage: "An Giang" },
        22: { id: "provinces.VN.22", defaultMessage: "Nghệ An" },
        71: { id: "provinces.VN.71", defaultMessage: "Điện Biên" },
        45: { id: "provinces.VN.45", defaultMessage: "Đồng Tháp" },
        23: { id: "provinces.VN.23", defaultMessage: "Hà Tỉnh" },
        72: { id: "provinces.VN.72", defaultMessage: "Đắk Nông" },
        46: { id: "provinces.VN.46", defaultMessage: "Tiền Giang" },
        24: { id: "provinces.VN.24", defaultMessage: "Quảng Bình" },
        73: { id: "provinces.VN.73", defaultMessage: "Hậu Giang" },
        47: { id: "provinces.VN.47", defaultMessage: "Kiên Giang" },
        25: { id: "provinces.VN.25", defaultMessage: "Quảng Trị" },
        CT: { id: "provinces.VN.CT", defaultMessage: "Cần Thơ" },
        49: { id: "provinces.VN.49", defaultMessage: "Vĩnh Long" },
        26: { id: "provinces.VN.26", defaultMessage: "Thừa Thiên-Huế" },
        DN: { id: "provinces.VN.DN", defaultMessage: "Đà Nẵng" },
        50: { id: "provinces.VN.50", defaultMessage: "Bến Tre" },
        27: { id: "provinces.VN.27", defaultMessage: "Quảng Nam" },
        HN: { id: "provinces.VN.HN", defaultMessage: "Hà Nội" },
        51: { id: "provinces.VN.51", defaultMessage: "Trà Vinh" },
        28: { id: "provinces.VN.28", defaultMessage: "Kon Tum" },
        52: { id: "provinces.VN.52", defaultMessage: "Sóc Trăng" },
    }),
    VU: defineMessages({
        TAE: { id: "provinces.VU.TAE", defaultMessage: "Taféa" },
        TOB: { id: "provinces.VU.TOB", defaultMessage: "Torba" },
        MAP: { id: "provinces.VU.MAP", defaultMessage: "Malampa" },
        PAM: { id: "provinces.VU.PAM", defaultMessage: "Pénama" },
        SAM: { id: "provinces.VU.SAM", defaultMessage: "Sanma" },
        SEE: { id: "provinces.VU.SEE", defaultMessage: "Shéfa" },
    }),
    WF: defineMessages({}),
    WS: defineMessages({
        FA: { id: "provinces.WS.FA", defaultMessage: "Fa'asaleleaga" },
        VF: { id: "provinces.WS.VF", defaultMessage: "Va'a-o-Fonoti" },
        GE: { id: "provinces.WS.GE", defaultMessage: "Gaga'emauga" },
        VS: { id: "provinces.WS.VS", defaultMessage: "Vaisigano" },
        GI: { id: "provinces.WS.GI", defaultMessage: "Gagaifomauga" },
        AA: { id: "provinces.WS.AA", defaultMessage: "A'ana" },
        PA: { id: "provinces.WS.PA", defaultMessage: "Palauli" },
        AL: { id: "provinces.WS.AL", defaultMessage: "Aiga-i-le-Tai" },
        SA: { id: "provinces.WS.SA", defaultMessage: "Satupa'itea" },
        AT: { id: "provinces.WS.AT", defaultMessage: "Atua" },
        TU: { id: "provinces.WS.TU", defaultMessage: "Tuamasaga" },
    }),
    YE: defineMessages({
        BA: { id: "provinces.YE.BA", defaultMessage: "Al Bayḑā'" },
        DA: { id: "provinces.YE.DA", defaultMessage: "Aḑ Ḑāli‘" },
        DH: { id: "provinces.YE.DH", defaultMessage: "Dhamār" },
        HD: { id: "provinces.YE.HD", defaultMessage: "Ḩaḑramawt" },
        HJ: { id: "provinces.YE.HJ", defaultMessage: "Ḩajjah" },
        IB: { id: "provinces.YE.IB", defaultMessage: "Ibb" },
        JA: { id: "provinces.YE.JA", defaultMessage: "Al Jawf" },
        LA: { id: "provinces.YE.LA", defaultMessage: "Laḩij" },
        MA: { id: "provinces.YE.MA", defaultMessage: "Ma'rib" },
        MR: { id: "provinces.YE.MR", defaultMessage: "Al Mahrah" },
        MU: { id: "provinces.YE.MU", defaultMessage: "Al Ḩudaydah" },
        MW: { id: "provinces.YE.MW", defaultMessage: "Al Maḩwīt" },
        RA: { id: "provinces.YE.RA", defaultMessage: "Raymah" },
        SD: { id: "provinces.YE.SD", defaultMessage: "Şa'dah" },
        SH: { id: "provinces.YE.SH", defaultMessage: "Shabwah" },
        SN: { id: "provinces.YE.SN", defaultMessage: "Şan'ā'" },
        TA: { id: "provinces.YE.TA", defaultMessage: "Tā'izz" },
        AB: { id: "provinces.YE.AB", defaultMessage: "Abyān" },
        AD: { id: "provinces.YE.AD", defaultMessage: "'Adan" },
        AM: { id: "provinces.YE.AM", defaultMessage: "'Amrān" },
    }),
    ZA: defineMessages({
        MP: { id: "provinces.ZA.MP", defaultMessage: "Mpumalanga" },
        EC: { id: "provinces.ZA.EC", defaultMessage: "Eastern Cape" },
        NC: { id: "provinces.ZA.NC", defaultMessage: "Northern Cape" },
        FS: { id: "provinces.ZA.FS", defaultMessage: "Free State" },
        NL: { id: "provinces.ZA.NL", defaultMessage: "Kwazulu-Natal" },
        GT: { id: "provinces.ZA.GT", defaultMessage: "Gauteng" },
        NW: { id: "provinces.ZA.NW", defaultMessage: "North-West (South Africa)" },
        LP: { id: "provinces.ZA.LP", defaultMessage: "Limpopo" },
        WC: { id: "provinces.ZA.WC", defaultMessage: "Western Cape" },
    }),
    ZM: defineMessages({
        "06": { id: "provinces.ZM.06", defaultMessage: "North-Western" },
        "01": { id: "provinces.ZM.01", defaultMessage: "Western" },
        "07": { id: "provinces.ZM.07", defaultMessage: "Southern (Zambia)" },
        "02": { id: "provinces.ZM.02", defaultMessage: "Central" },
        "08": { id: "provinces.ZM.08", defaultMessage: "Copperbelt" },
        "03": { id: "provinces.ZM.03", defaultMessage: "Eastern" },
        "09": { id: "provinces.ZM.09", defaultMessage: "Lusaka" },
        "04": { id: "provinces.ZM.04", defaultMessage: "Luapula" },
        "05": { id: "provinces.ZM.05", defaultMessage: "Northern" },
    }),
    ZW: defineMessages({
        MS: { id: "provinces.ZW.MS", defaultMessage: "Matabeleland South" },
        MA: { id: "provinces.ZW.MA", defaultMessage: "Manicaland" },
        MV: { id: "provinces.ZW.MV", defaultMessage: "Masvingo" },
        MC: { id: "provinces.ZW.MC", defaultMessage: "Mashonaland Central" },
        MW: { id: "provinces.ZW.MW", defaultMessage: "Mashonaland West" },
        ME: { id: "provinces.ZW.ME", defaultMessage: "Mashonaland East" },
        MI: { id: "provinces.ZW.MI", defaultMessage: "Midlands" },
        BU: { id: "provinces.ZW.BU", defaultMessage: "Bulawayo" },
        MN: { id: "provinces.ZW.MN", defaultMessage: "Matabeleland North" },
        HA: { id: "provinces.ZW.HA", defaultMessage: "Harare" },
    }),
};

export default Provinces;
