import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

/* eslint-disable import/prefer-default-export */
export const baseApi = createApi({
    reducerPath: "baseApi",
    baseQuery: fetchBaseQuery({
        mode: "same-origin",
        prepareHeaders: (headers) => {
            headers.set("x-csrftoken", Cookies.get("csrftoken"));
            return headers;
        },
    }),
    endpoints: () => ({}),
});
