import { useDispatch, useSelector, type DefaultRootState } from "react-redux";

import type { AppDispatch } from "app/store/types";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export function useAppSelector<T>(
    selector: (state: DefaultRootState) => T,
    equalityFn?: (left: T, right: T) => boolean
) {
    return useSelector<DefaultRootState, T>(selector, equalityFn);
}
