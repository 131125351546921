import { memo, useEffect, useMemo, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { enCA } from "date-fns/esm/locale";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import { supportedLocales } from "utilities/localization";
import { useAppDispatch, useAppSelector } from "hooks/app";
import { switchLocale } from "app/store/locale-reducer";

const LocalizedRouter: React.FC = memo(({ children }) => {
    const dispatch = useAppDispatch();
    const { language, locale } = useAppSelector((state) => state.appLocale);

    const [dateFnsLocale, setDateFnsLocale] = useState<Locale>(null);
    const rootPath = useMemo(() => `/${language}`, [language]);

    useEffect(() => {
        (async () => {
            if (locale != null) {
                await dispatch(switchLocale(locale));

                await import(
                    /* webpackMode: "lazy-once" */
                    `date-fns/locale/${locale}/index.js`
                )
                    .then((fnsLocale) => setDateFnsLocale(fnsLocale))
                    .catch(() => setDateFnsLocale(enCA));
            }
        })();
    }, [locale, dispatch]);

    return (
        <BrowserRouter keyLength={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateFnsLocale}>
                <Switch>
                    <Route path={rootPath}>{children}</Route>
                    <Route
                        strict
                        path="/:lang([a-z]{2})(/*)?"
                        render={({ match, location }) => {
                            // This route is only taken if the locale used initially did not match the current locale of the app
                            const { lang } = match.params;
                            if (lang in supportedLocales) {
                                // If we get to this point, then the locale of the url is not the one
                                // currently in use, so we switch to the one the user selected (if possible. See above implementation)
                                // TODO: This has not been tested properly
                                dispatch(switchLocale(lang));
                                return null;
                            }
                            return (
                                <Redirect
                                    to={{
                                        ...location,
                                        pathname: [rootPath, ...location.pathname.split("/").slice(2)].join("/"),
                                    }}
                                />
                            );
                        }}
                    />
                    <Route
                        exact
                        path="/*"
                        render={({ location }) => (
                            <Redirect
                                to={{
                                    ...location,
                                    pathname: rootPath + location.pathname,
                                }}
                            />
                        )}
                    />
                </Switch>
            </LocalizationProvider>
        </BrowserRouter>
    );
});

export default LocalizedRouter;
