import { ApolloClient, InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";

export const client = new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === "development",
    link: createUploadLink({
        uri: "/graphql/",
        credentials: "include",
    }),
    cache: new InMemoryCache({
        typePolicies: {
            ClientNode: {
                fields: {
                    isClient: {
                        read() {
                            return true;
                        },
                    },
                    isPractitioner: {
                        read() {
                            return false;
                        },
                    },
                },
            },
            PractitionerNode: {
                fields: {
                    isClient: {
                        read() {
                            return false;
                        },
                    },
                    isPractitioner: {
                        read() {
                            return true;
                        },
                    },
                },
            },
            Query: {
                fields: {
                    consultations: relayStylePagination([
                        "status",
                        "status_In",
                        "order",
                        "requestedSchedule_End_Lt",
                        "requestedSchedule_End_Gte",
                    ]),
                    practitioners: relayStylePagination([]),
                    // schedules: relayStylePagination(["$start", "$end", "@connection", ["key"]]),
                    schedules: relayStylePagination([
                        "searchQuery",
                        "specialties",
                        "languages",
                        "practitioner",
                        "start_Gte",
                        "end_Lt",
                    ]),
                    payments: relayStylePagination(["status", "order", "timestamp_Gte", "timestamp_Lt"]),
                    tags: relayStylePagination(["type"]),
                },
            },
        },
    }),
});

export type ApolloAppCache = typeof client.cache;
export type ApolloAppClient = typeof client;
