import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { isEmpty } from "lodash-es";
import { useIntl } from "react-intl";
import { minutesToMilliseconds } from "date-fns";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import clsx from "clsx";
import IdleTimer, { type IdleTimerAPI } from "react-idle-timer";
import { toast } from "react-toastify";
import { useMachine, useSelector as useMachineSelector } from "@xstate/react";
import { type InvokeCreator } from "xstate";
import { onAuthStateChanged } from "firebase/auth";

import { USER_IDLE_TIMEOUT_MINUTES } from "app/constants";
import { GET_USER } from "api/graphql";
import { userSignOut, userUpdate } from "app/store/user-reducer";
import { useAppDispatch, useAppSelector } from "hooks/app";
import { AuthenticationContext } from "hooks/auth";
import AuthModal from "components/auth/AuthModal";
import Toasts from "utilities/toasts";
import { firebaseAuth } from "auth/firebase/firebase";
import { client } from "app/apollo";
import { store } from "app/store";
import appDebug from "utilities/logging";

import authMachine, { AUTH_END_REASON, type AuthContext, type AuthEvent } from "./auth-machine";

const logger = appDebug.extend("authProvider");

const attemptBackgroundSignin: InvokeCreator<AuthContext, AuthEvent> = async ({ isFirebaseAuthReady }) => {
    if (!isFirebaseAuthReady) {
        throw new Error("Missing firebase auth");
    }
    const { data: { me = {} } = {} } = await client.query({
        query: GET_USER,
    });
    if (!isEmpty(me)) {
        store.dispatch(userUpdate(me));
    } else {
        throw new Error("Error fetching the user");
    }
};

const idleTimeoutMillis = minutesToMilliseconds(USER_IDLE_TIMEOUT_MINUTES);

const AuthenticationProvider: React.FC = memo(({ children }) => {
    const [idleTimer, setIdleTimer] = useState<IdleTimerAPI>(null);
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.users.user);
    const resetErrorBoundaryHandler = useRef<FallbackProps["resetErrorBoundary"]>();
    const toastId = useRef(null);
    const isPrivateRoute = useAppSelector((state) => state.routes.isRouteProtected);

    const idleMonitor = useCallback<InvokeCreator<AuthContext, AuthEvent>>(
        () => (_, onReceive) => {
            onReceive((e) => {
                if (e.type === "START") {
                    idleTimer?.start();
                }
                if (e.type === "STOP") {
                    idleTimer?.pause();
                }
            });
        },
        [idleTimer]
    );

    const [current, send, service] = useMachine(authMachine, {
        devTools: process.env.NODE_ENV === "development",
        services: {
            attemptBackgroundSignin,
            idleMonitor,
        },
    });

    const isAuthenticating = useMachineSelector(service, (state) => state.context.isAuthenticating);
    const isShowingAuthModal = useMachineSelector(service, (state) => state.context.isShowingAuthModal);
    const isAuthenticated = useMachineSelector(service, (state) => state.matches("authenticated"));

    const handleCloseToast = useCallback(() => {
        if (toastId.current != null && toast.isActive(toastId.current)) {
            toast.dismiss(toastId.current);
        }
        toastId.current = null;
    }, []);

    const handleResetErrorBoundary = useCallback(() => {
        logger("Resetting error boundary...");
        resetErrorBoundaryHandler.current?.();
        resetErrorBoundaryHandler.current = null;
    }, []);

    const showAuthToast = useCallback(
        (message: string) => {
            handleCloseToast();
            toastId.current = Toasts.show(Toasts.types.SUCCESS, message, {
                position: Toasts.positions.TOP_RIGHT,
                delay: 300,
                autoClose: false,
                onClose: handleCloseToast,
            });
        },
        [handleCloseToast]
    );

    const handleIdleTimeout = useCallback(async () => {
        await dispatch(userSignOut());
    }, [dispatch]);

    const handleAuthSuccess = useCallback(() => {
        handleResetErrorBoundary();
        handleCloseToast();
    }, [handleCloseToast, handleResetErrorBoundary]);

    const handleUnauthorizedError = useCallback(
        async (error: Error) => {
            if (error.message === "UNAUTHORIZED") {
                await dispatch(userSignOut());
            } else {
                logger(error);
                throw error;
            }
        },
        [dispatch]
    );

    const authState = useMemo<React.ContextType<typeof AuthenticationContext>>(
        () => ({
            isAuthenticated,
            isAuthenticating,
            isShowingAuthModal,
            showAuthToast,
            handleUnauthorizedError,
        }),
        [isAuthenticated, isAuthenticating, isShowingAuthModal, showAuthToast, handleUnauthorizedError]
    );

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (currentUser) => {
            if (isEmpty(currentUser)) {
                send("firebaseMissing");
            } else {
                send("firebaseReady");
            }
        });
        return unsubscribe;
    }, [send]);

    useEffect(() => {
        send({ type: "locationChanged", requiresAuth: isPrivateRoute });
    }, [isPrivateRoute, send]);

    useEffect(() => {
        if (!isEmpty(user)) {
            if (!isAuthenticated) {
                send("authSuccess");
            }
        } else if (isAuthenticated) {
            if (idleTimer?.isIdle()) {
                send({ type: "authEnd", reason: AUTH_END_REASON.TIMEOUT });
            } else {
                send({ type: "authEnd", reason: AUTH_END_REASON.EXTERNAL });
            }
        }
    }, [current, idleTimer, isAuthenticated, send, user]);

    useEffect(() => {
        if (isShowingAuthModal) {
            if (current.context.authEndReason === AUTH_END_REASON.EXTERNAL) {
                showAuthToast(formatMessage({ defaultMessage: "You need to authenticate to view this content" }));
            } else if (current.context.authEndReason === AUTH_END_REASON.TIMEOUT) {
                showAuthToast(
                    formatMessage({ defaultMessage: "You've been logged out due to inactivity. Please login again" })
                );
            }
        }
        // TODO: Show a banner if no modal is showing...
    }, [current, formatMessage, isShowingAuthModal, showAuthToast]);

    return (
        <AuthenticationContext.Provider value={authState}>
            {isShowingAuthModal && <AuthModal onAuthSuccess={handleAuthSuccess} />}
            <div
                className={clsx({
                    "h-screen w-screen filter blur transition duration-1000 ease-in-out": isShowingAuthModal,
                })}
            >
                <ErrorBoundary
                    fallbackRender={({ error, resetErrorBoundary }) => {
                        resetErrorBoundaryHandler.current = resetErrorBoundary;
                        handleUnauthorizedError(error);
                        return null;
                    }}
                >
                    {children}
                </ErrorBoundary>
            </div>
            <IdleTimer
                ref={(ref) => setIdleTimer(ref)}
                timeout={idleTimeoutMillis}
                eventsThrottle={500}
                debounce={500}
                onIdle={handleIdleTimeout}
                startManually
                stopOnIdle
            />
        </AuthenticationContext.Provider>
    );
});

export default AuthenticationProvider;
