import React, { forwardRef, memo, useMemo } from "react";

import { Route, RouteChildrenProps, RouteComponentProps, RouteProps } from "react-router-dom";

interface RefForwardingRouteProps<R> extends RouteProps {
    ref?: React.Ref<R> | undefined;
    render?:
        | ((
              props: Parameters<RouteProps["render"]>[0],
              forwardedRef?: React.Ref<R>
          ) => ReturnType<RouteProps["render"]>)
        | undefined;
    children?:
        | ((props: RouteChildrenProps<any>, forwardedRef?: React.Ref<R>) => React.ReactNode)
        | React.ReactNode
        | undefined;
    component?: React.ComponentType<RouteComponentProps & { ref?: React.Ref<R> }> | undefined;
}

interface RefForwardingRouteType {
    <R>(props: RefForwardingRouteProps<R>): React.ReactElement<React.PropsWithRef<RefForwardingRouteProps<R>>>;
}

interface WithRouterAndRefComponentType {
    <T, R>(component: React.ComponentType<React.PropsWithRef<T>>, ref: React.Ref<R>): React.FC<React.PropsWithRef<T>>;
}

const withRouterAndRefComponent: WithRouterAndRefComponentType = (WrappedComponent, ref) => (props) =>
    <WrappedComponent ref={ref} {...props} />;

const RefForwardingRoute: RefForwardingRouteType = memo(
    forwardRef(({ render, children, component, ref: _ref, ...props }, forwardedRef) => {
        const routeChildren = useMemo(() => {
            if (React.isValidElement(children)) {
                if (React.Children.count(children) === 1) {
                    return React.cloneElement(children, {
                        ref: forwardedRef,
                        ...children.props,
                    });
                }
            }
            return children;
        }, [children, forwardedRef]);
        return (
            <Route
                {...props}
                render={render && ((renderProps) => render(renderProps, forwardedRef))}
                component={component && withRouterAndRefComponent(component, forwardedRef)}
            >
                {typeof children === "function"
                    ? (childrenProps) => children?.(childrenProps, forwardedRef)
                    : routeChildren}
            </Route>
        );
    })
);

export default RefForwardingRoute;
