import { StrictMode } from "react";

import { ThemeProvider } from "@mui/material/styles";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ApolloProvider } from "@apollo/client";
import ReactPixel from "react-facebook-pixel";

import { client as apolloClient } from "app/apollo";
import { AWSLexService, LexBotContext } from "providers/aws-lex";

import App from "./app/App";
import { store, persistor } from "./app/store";
import theme from "./theme";

// aws
const lexConfig = JSON.parse(process.env.REACT_APP_AWS_LEX_CONFIG);
const lexClientService = new AWSLexService(lexConfig);

if (process.env.NODE_ENV !== "development") {
    ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, null, { autoConfig: false, debug: false });
    ReactPixel.pageView();
}

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.25 : 0.05,
    denyUrls: [/https?:\/\/(www\.)?content-firebaseappcheck\.googleapis\.com/],
});

const addSentryTags: React.ComponentPropsWithRef<typeof Sentry.ErrorBoundary>["beforeCapture"] = (scope) => {
    scope.setTag("location", "app.main");
};

// WRAPPERS FOR APP
const Root = () => (
    <ApolloProvider client={apolloClient}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <LexBotContext.Provider value={lexClientService}>
                    <ThemeProvider theme={theme}>
                        <Sentry.ErrorBoundary
                            beforeCapture={addSentryTags}
                            showDialog={process.env.NODE_ENV === "production"}
                        >
                            <StrictMode>
                                <App />
                            </StrictMode>
                        </Sentry.ErrorBoundary>
                    </ThemeProvider>
                </LexBotContext.Provider>
            </PersistGate>
        </Provider>
    </ApolloProvider>
);

ReactDOM.render(<Root />, document.getElementById("root"));
