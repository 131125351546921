import { useCallback, useMemo } from "react";

import { LocationDescriptorObject } from "history";
import { useHistory, useLocation } from "react-router-dom";

import { RouteId } from "routes";
import timezone from "utilities/helpers/timezone";

import { useLocalizedRoutePath } from "./localization";

export type HistoryAction<T = {}> = (
    routeParam: RouteId | string,
    extra?: { params?: Record<string, string> } & Partial<Omit<LocationDescriptorObject<T>, "pathname">>
) => void;

export const useHistoryActions = <T = {}>() => {
    const history = useHistory<T>();
    const localizedRoutePath = useLocalizedRoutePath();

    const prepareRoute = useCallback(
        (routePath: string, params: Parameters<HistoryAction<T>>[1]["params"]) => localizedRoutePath(routePath, params),
        [localizedRoutePath]
    );

    const canGoBack = useCallback(() => history.length > 1, [history]);

    const push = useCallback<HistoryAction<T>>(
        (routeParam, { state, params, ...rest } = {}) =>
            history.push(
                {
                    pathname: prepareRoute(routeParam, params),
                    ...rest,
                },
                state
            ),
        [history, prepareRoute]
    );
    const replace = useCallback<HistoryAction<T>>(
        (routeParam, { state, params, ...rest } = {}) =>
            history.replace(
                {
                    pathname: prepareRoute(routeParam, params),
                    ...rest,
                },
                state
            ),
        [history, prepareRoute]
    );
    const pop = useCallback(() => {
        const popWillSucceed = canGoBack();
        history.goBack();
        return popWillSucceed;
    }, [canGoBack, history]);

    const result = useMemo(() => ({ push, replace, pop, canGoBack } as const), [push, replace, pop, canGoBack]);
    return result;
};

export const usePathStartsWith = () => {
    const location = useLocation();
    const localizedRoutePath = useLocalizedRoutePath();
    return useCallback(
        (route: RouteId) => location.pathname.startsWith(localizedRoutePath(route)),
        [location, localizedRoutePath]
    );
};

export const useTimeZone = () => useMemo(() => timezone(), []);

export const useQueryParams = () => {
    const location = useLocation();
    return useMemo(() => new URLSearchParams(location.search), [location]);
};
