import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import type { DefaultRootState } from "react-redux";

import type { ApiError, AuthenticatePayload } from "./types";

const axiosInstance = axios.create({
    baseURL: "/bpapi",
    method: "GET",
    headers: {
        "Content-Type": "application/json",
    },
    transformRequest: [
        (data) => {
            if (typeof data !== "string") {
                return JSON.stringify(data);
            }
            return data;
        },
        ...(Array.isArray(axios.defaults.transformRequest)
            ? axios.defaults.transformRequest
            : [axios.defaults.transformRequest]),
    ],
    responseType: "json",
    // timeout: 750, TODO BET-48: Investigate why this causes requests to timeout...when they don't even take as long
});

const marshallAxiosError = (error: Error) => {
    if (axios.isAxiosError(error)) {
        if (error.response) {
            return { error: { data: error.response.data, status: error.response.status } };
        }
        if (error.request as XMLHttpRequest) {
            return { error: { data: error.message, status: parseInt(error.code) } };
        }
    }

    return { error: { data: error.message, status: -1 } };
};

type Extras = { isAuthRetry?: boolean };
type AxiosBaseQuery<T> = BaseQueryFn<AxiosRequestConfig, T, ApiError, Extras>;

const axiosBaseQuery = <T>() => {
    const doQuery: AxiosBaseQuery<T> = async (args, api, { isAuthRetry = false } = {}) => {
        const {
            emr: { authorization },
            users: { firebaseUser },
        } = api.getState() as DefaultRootState;

        return axiosInstance
            .request<T>({
                ...args,
                ...(authorization.token
                    ? {
                          headers: {
                              Authorization: `Bearer ${authorization.token}`,
                          },
                      }
                    : {}),
            })
            .then(({ data }) => ({ data }))
            .catch(async (error: AxiosError<ApiError>) => {
                if (
                    !isAuthRetry &&
                    error.response?.status === 401 &&
                    error.response?.data.name === "NotAuthenticated"
                ) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    await emrApi.endpoints.authenticate.initiate(
                        {
                            email: firebaseUser.email,
                            password: firebaseUser.uid,
                            strategy: "local",
                        },
                        { track: false }
                    )(api.dispatch, api.getState, null);

                    // retry the query after attempting to authenticate
                    return doQuery(args, api, { isAuthRetry: true });
                }
                const errorResult = marshallAxiosError(error);
                return { error: errorResult.error.data };
            });
    };

    return doQuery;
};

const baseQuery = axiosBaseQuery();

export const emrApi = createApi({
    reducerPath: "emrApi",
    baseQuery,
    endpoints: (builder) => ({
        authenticate: builder.mutation({
            queryFn: async (payload: AuthenticatePayload) => {
                try {
                    const { data } = await axiosInstance.request<{ accessToken: string; user: { _id: string } }>({
                        url: "authentication",
                        method: "POST",
                        data: payload,
                    });
                    // eslint-disable-next-line no-underscore-dangle
                    return { data: { accessToken: data.accessToken, uid: data.user._id } };
                } catch (error) {
                    const result = marshallAxiosError(error);
                    return { error: result.error.data };
                }
            },
        }),
    }),
});

export const { useAuthenticateMutation } = emrApi;
