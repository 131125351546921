import { toast, ToastOptions } from "react-toastify";

const TYPES = {
    ERROR: "error",
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
} as const;

const POSITIONS = {
    TOP_LEFT: "top-left",
    TOP_RIGHT: "top-right",
    TOP_CENTER: "top-center",
    BOTTOM_LEFT: "bottom-left",
    BOTTOM_CENTER: "bottom-center",
    BOTTOM_RIGHT: "bottom-right",
} as const;

export type MessageType = typeof TYPES[keyof typeof TYPES];

const showMessage = (type: MessageType, message: any, params?: ToastOptions) =>
    toast[type](message, {
        position: POSITIONS.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        ...params,
    });

const Toasts = {
    types: TYPES,
    positions: POSITIONS,
    show: showMessage,
};

export default Toasts;
