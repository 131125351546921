import { createContext, useContext } from "react";

export { useFirebaseErrorHandler, useFirebaseAuth } from "./firebase";

interface AuthenticationState {
    readonly isAuthenticated: boolean;
    readonly isAuthenticating: boolean;
    readonly isShowingAuthModal: boolean;
    readonly showAuthToast: (message: string) => void;
    // readonly showAuthenticationModal: () => void;
    readonly handleUnauthorizedError: (error: Error) => void;
}

export const AuthenticationContext = createContext<AuthenticationState>({
    isAuthenticated: false,
    isAuthenticating: false,
    isShowingAuthModal: false,
    showAuthToast: () => {
        throw new Error("No Context provider in scope");
    },
    handleUnauthorizedError: () => {
        throw new Error("No Context provider in scope");
    },
});

export const useAuthentication = () => useContext(AuthenticationContext);
