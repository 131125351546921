import { useCallback, useDebugValue, useMemo, useState } from "react";

import { captureException } from "@sentry/react";
import {
    signInWithPopup,
    getRedirectResult,
    signInWithEmailAndPassword,
    linkWithPopup,
    fetchSignInMethodsForEmail,
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    GoogleAuthProvider,
    EmailAuthProvider,
    type Auth,
    type AuthError,
    type UserCredential,
} from "firebase/auth";
import { isEmpty } from "lodash-es";

import Toasts from "utilities/toasts";
import appDebug from "utilities/logging";
import { store } from "app/store";

export const useFirebaseErrorHandler = () =>
    useCallback((error: AuthError) => {
        Toasts.show(Toasts.types.ERROR, error.message);
        if (error.stack) {
            captureException(error.stack);
        }
    }, []);

interface FirebaseUser {
    uid?: string;
    displayName?: string;
    email?: string;
    phoneNumber?: string;
    photoURL?: string;
    emailVerified: boolean;
}

type ErrorHandler = (error: AuthError) => void;
type SocialHook = (errorHandler?: ErrorHandler) => Promise<boolean>;
type EmailPasswordHook = (email: string, password: string, errorHandler?: ErrorHandler) => Promise<boolean>;

interface FirebaseAuthHooks {
    signUpWithEmail: EmailPasswordHook;
    signUpWithFacebook: SocialHook;
    signUpWithGoogle: SocialHook;
    signInWithEmail: EmailPasswordHook;
    signInWithFacebook: SocialHook;
    signInWithGoogle: SocialHook;
    attemptFinishRedirect: SocialHook;
    linkWithFacebook: SocialHook;
    linkWithGoogle: SocialHook;
}

type FirebaseAuth = readonly [
    hooks: FirebaseAuthHooks,
    data: Readonly<{
        firebaseIdToken: string;
        firebaseUser: FirebaseUser;
        isAwaitingAuthentication: boolean;
        isAuthenticationSuccess: boolean;
    }>
];

const logger = appDebug.extend("useFirebaseAuth");

// https://lifelong-89bf9.firebaseapp.com/__/auth/handler
const signInWithEmail = (firebaseAuth: Auth, email: string, password: string) =>
    signInWithEmailAndPassword(firebaseAuth, email, password).catch(async (err: AuthError) => {
        const { formatMessage } = store.getState().appLocale.intl;
        if (err.code === "auth/wrong-password") {
            const signInMethods = await fetchSignInMethodsForEmail(firebaseAuth, email);
            if (signInMethods.length && !signInMethods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
                Toasts.show(
                    Toasts.types.WARNING,
                    formatMessage(
                        {
                            defaultMessage:
                                "No password is associated with this email. However, the email is associated with one of the following platforms: {options}",
                        },
                        {
                            options: signInMethods.map((p) => `"${p}"`).join(","),
                        }
                    ),
                    {
                        autoClose: false,
                        position: Toasts.positions.TOP_CENTER,
                    }
                );
            }
        }
        throw err;
    });
const signInWithFacebook = (firebaseAuth: Auth) =>
    signInWithPopup(firebaseAuth, new FacebookAuthProvider().addScope("email"));
const signInWithGoogle = (firebaseAuth: Auth) => signInWithPopup(firebaseAuth, new GoogleAuthProvider());
const signUpWithEmail = (firebaseAuth: Auth, email: string, password: string) =>
    createUserWithEmailAndPassword(firebaseAuth, email, password).catch(async (err: AuthError) => {
        if (err.code === "auth/email-already-in-use") {
            const signInMethods = await fetchSignInMethodsForEmail(firebaseAuth, email);
            if (signInMethods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
                // although we are allowing this, the backend may not allow it because of potential duplicate key error on the email
                return signInWithEmail(firebaseAuth, email, password);
            }
            // at this point, it is likely a social account, but throw error anyways...
        }
        throw err;
    });
const signUpWithFacebook = signInWithFacebook;
const signUpWithGoogle = signInWithGoogle;
const linkWithGoogle = (firebaseAuth: Auth) => linkWithPopup(firebaseAuth.currentUser, new GoogleAuthProvider());
const linkWithFacebook = (firebaseAuth: Auth) =>
    linkWithPopup(firebaseAuth.currentUser, new FacebookAuthProvider().addScope("email"));

export const useFirebaseAuth = (auth: Auth, defaultErrorHandler: ErrorHandler): FirebaseAuth => {
    const [firebaseIdToken, setFirebaseIdToken] = useState<string>(null);
    const [isAwaitingAuthentication, setIsAwaitingAuthentication] = useState<boolean>(undefined);
    const [isAuthenticationSuccess, setIsAuthenticationSuccess] = useState<boolean>(undefined);
    const [firebaseUser, setFirebaseUser] = useState<FirebaseUser>(null);

    const completeAuthAction = useCallback(
        async (pendingAction: Promise<UserCredential>, errorHandler?: ErrorHandler) => {
            let isAuthSuccess = false;
            if (isAwaitingAuthentication) {
                return isAuthSuccess;
            }

            setIsAwaitingAuthentication(true);
            const { user } = await pendingAction
                .then((arg) => {
                    logger("Auth result: %O", arg);

                    // in the off chance that a redirect is checked, arg may be null
                    isAuthSuccess = !isEmpty(arg?.user);
                    return { user: arg?.user };
                })
                .catch((error: AuthError) => {
                    logger("Auth failure: %O", error);

                    (errorHandler ?? defaultErrorHandler)?.(error);
                    isAuthSuccess = false;
                    return { user: null } as UserCredential;
                });

            if (user != null) {
                setFirebaseUser({
                    emailVerified: user.emailVerified,
                    displayName: user.displayName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    uid: user.uid,
                    photoURL: user.photoURL,
                });

                const token = await user.getIdToken();

                setFirebaseIdToken(token);
            } else {
                setFirebaseUser(null);
                setFirebaseIdToken(null);
            }

            setIsAwaitingAuthentication(false);
            setIsAuthenticationSuccess(isAuthSuccess);

            return isAuthSuccess;
        },
        [isAwaitingAuthentication, defaultErrorHandler]
    );

    useDebugValue(isAwaitingAuthentication ? "Awaiting authentication" : "Awaiting activation");
    useDebugValue(isAuthenticationSuccess ? "Authentication success" : "Authentication failure");

    const hooks: FirebaseAuthHooks = useMemo<FirebaseAuthHooks>(
        () => ({
            signUpWithEmail: (email: string, password: string, errorHandler?: ErrorHandler) =>
                completeAuthAction(signUpWithEmail(auth, email, password), errorHandler),
            signInWithEmail: (email: string, password: string, errorHandler?: ErrorHandler) =>
                completeAuthAction(signInWithEmail(auth, email, password), errorHandler),
            signUpWithFacebook: (errorHandler?: ErrorHandler) =>
                completeAuthAction(signUpWithFacebook(auth), errorHandler),
            signUpWithGoogle: (errorHandler?: ErrorHandler) => completeAuthAction(signUpWithGoogle(auth), errorHandler),
            signInWithFacebook: (errorHandler?: ErrorHandler) =>
                completeAuthAction(signInWithFacebook(auth), errorHandler),
            signInWithGoogle: (errorHandler?: ErrorHandler) => completeAuthAction(signInWithGoogle(auth), errorHandler),
            attemptFinishRedirect: (errorHandler?: ErrorHandler) =>
                completeAuthAction(getRedirectResult(auth), errorHandler),
            linkWithFacebook: (errorHandler?: ErrorHandler) => completeAuthAction(linkWithFacebook(auth), errorHandler),
            linkWithGoogle: (errorHandler?: ErrorHandler) => completeAuthAction(linkWithGoogle(auth), errorHandler),
        }),
        [auth, completeAuthAction]
    );

    return useMemo(
        () =>
            [
                hooks,
                {
                    firebaseIdToken,
                    firebaseUser,
                    isAwaitingAuthentication,
                    isAuthenticationSuccess,
                },
            ] as const,
        [hooks, firebaseIdToken, firebaseUser, isAwaitingAuthentication, isAuthenticationSuccess]
    );
};
