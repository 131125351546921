/* eslint-disable react/button-has-type */
// import Mic from '@mui/icons-material/MicNoneRounded';
import React from "react";

import { IActionProvider } from "./type-utils";

// eslint-disable-next-line arrow-body-style
const AiChatbotChatHeader: React.FC<IActionProvider> = () => {
    // const [isSpeaking, setIsSpeaking] = useState(false);
    // const [isAbleToRecordAudio, setIsAbleToRecordAudio] = useState(false);

    // const micButtonPressed = () => {
    //     actionProvider.handleUserSpeaking(!isSpeaking);
    //     setIsSpeaking(!isSpeaking);
    // }

    // useEffect(() => {
    //     setIsAbleToRecordAudio(navigator.mediaDevices?.getUserMedia !== null);
    // }, []);

    return (
        <div className="flex flex-col items-center justify-around py-2 h-16 bg-secondary-400 rounded-t">
            {/* <button
                type="button"
                className="rounded-full p-2 shadow"
                disabled={!isAbleToRecordAudio}
                onClick={micButtonPressed}>
                 <Mic />
            </button> */}
            <div
                className="flex items-center -mt-1 px-1 py-2 w-full text-indigo-100 leading-none bg-indigo-800 lg:inline-flex"
                role="alert"
            >
                <span className="flex mr-3 px-2 py-1 text-xl font-bold bg-red-500 rounded-full uppercase">
                    <button>
                        <i className="far fa-times-circle" />
                    </button>
                </span>
                <span className="flex-auto mr-2 text-center font-bold">Chat feature is still in testing</span>
            </div>
            <p className="text-center text-lg font-semibold">Chat with us!</p>
        </div>
    );
};

export default AiChatbotChatHeader;
