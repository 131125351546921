import { useState } from "react";

import HelpIcon from "@mui/icons-material/Help";
import PawIcon from "@mui/icons-material/Pets";
import HandshakeIcon from "@mui/icons-material/Handshake";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Article, YouTube } from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import LocalizedNavLink from "components/l10n/LocalizedNavLink";
import ROUTES from "routes";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        disableScrollLock
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[800],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
    "& .MuiBackdrop-root": {
        opacity: "0 !important",
    },
}));

// eslint-disable-next-line react/function-component-definition
export default function MoreMenu() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <nav onMouseEnter={handleClick}>
            <button
                type="button"
                aria-label="menu"
                id="menu"
                aria-controls="long-menu"
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                className="flex gap-x-1 hover:text-alpha text-gray-800 font-bold cursor-pointer transition duration-500 ease-in-out"
            >
                <FormattedMessage defaultMessage="More" />
                <div className="pl-0">
                    <i className="fas fa-caret-down" />
                </div>
            </button>
            <StyledMenu
                id="menu"
                MenuListProps={{
                    "aria-labelledby": "menu",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="mt-4"
            >
                <div onMouseLeave={handleClose}>
                    <LocalizedNavLink to={ROUTES.ABOUT} activeClassName="underline">
                        <MenuItem onClick={handleClose} disableRipple>
                            <p className="hover:text-alpha text-gray-800 font-bold cursor-pointer transition duration-500 ease-in-out">
                                <PawIcon />
                                <FormattedMessage defaultMessage="About Us" />
                            </p>
                        </MenuItem>
                    </LocalizedNavLink>

                    <LocalizedNavLink to={ROUTES.FAQ} activeClassName="underline">
                        <MenuItem onClick={handleClose} disableRipple>
                            <p className="hover:text-alpha text-gray-800 font-bold cursor-pointer transition duration-500 ease-in-out">
                                <HelpIcon />
                                <FormattedMessage defaultMessage="FAQ" />
                            </p>
                        </MenuItem>
                    </LocalizedNavLink>
                    <LocalizedNavLink to={ROUTES.PARTNERS} activeClassName="underline">
                        <MenuItem onClick={handleClose} disableRipple>
                            <p className="hover:text-alpha text-gray-800 font-bold cursor-pointer transition duration-500 ease-in-out">
                                <HandshakeIcon />
                                <FormattedMessage defaultMessage="Partners and Affiliates" />
                            </p>
                        </MenuItem>
                    </LocalizedNavLink>

                    <Link to={{ pathname: process.env.REACT_APP_BLOG_LINK }} target="_blank">
                        <MenuItem onClick={handleClose} disableRipple>
                            <p className="hover:text-alpha text-gray-800 font-bold cursor-pointer transition duration-500 ease-in-out">
                                <Article />
                                <FormattedMessage defaultMessage="Blog" />
                            </p>
                        </MenuItem>
                    </Link>

                    <Link to={{ pathname: process.env.REACT_APP_YOUTUBE_CHANNEL }} target="_blank">
                        <MenuItem onClick={handleClose} disableRipple>
                            <p className="hover:text-alpha text-gray-800 font-bold cursor-pointer transition duration-500 ease-in-out">
                                <YouTube />
                                <FormattedMessage defaultMessage="Videos" />
                            </p>
                        </MenuItem>
                    </Link>
                </div>
            </StyledMenu>
        </nav>
    );
}
