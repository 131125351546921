import { useContext, createContext } from "react";

import {
    LexRuntimeV2Client,
    Message,
    DeleteSessionCommand,
    RecognizeTextCommand,
    RecognizeUtteranceCommand,
} from "@aws-sdk/client-lex-runtime-v2";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { nanoid } from "nanoid";

import { ChatbotService } from "services/chatbot-service";
import timezone from "utilities/helpers/timezone";

interface AWSLexConfig {
    botAliasId: string;
    botId: string;
    localeId: string;
}

export class AWSLexService implements ChatbotService<Message[]> {
    private readonly lexClient: LexRuntimeV2Client;

    private readonly userTimezone: string;

    private readonly lexConfig: AWSLexConfig & { requestAttributes: Record<string, string> };

    private sessionId: string = null;

    constructor(lexConfig: AWSLexConfig) {
        this.lexClient = new LexRuntimeV2Client({
            region: process.env.REACT_APP_AWS_LEX_REGION,
            apiVersion: "latest",
            credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({ region: process.env.REACT_APP_AWS_LEX_REGION }),
                identityPoolId: process.env.REACT_APP_AWS_LEX_IDENTITY_POOL_ID,
            }),
        });
        this.userTimezone = timezone();

        this.lexConfig = {
            ...lexConfig,
            requestAttributes: {
                "x-amz-lex:time-zone": this.userTimezone,
            },
        };
    }

    initiateConversation(): void {
        this.sessionId = null;
    }

    async respondToText(text: string): Promise<Message[]> {
        try {
            if (this.sessionId == null) {
                this.sessionId = nanoid();
            }
            const resp = await this.lexClient.send(
                new RecognizeTextCommand({
                    ...this.lexConfig,
                    sessionId: this.sessionId,
                    text,
                })
            );

            return resp.messages;
        } catch (e) {
            // TODO: Handle this error
            return [];
        }
    }

    async respondToVoice(data: ArrayBuffer): Promise<Message[]> {
        const resp = await this.lexClient.send(
            new RecognizeUtteranceCommand({
                ...this.lexConfig,
                sessionId: this.sessionId,
                requestAttributes: `x-amz-lex:time-zone ${this.userTimezone}`,
                inputStream: new Uint8Array(data),
                requestContentType: "audio/l16; rate=16000; channels=1",
                responseContentType: "text/plain;charset=utf-8",
            })
        );
        return resp.messages as unknown as Message[];
    }

    async endConversation(): Promise<void> {
        if (this.sessionId != null) {
            const { sessionId } = this;
            // unset session id here to avoid race condition
            this.sessionId = null;
            try {
                await this.lexClient.send(
                    new DeleteSessionCommand({
                        ...this.lexConfig,
                        sessionId,
                    })
                );
            } catch {
                // TODO: Handle this error
            }
        }
    }

    isConversationStarted(): boolean {
        return this.sessionId != null;
    }
}

export const LexBotContext = createContext<ChatbotService<Message[]>>(null);

export const useLexService = () => useContext(LexBotContext);
