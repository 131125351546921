import { NavLink, NavLinkProps, useLocation } from "react-router-dom";

import { useLocalizedRoutePath } from "hooks/localization";

type LocalizedNavLinkProps = NavLinkProps;

const LocalizedNavLink: React.FC<LocalizedNavLinkProps> = ({ children, to, ...props }) => {
    const localizeRoutePath = useLocalizedRoutePath();
    const location = useLocation();
    let localizedTo;

    switch (typeof to) {
        case "function":
            localizedTo = localizeRoutePath(to(location));
            break;
        case "string":
        case "object":
            localizedTo = localizeRoutePath(to);
            break;
        default:
            localizedTo = to;
    }

    return (
        <NavLink to={localizedTo} {...props}>
            {children}
        </NavLink>
    );
};

export default LocalizedNavLink;
