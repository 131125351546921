// Importing BetterPaws Logo into the ChatbotAvater container
import LogoImg from "components/shared/svg/LogoSmall";

const Logo: React.FC = () => (
    <div className="px-1">
        <LogoImg baseClassName="opacity-0" />
    </div>
);

export default Logo;
