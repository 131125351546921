import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import { createMakeStyles, getTssDefaultEmotionCache } from "tss-react";

const theme = createTheme({
    palette: {
        primary: {
            main: "#009ce7",
            light: "#40baf5",
            dark: "#0185c4",
        },
        secondary: {
            main: "#fe6b9e",
            light: "#f8b4d9",
            dark: "#fa9bce",
        },
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    "&.MuiTableCell-head": {
                        fontWeight: 700,
                    },
                },
            },
        },
    },
});

// For some reason, was getting a warning that only applied to ssr react
// turn it off with: https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922
const cache = getTssDefaultEmotionCache();
cache.compat = true;

const defaultTheme = responsiveFontSizes(theme);
export default defaultTheme;
export const { makeStyles, useStyles } = createMakeStyles({ useTheme: () => defaultTheme });
