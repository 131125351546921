import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { firebaseAuth } from "auth/firebase/firebase";

interface PractitionerUser {
    __typename?: "PractitionerNode";
    isClient?: false;
    isPractitioner?: true;
    verified?: boolean;
    consultationDuration?: number;
    consultationPrice?: number;
    biography?: string;
    displayName?: string;
    practiceAddress?: string;
    practiceName?: string;
    practiceUrl?: string;
    yearsOfExperience?: number;
    private: {
        paymentEmail?: string;
        paypalMe?: string;
        taxNumber?: string;
    };
}

interface ClientUser {
    __typename?: "ClientNode";
    isClient?: true;
    isPractitioner?: false;
    dateOfBirth?: string;
    membershipStatus?: string;
}

interface BaseUser {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    // GRAPHQL...
    overwrite?: boolean;
    avatar?: string;
    pk?: string;
    country?: string;
    about?: string;
    language?: string;
    city?: string;
    province?: string;
    private?: {
        phoneNumber?: string;
        organization?: string;
    };
}

// eslint-disable-next-line import/no-unused-modules
export interface UserState {
    user: BaseUser & (ClientUser | PractitionerUser);
    firebaseUser: {
        uid?: string;
        displayName?: string;
        email?: string;
        phoneNumber?: string;
        photoURL?: string;
        emailVerified?: boolean;
    };
}

const initialState: UserState = {
    user: {},
    firebaseUser: {},
};

export const userSignOut = createAsyncThunk<void>("user/userSignOut", async () => firebaseAuth.signOut());

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        userSignIn: (state, action: PayloadAction<UserState["user"]>) => {
            const { __typename: gqlTypeName, overwrite, isClient, isPractitioner, ...data } = action.payload;
            state.user = { ...data };
            state.user.isClient = overwrite ? isClient : gqlTypeName === "ClientNode";
            state.user.isPractitioner = overwrite ? isPractitioner : gqlTypeName === "PractitionerNode";
            state.user.__typename = gqlTypeName;
        },

        userUpdate: (state, action: PayloadAction<UserState["user"]>) => {
            const { __typename: gqlTypeName, overwrite, isClient, isPractitioner, ...data } = action.payload;
            state.user = {
                ...state.user,
                ...data,
            };
            state.user.isClient = overwrite ? isClient : gqlTypeName === "ClientNode";
            state.user.isPractitioner = overwrite ? isPractitioner : gqlTypeName === "PractitionerNode";
            state.user.__typename = gqlTypeName;
        },

        firebaseUserUpdate: (state, action: PayloadAction<UserState["firebaseUser"]>) => {
            state.firebaseUser = { ...state.firebaseUser, ...action.payload };
        },
    },
    extraReducers: (builder) =>
        builder.addCase(userSignOut.fulfilled, (state) => {
            state.user = { ...initialState.user };
            state.firebaseUser = { ...initialState.firebaseUser };
        }),
});

export const { userSignIn, userUpdate, firebaseUserUpdate } = userSlice.actions;
export default userSlice.reducer;
