import { gql } from "@apollo/client";

export const GET_CONSULTATIONS_STATUSES_COUNTS = gql`
    query consultationsTotalCount {
        consultationsTotalCount {
            totalCount {
                confirmed
                completed
                cancelled
                conflicted
                awaitingPayment
                missed
            }
        }
    }
`;

export const GET_CONSULTATIONS = gql`
    query getConsultations(
        $status_In: [TelehealthConsultationStatusChoices]
        $before: DateTime
        $after: DateTime
        $cursor: String
        $status: TelehealthConsultationStatusChoices
        $order: String
    ) {
        consultations(
            status_In: $status_In
            requestedSchedule_End_Lt: $before
            requestedSchedule_End_Gte: $after
            after: $cursor
            status: $status
            order: $order
        ) {
            edges {
                node {
                    id
                    client {
                        id
                        avatar
                        firstName
                        lastName
                        province
                        country
                    }
                    createdAt
                    duration
                    notes
                    price
                    status
                    specialties
                    languages
                    appFee
                    practitionerFee
                    requestedSchedule {
                        end
                        id
                        practitioner {
                            avatar
                            displayName
                            id
                            consultationPrice
                            province
                            country
                        }
                        start
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;

export const GET_PAYMENTS = gql`
    query getPayments(
        $status: String
        $timestamp_Gte: DateTime
        $timestamp_Lt: DateTime
        $cursor: String
        $order: String
        $first: Int
    ) {
        payments(
            status: $status
            timestamp_Gte: $timestamp_Gte
            timestamp_Lt: $timestamp_Lt
            after: $cursor
            order: $order
            first: $first
        ) {
            edges {
                node {
                    id
                    amount
                    status
                    timestamp
                    consultation {
                        id
                        requestedSchedule {
                            id
                            practitioner {
                                id
                                displayName
                            }
                        }
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;

export const GET_CONFIG = gql`
    query getConfig {
        config {
            androidMinVersionCode
            avatarMaxBytes
            consultationBookingLeadTime
            consultationJoinLeadTime
            consultationPriceMax
            consultationPriceMin
            clientSignUpAvailable
            documentFeeTermsVersion
            documentPrivacyVersion
            documentTosVersion
            appFee
            appFeeType
        }
    }
`;

const PET_FRAGMENT = gql`
    fragment petFragment on PetNode {
        name
        age
        sex
        kind
        id
    }
`;

const CLIENT_FRAGMENT = gql`
    fragment clientFragment on ClientNode {
        about
        avatar
        city
        country
        dateOfBirth
        firstName
        id
        isClient @client
        isPractitioner @client
        language
        lastName
        province
        pk
        pets {
            edges {
                node {
                    ...petFragment
                }
            }
        }
    }
    ${PET_FRAGMENT}
`;

const PRACTITIONER_FRAGMENT = gql`
    fragment practitionerFragment on PractitionerNode {
        about
        avatar
        biography
        city
        consultationDuration
        consultationPrice
        country
        displayName
        firstName
        id
        isClient @client
        isPractitioner @client
        language
        lastName
        practiceAddress
        practiceName
        practiceUrl
        province
        yearsOfExperience
        pk
        verified

        practitionertagSet {
            edges {
                node {
                    level
                    tag {
                        id
                        name
                        type
                    }
                }
            }
        }
    }
`;

export const PRACTITIONER_EDIT = gql`
    mutation practitionerEdit(
        $about: String
        $avatarFile: Upload
        $biography: String
        $city: String
        $consultationDuration: Int
        $consultationPrice: Decimal
        $country: String
        $displayName: String
        $firstName: String
        $language: String
        $lastName: String
        $paymentEmail: String
        $paypalMe: String
        $phoneNumber: String
        $practiceAddress: String
        $practiceName: String
        $practiceUrl: String
        $province: String
        $taxNumber: String
        $yearsOfExperience: String
        $tagIdList: [ID]
        $levelList: [Int]
    ) {
        practitionerEdit(
            input: {
                about: $about
                avatarFile: $avatarFile
                biography: $biography
                city: $city
                consultationDuration: $consultationDuration
                consultationPrice: $consultationPrice
                country: $country
                displayName: $displayName
                firstName: $firstName
                language: $language
                lastName: $lastName
                paymentEmail: $paymentEmail
                paypalMe: $paypalMe
                phoneNumber: $phoneNumber
                practiceAddress: $practiceAddress
                practiceName: $practiceName
                practiceUrl: $practiceUrl
                province: $province
                taxNumber: $taxNumber
                yearsOfExperience: $yearsOfExperience
                tagIdList: $tagIdList
                levelList: $levelList
            }
        ) {
            clientMutationId
            practitioner {
                ...practitionerFragment
                email
                private {
                    paymentEmail
                    paypalMe
                    phoneNumber
                    taxNumber
                }
            }
        }
    }
    ${PRACTITIONER_FRAGMENT}
`;

export const FETCH_SCHEDULES = gql`
    query fetchSchedules(
        $practitioner: ID
        $start: DateTime
        $end: DateTime
        $cursor: String
        $searchQuery: String
        $specialties: [String]
        $languages: [String]
        $distinctOn: String
        $first: Int
        $withPractitioner: Boolean = false
        $withConsultation: Boolean = false
    ) {
        schedules(
            practitioner: $practitioner
            start_Gte: $start
            end_Lt: $end
            after: $cursor
            searchQuery: $searchQuery
            specialties: $specialties
            languages: $languages
            distinctOn: $distinctOn
            first: $first
        ) {
            edges {
                node {
                    id
                    start
                    end
                    practitioner @include(if: $withPractitioner) {
                        ...practitionerFragment
                    }
                    consultation @include(if: $withConsultation) {
                        id
                        status
                        client {
                            firstName
                            lastName
                        }
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
    ${PRACTITIONER_FRAGMENT}
`;

export const CREATE_SCHEDULES = gql`
    mutation scheduleCreateBatch($startList: [DateTime!]!) {
        scheduleCreateBatch(input: { startList: $startList }) {
            schedules {
                id
                start
                end
            }
            errors
        }
    }
`;

export const DELETE_SCHEDULES = gql`
    mutation scheduleDeleteBatch($ids: [ID!]!) {
        scheduleDeleteBatch(input: { ids: $ids }) {
            schedules {
                id
            }
            errors
        }
    }
`;

export const GET_USER = gql`
    query getUser {
        me {
            ...clientFragment
            ... on ClientNode {
                email
                private {
                    phoneNumber
                    organization
                }
            }

            ...practitionerFragment
            ... on PractitionerNode {
                email
                private {
                    paymentEmail
                    paypalMe
                    phoneNumber
                    taxNumber
                }
            }
        }
    }
    ${CLIENT_FRAGMENT}
    ${PRACTITIONER_FRAGMENT}
`;

export const GET_CLIENT = gql`
    query getClient($id: ID!) {
        client(id: $id) {
            ...clientFragment
        }
    }
    ${CLIENT_FRAGMENT}
`;

export const GET_PRACTITIONER = gql`
    query getPractitioner($id: ID!) {
        practitioner(id: $id) {
            ...practitionerFragment
        }
    }
    ${PRACTITIONER_FRAGMENT}
`;

export const GET_TAGS = gql`
    query getTags($cursor: String, $type: String) {
        tags(after: $cursor, type: $type) {
            edges {
                node {
                    id
                    type
                    name
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;

// const GET_ATTENDEE_DISPLAY_NAME = gql`
//     query getAttendeeName($ourAttendeeId: String, $consultationId: String) {
//         attendeeList(ourAttendeeId: $ourAttendeeId, consultationId: $consultationId) {
//             ourAttendeeId
//             chimeAttendeeName
//         }
//     }
// `;

export const LOGIN = gql`
    mutation login($timeZone: String, $firebaseIdToken: String!) {
        login(input: { timeZone: $timeZone, firebaseIdToken: $firebaseIdToken }) {
            user {
                ...clientFragment
                ... on ClientNode {
                    email
                    private {
                        phoneNumber
                        organization
                    }
                }

                ...practitionerFragment
                ... on PractitionerNode {
                    email
                    private {
                        paymentEmail
                        paypalMe
                        phoneNumber
                        taxNumber
                    }
                }
            }
        }
    }
    ${CLIENT_FRAGMENT}
    ${PRACTITIONER_FRAGMENT}
`;

export const LOGOUT = gql`
    mutation logout {
        logout(input: {}) {
            clientMutationId
        }
    }
`;

export const SIGN_UP_CLIENT = gql`
    mutation clientCreate(
        $about: String
        $avatarFile: Upload
        $city: String
        $country: String
        $dateOfBirth: Date
        $firebaseIdToken: String!
        $firstName: String
        $language: String
        $lastName: String
        $phoneNumber: String
        $province: String
        $recaptchaToken: String
        $timeZone: String
        $pets: [PetCreateInput]
    ) {
        clientCreate(
            input: {
                about: $about
                avatarFile: $avatarFile
                city: $city
                country: $country
                dateOfBirth: $dateOfBirth
                firebaseIdToken: $firebaseIdToken
                firstName: $firstName
                language: $language
                lastName: $lastName
                phoneNumber: $phoneNumber
                province: $province
                recaptchaToken: $recaptchaToken
                timeZone: $timeZone
                pets: $pets
            }
        ) {
            user {
                ...clientFragment
            }
        }
    }
    ${CLIENT_FRAGMENT}
`;

export const CLIENT_EDIT = gql`
    mutation clientEdit(
        $about: String
        $avatarFile: Upload
        $city: String
        $country: String
        $dateOfBirth: Date
        $firstName: String
        $language: String
        $lastName: String
        $phoneNumber: String
        $province: String
    ) {
        clientEdit(
            input: {
                about: $about
                avatarFile: $avatarFile
                city: $city
                country: $country
                dateOfBirth: $dateOfBirth
                firstName: $firstName
                language: $language
                lastName: $lastName
                phoneNumber: $phoneNumber
                province: $province
            }
        ) {
            clientMutationId
            client {
                ...clientFragment
                email
                private {
                    phoneNumber
                }
            }
        }
    }
    ${CLIENT_FRAGMENT}
`;

export const PET_CREATE = gql`
    mutation petCreate($kind: Kind!, $name: String!, $age: Int!, $sex: Sex!) {
        petCreate(input: { kind: $kind, name: $name, age: $age, sex: $sex }) {
            pet {
                ...petFragment
            }
        }
    }
    ${PET_FRAGMENT}
`;
export const GET_COUNTRIES = `
    query countries($countryCodes: [String] = []) {
        countries(countryCodes: $countryCodes) {
            edges {
                node {
                    countryName
                    countryCode
                }
            }
        }
    }
`;

const SUBSCRIPTION_ITEM_FRAGMENT = gql`
    fragment subscriptionItemFragment on SubscribableNode {
        id
        name
        description
        sweetSpot
        price
        benefits
        frequency
        currency
        isFree
    }
`;

export const CONSULTATION_CREATE = gql`
    mutation consultationCreate($scheduleId: ID!, $notes: String, $specialties: [String], $languages: [String]) {
        consultationCreate(
            input: { scheduleId: $scheduleId, notes: $notes, specialties: $specialties, languages: $languages }
        ) {
            consultation {
                id
                client {
                    id
                }
                requestedSchedule {
                    start
                }
                notes
                status
                specialties
                languages
            }
            vendorId
        }
    }
`;

export const PAYMENT_TRY_COMPLETE = gql`
    mutation paymentTryComplete($vendorId: String!) {
        paymentTryComplete(input: { vendorId: $vendorId }) {
            clientMutationId
            payment {
                consultation {
                    id
                    requestedSchedule {
                        start
                    }
                    status
                }
                client {
                    id
                }
            }
        }
    }
`;

export const GET_CONSULTATION = gql`
    query getConsultation($id: ID!) {
        consultation(id: $id) {
            payment {
                vendorId
            }
        }
    }
`;

export const CANCEL_CONSULTATION = gql`
    mutation consultationCancel($consultationId: ID!) {
        consultationCancel(input: { consultationId: $consultationId }) {
            clientMutationId
        }
    }
`;

export const CREATE_SUBSCRIPTION = gql`
    mutation subscriptionCreate(
        $subscribableItemId: ID!
        $isTrialSubscription: Boolean
        $promoCode: String
        $redirectUrl: String!
    ) {
        userSubscriptionCreate(
            input: {
                subscribableItemId: $subscribableItemId
                promoCode: $promoCode
                isTrialSubscription: $isTrialSubscription
                redirectUrl: $redirectUrl
            }
        ) {
            membershipSubscription {
                id
                licencedStatus
            }
            subscriptionItem {
                ...subscriptionItemFragment
            }
            stripeCheckoutUrl
        }
    }
    ${SUBSCRIPTION_ITEM_FRAGMENT}
`;

export const GET_USER_SUBSCRIPTIONS = gql`
    query userSubscriptions {
        userSubscriptions {
            edges {
                node {
                    id
                    licencedStatus
                }
            }
        }
    }
`;

export const GET_USER_IS_MEMBER = gql`
    query isMember {
        userHasMembershipSubscription {
            isMember
            subscriptionState
        }
    }
`;

export const GET_SUBSCRIBABLES = gql`
    query getSubscribables {
        subscribables {
            edges {
                node {
                    ...subscriptionItemFragment
                }
                allowsTrial
            }
        }
    }
    ${SUBSCRIPTION_ITEM_FRAGMENT}
`;

export const GET_CUSTOMER_PORTAL = gql`
    query portalQuery($returnUrl: String) {
        customerStripePortal(returnUrl: $returnUrl) {
            portalUrl
            locale
        }
    }
`;
