import { EventFrom, ContextFrom, ConditionPredicate, send } from "xstate";
import { assign } from "xstate/lib/actions";
import { createModel } from "xstate/lib/model";

export const enum AUTH_END_REASON {
    TIMEOUT,
    EXTERNAL,
}

const authenticationModel = createModel(
    {
        isAuthenticating: false,
        isFirebaseAuthReady: false,
        isShowingAuthModal: false,
        isPrivateRoute: false,
        authEndReason: undefined as AUTH_END_REASON,
    },
    {
        events: {
            firebaseReady: () => ({}),
            firebaseMissing: () => ({}),
            locationChanged: (): { requiresAuth?: boolean } => ({}),
            authSuccess: () => ({}),
            authFailure: () => ({}),
            authEnd: (): { reason?: AUTH_END_REASON } => ({}),
        },
    }
);

export type AuthContext = ContextFrom<typeof authenticationModel>;
export type AuthEvent = EventFrom<typeof authenticationModel>;

const isRouteProtected: ConditionPredicate<AuthContext, AuthEvent> = ({ isPrivateRoute }, evt) =>
    evt.type === "locationChanged" ? evt.requiresAuth : isPrivateRoute;
const isAuthenticated: ConditionPredicate<AuthContext, AuthEvent> = (_, __, { state }) =>
    state.matches("authenticated");

const updateRouteProtection = authenticationModel.assign({
    isPrivateRoute: (ctx, evt) => {
        if (evt.type === "locationChanged") {
            return evt.requiresAuth ?? false;
        }
        return ctx.isPrivateRoute;
    },
});

const authMachine = authenticationModel.createMachine(
    {
        id: "authentication-provider",
        preserveActionOrder: true,
        context: authenticationModel.initialContext,
        initial: "loading",
        states: {
            loading: {
                entry: assign({ isAuthenticating: true }),
                on: {
                    firebaseMissing: {
                        target: "anonymous",
                        actions: authenticationModel.assign({ isAuthenticating: false }),
                    },
                },
            },
            authenticating: {
                id: "authentication-handler",
                type: "compound",
                initial: "authInternally",
                states: {
                    authInternally: {
                        invoke: {
                            src: "attemptBackgroundSignin",
                            onDone: {
                                actions: send("authSuccess"),
                            },
                            onError: {
                                actions: send("authFailure"),
                            },
                        },
                    },
                    authWithModal: {
                        entry: assign({ isShowingAuthModal: true }),
                        exit: assign({ isShowingAuthModal: false }),
                    },
                },
                on: {
                    authSuccess: {
                        target: "authenticated",
                    },
                    authFailure: [
                        {
                            actions: authenticationModel.assign({ isAuthenticating: false }),
                            target: "anonymous",
                            cond: (...args) => !isRouteProtected(...args),
                        },
                        {
                            target: ".authWithModal",
                        },
                    ],
                },
                entry: assign({ isAuthenticating: true }),
            },
            authenticated: {
                preserveActionOrder: true,
                invoke: [
                    {
                        id: "idle-timer",
                        src: "idleMonitor",
                    },
                ],
                on: {
                    authEnd: {
                        target: "anonymous",
                        actions: assign({
                            authEndReason: (_, { reason }) => reason,
                        }),
                    },
                },
                entry: [
                    assign({ isAuthenticating: false, authEndReason: undefined }),
                    send({ type: "START" }, { to: "idle-timer" }),
                ],
                exit: send({ type: "STOP" }, { to: "idle-timer" }),
            },
            anonymous: {
                type: "compound",
                initial: "unknown",
                states: {
                    unknown: {
                        always: [
                            { target: "#authentication-provider.authenticating", cond: isRouteProtected },
                            { target: "publicRoute" },
                        ],
                    },
                    publicRoute: {},
                },
            },
        },
        on: {
            firebaseReady: [
                {
                    actions: authenticationModel.assign({ isFirebaseAuthReady: true }),
                    target: "authenticating",
                    cond: (_ctx, _evt, { state }) => state.matches("loading"),
                },
                {
                    actions: authenticationModel.assign({ isFirebaseAuthReady: true }),
                },
            ],
            firebaseMissing: [
                {
                    actions: authenticationModel.assign({ isFirebaseAuthReady: false }),
                    target: "anonymous",
                    cond: (_ctx, _evt, { state }) => !state.matches("authenticating"),
                },
                {
                    actions: authenticationModel.assign({ isFirebaseAuthReady: false }),
                },
            ],
            locationChanged: [
                {
                    // FIXME: When location changes and the authmodal is showing, we need to hide it
                    actions: "updateRouteProtection",
                    target: "authenticating",
                    cond: (...args) =>
                        !isAuthenticated(...args) && isRouteProtected(...args) && !args[2].state.matches("loading"),
                },
                {
                    actions: "updateRouteProtection",
                },
            ],
            authSuccess: {
                target: "authenticated",
                cond: (_, __, { state }) => !state.matches("authenticating"),
            },
        },
    },
    {
        guards: {
            isRouteProtected,
            isAuthenticated,
        },
        actions: {
            updateRouteProtection,
        },
    }
);

export default authMachine;
