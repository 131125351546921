import { ChatMessage, createChatBotMessage } from "react-chatbot-kit";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { type PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import type { DefaultRootState } from "react-redux";

import { store } from "app/store";

import AiChatbotChatHeader from "./AiChatbotChatHeader";
import AiChatbotRecordingWidget from "./widgets/AiChatbotRecordingWidget";
import { IChatbotConfig } from "./type-utils";
import AiChatbotYesNoWidget from "./widgets/AiChatbotYesNoWidget";
import AiChatbotAvatar from "./AiChatbotAvatar";

const botName = "PetDocBot";

// https://gist.github.com/FredrikOseberg/87795296efb67fe76fa05fc839d22e25
const config: IChatbotConfig = {
    botName,
    initialMessages: [createChatBotMessage(`Hey! How may we help you?`)],
    customComponents: {
        header: AiChatbotChatHeader,
        // To change the AiChatbotAvatar
        botAvatar: AiChatbotAvatar,
    },

    customStyles: {
        chatButton: {
            backgroundColor: "#434190",
        },
    },
    widgets: [
        {
            widgetName: "recording",
            widgetFunc: AiChatbotRecordingWidget,
            mapStateToProps: ["recordings", "chatMode"],
        },
        {
            widgetName: "yesno",
            widgetFunc: AiChatbotYesNoWidget,
            mapStateToProps: ["messages", "chatbotService"],
        },
    ],
};

const chatbotSlice = createSlice({
    name: "chat/chatbotMessagesSlice",
    initialState: [] as ChatMessage[],
    reducers: {
        saveChatMessages: (state, action: PayloadAction<ChatMessage[]>) => [...action.payload],
    },
});

const persistConfig: PersistConfig<ReturnType<typeof chatbotSlice.reducer>> = {
    key: `${process.env.REACT_APP_PRODUCT_NAME}-chat`,
    storage,
    version: 1,
    debug: process.env.NODE_ENV === "development",
};

const chatReducer = persistReducer(persistConfig, chatbotSlice.reducer);

const CHAT_SELECTOR_KEY = "chatbot";
store.injectReducer(CHAT_SELECTOR_KEY, chatReducer, true);

export const { saveChatMessages } = chatbotSlice.actions;
export const selectChatbotMessages = (
    state: DefaultRootState & { [CHAT_SELECTOR_KEY]: ReturnType<typeof chatbotSlice.reducer> }
) => state[CHAT_SELECTOR_KEY];

export default config;
