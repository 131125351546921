import { createSlice } from "@reduxjs/toolkit";

// eslint-disable-next-line import/no-unused-modules
export interface RouteState {
    isRouteProtected: boolean;
    // For handling suspense routes loading...
    isRouteLoading: boolean;
}

const initialState: RouteState = {
    isRouteProtected: false,
    isRouteLoading: false,
};

const routeSlice = createSlice({
    name: "routes-slice",
    initialState,
    reducers: {
        enterPrivateRoute(state) {
            state.isRouteProtected = true;
        },
        exitPrivateRoute(state) {
            state.isRouteProtected = false;
        },
        routeIsLoading(state) {
            state.isRouteLoading = true;
        },
        routeIsLoaded(state) {
            state.isRouteLoading = false;
        },
    },
});

export const {
    enterPrivateRoute,
    exitPrivateRoute,
    routeIsLoaded: routeLoaded,
    routeIsLoading: routeLoading,
} = routeSlice.actions;
export default routeSlice.reducer;
