import { useState, useCallback, useMemo, useEffect } from "react";

import QuestionAnswerRounded from "@mui/icons-material/QuestionAnswerRounded";
// import Fade from "@mui/material/Fade";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";

import type { ChatMessage } from "react-chatbot-kit";
import Chatbot from "react-chatbot-kit";
import { useRouteMatch } from "react-router-dom";

import { useLexService } from "providers/aws-lex";
import { useLocalizedRoutePath } from "hooks/localization";
import ROUTES from "routes";
import { useAppDispatch, useAppSelector } from "hooks/app";
import { makeStyles } from "theme";

import config, { saveChatMessages, selectChatbotMessages } from "./chatbotconfig";
import MessageParser from "./AiChatbotMessageParser";
import ActionProvider from "./AiChatbotActionProvider";
import { IChatbotConfig } from "./type-utils";

import "react-chatbot-kit/build/main.css";
import "components/chat/AiChatbotStyles.css";

const useStyles = makeStyles()({
    chatBox: {},
});

const AiChatbot: React.FC = () => {
    const { classes } = useStyles();
    const chatbotService = useLexService();
    const localizedRoutePath = useLocalizedRoutePath();
    const homePageRoute = useRouteMatch({ path: localizedRoutePath(ROUTES.LANDING) });
    const dispatch = useAppDispatch();
    const initialMessages = useAppSelector(selectChatbotMessages);

    const [isShowChat, setIsShowChat] = useState(homePageRoute.isExact);

    const chatConfig: IChatbotConfig = useMemo(
        () => ({
            ...config,
            state: {
                ...config.state,
                recordings: [],
                chatbotService,
                chatMode: "text",
            },
        }),
        [chatbotService]
    );

    const toggleShowChat = useCallback(() => setIsShowChat((isShowing) => !isShowing), []);

    // Doesn't work due to bug: https://github.com/FredrikOseberg/react-chatbot-kit/issues/94
    const handleSaveChatHistory = (messages: ChatMessage[]) => {
        dispatch(saveChatMessages(messages));
    };

    const handleClickAway = () => {
        setIsShowChat(false);
    };

    // TODO: Due to the bug identified above, we cannot save chathistory, so idealy
    // we should end the conversation so that the bot does not get lost
    useEffect(() => {
        if (!isShowChat && chatbotService.isConversationStarted()) {
            chatbotService.endConversation();
        } else if (isShowChat && !chatbotService.isConversationStarted()) {
            chatbotService.initiateConversation();
        }
    }, [isShowChat, chatbotService]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="fixed z-30 bottom-8 right-0 flex flex-col items-end justify-end mr-4">
                <Grow
                    in={isShowChat}
                    className={`transform origin-bottom-right -mb-14 ${classes.chatBox}`}
                    mountOnEnter
                    unmountOnExit // This is required to avoid ghost container
                    timeout={{
                        exit: 500,
                        appear: 1000,
                        enter: 500,
                    }}
                >
                    <div className="flex rounded-t-3xl shadow">
                        <Chatbot
                            config={chatConfig}
                            messageParser={MessageParser}
                            actionProvider={ActionProvider}
                            placeholderText="Please type a message"
                            messageHistory={initialMessages}
                            saveMessages={handleSaveChatHistory}
                        />
                    </div>
                </Grow>

                <Grow
                    in={!isShowChat}
                    timeout={{
                        exit: 150,
                        appear: 1000,
                        enter: 1000,
                    }}
                >
                    <button
                        type="button"
                        className="flex-none w-12 h-12 bg-secondary-400 rounded-full shadow hover:opacity-100 opacity-90"
                        onClick={toggleShowChat}
                    >
                        <QuestionAnswerRounded fontSize="medium" htmlColor="#000000" />
                    </button>
                </Grow>
            </div>
        </ClickAwayListener>
    );
};

export default AiChatbot;
// export AiChatbot2;
