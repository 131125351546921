// @ts-ignore
import { useState } from "react";

import { Button, Paper } from "@mui/material";
import CheckCircleSharp from "@mui/icons-material/CheckCircleSharp";
import CancelSharp from "@mui/icons-material/CancelSharp";

import { Message } from "@aws-sdk/client-lex-runtime-v2";

import { ChatbotService } from "services/chatbot-service";
import { makeStyles } from "theme";

import { IChatbotWidgetProps } from "../type-utils";

interface AiChatbotYesNoWidgetProps extends IChatbotWidgetProps {
    chatbotService: ChatbotService<Message[]>;
    chatSessionId?: string;
}

const useStyles = makeStyles()((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-around",
        padding: theme.spacing(0.5),
        color: "",
        margin: 0,
    },
    button: {
        borderRadius: 8,
        margin: theme.spacing(0.5),
    },
}));

const AiChatbotYesNoWidget: React.FC<AiChatbotYesNoWidgetProps> = ({ actionProvider, chatbotService }) => {
    const { classes } = useStyles();

    const [selectionMade, setSelectionMade] = useState(false);

    /* TODO BET-27: make sure the buttons are not clickable once the chat window has scrolled
    past where this widget was rendered */
    const handleClick = (affirm: boolean) => {
        if (!selectionMade) {
            setSelectionMade(true);
            chatbotService
                .respondToText(affirm ? "Yes" : "No")
                .then((responseMessages) => actionProvider.handleAWSLexResponseMessage(responseMessages));
        }
    };

    return (
        <Paper component="ul" className={classes.root}>
            <li>
                <Button className={classes.button} color="primary" onClick={() => handleClick(true)}>
                    <CheckCircleSharp />
                    <span>yes</span>
                </Button>
            </li>

            <li>
                <Button className={classes.button} color="secondary" onClick={() => handleClick(false)}>
                    <CancelSharp />
                    <span>no</span>
                </Button>
            </li>
        </Paper>
    );
};

export default AiChatbotYesNoWidget;
