type FeatureKeys = "SUBSCRIPTIONS_ENABLED" | "CHATBOT_ENABLED";

const FeaturesMap: Record<FeatureKeys, boolean> = JSON.parse(process.env.REACT_APP_FEATURE_FLAGS);

export const enum FEATURE {
    SUBSCRIPTIONS = 1,
    CHATBOT = 2,
}

export const isFeatureEnabled = (feature: FEATURE) => {
    switch (feature) {
        case FEATURE.SUBSCRIPTIONS:
            return FeaturesMap.SUBSCRIPTIONS_ENABLED === true;
        case FEATURE.CHATBOT:
            return FeaturesMap.CHATBOT_ENABLED === true;
        default:
            return false;
    }
};
