// https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9
import React, { memo } from "react";

import { Switch, type SwitchProps, Route, type RouteProps, Redirect, type RedirectProps } from "react-router-dom";

import { useLocalizedRoutePath } from "hooks/localization";
import RefForwardingRoute from "components/shared/RefForwardingRoute";
import PrivateRoute from "components/auth/PrivateRoute";

interface LocalizedSwitchProps extends SwitchProps {
    ref?: React.Ref<any>;
}

const LocalizedSwitch: React.FC<LocalizedSwitchProps> = memo(
    React.forwardRef(({ children, ...props }, forwardedRef) => {
        /**
         * inject params and formatMessage through hooks, so we can localize the route
         */
        const localizeRoutePath = useLocalizedRoutePath();

        /**
         * Localizes all children routes/redirects
         *
         * Also supplies itself to the rendered children in case they need to render
         * nested localized child routes
         *
         * @param childNodes Children routes to render with localized version of their routes
         * @returns All child routes with a localized version of their route
         */
        function localizeChildren(childNodes: React.ReactNode): React.ReactNode {
            return React.Children.map(childNodes, (child) => {
                if (React.isValidElement(child)) {
                    switch (child.type) {
                        case React.Fragment:
                            return localizeChildren(child.props.children);
                        case Route:
                        case RefForwardingRoute:
                        case PrivateRoute:
                            return React.cloneElement<RouteProps>(child, {
                                ref: forwardedRef,
                                ...child.props,
                                path: localizeRoutePath(child.props.path),
                            });
                        case Redirect:
                            return React.cloneElement<RedirectProps>(child, {
                                ...child.props,
                                from: localizeRoutePath(child.props.from),
                                to: localizeRoutePath(child.props.to),
                            });
                        default:
                            return child;
                    }
                }
                return child;
            });
        }

        /**
         * Apply localization to all routes
         * Also checks if all children elements are <Route /> components
         *
         * TODO: Add one more route to the end of Switch which can serve as a 404 page
         */
        return <Switch {...props}>{localizeChildren(children)}</Switch>;
    })
);

export default LocalizedSwitch;
