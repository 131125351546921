import {
    createContext,
    memo,
    useCallback,
    useState,
    useContext,
    useEffect,
    useRef,
    useMemo,
    useLayoutEffect,
} from "react";

import { useAppSelector } from "hooks/app";

interface TidioProviderState {
    tidioChatApi?: Window["tidioChatApi"];
    tidioReady: boolean;
}

interface TidioProviderProps {
    tidioScriptUrl: string;
}

const TidioContext = createContext<TidioProviderState>(null);

export const useTidio = () => useContext(TidioContext);

const TIDIO_CHAT_SCRIPT_ID = "tidioChatApiId";

const createTidioElement = (scriptUrl: string) => {
    const script = document.createElement("script");
    script.src = `${scriptUrl}?time=${Date.now()}`;
    script.id = TIDIO_CHAT_SCRIPT_ID;
    return script;
};

const TidioProvider: React.FC<TidioProviderProps> = memo(({ children, tidioScriptUrl }) => {
    const [tidioChatApi, setTidioChatApi] = useState<TidioProviderState["tidioChatApi"]>(null);
    const tidioScript = useRef<HTMLScriptElement>(null);
    const isTidioReady = useMemo(() => tidioChatApi != null, [tidioChatApi]);
    const firebaseUser = useAppSelector((state) => state.users.firebaseUser);

    const handleUpdateTidioChatLoad = useCallback(() => {
        setTidioChatApi(window.tidioChatApi);
        window.tidioChatApi.addVisitorTags([process.env.REACT_APP_ENVIRONMENT]);
    }, []);

    const tidio = useMemo<TidioProviderState>(
        () => ({
            tidioChatApi,
            tidioReady: isTidioReady,
        }),
        [isTidioReady, tidioChatApi]
    );

    useEffect(() => {
        document.addEventListener("tidioChat-ready", handleUpdateTidioChatLoad);
        return () => {
            document.removeEventListener("tidioChat-ready", handleUpdateTidioChatLoad);
        };
    }, [handleUpdateTidioChatLoad]);

    useEffect(() => {
        if (tidioChatApi) {
            if (firebaseUser) {
                document.tidioIdentify = {
                    email: firebaseUser.email,
                    distinct_id: firebaseUser.uid,
                };
                tidioChatApi.setVisitorData({ email: firebaseUser.email, name: firebaseUser.displayName });
            } else {
                tidioChatApi.setVisitorData({ email: null, name: null });
                document.tidioIdentify = null;
            }
        }
    }, [firebaseUser, tidioChatApi]);

    useLayoutEffect(() => {
        tidioScript.current = createTidioElement(tidioScriptUrl);
        document.body.appendChild(tidioScript.current);
        return () => {
            document.body.removeChild(tidioScript.current);
            tidioScript.current = null;
        };
    }, [tidioScriptUrl]);

    return <TidioContext.Provider value={tidio}>{children}</TidioContext.Provider>;
});

export default TidioProvider;
