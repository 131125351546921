import { memo } from "react";

import { Helmet } from "react-helmet";

import { useAppSelector } from "hooks/app";

interface MetaTagProps {
    title: string;
    description?: string;
}

const MetaTag: React.FC<MetaTagProps> = memo(({ title, description }) => {
    const language = useAppSelector((state) => state.appLocale.language);

    return (
        <Helmet
            htmlAttributes={{ lang: language }}
            defaultTitle={process.env.REACT_APP_PRODUCT_NAME}
            titleTemplate={`%s | ${process.env.REACT_APP_PRODUCT_NAME}`}
            meta={[
                {
                    name: "description",
                    content: description,
                },
            ]}
        >
            <title>{title}</title>
        </Helmet>
    );
});

export default MetaTag;
