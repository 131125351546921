import debug from "debug";

const appDebug = debug("betterpaws");
if (process.env.NODE_ENV === "development") {
    debug.disable();
    // enable debugging by default in dev
    debug.enable(`${appDebug.namespace}:*`);
}
// https://github.com/debug-js/debug#browser-support
// To enable logging in production:
// create a new variable in localStorage called "debug"
// and set its value to "betterpaws:*"

export default appDebug;
