import { memo, useCallback, useEffect, useMemo, useRef } from "react";

import { Typography, colors } from "@mui/material";
import FacebookLogo from "@mui/icons-material/Facebook";
import GoogleLogo from "@mui/icons-material/Google";

import { FormattedMessage, useIntl } from "react-intl";
import { Form, Field } from "react-final-form";
import type { AuthError } from "firebase/auth";
import { createForm, FORM_ERROR } from "final-form";

import { firebaseAuth } from "auth/firebase/firebase";
import ProgressOverlay from "components/shared/ProgressOverlay";
import LocalizedNavLink from "components/l10n/LocalizedNavLink";
import { useHistoryActions } from "hooks/browser";
import { useFirebaseAuth, useFirebaseErrorHandler } from "hooks/auth";
import ROUTE from "routes";
import { useLoginUser } from "hooks/auth/users";
import UserModel from "models/UserModel";
import { useLocalStorage } from "hooks/storage";
import appDebug from "utilities/logging";

import { useTidio } from "../Tidio";

interface FirebaseSignInFormProps {
    onSignupLinkClick?: () => void;
    onPasswordForgetLinkClick?: () => void;
    onSignInSuccess?: () => void;
    onSignInError?: (error: AuthError) => void;
}

interface FormModel extends UserModel {
    password?: string;
    rememberMe?: boolean;
    isSocial: boolean;
    isRedirect: boolean;
}

const SIGN_IN_REMEMBER_KEY = "__signin_remember_me";

const logger = appDebug.extend("FirebaseSignInForm");

const FirebaseSignInForm: React.FC<FirebaseSignInFormProps> = memo(
    ({ onSignupLinkClick, onPasswordForgetLinkClick, onSignInSuccess, onSignInError }) => {
        const firebaseErrorHandler = useFirebaseErrorHandler();
        const [rememberUser, setRememberUser] = useLocalStorage<string>(SIGN_IN_REMEMBER_KEY);
        const login = useLoginUser();
        const { push: historyPush } = useHistoryActions();
        const { formatMessage } = useIntl();
        const socialSignInMethod = useRef<() => Promise<boolean>>();
        const { tidioChatApi, tidioReady } = useTidio();

        const signupErrorHandler = useCallback(
            (error: AuthError) => {
                (onSignInError ?? firebaseErrorHandler)(error);
            },
            [firebaseErrorHandler, onSignInError]
        );

        const [{ signInWithEmail, signInWithFacebook, signInWithGoogle }, { isAwaitingAuthentication }] =
            useFirebaseAuth(firebaseAuth, signupErrorHandler);

        const finishSignIn = useCallback(async () => {
            const firebaseUser = firebaseAuth.currentUser;

            const firebaseIdToken = await firebaseUser.getIdToken();
            logger("firebaseIdToken: %O", firebaseIdToken);

            return login({ firebaseIdToken })
                .then(() => {
                    if (onSignInSuccess) {
                        onSignInSuccess();
                    } else {
                        /* TODO: Sigin should take a redirect URL,
                        rather than assuming one of Onboarding or Dashboard */
                        historyPush(ROUTE.DASHBOARD);
                    }
                })
                .catch((err) => ({ [FORM_ERROR]: err?.message ?? err }));
        }, [historyPush, login, onSignInSuccess]);

        const onSubmit = useCallback(
            async (values: FormModel) => {
                if (values.isSocial || values.isRedirect) {
                    if (!(await socialSignInMethod.current?.())) {
                        logger("login failed!!");
                        return { [FORM_ERROR]: "Login Failed" };
                    }
                } else if (!(await signInWithEmail(values.email, values.password))) {
                    return { [FORM_ERROR]: "Login Failed" };
                }
                if (values.rememberMe) {
                    setRememberUser(values.email);
                } else {
                    setRememberUser(undefined);
                }
                return finishSignIn();
            },
            [finishSignIn, setRememberUser, signInWithEmail]
        );

        const finalForm = useMemo(() => {
            const initialValues: FormModel = {
                isSocial: false,
                isRedirect: false,
                email: null,
                firstName: null,
                password: null,
                lastName: null,
            };
            const form = createForm<FormModel>({ onSubmit, initialValues });
            // pause validation until attached to an actual form, then validation resumes
            form.pauseValidation();
            return form;
        }, [onSubmit]);

        useEffect(() => {
            if (tidioReady) {
                tidioChatApi.hide();
                return () => {
                    tidioChatApi?.show();
                };
            }
            return undefined;
        }, [tidioChatApi, tidioReady]);

        return (
            // @ts-ignore
            <Form<FormModel>
                form={finalForm}
                subscription={{
                    submitting: true,
                    submitSucceeded: true,
                    hasSubmitErrors: true,
                    submitError: true,
                    modifiedSinceLastSubmit: true,
                }}
                render={({
                    handleSubmit,
                    submitting,
                    submitSucceeded,
                    hasSubmitErrors,
                    submitError,
                    form,
                    modifiedSinceLastSubmit,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="p-8 w-full bg-white rounded-lg shadow-lg md:max-w-md"
                        data-cy="signInForm"
                    >
                        {(isAwaitingAuthentication || submitting || submitSucceeded) && <ProgressOverlay />}

                        <div className="fade-in">
                            <h1 className="mt-4 text-center text-gray-800 text-3xl font-extrabold leading-9">
                                <FormattedMessage defaultMessage="Sign in" />
                            </h1>
                            <div className="flex flex-wrap gap-2 justify-center my-4 text-center text-gray-600 leading-5">
                                <p className="inline-block whitespace-nowrap">
                                    <FormattedMessage defaultMessage="Don't have an account?" />
                                </p>
                                {onSignupLinkClick ? (
                                    <button type="button" onClick={onSignupLinkClick}>
                                        <div className="inline-block hover:text-blue-500 text-blue-600 hover:underline focus:underline font-bold focus:outline-none transition duration-500 ease-in-out">
                                            <FormattedMessage defaultMessage="Register." />
                                        </div>
                                    </button>
                                ) : (
                                    <LocalizedNavLink to={ROUTE.ONBOARDING}>
                                        <div className="inline-block hover:text-blue-500 text-blue-600 hover:underline focus:underline font-bold focus:outline-none transition duration-500 ease-in-out">
                                            <FormattedMessage defaultMessage="Register." />
                                        </div>
                                    </LocalizedNavLink>
                                )}
                            </div>
                            {hasSubmitErrors && !modifiedSinceLastSubmit && submitError && (
                                <Typography
                                    variant="caption"
                                    align="center"
                                    display="inline-block"
                                    width="99%"
                                    fontWeight="bolder"
                                    color={colors.red[500]}
                                >
                                    {submitError}
                                </Typography>
                            )}
                        </div>
                        <div className="fade-in flex flex-col mt-7">
                            <div className="rounded-md shadow-sm">
                                <Field
                                    name="email"
                                    initialValue={rememberUser ?? undefined}
                                    render={({ input }) => (
                                        <div>
                                            <i className="fas fa-envelope absolute z-10 ml-3 mt-3 text-gray-800" />
                                            <input
                                                aria-label={formatMessage({ defaultMessage: "Email" })}
                                                {...input}
                                                type="text"
                                                required
                                                className="placeholder-gray-600 relative block px-8 py-2 w-full text-gray-800 border focus:border-alpha border-gray-400 rounded-none rounded-t-md focus:outline-none appearance-none sm:text-sm sm:leading-5"
                                                placeholder={formatMessage({ defaultMessage: "Email" })}
                                            />
                                        </div>
                                    )}
                                />
                                <Field
                                    name="password"
                                    render={({ input }) => (
                                        <div className="-mt-px">
                                            <i className="fas fa-lock absolute z-10 ml-3 mt-3 text-gray-800" />
                                            <input
                                                aria-label={formatMessage({ defaultMessage: "Password" })}
                                                {...input}
                                                type="password"
                                                required
                                                className="placeholder-gray-600 relative block px-8 py-2 w-full text-gray-800 border focus:border-alpha border-gray-400 rounded-b-md rounded-none focus:outline-none appearance-none sm:text-sm sm:leading-5"
                                                placeholder={formatMessage({ defaultMessage: "Password" })}
                                            />
                                        </div>
                                    )}
                                />
                                <Field name="isSocial" initialValue={false} render={() => null} />
                            </div>

                            <div className="fade-in flex items-center justify-end my-1 text-center text-sm leading-5">
                                {onPasswordForgetLinkClick ? (
                                    <button type="button" onClick={onPasswordForgetLinkClick}>
                                        <div className="inline-block hover:text-blue-500 text-blue-600 hover:underline focus:underline font-bold focus:outline-none transition duration-500 ease-in-out">
                                            <FormattedMessage defaultMessage="Forgot your password?" />
                                        </div>
                                    </button>
                                ) : (
                                    <LocalizedNavLink to={ROUTE.PASSWORD_FORGET}>
                                        <div className="inline-block hover:text-blue-500 text-blue-600 hover:underline focus:underline font-bold focus:outline-none transition duration-500 ease-in-out">
                                            <FormattedMessage defaultMessage="Forgot your password?" />
                                        </div>
                                    </LocalizedNavLink>
                                )}
                            </div>

                            <div className="mt-6">
                                <button
                                    type="submit"
                                    onClick={() => {
                                        form.change("isSocial", false);
                                    }}
                                    className="group relative flex justify-center px-4 py-2 w-full text-white text-sm font-bold bg-alpha rounded-full shadow-lg hover:opacity-75 transition duration-500 ease-in-out"
                                >
                                    <FormattedMessage defaultMessage="Sign in" />
                                </button>
                            </div>

                            <div className="flex flex-1 mx-10 my-8 border-t border-gray-500 md:mx-20" />

                            <div className="flex flex-col gap-y-5 justify-between pb-4 text-white">
                                <button
                                    type="button"
                                    onClick={async () => {
                                        form.change("isSocial", true);
                                        socialSignInMethod.current = signInWithGoogle;
                                        await form.submit();
                                    }}
                                    className="flex gap-x-4 items-center px-4 py-2 w-full text-sm font-bold bg-alpha rounded-full shadow-lg hover:opacity-75 transition duration-500 ease-in-out"
                                >
                                    <div className="w-6">
                                        <GoogleLogo />
                                    </div>
                                    <span className="flex-1 justify-center mr-8">
                                        <FormattedMessage defaultMessage="Sign in with Google" />
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    onClick={async () => {
                                        form.change("isSocial", true);
                                        socialSignInMethod.current = signInWithFacebook;
                                        await form.submit();
                                    }}
                                    className="flex gap-x-4 items-center px-4 py-2 w-full text-sm font-bold bg-alpha rounded-full shadow-lg hover:opacity-75 transition duration-500 ease-in-out"
                                >
                                    <div className="w-6">
                                        <FacebookLogo />
                                    </div>
                                    <span className="flex-1 justify-center mr-8">
                                        <FormattedMessage defaultMessage="Sign in with Facebook" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            />
        );
    }
);

export default FirebaseSignInForm;
