/* eslint-disable import/prefer-default-export */
import { useCallback } from "react";

import { useIntl } from "react-intl";
import * as Sentry from "@sentry/react";
import { ToastOptions } from "react-toastify";

import Toasts, { type MessageType } from "utilities/toasts";
import { RECAPTCHA_FAIL_ERROR_MESSAGE, UNEXPECTED_ERROR_MESSAGE } from "app/constants";
import { useAuthentication } from "hooks/auth";
import appDebug from "utilities/logging";

const logger = appDebug.extend("useBackendErrorHandler");
export const useBackendErrorHandler = (
    extraErrors?: Record<string, { message?: any; type?: MessageType; params?: ToastOptions }>
) => {
    const { formatMessage } = useIntl();
    const { handleUnauthorizedError } = useAuthentication();

    return useCallback(
        (err: Error) => {
            if (err.message in (extraErrors ?? {})) {
                const { type = Toasts.types.ERROR, message, params } = extraErrors[err.message];
                if (message && type) {
                    Toasts.show(type, message, params);
                }
                return;
            }
            switch (err.message) {
                case "UNAUTHORIZED":
                    handleUnauthorizedError(err);
                    break;
                case "RecaptchaError":
                    Toasts.show(Toasts.types.ERROR, formatMessage(RECAPTCHA_FAIL_ERROR_MESSAGE));
                    break;
                default:
                    Sentry.captureException(err);
                    logger(err);
                    Toasts.show(Toasts.types.ERROR, formatMessage(UNEXPECTED_ERROR_MESSAGE));
            }
        },
        [extraErrors, handleUnauthorizedError, formatMessage]
    );
};
