import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
    browserPopupRedirectResolver,
    browserSessionPersistence,
    connectAuthEmulator,
    debugErrorMap,
    initializeAuth,
    prodErrorMap,
} from "firebase/auth";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { isSupported, initializeAnalytics } from "firebase/analytics";

if (process.env.NODE_ENV === "development") {
    (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_APP_CHECK_DEBUG_TOKEN;
}

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
const firebaseApp = initializeApp(firebaseConfig);
initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
    isTokenAutoRefreshEnabled: true,
});

export const firebaseAuth = initializeAuth(firebaseApp, {
    errorMap: process.env.NODE_ENV === "production" ? prodErrorMap : debugErrorMap,
    popupRedirectResolver: browserPopupRedirectResolver,
    persistence: browserSessionPersistence,
});

export const firebaseDatabase = getDatabase(firebaseApp);

if (process.env.NODE_ENV === "development") {
    connectAuthEmulator(firebaseAuth, "http://localhost:9099", { disableWarnings: true });
    connectDatabaseEmulator(firebaseDatabase, "localhost", 9000);
}

if (process.env.NODE_ENV === "production") {
    isSupported().then((analyticsSupported) => {
        if (analyticsSupported) {
            initializeAnalytics(firebaseApp);
        }
    });
}
