import { memo } from "react";

import Fade, { type FadeProps } from "@mui/material/Fade";
import LinearProgress from "@mui/material/LinearProgress";

import { makeStyles } from "theme";

interface ProgressOverlay2Props extends Omit<FadeProps, "in" | "timeout" | "children"> {
    hideLoadingAnimation?: boolean;
}

const useStyles = makeStyles()((theme) => ({
    bar: {
        height: theme.spacing(1),
        "& .MuiLinearProgress-bar": {
            background: [
                /* Gradients created with https://cssgradient.io/ */
                "radial-gradient(circle, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);",
            ],
        },
        "&.MuiLinearProgress-indeterminate": {
            background: [
                "rgb(131,58,180);",
                "radial-gradient(circle, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);",
            ],
        },
    },
}));

const ProgressOverlay2: React.FC<ProgressOverlay2Props> = memo(({ hideLoadingAnimation = false, ...props }) => {
    const { classes } = useStyles();

    return (
        <Fade {...props} mountOnEnter unmountOnExit in={!hideLoadingAnimation} timeout={250}>
            <div className="flex flex-col items-stretch justify-center w-screen">
                <LinearProgress color="inherit" className={classes.bar} />
            </div>
        </Fade>
    );
});

export default ProgressOverlay2;
