import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { pick as pickLanguage } from "accept-language-parser";

import { supportedLocales, switchAppIntl } from "utilities/localization";

type IntlType = Awaited<ReturnType<typeof switchAppIntl>>;
// eslint-disable-next-line import/no-unused-modules
export interface AppLocaleState {
    locale: string;
    language: string;
    readonly defaultLocale: string;
    readonly intl: IntlType;
}

const validateLocale = (newLocale: string) => pickLanguage(supportedLocales, newLocale);
const computeLanguage = (locale: string) => new Intl.Locale(locale).language;

const switchLocale = createAsyncThunk<IntlType, string, { state: { appLocale: AppLocaleState } }>(
    "app-locale/switchLocale",
    async (locale: string, { getState }) => {
        const validLocale = validateLocale(locale);
        if (validLocale) {
            return switchAppIntl(validLocale);
        }
        return switchAppIntl(getState().appLocale.defaultLocale);
    },
    {
        condition: (locale, { getState }) => getState().appLocale.locale !== locale,
    }
);

const initialState: AppLocaleState = {
    locale: null,
    language: process.env.REACT_APP_DEFAULT_LANGUAGE,
    defaultLocale: process.env.REACT_APP_DEFAULT_LANGUAGE,
    intl: null,
};

const appLocalSlice = createSlice({
    name: "app-locale",
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder.addCase(switchLocale.fulfilled, (state, intlPayload: PayloadAction<IntlType>) => {
            const intl = intlPayload.payload;
            state.locale = validateLocale(intl.locale);
            state.language = computeLanguage(intl.locale);
            state.intl = intl;
        }),
});

export { switchLocale };
export default appLocalSlice.reducer;
