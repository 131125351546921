import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import PawIcon from "@mui/icons-material/Pets";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import HandshakeIcon from "@mui/icons-material/Handshake";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Article, EmojiPeopleOutlined, Shop2, YouTube } from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import LocalizedNavLink from "components/l10n/LocalizedNavLink";
import { useAuthentication } from "hooks/auth";
import ROUTES from "routes";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

// eslint-disable-next-line react/function-component-definition
export default function MobileMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { isAuthenticated, isAuthenticating } = useAuthentication();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <nav>
            <IconButton
                aria-label="menu"
                id="menu"
                aria-controls="long-menu"
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MenuIcon className="text-gray-800" />
            </IconButton>
            <StyledMenu
                id="menu"
                MenuListProps={{
                    "aria-labelledby": "menu",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <LocalizedNavLink to={ROUTES.LANDING} exact activeClassName="underline">
                    <MenuItem onClick={handleClose} disableRipple>
                        <HomeIcon />
                        <FormattedMessage defaultMessage="Home" />
                    </MenuItem>
                </LocalizedNavLink>

                <LocalizedNavLink to={ROUTES.MEMBERS_BENEFITS} activeClassName="underline">
                    <MenuItem onClick={handleClose} disableRipple>
                        <EmojiPeopleOutlined />
                        <FormattedMessage defaultMessage="Members Benefits" />
                    </MenuItem>
                </LocalizedNavLink>

                <LocalizedNavLink to={ROUTES.LOST_PETS} activeClassName="underline">
                    <MenuItem onClick={handleClose} disableRipple>
                        <PawIcon />
                        <FormattedMessage defaultMessage="Lost Pets" />
                    </MenuItem>
                </LocalizedNavLink>

                <LocalizedNavLink to={ROUTES.SHOP} activeClassName="underline">
                    <MenuItem onClick={handleClose} disableRipple>
                        <Shop2 />
                        <FormattedMessage defaultMessage="Shop" />
                    </MenuItem>
                </LocalizedNavLink>

                <LocalizedNavLink to={ROUTES.FAQ} activeClassName="underline">
                    <MenuItem onClick={handleClose} disableRipple>
                        <HelpIcon />
                        <FormattedMessage defaultMessage="FAQ" />
                    </MenuItem>
                </LocalizedNavLink>
                <LocalizedNavLink to={ROUTES.PARTNERS} activeClassName="underline">
                    <MenuItem onClick={handleClose} disableRipple>
                        <HandshakeIcon />
                        <FormattedMessage defaultMessage="Partners and Affiliates" />
                    </MenuItem>
                </LocalizedNavLink>

                <Link to={{ pathname: process.env.REACT_APP_BLOG_LINK }} target="_blank">
                    <MenuItem onClick={handleClose} disableRipple>
                        <Article />
                        <FormattedMessage defaultMessage="Blog" />
                    </MenuItem>
                </Link>
                <Link to={{ pathname: process.env.REACT_APP_YOUTUBE_CHANNEL }} target="_blank">
                    <MenuItem onClick={handleClose} disableRipple>
                        <YouTube />
                        <FormattedMessage defaultMessage="Videos" />
                    </MenuItem>
                </Link>
                <Divider sx={{ my: 0.5 }} />
                <div className="px-4 py-2">
                    <LocalizedNavLink
                        to={isAuthenticated ? ROUTES.DASHBOARD : ROUTES.SIGN_IN}
                        className={isAuthenticating && "hidden"}
                    >
                        <button
                            type="button"
                            onClick={handleClose}
                            className="py-1 w-full text-alpha hover:text-white hover:bg-alpha bg-transparent border border-alpha rounded-md transition duration-500 ease-in-out"
                        >
                            {isAuthenticated && <FormattedMessage defaultMessage="Back to Dashboard" />}
                            {!isAuthenticated && <FormattedMessage defaultMessage="Sign In" />}
                        </button>
                    </LocalizedNavLink>
                </div>
            </StyledMenu>
        </nav>
    );
}
