import { defineMessages, MessageDescriptor } from "react-intl";

const ROUTES = {
    ABOUT: "route.about",
    ACCOUNT: "route.account",
    APPOINTMENTS: "route.appointments",
    BOOKING: "route.booking",
    BOOKING_PRACTITIONER_SCHEDULE: "route.booking.practitioner-schedule",
    BOOKING_SCHEDULES: "route.booking.schedules",
    CONTACT: "route.contact",
    DASHBOARD: "route.dashboard",
    FAQ: "route.faq",
    LANDING: "route.root",
    LOGOUT: "route.logout",
    LOST_PETS: "route.lost",
    LOST_PETS_RESET: "route.lost.reset",
    MEMBERS_BENEFITS: "route.members-benefits",
    MESSENGER: "route.messenger",
    ONBOARDING: "route.onboarding",
    ONBOARDING_SIGNUP: "route.onboarding.signup",
    ONBOARDING_SUBSCRIPTION: "route.onboarding.subscription",
    PARTNERS: "route.partners.home",
    PARTNERS_SIGNUP: "route.partners.signup",
    PASSWORD_FORGET: "route.password-forget",
    PAYMENTS: "route.payments",
    PETS: "route.pets",
    PET_CREATE: "route.pets.pet-create",
    PET_DETAIL: "route.pets.pet-detail",
    PET_EMR_DETAIL: "route.pets.pet-emr-detail",
    PET_VET_DETAIL: "route.pets.pet-vet-detail",
    PRIVACY_POLICY: "route.privacy-policy",
    PROFESSIONALS: "route.professional",
    PROFESSIONALS_SIGNUP: "route.professional.signup",
    PUBLIC_BOOKING: "route.public-booking",
    PURCHASE: "route.purchase",
    SCHEDULE: "route.schedule",
    SHOP: "route.shop",
    SIGN_IN: "route.sign-in",
    SIGN_UP: "route.sign-up",
    SUBSCRIPTION_STATUS: "route.subscription-status",
    TAGS: "route.tags",
    TERMS_OF_SERVICE: "route.terms-of-service",
    UPDATE_EMAIL: "route.update-email",
    VERIFY_EMAIL: "route.verify-email",
} as const;

export type RouteId = typeof ROUTES[keyof typeof ROUTES];
interface RouteMessageDescriptor<T extends string> extends MessageDescriptor {
    id: T;
}
type RouteMessageObject = { [K in RouteId]: RouteMessageDescriptor<K> };

export const routeNames: Record<string, MessageDescriptor> = defineMessages<
    RouteId,
    MessageDescriptor,
    RouteMessageObject
>({
    [ROUTES.LANDING]: {
        id: "route.root",
        defaultMessage: "/:lang/",
        description: "[Routes] Root page",
    },
    [ROUTES.ABOUT]: {
        id: "route.about",
        defaultMessage: "/:lang/about",
        description: "[Routes] About page",
    },
    [ROUTES.ACCOUNT]: {
        id: "route.account",
        defaultMessage: "/:lang/dashboard/account",
        description: "[Routes] Account",
    },
    [ROUTES.APPOINTMENTS]: {
        id: "route.appointments",
        defaultMessage: "/:lang/dashboard/appointments",
        description: "[Routes] Appointments",
    },
    [ROUTES.PAYMENTS]: {
        id: "route.payments",
        defaultMessage: "/:lang/dashboard/payments",
        description: "[Routes] Payments",
    },
    [ROUTES.BOOKING]: {
        id: "route.booking",
        defaultMessage: "/:lang/dashboard/booking",
        description: "[Routes] Booking",
    },
    [ROUTES.PUBLIC_BOOKING]: {
        id: "route.public-booking",
        defaultMessage: "/:lang/booking",
        description: "[Routes] Public Booking Schedules",
    },
    [ROUTES.BOOKING_SCHEDULES]: {
        id: "route.booking.schedules",
        defaultMessage: "/:lang/dashboard/booking/schedules",
        description: "[Routes] Booking Schedules",
    },
    [ROUTES.BOOKING_PRACTITIONER_SCHEDULE]: {
        id: "route.booking.practitioner-schedule",
        defaultMessage: "/:lang/dashboard/booking/practitioners/:id/schedule",
        description: "[Routes] Booking Practitioner's Schedule",
    },
    [ROUTES.CONTACT]: {
        id: "route.contact",
        defaultMessage: "/:lang/contact",
        description: "[Routes] Contact Us page",
    },
    [ROUTES.DASHBOARD]: {
        id: "route.dashboard",
        defaultMessage: "/:lang/dashboard",
        description: "[Routes] Dashboard",
    },
    [ROUTES.FAQ]: {
        id: "route.faq",
        defaultMessage: "/:lang/faq",
        description: "[Routes] FAQ",
    },
    [ROUTES.MESSENGER]: {
        id: "route.messenger",
        defaultMessage: "/:lang/dashboard/messenger",
        description: "[Routes] Messenger",
    },
    [ROUTES.PARTNERS]: {
        id: "route.partners.home",
        defaultMessage: "/:lang/partners",
        description: "[Routes] Partners home",
    },
    [ROUTES.PARTNERS_SIGNUP]: {
        id: "route.partners.signup",
        defaultMessage: "/:lang/partners/signup",
        description: "[Routes] Partners signup",
    },
    [ROUTES.PETS]: {
        id: "route.pets",
        defaultMessage: "/:lang/dashboard/pets",
        description: "[Routes] Pets page",
    },
    [ROUTES.PET_DETAIL]: {
        id: "route.pets.pet-detail",
        defaultMessage: "/:lang/dashboard/pets/:petId",
        description: "[Routes] Pet details page",
    },
    [ROUTES.PET_VET_DETAIL]: {
        id: "route.pets.pet-vet-detail",
        defaultMessage: "/:lang/dashboard/pets/:petId/vet/:vetId",
        description: "[Routes] Vet details for given pet",
    },
    [ROUTES.PET_EMR_DETAIL]: {
        id: "route.pets.pet-emr-detail",
        defaultMessage: "/:lang/dashboard/pets/:petId/emr/:medicalRecordId",
        description: "[Routes] Pet medical record page",
    },
    [ROUTES.PET_CREATE]: {
        id: "route.pets.pet-create",
        defaultMessage: "/:lang/dashboard/pets/new",
        description: "[Routes] Pet creation page",
    },
    [ROUTES.ONBOARDING]: {
        id: "route.onboarding",
        defaultMessage: "/:lang/onboarding",
        description: "[Routes] Onboarding root",
    },
    [ROUTES.ONBOARDING_SUBSCRIPTION]: {
        id: "route.onboarding.subscription",
        defaultMessage: "/:lang/onboarding/subscription",
        description: "[Routes] Onboarding subscription step",
    },
    [ROUTES.ONBOARDING_SIGNUP]: {
        id: "route.onboarding.signup",
        defaultMessage: "/:lang/onboarding/signup",
        description: "[Routes] Onboarding signup step",
    },
    [ROUTES.VERIFY_EMAIL]: {
        id: "route.verify-email",
        defaultMessage: "/:lang/onboarding/verify-email",
        description: "[Routes] Verify email dialog",
    },
    [ROUTES.PASSWORD_FORGET]: {
        id: "route.password-forget",
        defaultMessage: "/:lang/password-forget",
        description: "[Routes] Forgotten Password Request page",
    },
    [ROUTES.PROFESSIONALS]: {
        id: "route.professional",
        defaultMessage: "/:lang/professionals",
        description: "[Routes] Professional marketing page",
    },
    [ROUTES.PROFESSIONALS_SIGNUP]: {
        id: "route.professional.signup",
        defaultMessage: "/:lang/professionals/signup",
        description: "[Routes] Professional signup page",
    },
    [ROUTES.PRIVACY_POLICY]: {
        id: "route.privacy-policy",
        defaultMessage: "/:lang/privacy-policy",
        description: "[Routes] Privacy Policy",
    },
    [ROUTES.SCHEDULE]: {
        id: "route.schedule",
        defaultMessage: "/:lang/dashboard/schedule",
        description: "[Routes] Schedule page",
    },
    [ROUTES.SHOP]: {
        id: "route.shop",
        defaultMessage: "/:lang/shop",
        description: "[Routes] Shop page",
    },
    [ROUTES.PURCHASE]: {
        id: "route.purchase",
        defaultMessage: "/:lang/purchase/:id",
        description: "[Routes] Purchase page",
    },
    [ROUTES.SIGN_IN]: {
        id: "route.sign-in",
        defaultMessage: "/:lang/sign-in",
        description: "[Routes] Sign In page",
    },
    [ROUTES.SIGN_UP]: {
        id: "route.sign-up",
        defaultMessage: "/:lang/sign-up",
        description: "[Routes] Sign Up page",
    },
    [ROUTES.SUBSCRIPTION_STATUS]: {
        id: "route.subscription-status",
        defaultMessage: "/:lang/dashboard/subscription",
        description: "[Routes] Subscription status page",
    },
    [ROUTES.TAGS]: {
        id: "route.tags",
        defaultMessage: "/:lang/tags",
        description: "[Routes] Tags Page",
    },
    [ROUTES.TERMS_OF_SERVICE]: {
        id: "route.terms-of-service",
        defaultMessage: "/:lang/terms-of-service",
        description: "[Routes] Terms Of Service",
    },
    [ROUTES.UPDATE_EMAIL]: {
        id: "route.update-email",
        defaultMessage: "/:lang/update-email",
        description: "[Routes] Update Email",
    },
    [ROUTES.LOST_PETS]: {
        id: "route.lost",
        defaultMessage: "/:lang/lost",
        description: "[Routes] BetterPaws Lost pets",
    },
    [ROUTES.LOST_PETS_RESET]: {
        id: "route.lost.reset",
        defaultMessage: "/:lang/lost/reset-pin",
        description: "[Routes] Betterpaws Lost pets pin reset",
    },
    [ROUTES.LOGOUT]: {
        id: "route.logout",
        defaultMessage: "/:lang/logout",
        description: "[Routes] Logout",
    },
    [ROUTES.MEMBERS_BENEFITS]: {
        id: "route.members-benefits",
        defaultMessage: "/:lang/members/",
        description: "[Routes] Members Benefits page",
    },
});

export default ROUTES;
