import { useCallback, useEffect, useMemo, useState } from "react";

import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { sendEmailVerification } from "firebase/auth";
import { captureException } from "@sentry/react";

import { firebaseAuth } from "auth/firebase/firebase";
import { SIGN_UP_CLIENT, LOGIN, GET_USER_IS_MEMBER } from "api/graphql";
import { useBackendErrorHandler } from "hooks/api/graphql";
import ROUTES from "routes";
import { TRACKER_EVENT, USER_TYPE } from "app/constants";
import { useTimeZone, useHistoryActions } from "hooks/browser";
import { useAppDispatch, useAppSelector } from "hooks/app";
import { userSignIn } from "app/store/user-reducer";
import Toasts from "utilities/toasts";
import { useAuthenticateMutation } from "services/emr-service";
import { useTracker } from "hooks/analytics";

import { useAuthentication } from ".";

type ErrorHandler = (err: Error) => void;

export const useCreateUser = (errorHandler?: ErrorHandler) => {
    const [clientSignUp] = useMutation(SIGN_UP_CLIENT);
    const signUpErrorHandler = useBackendErrorHandler({
        LOGIN_FAILED: {},
    });
    const { locale } = useIntl();
    const timeZone = useTimeZone();
    const dispatch = useAppDispatch();
    const tracker = useTracker();

    return (variables: { firebaseIdToken: string; recaptchaToken: string } & Record<string, any>) =>
        clientSignUp({
            variables: {
                ...variables,
                language: locale,
                timeZone,
            },
        })
            .then(({ data }) => {
                if (!firebaseAuth.currentUser.emailVerified) {
                    // verify the user's email
                    sendEmailVerification(firebaseAuth.currentUser).catch(signUpErrorHandler);
                }

                // sign in user
                dispatch(userSignIn(data.clientCreate.user));

                // update tracking info
                tracker({
                    event: TRACKER_EVENT.SIGN_UP,
                    userId: data.clientCreate.user.id,
                    userType: USER_TYPE.CLIENT,
                });
            })
            .catch((e: Error) => {
                if (errorHandler) {
                    errorHandler(e);
                    return;
                }
                if (e.message === "LOGIN_FAILED") {
                    signUpErrorHandler(e);
                } else throw e;
            });
};

export const useLoginUser = (errorHandler?: ErrorHandler) => {
    const [login] = useMutation(LOGIN);
    const { isAuthenticated } = useAuthentication();
    const { formatMessage } = useIntl();
    const timeZone = useTimeZone();
    const dispatch = useAppDispatch();
    const [loginEmrUser] = useAuthenticateMutation();
    const signInErrorHandler = useBackendErrorHandler({
        LOGIN_FAILED: {
            message: formatMessage({ defaultMessage: "Invalid username or password; did you create an account?" }),
            type: Toasts.types.ERROR,
            params: {
                position: Toasts.positions.TOP_CENTER,
            },
        },
    });

    const doLogin = useCallback(
        async (variables: { firebaseIdToken: string } & Record<string, any>, force: boolean = false) => {
            try {
                if (!isAuthenticated || force) {
                    const { data } = await login({
                        variables: {
                            ...variables,
                            timeZone, // Ensure that user's time zone is current
                        },
                    });
                    dispatch(userSignIn(data.login.user));
                }
            } catch (e) {
                if (errorHandler) {
                    errorHandler(e);

                    return;
                }
                if (e.message === "LOGIN_FAILED") {
                    signInErrorHandler(e);
                }
                throw e;
            }

            if (firebaseAuth.currentUser) {
                await loginEmrUser({
                    email: firebaseAuth.currentUser.email,
                    password: firebaseAuth.currentUser.uid,
                    strategy: "local",
                }).catch(captureException);
            }
        },
        [dispatch, errorHandler, isAuthenticated, login, loginEmrUser, signInErrorHandler, timeZone]
    );

    return doLogin;
};

export const useLogoutUser = (errorHandler?: ErrorHandler) => {
    const { isAuthenticated } = useAuthentication();
    const apolloClient = useApolloClient();
    const { push: historyPush } = useHistoryActions();

    type LogoutParams = { force?: boolean };

    const doLogout = useCallback(
        async ({ force = false }: LogoutParams = {}) => {
            // TODO: logout should first redirect to an intermediate page, then do the actual
            // logout of the user
            try {
                if (isAuthenticated || force) {
                    await apolloClient.clearStore(); // TODO: What is this doing here?
                    historyPush(ROUTES.LOGOUT, {
                        state: {
                            doLogout: true,
                        },
                    });
                }
            } catch (e) {
                if (errorHandler) {
                    errorHandler(e);
                    return;
                }
                throw e;
            }
        },
        [apolloClient, errorHandler, historyPush, isAuthenticated]
    );

    return doLogout;
};

export const useHasMembership = (errorHandler?: ErrorHandler) => {
    const [isCheckingMembership, setIsCheckingMembership] = useState<boolean>(undefined);
    const user = useAppSelector((state) => state.users.user);
    const [isMember, setIsMember] = useState<boolean>(undefined);
    const [membershipStatus, setMembershipStatus] = useState<"FREE" | "TRIALING" | "ACTIVE">();
    const [checkMembership, { data: membershipData, loading: loadingMembership, called: membershipCheckCalled }] =
        useLazyQuery(GET_USER_IS_MEMBER, {
            onError: (e) => {
                if (!e.networkError) {
                    errorHandler?.(e);
                }
            },
            fetchPolicy: "no-cache",
        });

    useEffect(() => {
        if (isCheckingMembership && !membershipCheckCalled) {
            checkMembership();
        }
        if (membershipCheckCalled && !loadingMembership) {
            setIsMember(membershipData?.userHasMembershipSubscription?.isMember);
            setMembershipStatus(membershipData?.userHasMembershipSubscription?.subscriptionState);
            setIsCheckingMembership(false);
        }
    }, [loadingMembership, membershipData, membershipCheckCalled, isCheckingMembership, checkMembership]);

    const checkUserIsMember = useCallback(
        ({ force = false }: { force?: boolean } = {}) => {
            if (user.isPractitioner) {
                // This is to prevent practitioners from being required to purchase a subscription
                // FIXME: Is there a better way?
                setIsMember(true);
            } else if (user.isClient || force) {
                setIsCheckingMembership(true);
            }
        },
        [user]
    );

    return useMemo(
        () =>
            [
                checkUserIsMember,
                {
                    isMember,
                    isCheckingMembership,
                    membershipStatus: membershipStatus?.toLowerCase() as Lowercase<typeof membershipStatus>,
                },
            ] as const,
        [checkUserIsMember, isMember, isCheckingMembership, membershipStatus]
    );
};
