import { useCallback } from "react";

import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import ReactPixel from "react-facebook-pixel";

import { TRACKER_EVENT, USER_TYPE } from "app/constants";

interface TrackerData {
    event: typeof TRACKER_EVENT[keyof typeof TRACKER_EVENT];
    userId: string;
    userType?: typeof USER_TYPE[keyof typeof USER_TYPE];
}

// eslint-disable-next-line import/prefer-default-export
export const useTracker = () => {
    const sendDataToGTM = useGTMDispatch();
    const tracker = useCallback(
        ({ event, ...data }: TrackerData) => {
            sendDataToGTM({ event, ...data });
            ReactPixel.trackCustom(event, data);
        },
        [sendDataToGTM]
    );
    return tracker;
};
