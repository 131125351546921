import { createElement } from "react";

import { createIntl, createIntlCache } from "@formatjs/intl";

import timezone from "./helpers/timezone";

export const supportedLocales = process.env.REACT_APP_SUPPORTED_LOCALES.split(",");

export const languageMap: Record<string, string> = supportedLocales.reduce(
    (acc, spec) => ({ ...acc, [spec]: new Intl.Locale(spec).language }),
    {}
);

// https://github.com/formatjs/formatjs/issues/1441
const intlCache = createIntlCache();
export const switchAppIntl = async (locale: string) => {
    // FIXME: Fix the name of this chunk...
    const localeMessages = await import(
        /* webpackMode: "lazy-once" */
        /* webpackPreload: true */
        /* webpackChunkName: "i18n/lang" */
        `i18n/compiled/${locale}.json`
    );

    const intl = createIntl(
        {
            locale,
            timeZone: timezone(),
            messages: localeMessages,
            // https://formatjs.io/docs/react-intl/components/#defaultrichtextelements
            defaultRichTextElements: {
                italic: (...chunks: unknown[]) => createElement("span", { className: "italic" }, ...chunks),
                span: (...chunks: unknown[]) => createElement("span", {}, ...chunks),
            },
        },
        intlCache
    );
    return intl;
};

export const languageNames = (() => {
    const supportedLanguages = Object.values(languageMap);
    return Object.fromEntries(
        Object.entries({
            en: "English",
            es: "Español",
        }).filter(([language]) => language in supportedLanguages)
    );
})();

export const getProvinceDescriptor = (country: string, formatMessage: (arg: any) => string) => {
    switch (country) {
        case "CA":
            return formatMessage({ defaultMessage: "province" });
        case "MX":
        case "US":
            return formatMessage({ defaultMessage: "state" });
        default:
            return formatMessage({ defaultMessage: "state or province" });
    }
};
