import { lazy, Suspense, useEffect } from "react";

import { Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { onIdTokenChanged } from "firebase/auth";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// @ts-ignore
import { CartProvider } from "use-shopping-cart";
import { RawIntlProvider } from "react-intl";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";

import LocalizedRouter from "components/l10n/LocalizedRouter";
import AiChatbot from "components/chat/AiChatbot";
import Navigation from "pages/Root/components/Navigation";
import TidioProvider from "components/shared/Tidio";
import ROUTES from "routes";
import LocalizedSwitch from "components/l10n/LocalizedSwitch";
import { firebaseAuth } from "auth/firebase/firebase";
import { switchLocale } from "app/store/locale-reducer";
import { useAppDispatch, useAppSelector } from "hooks/app";
import PrivateRoute from "components/auth/PrivateRoute";
import AuthenticationProvider from "components/auth/AuthenticationProvider";
import SuspenseOverlay from "components/shared/SuspenseOverlay";
import LogoutPage from "pages/Logout";
import { useIsFeatureEnabled } from "hooks/features";
import { FEATURE } from "utilities/features";

import { firebaseUserUpdate } from "./store/user-reducer";

import "react-toastify/dist/ReactToastify.min.css";
import "../assets/css/tailwind.css";
import "../assets/css/custom.scss";
import "pure-react-carousel/dist/react-carousel.es.css";

const OnboardingPage = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "Onboarding" */
            "pages/Onboarding"
        )
);
const ProfessionalsPage = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "Professionals" */
            "pages/Professionals"
        )
);
const DashBoardPage = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "dashboard" */
            "pages/Dashboard"
        )
);
const LandingPage = lazy(
    () =>
        import(
            /* webpackPreload: true */
            /* webpackChunkName: "Root" */
            "pages/Root"
        )
);

const ShopPage = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "Shop" */
            "pages/Shop"
        )
);

const PurchasePage = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "PurchasePage" */
            "pages/Purchase"
        )
);

const PartnersPage = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "PartnersPage" */
            "pages/Partners"
        )
);

const LostPetsPage = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "LostPets" */
            "pages/LostPets"
        )
);

// props are defined outside the component tree to prevent unneccessary reloading of the script
const recaptchaScriptProps: React.ComponentProps<typeof GoogleReCaptchaProvider>["scriptProps"] = {
    appendTo: "body",
    async: true,
};

const gtmParams: React.ComponentPropsWithoutRef<typeof GTMProvider>["state"] = {
    id: "GTM-5GMV6VF",
    environment: {
        gtm_auth: process.env.REACT_APP_GTM_AUTH,
        gtm_preview: process.env.REACT_APP_GTM_PREVIEW,
    },
};

const App = () => {
    const dispatch = useAppDispatch();
    const { language, intl } = useAppSelector((state) => state.appLocale);
    const isChatbotEnabled = useIsFeatureEnabled(FEATURE.CHATBOT);

    useEffect(() => {
        // @ts-ignore
        const browserLocale = [...navigator.languages, navigator.userLanguage ?? navigator.language].join(",");
        dispatch(switchLocale(browserLocale));
    }, [dispatch]);

    useEffect(() => {
        const unsubscribe = onIdTokenChanged(firebaseAuth, (user) => {
            if (user) {
                const { uid, displayName, email, phoneNumber, photoURL, emailVerified } = user;
                dispatch(firebaseUserUpdate({ uid, displayName, email, phoneNumber, photoURL, emailVerified }));
            } else {
                dispatch(firebaseUserUpdate({}));
            }
        });
        return unsubscribe;
    }, [dispatch]);

    useEffect(() => {
        firebaseAuth.languageCode = language;
        document.tidioChatLang = language;
    }, [language]);

    if (intl == null) {
        console.log("We are null...");
        // TODO: Is there a better way?
        return null;
    }

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            useRecaptchaNet
            scriptProps={recaptchaScriptProps}
        >
            <CartProvider
                stripe={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                currency={process.env.REACT_APP_CURRENCY}
                cartMode="checkout-session"
            >
                <GTMProvider state={gtmParams}>
                    <RawIntlProvider value={intl}>
                        <ToastContainer />
                        <Suspense fallback={<SuspenseOverlay />}>
                            {/* https://medium.com/ableneo/internationalize-react-apps-done-right-using-react-intl-library-82978dbe175e */}
                            <LocalizedRouter>
                                <TidioProvider tidioScriptUrl={process.env.REACT_APP_TIDIO_CHAT_CODE}>
                                    <AuthenticationProvider>
                                        {/* <LanguageSwitcher /> */}
                                        <div className="relative flex flex-col items-stretch max-w-screen h-screen bg-pink-100">
                                            <div className="fixed z-30 top-0">
                                                <Navigation />
                                            </div>
                                            <div className="flex flex-1 pt-16 max-h-screen box-border">
                                                <LocalizedSwitch>
                                                    <Route path={ROUTES.ONBOARDING} component={OnboardingPage} />
                                                    <PrivateRoute path={ROUTES.DASHBOARD} component={DashBoardPage} />
                                                    <Route path={ROUTES.PROFESSIONALS} component={ProfessionalsPage} />
                                                    <Route path={ROUTES.SHOP} component={ShopPage} />
                                                    <Route path={ROUTES.PURCHASE} component={PurchasePage} />
                                                    <Route path={ROUTES.PARTNERS} component={PartnersPage} />
                                                    <Route path={ROUTES.LOGOUT} component={LogoutPage} />
                                                    <Route path={ROUTES.LOST_PETS} component={LostPetsPage} />
                                                    <Route path={ROUTES.LANDING} component={LandingPage} />
                                                    {/* <Route path={ROUTES.PUBLIC_BOOKING} component={PublicBooking} /> */}
                                                </LocalizedSwitch>
                                            </div>
                                        </div>
                                        {isChatbotEnabled && <AiChatbot />}
                                    </AuthenticationProvider>
                                </TidioProvider>
                            </LocalizedRouter>
                        </Suspense>
                    </RawIntlProvider>
                </GTMProvider>
            </CartProvider>
        </GoogleReCaptchaProvider>
    );
};
export default App;
