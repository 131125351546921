import CircularProgress from "@mui/material/CircularProgress";

import clsx from "clsx";

export default ({ nested = false }) => (
    <div
        className={clsx("fade-in opacity-50", { "fixed z-50 left-0 top-0 w-full h-screen": !nested })}
        style={{ background: "rgba(127, 127, 127, 0.5)" }}
    >
        <div
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
            }}
        >
            <CircularProgress color="secondary" />
        </div>
    </div>
);
