interface LogoSmallProps {
    wordClassName?: string;
    baseClassName?: string;
}

const LogoSmall: React.FC<LogoSmallProps> = ({ wordClassName = "text-beta", baseClassName = "text-alpha" }) => (
    <svg viewBox="0 0 17.91525 17.91525" xmlns="http://www.w3.org/2000/svg">
        <g style={{ display: "inline", opacity: 1 }}>
            <g style={{ display: "inline", opacity: 1 }}>
                <g
                    style={{
                        opacity: 1,
                        fill: "#009ce7",
                        fillOpacity: 1,
                        fillRule: "evenodd",
                    }}
                >
                    <g style={{ fill: "#009ce7", fillOpacity: 1 }}>
                        <path
                            style={{ fillOpacity: 1, stroke: "none" }}
                            className={`fill-current ${baseClassName}`}
                            d="M-126.267 1038.85c22.737 50.44 15.792 102.75-15.51 116.87-31.303 14.12-75.11-15.31-97.845-65.74-22.737-50.43-15.793-102.745 15.51-116.863 31.303-14.114 75.108 15.317 97.845 65.733zM183.155 1038.85c-22.738 50.44-15.793 102.75 15.512 116.87 31.303 14.12 75.106-15.31 97.846-65.74 22.734-50.43 15.789-102.745-15.513-116.863-31.301-14.114-75.108 15.317-97.845 65.733zM6.7856 937.757C18.4404 991.826.6748 1041.52-32.8931 1048.76c-33.5654 7.23-70.2249-30.74-81.8779-84.804-11.653-54.068 6.112-103.764 39.6792-110.997 33.5669-7.236 70.2246 30.729 81.8774 84.798zM49.2676 937.803C37.623 991.871 55.376 1041.57 88.9414 1048.8c33.5676 7.24 70.2256-30.73 81.8776-84.797 11.654-54.069-6.109-103.765-39.678-110.998-33.5678-7.234-70.225 30.729-81.8734 84.798zM-35.2275 1118.5c-8.1924 14.15-46.1563 60.99-72.4145 76.97-26.256 15.98-58.792 39.38-53.332 93.11 5.457 53.74 60.575 76.74 96.8597 74.7 36.2867-2.03 104.6993-8.71 153.543-1.94 48.8413 6.77 110.4863 1.64 124.9223-49.81 14.436-51.45-17.85-84.23-43.044-102.83-25.193-18.59-67.265-74.2-80.2269-99.73-12.96-25.52-78.9268-72.26-126.3076 9.53z"
                            transform="translate(8.15577 -24.18675) scale(.03005)"
                        />
                    </g>
                </g>
            </g>
            <g
                aria-label="BP"
                style={{
                    fontSize: "10.5833px",
                    lineHeight: "1.25",
                    fill: "#e91d63",
                    strokeWidth: ".264583",
                }}
            >
                <path
                    className={`fill-current ${wordClassName}`}
                    d="M114.39519 126.92583q0-.4445-.34925-.60325-.33866-.16933-1.14299-.16933v1.61924q.49741 0 .80433-.0741.3175-.0741.49741-.254.1905-.1905.1905-.51858zm.13758 2.82574q0-.34925-.17991-.53975-.17992-.1905-.51858-.26458-.33867-.0847-.93133-.0847v1.83091q.82549 0 1.22766-.20108.40216-.21166.40216-.74083zm1.30175-1.38641q.98425.58208.98425 1.55574 0 .42333-.16934.81492-.15875.381-.48683.69849-.3175.3175-.84666.508-.52917.17992-1.19591.17992h-2.45533q-.64558 0-.93133-.32808-.27517-.32809-.27517-1.17475v-4.47674q0-.35983.127-.63499.127-.27517.40217-.4445.28575-.16933.67733-.16933h2.42358q1.05833.0106 1.75682.58208.70908.56091.70908 1.44991 0 .28575-.0529.508-.0423.21166-.13758.381-.0953.16933-.22225.29633-.127.127-.30692.254zM121.98342 127.39149q0-.39158-.0741-.61383-.0741-.23283-.26458-.34925-.17992-.11641-.40217-.14816-.21166-.0423-.59266-.0423v2.32833q.29633 0 .51858-.0529.23283-.0529.42333-.17991.1905-.127.28575-.35983.10584-.24342.10584-.58209zm-1.3335 3.15383q0 .85724-.3175 1.22766-.3175.35983-.97366.35983-.65617 0-.96308-.32808-.30692-.33867-.30692-1.18533v-4.46615q0-.64558.34925-.9525.34925-.3175.92075-.3175h1.88382q.68792 0 1.27.1905.58208.17992 1.00541.51858.43392.32809.66675.83608.24342.49742.24342 1.11125 0 .75141-.43392 1.31233-.43391.55033-1.14299.8255-.70909.27516-1.59808.27516h-.60325z"
                    style={{
                        fontFamily: "Coiny",
                    }}
                    transform="translate(-108.34793 -119.3)"
                />
            </g>
        </g>
    </svg>
);

export default LogoSmall;
