import { useMemo, useState, memo } from "react";

import Drawer from "@mui/material/Drawer";
import CircularProgress from "@mui/material/CircularProgress";
import { lighten } from "@mui/system";

import clsx from "clsx";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Logo from "components/shared/svg/Logo";
import LocalizedNavLink from "components/l10n/LocalizedNavLink";
import { makeStyles } from "theme";
import ROUTES from "routes";
import { useAuthentication } from "hooks/auth";

interface NavbarProps {
    transparent?: boolean;
    withSkeletonRoutes?: boolean;
}

const useStyles = makeStyles()((theme) => ({
    drawer: {
        "&.MuiPaper-root": {
            backgroundColor: lighten(theme.palette.primary.main, 0.3),
        },
    },
}));

const inActiveLinkClass = clsx("hover:underline");
const activeLinkClass = clsx("text-gray-700 font-bold lg:mb-0 lg:pb-0.5 lg:border-b-2");
const defaultLinkClass = clsx(
    "mb-4 text-quaternary-800 whitespace-nowrap text-lg antialiased font-semibold border-solid border-gray-700 transition duration-500 ease-in-out sm:mx-6 sm:subpixel-antialiased md:antialiased lg:mb-0 lg:mx-8 lg:text-lg"
);

const skeletonRoute = [
    <div key={1} className={clsx(defaultLinkClass, "w-3/4 h-1/2 bg-secondary-300 rounded animate-pulse")} />,
    <div key={2} className={clsx(defaultLinkClass, "w-3/4 h-1/2 bg-secondary-300 rounded animate-pulse")} />,
    <div key={3} className={clsx(defaultLinkClass, "w-3/4 h-1/2 bg-secondary-300 rounded animate-pulse")} />,
    <div key={4} className={clsx(defaultLinkClass, "w-3/4 h-1/2 bg-secondary-300 rounded animate-pulse")} />,
    <div key={5} className={clsx(defaultLinkClass, "w-3/4 h-1/2 bg-secondary-300 rounded animate-pulse")} />,
];

const Navbar: React.FC<NavbarProps> = memo(({ transparent = false, withSkeletonRoutes = false }) => {
    const { classes } = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);
    const { isAuthenticated, isAuthenticating } = useAuthentication();

    const navRoutes = useMemo(
        () => [
            <LocalizedNavLink
                key={1}
                to={ROUTES.ABOUT}
                className={(isActive) => clsx(defaultLinkClass, isActive ? activeLinkClass : inActiveLinkClass)}
            >
                About Us
            </LocalizedNavLink>,
            <LocalizedNavLink
                key={2}
                to={ROUTES.FAQ}
                className={(isActive) => clsx(defaultLinkClass, isActive ? activeLinkClass : inActiveLinkClass)}
            >
                FAQ
            </LocalizedNavLink>,
            <LocalizedNavLink
                key={3}
                to={ROUTES.SHOP}
                className={(isActive) => clsx(defaultLinkClass, isActive ? activeLinkClass : inActiveLinkClass)}
            >
                <FormattedMessage defaultMessage="Shop" />
            </LocalizedNavLink>,
            <Link key={4} to={{ pathname: process.env.REACT_APP_BLOG_LINK }} target="_blank">
                <div className={defaultLinkClass}>
                    Blog
                    <i className="fas fa-external-link-alt mx-1 text-center text-quaternary-800 text-sm" />
                </div>
            </Link>,
            <LocalizedNavLink
                key={5}
                to={ROUTES.LOST_PETS}
                className={(isActive) => clsx(defaultLinkClass, isActive ? activeLinkClass : inActiveLinkClass)}
            >
                <div className="bg-orange-300 border-8 border-orange-300 rounded-lg">Find Your Lost Pet </div>
            </LocalizedNavLink>,
            <div key={6} className="hover flex flex-1 flex-col-reverse items-center justify-end lg:flex-row">
                {isAuthenticated && (
                    <LocalizedNavLink
                        to={ROUTES.DASHBOARD}
                        className={(isActive) => clsx(defaultLinkClass, isActive ? activeLinkClass : inActiveLinkClass)}
                    >
                        Dashboard
                    </LocalizedNavLink>
                )}
                {isAuthenticating && <CircularProgress color="secondary" />}
                {!(isAuthenticating || isAuthenticated) && (
                    <>
                        <div className="flex py-2 lg:w-24">
                            <LocalizedNavLink
                                to={ROUTES.SIGN_IN}
                                className={(isActive) =>
                                    clsx(defaultLinkClass, isActive ? activeLinkClass : inActiveLinkClass)
                                }
                            >
                                <FormattedMessage defaultMessage="Sign In" />
                            </LocalizedNavLink>
                        </div>
                        <div className="flex py-2 lg:w-24">
                            <LocalizedNavLink
                                to={ROUTES.ONBOARDING}
                                className={(isActive) =>
                                    clsx(defaultLinkClass, isActive ? activeLinkClass : inActiveLinkClass)
                                }
                            >
                                <FormattedMessage defaultMessage="Sign Up" />
                            </LocalizedNavLink>
                        </div>
                    </>
                )}
            </div>,
        ],
        [isAuthenticated, isAuthenticating]
    );

    return (
        <nav className="flex-0 flex justify-center w-screen h-16 tracking-wide bg-transparent">
            <div className="flex justify-between px-2 py-2 w-full bg-secondary-400 hover:shadow-lg overscroll-none transition duration-500 ease-in-out sm:px-6 md:px-6 xl:px-12">
                <div className="flex-0 flex items-center justify-start">
                    {withSkeletonRoutes ? (
                        <div
                            className={clsx("flex w-32 cursor-pointer sm:w-36 md:w-40", {
                                "filter grayscale": withSkeletonRoutes,
                            })}
                        >
                            <Logo />
                        </div>
                    ) : (
                        <LocalizedNavLink to={ROUTES.LANDING} activeStyle={{ textDecoration: "underline" }}>
                            <div
                                className={clsx("flex w-32 cursor-pointer sm:w-36 md:w-40", {
                                    "filter grayscale": withSkeletonRoutes,
                                })}
                            >
                                <Logo />
                            </div>
                        </LocalizedNavLink>
                    )}
                </div>
                <div className="hidden flex-1 items-center justify-start xl:flex">
                    {withSkeletonRoutes ? skeletonRoute : navRoutes}
                </div>
                <Drawer
                    className="flex items-center xl:hidden"
                    PaperProps={{ className: classes.drawer }}
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={closeDrawer}
                >
                    <div
                        className={clsx(
                            "flex flex-col items-start justify-between p-7 w-80 h-1/2 bg-secondary-300",
                            transparent ? "text-white" : "text-quaternary-800"
                        )}
                    >
                        {withSkeletonRoutes ? skeletonRoute : navRoutes}
                    </div>
                </Drawer>
                <div className="flex flex-1 justify-end xl:hidden">
                    <button
                        onClick={openDrawer}
                        type="button"
                        className="flex items-center justify-center w-10 h-full text-gray-800 text-xl bg-transparent rounded-full"
                    >
                        <i className="fas fa-bars" />
                    </button>
                </div>
            </div>
        </nav>
    );
});

export default Navbar;
