import { Message } from "@aws-sdk/client-lex-runtime-v2";
import { ChatMessage, CreateChatbotMessage } from "react-chatbot-kit";

import { IActionProvider, IChatbotState } from "./type-utils";

class AiChatbotActionProvider implements IActionProvider {
    constructor(
        private createChatBotMessage: CreateChatbotMessage,
        private setState: React.Dispatch<React.SetStateAction<IChatbotState>>
    ) {}

    handleUserSpeaking(isSpeaking: boolean): void {
        this.setChatMode(isSpeaking);
        if (isSpeaking) {
            const audioMessage = this.createChatBotMessage(null, {
                widget: "recording",
                withAvatar: false,
                delay: 500,
            });
            this.addChatMessage(audioMessage);
        }
    }

    handleYesNoResponseMessage(message: string): void {
        if (message) {
            const booleanMessage = this.createChatBotMessage(message, {
                widget: "yesno",
                withAvatar: true,
            });
            this.addChatMessage(booleanMessage);
        }
    }

    handleAWSLexResponseMessage(messages: Message[]) {
        messages.forEach((message) => {
            const resp = this.createChatBotMessage(message.content);
            this.addChatMessage(resp);
        });
    }

    // TODO BET-79: Remove this response
    handleStaticResponseMessage() {
        const response = this.createChatBotMessage("I am sentient!");
        this.addChatMessage(response);
    }

    private addChatMessage(message: ChatMessage) {
        this.setState((state) => ({
            ...state,
            messages: [...state.messages, message],
        }));
    }

    private setChatMode(isSpeaking: boolean) {
        this.setState((state) => ({
            ...state,
            chatMode: isSpeaking ? "audio" : "text",
        }));
    }
}

export default AiChatbotActionProvider;
