import { createSlice } from "@reduxjs/toolkit";

import { emrApi } from "services/emr-service";
import { usersApi } from "services/emr-service/users";
import type { EmrServiceState } from "services/emr-service/types";

import { userSignOut } from "./user-reducer";

const initialState: EmrServiceState = {
    authorization: {},
};

const emrSlice = createSlice({
    name: "emr-auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(userSignOut.fulfilled, (state) => {
                state.authorization = initialState.authorization;
            })
            .addMatcher(usersApi.endpoints.createEmrUser.matchFulfilled, (state, { payload }) => {
                state.authorization.uid = payload;
            })
            .addMatcher(emrApi.endpoints.authenticate.matchFulfilled, (state, { payload }) => {
                state.authorization.token = payload.accessToken;
                state.authorization.uid = payload.uid;
            })
            .addMatcher(emrApi.endpoints.authenticate.matchRejected, (state) => {
                state.authorization = initialState.authorization;
            });
    },
});

export default emrSlice.reducer;
