import { defineMessage } from "react-intl";

// TODO: uppercase CONSULTATION_STATUSES values
export const CONSULTATION_STATUSES = {
    AWAITING_PAYMENT: "AWAITING_PAYMENT",
    CANCELLED: "CANCELLED",
    CONFIRMED: "CONFIRMED",
    CONFLICTED: "CONFLICTED",
    COMPLETED: "COMPLETED",
    MISSED: "MISSED",
    IN_PROGRESS: "IN_PROGRESS",
} as const;

export const COUNTRIES = {
    CANADA: "CA",
    MEXICO: "MX",
    USA: "US",
} as const;

export const JOIN_CALL_CHECK_ABILITY_INTERVAL = 3000;

export const USER_IDLE_TIMEOUT_MINUTES = parseInt(process.env.REACT_APP_USER_IDLE_TIMEOUT_MINUTES) || Infinity;

export const ORDER = {
    ASC: "asc",
    DESC: "desc",
} as const;

export const UNEXPECTED_ERROR_MESSAGE = defineMessage({ defaultMessage: "Something went wrong :(" });
export const RECAPTCHA_FAIL_ERROR_MESSAGE = defineMessage({
    defaultMessage: "Human verification failed. Please try again",
});

export const APP_FEE_TYPES = {
    PERCENTAGE: "PERCENTAGE",
    FLAT: "FLAT",
} as const;

export const SCHEDULES_DAY_TO_LOOK_AHEAD = 60;

export const TAG_TYPES = {
    SPECIALTY: "specialty",
    LANGUAGE: "language",
} as const;

export const TRACKER_EVENT = {
    SIGN_UP: "signUpTracker",
    PAYMENT: "paymentTracker",
    BOOKING: "bookingConsultationTracker",
} as const;

export const USER_TYPE = {
    CLIENT: "client",
    PRACTITIONER: "practitioner",
} as const;

export const NOTIFICATION_EVENTS = {
    CONSULTATION_ENDED: "CONSULTATION_ENDED",
    CONSULTATION_CANCELLED: "CONSULTATION_CANCELLED",
    CONSULTATION_CONFIRMED: "CONSULTATION_CONFIRMED",
    CONSULTATION_CONFLICTED: "CONSULTATION_CONFLICTED",
    CONSULTATION_RESCHEDULED: "CONSULTATION_RESCHEDULED",
    USER_UNVERIFIED: "USER_UNVERIFIED",
    USER_VERIFIED: "USER_VERIFIED",
} as const;

export const FIELD_NOT_SET = defineMessage({ defaultMessage: "Not Set" });

export const CONSULTATION_DURATION = {
    MIN: 300,
    MAX: 3600,
} as const;

export const PAYMENT_STATUSES = {
    APPROVED: "APPROVED",
    FAILED: "FAILED",
    PAID: "PAID",
    REFUNDED: "REFUNDED",
    SUBMITTED: "SUBMITTED",
} as const;

export const PAYMENTS_FILTER_NUMBER_OF_DAYS_TO_SUBTRACT = 30;

export const SCHEDULING_ACTION = {
    BATCH_CREATE: "batchCreate",
    BATCH_DELETE: "batchDelete",
    BATCH_CLEAR: "batchClear",
    DELETE: "delete",
} as const;
