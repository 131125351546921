import { AuthenticatePayload } from "./types";

import { emrApi } from ".";

const basePath = "/users";

export const usersApi = emrApi.injectEndpoints({
    endpoints: (builder) => ({
        createEmrUser: builder.mutation<string, Omit<AuthenticatePayload, "strategy">>({
            query: (data) => ({
                url: `${basePath}`,
                method: "POST",
                data,
            }),
            transformResponse: ({ _id }: any) => _id,
            extraOptions: {},
        }),
    }),
    overrideExisting: false,
});

export const { useCreateEmrUserMutation } = usersApi;
