import React from "react";

export interface ReactFrozenProps {
    frozen: boolean;
}

class ReactFrozen extends React.Component<ReactFrozenProps> {
    shouldComponentUpdate(nextProps: ReactFrozenProps) {
        if (nextProps.frozen) {
            return false;
        }
        // use shallow compare
        return this.props !== nextProps;
    }

    render() {
        // eslint-disable-next-line react/destructuring-assignment
        return this.props.children;
    }
}

export default ReactFrozen;
