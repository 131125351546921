import { useCallback } from "react";

import { useIntl } from "react-intl";
import type { LocationDescriptor } from "history";

import { RouteId, routeNames } from "routes";
import countries from "i18n/messages/countries";
import provinces from "i18n/messages/provinces";
import { getProvinceDescriptor } from "utilities/localization";
import { switchLocale } from "app/store/locale-reducer";

import { useAppDispatch, useAppSelector } from "./app";

export const useGetCountryName = () => {
    const { formatMessage } = useIntl();
    return useCallback(
        (countryCode: string) => (countryCode in countries ? formatMessage(countries[countryCode]) : ""),
        [formatMessage]
    );
};

export const useGetProvinceName = () => {
    const { formatMessage } = useIntl();
    return useCallback(
        (countryCode: string, provinceCode: string) =>
            (countryCode in provinces &&
                provinceCode in provinces[countryCode] &&
                formatMessage(provinces[countryCode][provinceCode])) ||
            "",
        [formatMessage]
    );
};

export const useGetProvinceDescriptor = () => {
    const { formatMessage } = useIntl();
    return useCallback((country: string) => getProvinceDescriptor(country, formatMessage), [formatMessage]);
};

export const useSwitchLanguage = () => {
    const { locale } = useIntl();
    const dispatch = useAppDispatch();

    return useCallback(
        async (language: string) => {
            if (locale === language) {
                return Promise.resolve();
            }

            return Promise.resolve(async () => {
                await dispatch(switchLocale(language));
            });
        },
        [dispatch, locale]
    );
};

const insertParamsToRoute = (route: string, params: any = {}) =>
    Object.keys(params).reduce(
        (routeWithParams, param) => routeWithParams.replaceAll(`:${param}`, params[param]),
        route
    );

export const useLocalizedRoutePath = () => {
    const { formatMessage } = useIntl();
    const language = useAppSelector((state) => state.appLocale.language);

    function resolveLocalizedRoutePath(path: [string, ...string[]], params?: Record<string, string>): string[];
    function resolveLocalizedRoutePath(path: string | RouteId, params?: Record<string, string>): string;
    function resolveLocalizedRoutePath(path: LocationDescriptor, params?: Record<string, string>): LocationDescriptor;
    function resolveLocalizedRoutePath(
        path: string | [string, ...string[]] | LocationDescriptor,
        params?: Record<string, string>
    ): LocationDescriptor | string[] {
        let result: string | string[];
        switch (typeof path) {
            case "undefined":
                return undefined;
            case "object":
                if (path == null) {
                    return null;
                }
                if (Array.isArray(path)) {
                    if (path.length === 0) {
                        throw new Error("Empty route not allowed");
                    }
                    result = path.map((route) => {
                        if (route in routeNames) {
                            return formatMessage(routeNames[route]);
                        }
                        return route;
                    });
                    break;
                }
                return {
                    ...path,
                    pathname: resolveLocalizedRoutePath(path.pathname as RouteId, params),
                };
            default:
                if (path in routeNames) {
                    result = formatMessage(routeNames[path]);
                } else {
                    return path;
                }
        }
        return Array.isArray(result)
            ? result.map((route) => insertParamsToRoute(route, { lang: language, ...params }))
            : insertParamsToRoute(result, { lang: language, ...params });
    }

    return useCallback(resolveLocalizedRoutePath, [resolveLocalizedRoutePath]);
};
