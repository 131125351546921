import { useEffect } from "react";

import { Route, RouteProps } from "react-router-dom";

import { useAppDispatch } from "hooks/app";
import { enterPrivateRoute, exitPrivateRoute } from "app/store/route-slice";
import ReactFrozen from "components/shared/ReactFrozen";
import { useAuthentication } from "hooks/auth";

const PrivateRoute: React.FC<RouteProps> = (props) => {
    const dispatch = useAppDispatch();
    const { isAuthenticated } = useAuthentication();

    useEffect(() => {
        dispatch(enterPrivateRoute());
        return () => {
            dispatch(exitPrivateRoute());
        };
    }, [dispatch]);

    return (
        <ReactFrozen frozen={!isAuthenticated}>
            <Route {...props} />
        </ReactFrozen>
    );
};
export default PrivateRoute;
