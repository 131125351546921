import { useEffect } from "react";

import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import Logo from "components/shared/svg/Logo";
import ROUTE from "routes";
import LocalizedNavLink from "components/l10n/LocalizedNavLink";
import { usePathStartsWith } from "hooks/browser";
import { useAuthentication } from "hooks/auth";

import MoreMenu from "./MoreMenu";
import MobileMenu from "./MobileMenu";

const Navigation = () => {
    const location = useLocation();
    const pathStartsWith = usePathStartsWith();
    const { isAuthenticated, isAuthenticating } = useAuthentication();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.replace(/^#/, ""));
            if (element) {
                element.scrollIntoView();
                return;
            }
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);

    if (pathStartsWith(ROUTE.DASHBOARD)) {
        return null;
    }

    return (
        <nav className="fixed z-50 left-0 top-0 flex items-center justify-center px-4 w-full h-16 bg-white shadow-md">
            <div className="flex gap-4 items-center w-full max-w-9xl">
                <div className="flex w-80">
                    <LocalizedNavLink to={ROUTE.LANDING}>
                        <div className="h-10">
                            <Logo />
                        </div>
                    </LocalizedNavLink>
                </div>
                <div className="hidden flex-1 gap-6 items-center justify-center whitespace-nowrap lg:flex">
                    <LocalizedNavLink to={ROUTE.MEMBERS_BENEFITS} activeClassName="underline">
                        <p className="hover:text-alpha text-gray-800 font-bold cursor-pointer transition duration-500 ease-in-out">
                            <FormattedMessage defaultMessage="Members Benefits" />
                        </p>
                    </LocalizedNavLink>
                    <LocalizedNavLink to={ROUTE.LOST_PETS} activeClassName="underline">
                        <p className="hover:text-alpha text-gray-800 font-bold cursor-pointer transition duration-500 ease-in-out">
                            <FormattedMessage defaultMessage="Lost Pets" />
                        </p>
                    </LocalizedNavLink>
                    <LocalizedNavLink to={ROUTE.SHOP} activeClassName="underline">
                        <p className="hover:text-alpha text-gray-800 font-bold cursor-pointer transition duration-500 ease-in-out">
                            <FormattedMessage defaultMessage="Shop" />
                        </p>
                    </LocalizedNavLink>
                    <MoreMenu />
                </div>
                <div className="item-center hidden gap-6 justify-end w-80 lg:flex">
                    <LocalizedNavLink
                        to={isAuthenticated ? ROUTE.DASHBOARD : ROUTE.SIGN_IN}
                        className={clsx({ hidden: isAuthenticating })}
                    >
                        <button
                            type="button"
                            className="px-8 py-2 text-white font-bold bg-alpha hover:bg-alpha-light rounded-full shadow-md transition duration-500 ease-in-out"
                        >
                            {isAuthenticated && <FormattedMessage defaultMessage="Back to Dashboard" />}
                            {!isAuthenticated && <FormattedMessage defaultMessage="Sign In" />}
                        </button>
                    </LocalizedNavLink>
                </div>
                <div className="flex flex-1 justify-end px-4 w-full lg:hidden">
                    <MobileMenu />
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
