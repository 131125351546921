import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";

import LocalizedNavLink from "components/l10n/LocalizedNavLink";
import ROUTES from "routes";
import { makeStyles } from "theme";
import { useHistoryActions } from "hooks/browser";
import SuspenseOverlay from "components/shared/SuspenseOverlay";
import { useAppDispatch } from "hooks/app";
import { LOGOUT } from "api/graphql";
import { userSignOut } from "app/store/user-reducer";
import MetaTag from "components/metatag/MetaTag";

interface LogoutProps {
    showLogoutDialog?: boolean;
}

const useStyles = makeStyles()({
    backdrop: {
        filter: "blur(3px)",
    },

    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const Logout: React.FC<LogoutProps> = ({ showLogoutDialog = true }) => {
    const { classes } = useStyles();
    const [logout] = useMutation(LOGOUT);
    const { replace: historyReplace } = useHistoryActions();
    const dispatch = useAppDispatch();
    const currentLocation = useLocation<{ doLogout?: boolean }>();
    const [secondsUntilRedirect, setSecondsUntilRedirect] = useState(10);
    const { formatMessage } = useIntl();

    useEffect(() => {
        const { state } = currentLocation;
        if (state?.doLogout) {
            logout().then(() => dispatch(userSignOut()));
        }
    }, [currentLocation, dispatch, logout]);

    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsUntilRedirect((seconds) => {
                if (seconds - 1 <= 0) {
                    clearInterval(interval);
                }
                return seconds - 1;
            });
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (secondsUntilRedirect <= 0) {
            setTimeout(() => historyReplace(ROUTES.LANDING), 0);
        }
    }, [historyReplace, secondsUntilRedirect]);

    return (
        <>
            <Dialog
                open={showLogoutDialog}
                onClose={() => historyReplace(ROUTES.LANDING)}
                disableEscapeKeyDown
                fullScreen={false}
                hideBackdrop
                BackdropProps={{
                    className: classes.backdrop,
                }}
                PaperProps={{
                    className: classes.content,
                }}
                TransitionComponent={Fade}
                transitionDuration={{
                    enter: 1000,
                    appear: 1500,
                    exit: 500,
                }}
            >
                <MetaTag title={formatMessage({ defaultMessage: "Signed Out" })} />
                <main className="flex flex-col justify-between p-3 py-6">
                    <DialogTitle>
                        <div className="flex flex-col px-6 py-2 h-40 text-center text-xl leading-loose">
                            <h1 className="font-museo_sans_300 font-bold">
                                <FormattedMessage defaultMessage="You are now signed out." />
                            </h1>
                            <h2 className="max-w-xs font-museo_sans_300 font-bold">
                                <FormattedMessage defaultMessage="Thank you for visiting us today and have a great day." />
                            </h2>
                        </div>
                    </DialogTitle>

                    <DialogContent>
                        <div className="flex flex-col justify-between mt-12 px-4 md:px-7">
                            <LocalizedNavLink to={ROUTES.SIGN_IN} replace className="my-2">
                                <Button fullWidth size="large" color="primary" variant="contained">
                                    <Typography variant="h6" fontWeight="bold">
                                        <FormattedMessage defaultMessage="Sign back in" />
                                    </Typography>
                                </Button>
                            </LocalizedNavLink>
                            <LocalizedNavLink to={ROUTES.LANDING} replace>
                                <Button fullWidth size="large">
                                    <Typography variant="h6" fontWeight="bold">
                                        <FormattedMessage defaultMessage="Go home" />({secondsUntilRedirect})
                                    </Typography>
                                </Button>
                            </LocalizedNavLink>
                        </div>
                    </DialogContent>
                </main>
            </Dialog>
            <SuspenseOverlay hideProgressOverlay />
        </>
    );
};

export default Logout;
