import { useState, Dispatch, SetStateAction, useEffect } from "react";

import appDebug from "utilities/logging";

// Partially taken from https://usehooks.com/useLocalStorage/
// further refined from https://gist.github.com/gragland/2970ae543df237a07be1dbbf810f23fe

const logger = appDebug.extend("withWebStorage");

/**
 * withWebStorage wraps any given web storage to provide a storage hook
 * @param storage Web storage (localstorage, sessionstorage, etc)
 * @returns react hook function
 */
const withWebStorage = (storage: Storage) =>
    /**
     * a custom react hook for manipulating a web storage object
     * @param key The key used to identify the item in storage
     * @param initialValue An optional initial value if nothing was found with key in storage
     * @returns The custom hook
     */
    function useWebStorage<T>(key: string, initialValue: T = null): readonly [T, Dispatch<SetStateAction<T>>] {
        // State to store our value
        // Pass initial state function to useState so logic is only executed once
        const [storedValue, setStoredValue] = useState<T>(() => {
            try {
                // Get from local storage by key
                const item = storage.getItem(key);
                // Parse stored json or if none return initialValue
                return item ? JSON.parse(item) : initialValue;
            } catch (error) {
                // If error also return initialValues

                logger(error);
                return initialValue;
            }
        });

        useEffect(() => {
            try {
                if (storedValue == null) {
                    // No storing 'null' or undefined in localstorage
                    storage.removeItem(key);
                } else {
                    // Save to local storage
                    storage.setItem(key, JSON.stringify(storedValue));
                }
            } catch (error) {
                // TODO: A more advanced implementation would handle the error case

                logger(error);
            }
        }, [key, storedValue]);

        return [storedValue, setStoredValue];
    };

export const useLocalStorage = withWebStorage(window.localStorage);
export const useSessionStorage = withWebStorage(window.sessionStorage);
