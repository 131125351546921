/* eslint-disable arrow-body-style */
import { useState, useEffect, useCallback, memo } from "react";

import { Backdrop } from "@mui/material";
import Stop from "@mui/icons-material/Stop";
import Play from "@mui/icons-material/PlayArrowRounded";

import appDebug from "utilities/logging";

import { IChatbotWidgetProps } from "../type-utils";

interface AiChatbotRecordingWidgetProps extends IChatbotWidgetProps {
    // duration in ms (defaults to 5000 ms)
    recordDuration?: number;
    recordings: Blob[];
}

const logger = appDebug.extend("AiChatbotRecordingWidget");

const getMediaRecorder = (): Promise<MediaRecorder> =>
    navigator.mediaDevices
        .getUserMedia({
            audio: {
                sampleRate: 16000,
                sampleSize: 16,
                channelCount: 1,
                echoCancellation: true,
            },
            video: false,
        })
        .then((stream) => {
            // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-lex-runtime-v2/modules/recognizeutterancerequest.html#requestcontenttype
            // audio/lpcm; sample-rate=8000; sample-size-bits=16; channel-count=1; is-big-endian=false
            return new MediaRecorder(stream);
        })
        .catch((error) => {
            logger(error);
            return null;
        });

const AiChatbotRecordingWidget: React.FC<AiChatbotRecordingWidgetProps> = memo(({ recordDuration = 5000 }) => {
    const [isBackdropShown, setIsBackdropShown] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isRecordingSaved, setIsRecordingSaved] = useState(false);
    const [audioUrl, setAudioUrl] = useState<string>(null);
    // const audioContext = new AudioContext({ latencyHint: "interactive" });

    const handleStopRecording = useCallback(() => {
        if (isRecording) {
            setIsBackdropShown(false);
            setIsRecording(mediaRecorder?.state !== "inactive");
        }
    }, [isRecording, mediaRecorder]);

    const handleStartRecording = useCallback(() => {
        if (!isRecording) {
            logger(`Media recording state: ${mediaRecorder.state}`);
            setIsBackdropShown(true);
            setIsRecording(mediaRecorder?.state === "recording");
        }
    }, [isRecording, mediaRecorder]);

    const handleSaveRecording = useCallback((recordingBlob: Blob) => {
        const copyBlob = recordingBlob.slice(0, recordingBlob.size, recordingBlob.type);
        // blob.data.arrayBuffer().then(buffer => onAudioAvailable(buffer));
        logger(`Audio is available! ${copyBlob.type}`);
        setAudioUrl(URL.createObjectURL(copyBlob));
        setIsRecordingSaved(true);
    }, []);

    useEffect(() => {
        getMediaRecorder().then((mr) => setMediaRecorder(mr));
    }, []);

    useEffect(() => {
        if (!isRecording && mediaRecorder !== null) {
            mediaRecorder.onstart = handleStartRecording;
            mediaRecorder.onstop = handleStopRecording;
            mediaRecorder.ondataavailable = (blob: BlobEvent) => {
                mediaRecorder.stop();
                handleSaveRecording(blob.data);
            };
            mediaRecorder.start(recordDuration);
        }
    }, [mediaRecorder, isRecording, handleSaveRecording, handleStopRecording, handleStartRecording, recordDuration]);

    return isRecordingSaved ? (
        <div>
            <a href={audioUrl}>
                <button type="button">
                    <Play />
                </button>
                Click me
            </a>
        </div>
    ) : (
        <div className="container">
            <Backdrop open={isBackdropShown}>
                <button type="button" className="p-2 rounded-full shadow" onClick={handleStopRecording}>
                    <Stop />
                </button>
            </Backdrop>
        </div>
    );
});

export default AiChatbotRecordingWidget;
