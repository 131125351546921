import { memo, useCallback, useMemo, useState } from "react";

import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";

import { makeStyles } from "theme";
import FirebaseSignInForm from "components/shared/forms/FirebaseSignInForm";

interface AuthModalProps {
    authComponent?: React.ReactElement;
    onAuthSuccess?: () => void;
}

const useStyles = makeStyles()((_theme) => ({
    root: {},

    backdrop: {
        backgroundColor: "rgba(255, 255, 255, .5)",
    },

    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const AuthModal: React.FC<AuthModalProps> = memo(({ authComponent, onAuthSuccess }) => {
    const { classes } = useStyles();
    const [isOpen, setIsOpen] = useState(true);

    const handleAuthSuccess = useCallback(() => {
        setIsOpen(false);
        onAuthSuccess?.();
    }, [onAuthSuccess]);

    const authChild = useMemo(
        () => authComponent ?? <FirebaseSignInForm onSignInSuccess={handleAuthSuccess} />,
        [authComponent, handleAuthSuccess]
    );

    return (
        <Dialog
            open={isOpen}
            disableEscapeKeyDown
            className={classes.root}
            BackdropProps={{
                className: classes.backdrop,
            }}
            PaperProps={{
                className: classes.content,
            }}
            TransitionComponent={Fade}
            transitionDuration={{
                enter: 1000,
                appear: 1500,
                exit: 500,
            }}
        >
            {authChild}
        </Dialog>
    );
});

export default AuthModal;
