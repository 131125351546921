import { defineMessages, MessageDescriptor } from "react-intl";

const Countries: Record<string, MessageDescriptor> = defineMessages({
    AW: { id: "countries.AW", defaultMessage: "Aruba" },
    AF: { id: "countries.AF", defaultMessage: "Afghanistan" },
    AO: { id: "countries.AO", defaultMessage: "Angola" },
    AI: { id: "countries.AI", defaultMessage: "Anguilla" },
    AX: { id: "countries.AX", defaultMessage: "Åland Islands" },
    AL: { id: "countries.AL", defaultMessage: "Albania" },
    AD: { id: "countries.AD", defaultMessage: "Andorra" },
    AE: { id: "countries.AE", defaultMessage: "United Arab Emirates" },
    AR: { id: "countries.AR", defaultMessage: "Argentina" },
    AM: { id: "countries.AM", defaultMessage: "Armenia" },
    AS: { id: "countries.AS", defaultMessage: "American Samoa" },
    AQ: { id: "countries.AQ", defaultMessage: "Antarctica" },
    TF: { id: "countries.TF", defaultMessage: "French Southern Territories" },
    AG: { id: "countries.AG", defaultMessage: "Antigua and Barbuda" },
    AU: { id: "countries.AU", defaultMessage: "Australia" },
    AT: { id: "countries.AT", defaultMessage: "Austria" },
    AZ: { id: "countries.AZ", defaultMessage: "Azerbaijan" },
    BI: { id: "countries.BI", defaultMessage: "Burundi" },
    BE: { id: "countries.BE", defaultMessage: "Belgium" },
    BJ: { id: "countries.BJ", defaultMessage: "Benin" },
    BQ: { id: "countries.BQ", defaultMessage: "Bonaire, Sint Eustatius and Saba" },
    BF: { id: "countries.BF", defaultMessage: "Burkina Faso" },
    BD: { id: "countries.BD", defaultMessage: "Bangladesh" },
    BG: { id: "countries.BG", defaultMessage: "Bulgaria" },
    BH: { id: "countries.BH", defaultMessage: "Bahrain" },
    BS: { id: "countries.BS", defaultMessage: "Bahamas" },
    BA: { id: "countries.BA", defaultMessage: "Bosnia and Herzegovina" },
    BL: { id: "countries.BL", defaultMessage: "Saint Barthélemy" },
    BY: { id: "countries.BY", defaultMessage: "Belarus" },
    BZ: { id: "countries.BZ", defaultMessage: "Belize" },
    BM: { id: "countries.BM", defaultMessage: "Bermuda" },
    BO: { id: "countries.BO", defaultMessage: "Bolivia, Plurinational State of" },
    BR: { id: "countries.BR", defaultMessage: "Brazil" },
    BB: { id: "countries.BB", defaultMessage: "Barbados" },
    BN: { id: "countries.BN", defaultMessage: "Brunei Darussalam" },
    BT: { id: "countries.BT", defaultMessage: "Bhutan" },
    BV: { id: "countries.BV", defaultMessage: "Bouvet Island" },
    BW: { id: "countries.BW", defaultMessage: "Botswana" },
    CF: { id: "countries.CF", defaultMessage: "Central African Republic" },
    CA: { id: "countries.CA", defaultMessage: "Canada" },
    CC: { id: "countries.CC", defaultMessage: "Cocos (Keeling) Islands" },
    CH: { id: "countries.CH", defaultMessage: "Switzerland" },
    CL: { id: "countries.CL", defaultMessage: "Chile" },
    CN: { id: "countries.CN", defaultMessage: "China" },
    CI: { id: "countries.CI", defaultMessage: "Côte d'Ivoire" },
    CM: { id: "countries.CM", defaultMessage: "Cameroon" },
    CD: { id: "countries.CD", defaultMessage: "Congo, The Democratic Republic of the" },
    CG: { id: "countries.CG", defaultMessage: "Congo" },
    CK: { id: "countries.CK", defaultMessage: "Cook Islands" },
    CO: { id: "countries.CO", defaultMessage: "Colombia" },
    KM: { id: "countries.KM", defaultMessage: "Comoros" },
    CV: { id: "countries.CV", defaultMessage: "Cabo Verde" },
    CR: { id: "countries.CR", defaultMessage: "Costa Rica" },
    CU: { id: "countries.CU", defaultMessage: "Cuba" },
    CW: { id: "countries.CW", defaultMessage: "Curaçao" },
    CX: { id: "countries.CX", defaultMessage: "Christmas Island" },
    KY: { id: "countries.KY", defaultMessage: "Cayman Islands" },
    CY: { id: "countries.CY", defaultMessage: "Cyprus" },
    CZ: { id: "countries.CZ", defaultMessage: "Czechia" },
    DE: { id: "countries.DE", defaultMessage: "Germany" },
    DJ: { id: "countries.DJ", defaultMessage: "Djibouti" },
    DM: { id: "countries.DM", defaultMessage: "Dominica" },
    DK: { id: "countries.DK", defaultMessage: "Denmark" },
    DO: { id: "countries.DO", defaultMessage: "Dominican Republic" },
    DZ: { id: "countries.DZ", defaultMessage: "Algeria" },
    EC: { id: "countries.EC", defaultMessage: "Ecuador" },
    EG: { id: "countries.EG", defaultMessage: "Egypt" },
    ER: { id: "countries.ER", defaultMessage: "Eritrea" },
    EH: { id: "countries.EH", defaultMessage: "Western Sahara" },
    ES: { id: "countries.ES", defaultMessage: "Spain" },
    EE: { id: "countries.EE", defaultMessage: "Estonia" },
    ET: { id: "countries.ET", defaultMessage: "Ethiopia" },
    FI: { id: "countries.FI", defaultMessage: "Finland" },
    FJ: { id: "countries.FJ", defaultMessage: "Fiji" },
    FK: { id: "countries.FK", defaultMessage: "Falkland Islands (Malvinas)" },
    FR: { id: "countries.FR", defaultMessage: "France" },
    FO: { id: "countries.FO", defaultMessage: "Faroe Islands" },
    FM: { id: "countries.FM", defaultMessage: "Micronesia, Federated States of" },
    GA: { id: "countries.GA", defaultMessage: "Gabon" },
    GB: { id: "countries.GB", defaultMessage: "United Kingdom" },
    GE: { id: "countries.GE", defaultMessage: "Georgia" },
    GG: { id: "countries.GG", defaultMessage: "Guernsey" },
    GH: { id: "countries.GH", defaultMessage: "Ghana" },
    GI: { id: "countries.GI", defaultMessage: "Gibraltar" },
    GN: { id: "countries.GN", defaultMessage: "Guinea" },
    GP: { id: "countries.GP", defaultMessage: "Guadeloupe" },
    GM: { id: "countries.GM", defaultMessage: "Gambia" },
    GW: { id: "countries.GW", defaultMessage: "Guinea-Bissau" },
    GQ: { id: "countries.GQ", defaultMessage: "Equatorial Guinea" },
    GR: { id: "countries.GR", defaultMessage: "Greece" },
    GD: { id: "countries.GD", defaultMessage: "Grenada" },
    GL: { id: "countries.GL", defaultMessage: "Greenland" },
    GT: { id: "countries.GT", defaultMessage: "Guatemala" },
    GF: { id: "countries.GF", defaultMessage: "French Guiana" },
    GU: { id: "countries.GU", defaultMessage: "Guam" },
    GY: { id: "countries.GY", defaultMessage: "Guyana" },
    HK: { id: "countries.HK", defaultMessage: "Hong Kong" },
    HM: { id: "countries.HM", defaultMessage: "Heard Island and McDonald Islands" },
    HN: { id: "countries.HN", defaultMessage: "Honduras" },
    HR: { id: "countries.HR", defaultMessage: "Croatia" },
    HT: { id: "countries.HT", defaultMessage: "Haiti" },
    HU: { id: "countries.HU", defaultMessage: "Hungary" },
    ID: { id: "countries.ID", defaultMessage: "Indonesia" },
    IM: { id: "countries.IM", defaultMessage: "Isle of Man" },
    IN: { id: "countries.IN", defaultMessage: "India" },
    IO: { id: "countries.IO", defaultMessage: "British Indian Ocean Territory" },
    IE: { id: "countries.IE", defaultMessage: "Ireland" },
    IR: { id: "countries.IR", defaultMessage: "Iran, Islamic Republic of" },
    IQ: { id: "countries.IQ", defaultMessage: "Iraq" },
    IS: { id: "countries.IS", defaultMessage: "Iceland" },
    IL: { id: "countries.IL", defaultMessage: "Israel" },
    IT: { id: "countries.IT", defaultMessage: "Italy" },
    JM: { id: "countries.JM", defaultMessage: "Jamaica" },
    JE: { id: "countries.JE", defaultMessage: "Jersey" },
    JO: { id: "countries.JO", defaultMessage: "Jordan" },
    JP: { id: "countries.JP", defaultMessage: "Japan" },
    KZ: { id: "countries.KZ", defaultMessage: "Kazakhstan" },
    KE: { id: "countries.KE", defaultMessage: "Kenya" },
    KG: { id: "countries.KG", defaultMessage: "Kyrgyzstan" },
    KH: { id: "countries.KH", defaultMessage: "Cambodia" },
    KI: { id: "countries.KI", defaultMessage: "Kiribati" },
    KN: { id: "countries.KN", defaultMessage: "Saint Kitts and Nevis" },
    KR: { id: "countries.KR", defaultMessage: "Korea, Republic of" },
    KW: { id: "countries.KW", defaultMessage: "Kuwait" },
    LA: { id: "countries.LA", defaultMessage: "Lao People's Democratic Republic" },
    LB: { id: "countries.LB", defaultMessage: "Lebanon" },
    LR: { id: "countries.LR", defaultMessage: "Liberia" },
    LY: { id: "countries.LY", defaultMessage: "Libya" },
    LC: { id: "countries.LC", defaultMessage: "Saint Lucia" },
    LI: { id: "countries.LI", defaultMessage: "Liechtenstein" },
    LK: { id: "countries.LK", defaultMessage: "Sri Lanka" },
    LS: { id: "countries.LS", defaultMessage: "Lesotho" },
    LT: { id: "countries.LT", defaultMessage: "Lithuania" },
    LU: { id: "countries.LU", defaultMessage: "Luxembourg" },
    LV: { id: "countries.LV", defaultMessage: "Latvia" },
    MO: { id: "countries.MO", defaultMessage: "Macao" },
    MF: { id: "countries.MF", defaultMessage: "Saint Martin (French part)" },
    MA: { id: "countries.MA", defaultMessage: "Morocco" },
    MC: { id: "countries.MC", defaultMessage: "Monaco" },
    MD: { id: "countries.MD", defaultMessage: "Moldova, Republic of" },
    MG: { id: "countries.MG", defaultMessage: "Madagascar" },
    MV: { id: "countries.MV", defaultMessage: "Maldives" },
    MX: { id: "countries.MX", defaultMessage: "Mexico" },
    MH: { id: "countries.MH", defaultMessage: "Marshall Islands" },
    MK: { id: "countries.MK", defaultMessage: "North Macedonia" },
    ML: { id: "countries.ML", defaultMessage: "Mali" },
    MT: { id: "countries.MT", defaultMessage: "Malta" },
    MM: { id: "countries.MM", defaultMessage: "Myanmar" },
    ME: { id: "countries.ME", defaultMessage: "Montenegro" },
    MN: { id: "countries.MN", defaultMessage: "Mongolia" },
    MP: { id: "countries.MP", defaultMessage: "Northern Mariana Islands" },
    MZ: { id: "countries.MZ", defaultMessage: "Mozambique" },
    MR: { id: "countries.MR", defaultMessage: "Mauritania" },
    MS: { id: "countries.MS", defaultMessage: "Montserrat" },
    MQ: { id: "countries.MQ", defaultMessage: "Martinique" },
    MU: { id: "countries.MU", defaultMessage: "Mauritius" },
    MW: { id: "countries.MW", defaultMessage: "Malawi" },
    MY: { id: "countries.MY", defaultMessage: "Malaysia" },
    YT: { id: "countries.YT", defaultMessage: "Mayotte" },
    NA: { id: "countries.NA", defaultMessage: "Namibia" },
    NC: { id: "countries.NC", defaultMessage: "New Caledonia" },
    NE: { id: "countries.NE", defaultMessage: "Niger" },
    NF: { id: "countries.NF", defaultMessage: "Norfolk Island" },
    NG: { id: "countries.NG", defaultMessage: "Nigeria" },
    NI: { id: "countries.NI", defaultMessage: "Nicaragua" },
    NU: { id: "countries.NU", defaultMessage: "Niue" },
    NL: { id: "countries.NL", defaultMessage: "Netherlands" },
    NO: { id: "countries.NO", defaultMessage: "Norway" },
    NP: { id: "countries.NP", defaultMessage: "Nepal" },
    NR: { id: "countries.NR", defaultMessage: "Nauru" },
    NZ: { id: "countries.NZ", defaultMessage: "New Zealand" },
    OM: { id: "countries.OM", defaultMessage: "Oman" },
    PK: { id: "countries.PK", defaultMessage: "Pakistan" },
    PA: { id: "countries.PA", defaultMessage: "Panama" },
    PN: { id: "countries.PN", defaultMessage: "Pitcairn" },
    PE: { id: "countries.PE", defaultMessage: "Peru" },
    PH: { id: "countries.PH", defaultMessage: "Philippines" },
    PW: { id: "countries.PW", defaultMessage: "Palau" },
    PG: { id: "countries.PG", defaultMessage: "Papua New Guinea" },
    PL: { id: "countries.PL", defaultMessage: "Poland" },
    PR: { id: "countries.PR", defaultMessage: "Puerto Rico" },
    KP: { id: "countries.KP", defaultMessage: "Korea, Democratic People's Republic of" },
    PT: { id: "countries.PT", defaultMessage: "Portugal" },
    PY: { id: "countries.PY", defaultMessage: "Paraguay" },
    PS: { id: "countries.PS", defaultMessage: "Palestine, State of" },
    PF: { id: "countries.PF", defaultMessage: "French Polynesia" },
    QA: { id: "countries.QA", defaultMessage: "Qatar" },
    RE: { id: "countries.RE", defaultMessage: "Réunion" },
    RO: { id: "countries.RO", defaultMessage: "Romania" },
    RU: { id: "countries.RU", defaultMessage: "Russian Federation" },
    RW: { id: "countries.RW", defaultMessage: "Rwanda" },
    SA: { id: "countries.SA", defaultMessage: "Saudi Arabia" },
    SD: { id: "countries.SD", defaultMessage: "Sudan" },
    SN: { id: "countries.SN", defaultMessage: "Senegal" },
    SG: { id: "countries.SG", defaultMessage: "Singapore" },
    GS: { id: "countries.GS", defaultMessage: "South Georgia and the South Sandwich Islands" },
    SH: { id: "countries.SH", defaultMessage: "Saint Helena, Ascension and Tristan da Cunha" },
    SJ: { id: "countries.SJ", defaultMessage: "Svalbard and Jan Mayen" },
    SB: { id: "countries.SB", defaultMessage: "Solomon Islands" },
    SL: { id: "countries.SL", defaultMessage: "Sierra Leone" },
    SV: { id: "countries.SV", defaultMessage: "El Salvador" },
    SM: { id: "countries.SM", defaultMessage: "San Marino" },
    SO: { id: "countries.SO", defaultMessage: "Somalia" },
    PM: { id: "countries.PM", defaultMessage: "Saint Pierre and Miquelon" },
    RS: { id: "countries.RS", defaultMessage: "Serbia" },
    SS: { id: "countries.SS", defaultMessage: "South Sudan" },
    ST: { id: "countries.ST", defaultMessage: "Sao Tome and Principe" },
    SR: { id: "countries.SR", defaultMessage: "Suriname" },
    SK: { id: "countries.SK", defaultMessage: "Slovakia" },
    SI: { id: "countries.SI", defaultMessage: "Slovenia" },
    SE: { id: "countries.SE", defaultMessage: "Sweden" },
    SZ: { id: "countries.SZ", defaultMessage: "Eswatini" },
    SX: { id: "countries.SX", defaultMessage: "Sint Maarten (Dutch part)" },
    SC: { id: "countries.SC", defaultMessage: "Seychelles" },
    SY: { id: "countries.SY", defaultMessage: "Syrian Arab Republic" },
    TC: { id: "countries.TC", defaultMessage: "Turks and Caicos Islands" },
    TD: { id: "countries.TD", defaultMessage: "Chad" },
    TG: { id: "countries.TG", defaultMessage: "Togo" },
    TH: { id: "countries.TH", defaultMessage: "Thailand" },
    TJ: { id: "countries.TJ", defaultMessage: "Tajikistan" },
    TK: { id: "countries.TK", defaultMessage: "Tokelau" },
    TM: { id: "countries.TM", defaultMessage: "Turkmenistan" },
    TL: { id: "countries.TL", defaultMessage: "Timor-Leste" },
    TO: { id: "countries.TO", defaultMessage: "Tonga" },
    TT: { id: "countries.TT", defaultMessage: "Trinidad and Tobago" },
    TN: { id: "countries.TN", defaultMessage: "Tunisia" },
    TR: { id: "countries.TR", defaultMessage: "Turkey" },
    TV: { id: "countries.TV", defaultMessage: "Tuvalu" },
    TW: { id: "countries.TW", defaultMessage: "Taiwan, Province of China" },
    TZ: { id: "countries.TZ", defaultMessage: "Tanzania, United Republic of" },
    UG: { id: "countries.UG", defaultMessage: "Uganda" },
    UA: { id: "countries.UA", defaultMessage: "Ukraine" },
    UM: { id: "countries.UM", defaultMessage: "United States Minor Outlying Islands" },
    UY: { id: "countries.UY", defaultMessage: "Uruguay" },
    US: { id: "countries.US", defaultMessage: "United States" },
    UZ: { id: "countries.UZ", defaultMessage: "Uzbekistan" },
    VA: { id: "countries.VA", defaultMessage: "Holy See (Vatican City State)" },
    VC: { id: "countries.VC", defaultMessage: "Saint Vincent and the Grenadines" },
    VE: { id: "countries.VE", defaultMessage: "Venezuela, Bolivarian Republic of" },
    VG: { id: "countries.VG", defaultMessage: "Virgin Islands, British" },
    VI: { id: "countries.VI", defaultMessage: "Virgin Islands, U.S." },
    VN: { id: "countries.VN", defaultMessage: "Viet Nam" },
    VU: { id: "countries.VU", defaultMessage: "Vanuatu" },
    WF: { id: "countries.WF", defaultMessage: "Wallis and Futuna" },
    WS: { id: "countries.WS", defaultMessage: "Samoa" },
    YE: { id: "countries.YE", defaultMessage: "Yemen" },
    ZA: { id: "countries.ZA", defaultMessage: "South Africa" },
    ZM: { id: "countries.ZM", defaultMessage: "Zambia" },
    ZW: { id: "countries.ZW", defaultMessage: "Zimbabwe" },
});

export default Countries;
