import { AWSLexService } from "providers/aws-lex";

import { IActionProvider, IChatbotState, IMessageParser } from "./type-utils";

/**
 * The MessageParser class decides what we do with the messages
 * typed into the chatbot
 */
class AiChatBotMessageParser implements IMessageParser {
    private readonly chatbotService: AWSLexService;

    constructor(private readonly actionProvider: IActionProvider, state: IChatbotState) {
        this.chatbotService = state?.chatbotService as AWSLexService;
    }

    parse(message: string) {
        this.chatbotService.respondToText(message).then((messages) => {
            this.actionProvider.handleAWSLexResponseMessage(messages);
        });
    }
}

export default AiChatBotMessageParser;
